import React, { useState } from 'react'
import Notification from '../../Notification'
import Breadcrumbs from '../../../controllers/breadCrumbs';
import { GenerateSlugName } from '../../../controllers/GenerateSlugName';
import { Check, ChevronDown, Edit, PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { Autocomplete, TextField } from '@mui/material';
import TextEditor from '../../../controllers/TextEditor';
import ImageUploader from '../../../controllers/ImageUploader';
import { SketchPicker } from 'react-color';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'react-bootstrap-icons';
import API from '../../../API';

const AddWebsiteTheme = () => {
    const navigate = useNavigate();
    const [themeData, setThemeData] = useState({
        title: "",
        theme_slug_name: "",
        image: "",
        short_description: "",
        long_description: "",
        key_points: "",
        amount: "",
        languages: [],
        variant: []
    })
    const [warn, setwarn] = useState(false);
    const [err, setErr] = useState(false);
    const [enableSlug, setEnableSlug] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    const languagesOptions = ["hindi", "english", "gujarati"]
    const handleChange = (field, value) => {
        setThemeData({ ...themeData, [field]: value });
    }
    const [slugField, setSlugField] = useState(false);
    const [openState, setOpenState] = useState("");
    const handleSlug = async (slug) => {
        if (slug?.length) {
            setSlugField(true);
            setThemeData({ ...themeData, theme_slug_name: await GenerateSlugName(slug) })
        }
    };
    const handleShortDescription = (value) => {
        setThemeData({ ...themeData, short_description: value })
    }
    const handleLongDescription = (value) => {
        setThemeData({ ...themeData, long_description: value })
    }
    const handleSelectLanguage = (option) => {
        if (option) {
            const pro = themeData?.languages?.length > 0 && themeData?.languages?.find((x) => x === option);
            if (!pro) {
                if (themeData?.languages?.length > 0) {
                    setThemeData({ ...themeData, languages: [...themeData?.languages, option] });
                } else {
                    setThemeData({ ...themeData, languages: [option] })
                }
            }
        }
    }
    const handleRemoveLangauge = (option) => {
        const removeFeatured = themeData?.languages.filter((x) => x !== option);
        setThemeData({ ...themeData, languages: removeFeatured });
    };
    const handleSetImage = (value) => {
        setThemeData({ ...themeData, image: value });
    };
    const handleCloseColor = () => {
        setOpenState("");
    };
    const HandleSubmit = async () => {
        try {
            const ApiCall = await API({ url: `/super/admin/se_site/theme/addTheme`, method: 'post', data: themeData })
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                setIsNotification(true);
                setNotificationMsg("Theme added successfully!");
                setSeverity("success");
                setThemeData({
                    title: "",
                    theme_slug_name: "",
                    image: "",
                    short_description: "",
                    long_description: "",
                    key_points: "",
                    amount: "",
                    languages: [],
                    variant: [{
                        image: '',
                        color: '#fff'
                    }]
                })
                navigate('/site-data/themes')
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
    }
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}
            <div className="h-auto overflow-y-auto pb-[64px]">
                <Breadcrumbs />
                <h1 className="text-xl md:text-2xl font-medium pb-4 text-primary">
                    Add new theme
                </h1>
                <div className="flex justify-evenly p-4">
                    <form
                        method="POST"
                        className="space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto"
                    >
                        <div className="bg-white group shadow  space-y-4 rounded-xl p-[25px]">
                            <div className="w-full">
                                <label
                                    htmlFor="title"
                                    className="flex items-center justify-between"
                                >
                                    <p className="text-[13px]">
                                        Title
                                        <span className="text-red-500 ms-1">
                                            *
                                        </span>
                                    </p>
                                </label>
                                <input
                                    className={
                                        (err && themeData?.title?.length === 0) || warn
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                    }
                                    type="text"
                                    name="title"
                                    value={themeData?.title}
                                    onChange={(e) => {
                                        handleChange(e.target.name, e.target.value);
                                        setwarn(false);
                                    }}
                                    placeholder="e.g. Dazzing"
                                    onBlur={() => handleSlug(themeData?.title)}
                                />
                            </div>
                            {slugField && (
                                <div className="w-full">
                                    <label htmlFor="category_name">
                                        theme Slug name
                                    </label>
                                    <div
                                        className={`flex items-center justify-between w-full border ${err && enableSlug
                                            ? "border-red-500"
                                            : "border-gray-300"
                                            } rounded-[4px]`}
                                    >
                                        <input
                                            className="w-full  focus:outline-none"
                                            type="text"
                                            name="theme_slug_name"
                                            value={themeData?.theme_slug_name}
                                            disabled={!enableSlug}
                                            onChange={(e) =>
                                                handleChange(e.target.name, e.target.value)
                                            }
                                        />
                                        {enableSlug ? (
                                            <Check
                                                onClick={async () => {
                                                    setThemeData({
                                                        ...themeData,
                                                        theme_slug_name:
                                                            await GenerateSlugName(
                                                                themeData?.theme_slug_name
                                                            )
                                                    }
                                                    );
                                                    setEnableSlug(!enableSlug);
                                                    setErr(false);
                                                }}
                                                className="text-green-500 p-1 h-8 w-8"
                                            />
                                        ) : (
                                            <Edit
                                                onClick={() =>
                                                    setEnableSlug(!enableSlug)
                                                }
                                                className="text-blue-500 p-1 h-7 w-7"
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="mb-4">
                                <div className="mb-4">
                                    <label
                                        htmlFor="key_points"
                                        className="flex items-center justify-between"
                                    >
                                        <p className="text-[13px]">
                                            short description
                                        </p>
                                    </label>
                                    <textarea
                                        className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                        type="text"
                                        name="short_description"
                                        value={themeData?.short_description}
                                        onChange={(e) => {
                                            handleChange(e.target.name, e.target.value);
                                        }}
                                        placeholder="e.g. Short description"
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <TextEditor label={'long description'} handleEditorData={handleLongDescription} />
                            </div>
                            <div className="w-full">
                                <label
                                    htmlFor="key_points"
                                    className="flex items-center justify-between"
                                >
                                    <p className="text-[13px]">
                                        Key Points
                                    </p>
                                </label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="text"
                                    name="key_points"
                                    value={themeData?.key_points}
                                    onChange={(e) => {
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                    placeholder="e.g. Theme"
                                />
                            </div>
                            <div className="w-full">
                                <label
                                    htmlFor="amount"
                                    className="flex items-center justify-between"
                                >
                                    <p className="text-[13px]">
                                        Amount
                                        <span className="text-red-500 ms-1">
                                            *
                                        </span>
                                    </p>
                                </label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="number"
                                    name="amount"
                                    value={themeData?.amount}
                                    onChange={(e) => {
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                    placeholder="e.g. 50"
                                />
                            </div>
                            <div className="w-full">
                                <label
                                    className=" text-gray-700 text-sm font-medium mb-1"
                                    htmlFor="trialPeriod"
                                >
                                    Languages
                                </label>
                                <Autocomplete
                                    disableClearable
                                    className='autocomplete'
                                    options={languagesOptions ? languagesOptions : []}
                                    getOptionLabel={(option) => option}
                                    onChange={(e, v) => { handleSelectLanguage(v) }}
                                    renderInput={(option) => (
                                        <TextField
                                            placeholder='Select languages'
                                            onKeyDown={(e) => e.key === 'Enter' ? e.preventDefault() : ''}
                                            className='cursor-pointer  placeholder:'
                                            {...option}
                                        />
                                    )}
                                />
                                <div className='flex flex-wrap mt-2'>
                                    {themeData?.languages?.map((elem, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded'
                                            >
                                                <span>{elem}</span>
                                                <X
                                                    className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                                    onClick={() => handleRemoveLangauge(elem)}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <details
                            open
                            className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]"
                        >
                            <summary className="text-base flex items-center justify-between font-medium text-black">
                                <div>
                                    <span>Media</span>
                                    <span className="text-red-500 ms-1">
                                        *
                                    </span>
                                    <p className="text-xs text-gray-500 font-normal">
                                        Upload captivating image to make your
                                        blog stand out.
                                    </p>
                                </div>
                                <span className="transition group-open:rotate-180">
                                    <ChevronDown className="text-black w-4 h-5" />
                                </span>
                            </summary>
                            <ImageUploader
                                alreadyImage={themeData?.image}
                                handleSetImage={handleSetImage}
                                folder="blog"
                                format="image"
                            />
                        </details>
                        <details
                            className="bg-white cursor-pointer group shadow space-y-4 rounded-xl p-[25px]"
                        >
                            <summary className="text-base flex items-center justify-between font-medium text-black">
                                <div>
                                    <span>Variant</span>
                                    <p className="text-xs text-gray-500 font-normal">
                                        Upload captivating image for variant.
                                    </p>
                                </div>
                                <span className="transition group-open:rotate-180">
                                    <ChevronDown className="text-black w-4 h-5" />
                                </span>
                            </summary>
                            <div className='divide-y'>
                                {themeData?.variant?.length > 0 &&
                                    themeData.variant.map((elem, index) => (
                                        <div key={index} className='flex py-4 gap-4'>
                                            <div>
                                                <label>Theme color</label>
                                                <ImageUploader
                                                    alreadyImage={elem.image}
                                                    handleSetImage={(image) => {
                                                        const updatedVariants = [...themeData.variant];
                                                        updatedVariants[index].image = image;
                                                        setThemeData({ ...themeData, variant: updatedVariants });
                                                    }}
                                                    folder="blog"
                                                    format="image"
                                                />
                                            </div>
                                            <div className="min-w-[300px] w-auto relative">
                                                <label>Theme color</label>
                                                <div
                                                    onClick={() => setOpenState(`border_color${index}`)}
                                                    className="flex cursor-pointer items-center border main-border-color p-1.5 mb-2 rounded space-x-2"
                                                >
                                                    <div
                                                        className="w-[30px] shadow h-[30px] rounded cursor-pointer"
                                                        style={{ backgroundColor: elem.color }}
                                                    ></div>
                                                    <span className="text-sm font-medium table-text">
                                                        {elem.color}
                                                    </span>
                                                </div>
                                                {openState === `border_color${index}` && (
                                                    <SketchPicker
                                                        color={elem.color}
                                                        onChange={(color) => {
                                                            const updatedVariants = [...themeData.variant];
                                                            updatedVariants[index].color = color.hex;
                                                            setThemeData({ ...themeData, variant: updatedVariants });
                                                            setOpenState("");
                                                        }}
                                                        onMouseLeave={() => handleCloseColor()}
                                                        className="absolute z-10"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='text-center w-full'>
                                <button
                                    type='button'
                                    className='bulk-btn inline-flex items-center'
                                    onClick={() => setThemeData({ ...themeData, variant: [...themeData.variant, { image: '', color: '#fff' }] })}
                                >
                                    <PlusCircle className="w-4 h-4 me-2" /> Add {themeData?.variant?.length > 0 && "More"} Variant
                                </button>
                            </div>
                        </details>
                        <div className="flex items-center justify-between w-full">
                            <Link
                                to={`/site-data/themes`}
                                className="w-fit flex text-link font-medium hover:underline text-sm items-center "
                            >
                                <ArrowLeft className="w-3 mr-1" />
                                Go to theme
                            </Link>
                            <button
                                className={
                                    themeData?.amount?.length && themeData?.title && themeData?.image?.length
                                        ? "inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                        : "font-medium text-sm text-white opacity-40 btn cursor-not-allowed"
                                }
                                type="button"
                                onClick={HandleSubmit}
                                disabled={
                                    themeData?.title === "" ||
                                    !themeData?.image?.length ||
                                    themeData?.amount === ""
                                }
                            >
                                Add New Theme
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )
}

export default AddWebsiteTheme