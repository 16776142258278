import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, Skeleton, TextField, Popover } from '@mui/material';
import API from '../../../API';
import ReactQuill from 'react-quill';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import { ArrowLeft, ChevronDown, X } from 'feather-icons-react/build/IconComponents';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import NoDataFound from '../../Main/NoDataFound';
const UpdateDummyPortfolioCategories = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [nodata, setNodata] = useState(false)
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [SelectedPortfolioParentCategory, setSelectedPortfolioParentCategory] = useState();
  const [PortfolioCatData, setPortfolioCatData] = useState({
    portfoliocategory_name: '',
    meta_tag_title: '',
    meta_tag_description: '',
    keyword: '',
  });
  const [description, setDescription] = useState()
  const [portfolioCatId, setportfolioCatId] = useState()
  const [formSpinner, setFormSpinner] = useState();
  const GetBlogCategoryById = async () => {
    setFormSpinner(true)
    try {
      const ApiCall = await API({ url: `/super/admin/dummydata/dummyportfoliocategory/getDummyPortfolioCategory/${params?.dummy_portfolio_categories_slug_name}`, method: 'get' })
      setportfolioCatId(ApiCall?.data?.data);
      setSelectedPortfolioParentCategory(ApiCall?.data?.data?.parent_portfoliocategory?._id)
      setPortfolioCatData({
        portfoliocategory_name: ApiCall?.data?.data?.portfoliocategory_name ? `${ApiCall?.data?.data?.portfoliocategory_name}` : '',
        meta_tag_title: ApiCall?.data?.data?.meta_tag_title ? `${ApiCall?.data?.data?.meta_tag_title}` : '',
        meta_tag_description: ApiCall?.data?.data.meta_tag_description ? `${ApiCall?.data?.data?.meta_tag_description}` : '',
        keyword: ApiCall?.data?.data?.keyword ? `${ApiCall?.data?.data?.keyword}` : '',
      })
      setDescription(ApiCall?.data?.data?.description)
      setFormSpinner(false)
    } catch (error) {
      setNodata(true)
      console.log(error)
    }
  }

  const {
    portfoliocategory_name,
    meta_tag_title,
    meta_tag_description,
    keyword,
  } = PortfolioCatData;
  let name, value;
  const HandleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    setPortfolioCatData({ ...PortfolioCatData, [name]: value });
  };

  // get Parents Category
  const [ParentCategory, setParentCategory] = useState([]);
  const GetParentCategory = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyportfoliocategory/getDummyPortfolioCategorys`, method: 'get' }); 
      if (data.status === 200 || data.status === 304) {
        setParentCategory(data?.data?.data?.filter(x => x._id !== PortfolioCatData?._id))
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  useEffect(() => {
    GetParentCategory();
    GetBlogCategoryById();
  }, []);



  const [err, setErr] = useState(false)
  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (portfoliocategory_name === '') {
      setErr(true)
    } else {
      try {
        const data = await API({ url: `/super/admin/dummydata/dummyportfoliocategory/updateDummyPortfolioCategory/${portfolioCatId._id}`, method: 'put', data: {
          ...PortfolioCatData, description: description,
          ...(SelectedPortfolioParentCategory
            ? { parent_portfoliocategory: SelectedPortfolioParentCategory }
            : ''),
        }});
        if (data.status === 200 || data.status === 304) {
          setTimeout(() => {
            navigate('/dummy-data/dummy-portfolio-category');
          }, 500);
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
        //Quil To HTML code and Functions
        const quillRef = useRef(null);
        const handleQuillChange = (value) => {
          setDescription(value);
        };
      
      const [html, setHtml] = useState({htmlPP:false,code:''});
      const {htmlPP,code}=html
        const handleViewHtml = (e) => {
          e.preventDefault();
          const quill = quillRef.current.getEditor();
          const htmlContent = quill.root.innerHTML
          const formattedHtml = formatCode(htmlContent);
          setHtml({htmlPP:true,code:formattedHtml}); // Log or use the HTML content as needed
        };
      
        const saveHTML=()=>{
          setHtml({...html,htmlPP:false})
          setDescription(code)
        }
  return (
    nodata ? <NoDataFound /> :
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <Breadcrumbs />
        <h1 className='text-xl md:text-2xl font-medium pb-4 text-primary '>
          Update dummy blog category - {portfolioCatId?.blogcategory_name}
        </h1>
        <div className='flex justify-evenly p-4'>
          <form
            method='POST'
            className='space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
          >
            {
              formSpinner ?
                <div className='space-y-4'>
                  <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                    <div className='flex items-center space-x-4 w-full'>
                      <div className='w-full'>
                        <Skeleton className='w-[200px] mb-1' variant='rounded' />
                        <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                      </div>
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[200px]' variant='rounded' />
                    </div>
                  </div>
                  <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                    <div className='w-full'>
                      <Skeleton className='w-[100px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[100px]' variant='rounded' />
                    </div>
                  </div>
                </div> :
                <>
                  <div className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'>
                    <div className='mb-4'>
                      <label
                        htmlFor='blogcategory_name'
                      >
                        Name
                      </label>
                      <input
                        className={portfoliocategory_name === '' && err ? 'w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none' : 'w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'}
                        type='text'
                        id='portfoliocategory_name'
                        name='portfoliocategory_name'
                        value={portfoliocategory_name}
                        onChange={HandleInput}
                        placeholder='e.g. Tech'
                      />
                      {
                        portfoliocategory_name === '' && err ? <p className='text-red-500 text-xs'>Please enter portfolio category name</p> : <></>
                      }
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='portfolio Category'>
                        Select Portfolio Category
                      </label>
                      <Autocomplete
                        className='autocomplete'
                        options={ParentCategory ? ParentCategory : []}
                        getOptionLabel={(parent) => parent.portfoliocategory_name}
                        onChange={(e, v) => {
                          v ? setSelectedPortfolioParentCategory(v._id) : setSelectedPortfolioParentCategory('');
                        }}
                        renderInput={(portfoliosCategory) => (
                          <TextField
                            placeholder={portfolioCatId?.parent_portfoliocategory?.portfoliocategory_name ? portfolioCatId?.parent_portfoliocategory?.portfoliocategory_name : "Select portfolio category"}
                            Value={portfoliosCategory}
                            className='cursor-pointer'
                            {...portfoliosCategory}
                          />
                        )}
                      />
                    </div>
                    <div className='mb-4'>
              <div className='flex justify-between items-center'>
                <label htmlFor='description'>
                portfolio category Description
                </label>
                <section
                    className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                    onClick={handleViewHtml}
                  >
                    View HTML
                  </section>
               </div>
                <ReactQuill
                  ref={quillRef}
                  modules={module}
                  theme='snow'
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={handleQuillChange}
                />
                <p className='text-right w-full text-xs mt-0 text-gray-400'>
                  of 280 words allowed
                </p>
                    </div>

                  </div>
                  <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                    <summary
                      className='text-base flex items-center justify-between font-medium text-black'
                    >
                      <div>
                        Search engine listing
                        <p className='text-xs text-gray-500 font-normal'>Add a title and description to see how this product might appear in a search engine listing</p>
                      </div>
                      <span className="transition group-open:rotate-180">
                        <ChevronDown className="text-black w-4 h-5" />
                      </span>
                    </summary>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label
                          htmlFor='Meta Tag Title'
                          className='flex items-center justify-between'
                        >
                          Meta Title
                        </label>
                        
                      </div>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='text'
                        id='Meta Tag Title'
                        name='meta_tag_title'
                        value={meta_tag_title}
                        onChange={HandleInput}
                        placeholder='Enter meta title'
                      />
                    </div>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label
                          htmlFor='Meta Tag description'
                          className='flex items-center justify-between'
                        >
                          Meta Description
                        </label>
                        
                      </div>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='text'
                        id='Meta Tag description'
                        name='meta_tag_description'
                        value={meta_tag_description}
                        onChange={HandleInput}
                        placeholder='Enter meta description'
                      />
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='keyword'>
                        Meta Keyword
                      </label>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='text'
                        id='keyword'
                        name='keyword'
                        value={keyword}
                        onChange={HandleInput}
                        placeholder='e.g. Cloth fashion'
                      />
                    </div>
                  </details>
                  <div className='flex items-center justify-between w-full'>
                    <Link to={`/dummy-data/dummy-portfolio-category`} className='w-fit flex text-link font-medium hover:underline text-sm items-center '><ArrowLeft className='w-3 mr-1' />Go to blog categories</Link>
                    <button
                      className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                      type='submit'
                      onClick={HandleSubmit}
                    >
                      Update Portfolio Category
                    </button>
                  </div>
                </>
            }
          </form>
        </div>
      </div>  
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
            // onClose={() => {
            //   setHtmlPP(false);
            // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] h-96  mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X onClick={()=>{
                    if(window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")){
                      setHtml({...html,htmlPP:false})
                    }
                  }}/>
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea 
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({...html,code:e.target.value})}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              </div>
              <button
                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

export default UpdateDummyPortfolioCategories;
