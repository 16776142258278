import { Pagination } from '@mui/material';
import {
  Check,
  Eye,
  Filter,
  Loader,
} from "feather-icons-react/build/IconComponents";
import X from "feather-icons-react/build/IconComponents/X";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import API from "../../API";
import { GetTenants, handleGetDeleteRequest } from "../../Redux/Action/tenant.action";
import Breadcrumbs from "../../controllers/breadCrumbs";
import TenentDeleteRequest from './TenentDeleteRequest';
const Tenants = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newTenant, setNewtenant] = useState(false);
  const [filterDD, setFilterDD] = useState(false);
  const [page, setPage] = useState(1);
  const verification_status = ["pending", "verified", "not_verified"];
  const [filter, setFilter] = useState({
    v_status: "",
    store_status: "",
    search: "",
  });
  const { v_status, store_status, search } = filter;
  const token = localStorage.getItem("token");
  // Get Tenants
  // const GetTenants = async () => {
  //   await fetch(
  //     `${process.env.REACT_APP_BACKEND_URL}/super/admin/tenant/getTenants?page=${page}&limit=${limit}&search=${search}&verification=${v_status}&status=${store_status}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "content-type": "application/json",
  //         token,
  //       },
  //     }
  //   ).then(async (res) => {
  //     if (res.status === 200 || res.status === 304) {
  //       let data = await res.json();
  //       setTenantInfo(data);
  //     }
  //   });
  // };
  // useEffect(() => {
  //   GetTenants();
  // }, [v_status, store_status, search, page]);
useEffect(() => {
  dispatch(handleGetDeleteRequest())
},[])
  useEffect(() => {
    dispatch(GetTenants(page, 10, v_status, store_status, search));
  }, [page, v_status, store_status, search]);
  const { allTenants,deleteStore } = useSelector((state) => state.tenantReducer)

  // UpdateTenant
  const UpdateTenant = async (value, id, verification_s, mail_sent) => {
    let msg;
    if (verification_s === undefined) {
      if (value === false) {
        msg = "Are You Sure To Disable This Tenant?";
      } else {
        msg = "Are You Sure To Enable This Tenant?";
      }
    } else {
      if (verification_s === "verified") {
        msg = "Are You Sure To verified This Tenant?";
      } else {
        msg = "Are You Sure To Cancel This Tenant?";
      }
    }
    let body;
    if (verification_s === undefined) {
      body = { status: value };
    } else {
      body = {
        verify_status: verification_s,
        status: value
      };
    }
    if (window.confirm(msg)) {
      try {
        const apicall = await API({ url: `/super/admin/tenant/updateTenant/${id}`, method: 'put', data: body })
        if (apicall?.data?.data) {
          toast.success("Tenant Updated Successfully");
          if (apicall?.data?.data?.verification_mail_sent === false && apicall?.data?.data?.status && apicall?.data?.data?.verify_status === 'verified') {
            await SendMail(id, apicall?.data?.data?.verification_mail_sent);
          }
        }
        if (verification_s === "not_verified") {
          const apicall = await API({ url: `/super/admin/tenant/sendRejectionMail/${id}`, method: 'post' })
        }
        dispatch(GetTenants());
      } catch (error) {
        toast.error(error?.response?.data?.error);
      }
    }


  };
  // Add Tenant
  const [err, seterr] = useState(false);
  const [success, setsuccess] = useState(false);

  const [isVarified, setIsVarified] = useState(false);
  const [RegData, setRegData] = useState({
    email: "",
    business_name: "",
    business_category: "",
  });
  const { email, business_name, business_category } = RegData;

  //Show And Hide Password
  const [message, setmessage] = useState("");
  // get All Entered  Input Data In our UseState

  const HandleInput = (e) => {
    setRegData({ ...RegData, [e.target.name]: e.target.value });
  };

  // Api Call for Register
  const HandleRegister = async () => {
    await API(
      {
        url: `/super/admin/tenant/addTenant`,
        method: "post",
        data: {
          email,
          business_category,
          store_id: storeName,
          business_name,
        },
      }
    ).then((res) => {
      if (res.status === 400 || res.status === 500) {
        toast.error("Something Went Wrong !  Check The Data You Entered ");
      } else {
        setNewtenant(false);
        navigate("/tenants");
      }
    });
  };

  const [storeName, setStoreName] = useState("");

  const Handleblur = () => {
    let store;
    if (!storeName) {
      store = business_name;
    } else {
      store = storeName;
    }
    const uniqueStore = store.toLowerCase().replaceAll(" ", "_");
    setStoreName(uniqueStore);
  };

  //   verify Store

  const storeapidata = async () => {
    try {
      if (storeName) {
        const storeApiData = await API({
          url: `/super/admin/tenant/storeIdVarify/${storeName}`,
          method: "GET"
        });
        if (storeApiData.status === 403) {
          seterr(true);
          setsuccess(false);
          setIsVarified(false);
        } else if (storeApiData.status === 200 || storeApiData.status === 304) {
          setsuccess(true);
          seterr(false);
          setIsVarified(true);
        } else {
          toast.error("something went Wrong!", { position: "top-right" });
        }
      }
    } catch (error) {
      toast.error("something went wrong!", {
        position: "top-right",
      });
    }
  };
  const [mailLoader, setMailLoader] = useState()
  const [mailId, setMailId] = useState()
  // verification mail send
  const SendMail = async (id, mail_status) => {
    if (!mail_status) {
      setMailId(id)
      setMailLoader(true)
      try {
        const apicall = await API({ url: `/super/admin/tenant/sendVerificationMail/${id}`, method: 'post' })
        dispatch(GetTenants());
      } catch (error) {
        toast.error(error?.response?.data?.error);
      } finally {
        setMailLoader(false)
        setMailId()
      }
    }
  };
  var startRecord = (allTenants?.currentPage - 1) * 10 + 1;
  var endRecord = Math.min(allTenants?.currentPage * 10, allTenants?.totalRecords);
  const columns = [
    {
      name: 'id',
      width: '60px',
      cell: (row) => <span>{row?.tenant_id || '-'}</span>,
      selector: (row) => row?.tenant_id,
      sortable: true,
    },
    {
      name: 'business name',
      minWidth: '180px',
      cell: (row) => <span>{row?.business_name || '-'}</span>,
      selector: (row) => row?.business_name,
      sortable: true,
    },
    {
      name: 'email',
      minWidth: '200px',
      cell: (row) => <span>{row?.tenant_email || '-'}</span>,
      selector: (row) => row?.tenant_email,
      sortable: true,
    },
    {
      name: 'category',
      cell: (row) => <span>{row?.business_category || '-'}</span>,
      selector: (row) => row?.business_category,
      sortable: true,
      minWidth: '200px'
    },
    {
      name: 'date',
      cell: (row) => <span>{moment(row?.createdAt).format('lll')}</span>,
      selector: (row) => row?.createdAt,
      sortable: true,
      minWidth: '200px'
    },
    {
      name: 'status',
      right: true,
      cell: (row) =>
        row.verify_status === "verified" && (
          <input
            className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            checked={row.status === true}
            name="slider"
            value={row.status}
            onChange={(e) => {
              UpdateTenant(
                !row?.status,
                row._id,
                undefined,
                row.verification_mail_sent
              );
            }}
          />
        ),
    },
    {
      name: 'verification',
      right: true,
      cell: (row) => (
        row?.verify_status === "pending" ? (
          <div className="flex justify-center space-x-2">
            <button
              className=" bg-green-600 text-white hover:shadow-lg transition-all duration-150 text-xs py-1 px-3 rounded-[4px] font-medium"
              onClick={() =>
                UpdateTenant(true, row._id, "verified", row?.verification_mail_sent)
              }
            >
              Verify
            </button>
            <button
              className="bg-red-600 text-white hover:shadow-lg transition-all duration-150 text-xs py-1 px-3 rounded-[4px] font-medium"
              onClick={() =>
                UpdateTenant(
                  row.status,
                  row._id,
                  "not_verified",
                  row?.verification_mail_sent
                )
              }
            >
              Cancel
            </button>
          </div>
        ) : row?.verify_status === "verified" ? (
          <span className="text-sm text-green-600 font-medium rounded-[4px]">
            Verified
          </span>
        ) : row?.verify_status === "delete_store" || row?.verify_status === 'not_verified' ? (
          <span className="text-sm text-red-600 font-medium rounded-[4px]">
            {row?.verify_status === "delete_store" ? "Deleted" : "Not verified"}
          </span>
        ) : <></>
      ),
      minWidth: '200px'
    },
    {
      name: 'mail sent',
      right: true,
      width: '120px',
      cell: (row) => (
        mailLoader && mailId === row?._id ? <div className="nb-spinner"></div> :
          row?.verification_mail_sent ?
            <Check className="text-green-700 w-[18px] h-[18px]" /> :
            <X className="text-red-600 w-[18px] h-[18px]" />
      ),
      selector: (row) => row?.verify_status,
      sortable: true,
    },
    {
      name: 'action',
      width: '100px',
      right: true,
      cell: (row) => (
        <button className="pe-3 inline-block">
          <Eye
            className="w-[18px] h-[18px] text-black"
            onClick={() =>
              navigate(`/tenants/${row._id}`)
            }
          />
        </button>
      ),
    },
  ];
  const [openTab, setOpenTab] = useState("verify")

  const newStoreCount = allTenants?.records?.filter((x) => x?.verify_status === "pending")
  const deleteStoreCount = deleteStore?.records?.filter((x) => x?.verify_status === "delete_request")

  return (
    <>
      <div className="w-full pb-[64px]">
        <div className="flex items-center justify-between mb-4">
          <div>
            <Breadcrumbs crumbs={[{ name: 'Dashboard', path: `/dashboard` }, { name: 'Tenant', path: `/tenants` }]} />
            <h1 className='text-xl md:text-2xl font-semibold text-primary'>Tenant Request</h1>
          </div>
          <div className="flex  space-x-3">
            <button
              className="filter-btn rounded w-10 h-10"
              onClick={() => setFilterDD(!filterDD)}
            >
              <Filter className="inline-flex w-5 h-5" />
            </button>
          </div>
        </div>
        <div class="grid mb-1 w-fit grid-flow-col text-center table-text border main-border-color bg-white rounded-[10px] p-1">
          <a
            className={`tab ${(openTab === "verify" ? 'focus-tab' : 'bg-transparent')}`}
            onClick={(e) => {
              e.preventDefault();
              setOpenTab('verify');
            }}
            data-toggle='tab'
            href='#link1'
          >
            Store Verify Request ( {newStoreCount?.length} )
          </a>
          <a
            className={`tab ${(openTab === "delete" ? 'focus-tab' : 'bg-transparent')}`}
            onClick={(e) => {
              e.preventDefault();
              setOpenTab('delete');
            }}
            data-toggle='tab'
            href='#link1'
          >
            Store Delete Request ( {deleteStoreCount?.length} )
          </a>
        </div>
        {
          openTab === "verify" ?
            <div className="flex space-x-4 w-full">
              <div className="w-full rounded space-y-3">
                <div className='w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
                  <DataTable
                    columns={columns}
                    data={allTenants?.records}
                    selectableRowsHighlight
                    highlightOnHover
                  />
                </div>
                {allTenants?.records?.length > 0 ? (
                  <div className='bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                    <span className='text-sm font-medium'>
                      {`${startRecord} - ${endRecord} of ${allTenants?.totalRecords}`}
                    </span>
                    <div>
                      {allTenants?.totalPages !== 1 ? (
                        <Pagination
                          count={allTenants?.totalPages}
                          page={page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
                <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
                  <Filter className="me-1 w-4 h-4" />
                  Filter
                </p>
                <hr className="border-[#a0d0eb] my-3"></hr>
                <div className="grid gap-y-5 max-w-full overflow-hidden">
                  <div>
                    <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                      Search
                    </span>
                    <label className="relative block w-full">
                      <input
                        className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                        placeholder="Search tenant..."
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) =>
                          setFilter({ ...filter, search: e.target.value })
                        }
                      />
                    </label>
                  </div>
                  <div>
                    <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                      Sort By Verification Status
                    </span>
                    <div className="relative">
                      <select
                        onChange={(e) => {
                          setFilter({ ...filter, v_status: e.target.value });
                        }}
                        name="verification_status"
                        className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                        value={v_status}
                      >
                        <option value=''>All</option>
                        {verification_status.map((elem, index) => {

                          return (
                            <>
                              <option key={index}>{elem}</option>
                            </>
                          );
                        })}
                      </select>
                      <div className="select-arrow"></div>
                    </div>
                  </div>
                  <div>
                    <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                      Sort By Store Status
                    </span>
                    <div className="relative">
                      <select
                        onChange={(e) => {
                          if (e.target.value === "All") {
                            setFilter({ ...filter, store_status: "" });
                          } else {
                            setFilter({
                              ...filter,
                              store_status: e.target.value,
                            });
                          }
                        }}
                        name="verification_status"
                        className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                        value={store_status}
                      >
                        <option>All</option>
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                      </select>
                      <div className="select-arrow"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> : <TenentDeleteRequest />
        }
      </div>
      {newTenant && (
        <div className="h-auto w-full absolute inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50  p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer">
          <div className="absolute pe-8 top-[50%] md:left-[50%] md:-translate-x-[50%] -translate-y-[50%] w-full max-w-[600px] max-h-full">
            <div className="relative bg-white shadow dark:bg-gray-700 p-4">
              <div className="flex h-fit items-center justify-center">
                <div className="w-full h-fit sm:w-8/12 xl:w-full mx-auto bg-white p-5 rounded-xl ">
                  <div className="flex items-center justify-between w-full">
                    <h2 className=" text-center text-2xl font-bold tracking-tight text-gray-900">
                      Add New Tenant
                    </h2>
                    <button
                      type="button"
                      className="inline-block z-10 bg-white text-[#222222] hover:text-red-500 transition-all duration-200 border-none rounded-none"
                      onClick={() => {
                        setNewtenant(false);
                      }}
                    >
                      <X className="h-5 w-5 close-btn" aria-hidden="true" />
                    </button>
                    {/* <img className="mx-auto h-8 w-auto" src={logo} alt="Your Company" /> */}

                  </div>
                  <h1 className="text-xs mt-5 mb-2 text-red-600">
                    Fields That Marked * are Compulsary
                  </h1>
                  <div>
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="rounded-md  space-y-4 shadow-sm">
                      <div>
                        <label htmlFor="email-address" className="sr-only">
                          Email address
                        </label>
                        <input
                          id="email-address"
                          name="email"
                          type="email"
                          value={email}
                          onChange={HandleInput}
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-[4px] text-sm focus:outline-none focus:border-gray-500"
                          placeholder="email address*"
                        />
                      </div>
                      <div>
                        <label htmlFor="Businessname" className="sr-only">
                          Business name
                        </label>
                        <input
                          id="BusinessName"
                          name="business_name"
                          type="Text"
                          value={business_name}
                          onBlur={Handleblur}
                          onChange={HandleInput}
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-[4px] text-sm focus:outline-none focus:border-gray-500"
                          placeholder="Business name*"
                        />
                      </div>
                      <div>
                        <label htmlFor="Store Id" className="sr-only">
                          Store id
                        </label>
                        <div className="relative">
                          <input
                            id="Store Id"
                            name="storeName"
                            type="text"
                            value={storeName}
                            onChange={(e) => {
                              setStoreName(
                                e.target.value
                                  .toLowerCase()
                                  .replace(/\s+/g, "-") // Replace consecutive spaces with a single hyphen
                                  .replace(/[^a-zA-Z0-9\-]/g, "") // Remove special characters
                                  .replace(/-+/g, "-") // Replace multiple hyphens with a single hyphen;
                              );
                              setmessage(<span className="text-link text-sm ">Please click on verify</span>)
                            }
                            }
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-[4px] text-sm focus:outline-none focus:border-gray-500"
                            placeholder="Store id"
                            verifyCallback={(response) =>
                              response
                                ? setIsVarified(true)
                                : setIsVarified(false)
                            }
                            // onFocus={() => {storeName?.length > 0 && setmessage(<span className="text-red-500">please click on verify</span>)}}
                            onBlur={() => setmessage("")}
                          />
                          {message}
                          {storeName ? (
                            <span
                              className="absolute z-10 py-1 top-2 right-4  justify-end rounded-md bg-indigo-600 px-3 text-xs font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={storeapidata}
                            >
                              Verify
                            </span>
                          ) : err && (
                            <h1 className="text-yellow-500 text-sm">
                              This id is already exist
                            </h1>
                          )}
                          {success && (
                            <h1 className="text-green-500 text-sm">
                              This store id is available
                            </h1>
                          )}
                        </div>
                      </div>


                      <div>
                        <label htmlFor="BusinessCategory" className="sr-only">
                          Business category
                        </label>
                        <input
                          id="BusinessCategory"
                          name="business_category"
                          type="text"
                          value={business_category}
                          onChange={HandleInput}
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-[4px] text-sm focus:outline-none focus:border-gray-500"
                          placeholder="Business category*"
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      {!isVarified ? (
                        <>
                          <button className="mt-4 opacity-50 cursor-not-allowed block w-full btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300">
                            Add Tenant
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="mt-4 block w-full btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
                            onClick={HandleRegister}
                          >
                            Add Tenant
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Tenants;
