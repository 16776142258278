import API from '../../API';
import { GET_SYSTEM_STATUS } from '../action.type';

export const getAppStatus = () => {
  return async (dispatch) => {
    try {
      let appStatus = await API({
        url: '/super/admin/systemStatus/getSystemStatus',
        method: 'get',
      });
      dispatch({
        type: GET_SYSTEM_STATUS,
        payload: appStatus,
      });
    } catch (error) {
      console.log({ error });
    }
  };
};

export const updateSystemStatus = (id, body) => {
  return async (dispatch) => {
    try {
      await API({ url: `/super/admin/systemStatus/updateStatus/${id}`, method: 'put', data: body });
      dispatch(getAppStatus());
    } catch (error) {
      console.log(error);
    }
  };
};
