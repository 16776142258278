import {
  Edit,
  Eye,
  Filter,
  PlusCircle,
  Trash,
  X,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  addChangelogCategory,
  changelogCategoryDelete,
  getAllChangelogCategories,
  updateChangelogCategory,
} from "../../Redux/Action/changelog.action";
import { Pagination, Popover, Tooltip } from "@mui/material";
import Breadcrumbs from "../../controllers/breadCrumbs";

const Changelogcategories = () => {
  const dispatch = useDispatch();
  const { allChangelogCategories } = useSelector(
    (state) => state?.changelogReducer
  );
  const [popUp, setPopup] = useState({
    addCatPP: false,
    updateCatPP: false,
  });
  const [filterDD, setFilterDD] = useState(false)
  const [err, setErr] = useState(false);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const { addCatPP, updateCatPP } = popUp;
  const [addCategoryData, setAddCategoryData] = useState({ category_name: "" });
  const [updateData, setUpdateData] = useState({ category_name: "", cat_id: "" })
  const [selectedRowsId, setSelectedRowsId] = useState([])
  useEffect(() => {
    dispatch(getAllChangelogCategories(page, limit));
  }, [dispatch, page, limit]);

  const HandleInput = (e) => {
    setErr(false);
    setAddCategoryData({ ...addCategoryData, [e.target.name]: e.target.value });
  };
  // add New Category
  const addNewCategory = () => {
    if (addCategoryData.category_name == "") {
      setErr(true);
    } else {
      dispatch(addChangelogCategory(addCategoryData));
      setPopup({ ...popUp, addCatPP: false });
      setAddCategoryData({ ...addCategoryData, category_name: "" });
    }
  };
  //   Update Category

  const UpdateCategory = () => {
    if (updateData.category_name == "") {
      setErr(true);
    } else {
      dispatch(updateChangelogCategory(updateData));
      setPopup({ ...popUp, updateCatPP: false });
      setUpdateData({ ...updateData, category_name: "" });
    }
  };

  const columns = [
    {
      name: "category name",
      //   (
      //     <div className="flex items-center">
      //       <button
      //         className="uppercase"
      //         onClick={() => SortData("announcement_title")}
      //       >
      //         Title
      //       </button>
      //       {iconVisible && sortBy === "announcement_title" ? (
      //         !orderBy.isDecending ? (
      //           <ChevronUp className="w-3 h-3" />
      //         ) : (
      //           <ChevronDown className="w-3 h-3" />
      //         )
      //       ) : (
      //         <></>
      //       )}
      //     </div>
      //   )
      width: "150px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize">{row.category_name}</span>
      ),
    },
    // {
    //   name: "TYPE",
    //   width: "150px",
    //   cell: (row) => (
    //     <span className="line-clamp-1 capitalize">{row.announcement_type}</span>
    //   ),
    // },
    // {
    //   name: (
    //     <div className="flex items-center">
    //       <button
    //         className="uppercase"
    //         onClick={() => SortData("short_description")}
    //       >
    //         Short Description
    //       </button>
    //       {iconVisible && sortBy === "short_description" ? (
    //         !orderBy.isDecending ? (
    //           <ChevronUp />
    //         ) : (
    //           <ArrowDown />
    //         )
    //       ) : (
    //         <></>
    //       )}
    //     </div>
    //   ),
    //   cell: (row) => <p className="line-clamp-2">{row.short_description}</p>,
    // },
    // {
    //   name: "TAG",
    //   width: "250px",
    //   cell: (row) => <p className="line-clamp-2">{row.tag}</p>,
    // },
    {
      name: <p className="w-full text-right block">ACTION</p>,
      cell: (row) => (
        <div className="space-x-2 w-full flex items-center justify-end">
          <div
            onClick={(e) => {
              setUpdateData({ ...updateData, category_name: row?.category_name, cat_id: row?._id })
              setPopup({ ...popUp, updateCatPP: true });
            }}
          >
            <button className="">
              <Edit className="w-[18px] h-[18px] text-blue-500" />
            </button>
          </div>
          <div
            onClick={() => dispatch(changelogCategoryDelete(row?._id))}
          >
            <button className="">
              <Trash className="w-[18px] h-[18px] text-red-500" />
            </button>
          </div>
        </div>
      ),
    },
  ]
  return (
    <div className=" w-full pb-[64px]">
      <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Changelog categories', path: `/changelog-categories` },
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>Changelog</h1>
        </div>
        <div className="flex space-x-3">
          <button
            className="flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
            type="button"
            onClick={() => setPopup({ ...popUp, addCatPP: true })}
          >
            <PlusCircle className="w-4 h-4 me-2" />
            Add category
          </button>
          <button
            className="filter-btn rounded w-10 h-10"
            onClick={() => setFilterDD(!filterDD)}
          >
            <Filter className="inline-flex rounded-md w-5 h-5" />
          </button>
        </div>
      </div>
      <details
        className={
          selectedRowsId?.length === 0
            ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative`
            : `cursor-pointer group w-[200px] mb-1.5 relative`
        }
      >
        <summary className="flex bg-white border border-gray-300 px-2 py-2 text-sm rounded justify-between items-center list-none">
          <span> Bulk Selection</span>
          <span
            className={
              selectedRowsId?.length === 0
                ? "transition group-open:rotate-0"
                : "transition group-open:rotate-180"
            }
          >
            <svg
              fill="none"
              height={14}
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width={14}
            >
              <path d="M6 9l6 6 6-6" />
            </svg>
          </span>
        </summary>
        <div
          className={
            selectedRowsId?.length === 0
              ? `hidden`
              : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
          }
        >
          <button
            className="mt-1 text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center"
            onClick={() => dispatch(changelogCategoryDelete(selectedRowsId))}
          >
            <Trash className="w-3.5 h-3.5" />
            <span>Delete selection</span>
          </button>
        </div>
      </details>
      <div className="w-full flex space-x-4">
        <div className="w-full space-y-3">
          <div className="w-full overflow-x-auto">
            <div className="w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]">
              <DataTable
                columns={columns}
                data={allChangelogCategories?.records}
                fixedHeader
                selectableRows
                fixedHeaderScrollHeight="700px"
                onSelectedRowsChange={({ selectedRows }) => {
                  setSelectedRowsId(selectedRows?.map((x) => x._id));
                }}
                selectableRowsHighlight
                highlightOnHover
              />
            </div>
          </div>
          {allChangelogCategories?.records && (
            <div className="bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[4px]">
              <div className="hidden sm:block">
                <p className="text-sm flex text-gray-700 space-x-4">
                  <div>
                    Current Page :
                    <span className="font-semibold ms-1">
                      {allChangelogCategories?.currentPage || ""}
                    </span>
                  </div>
                  <div>
                    Total Records :
                    <span className="font-semibold ms-1">
                      {allChangelogCategories?.totalRecords || ""}
                    </span>
                  </div>
                </p>
              </div>
              <div>
                {allChangelogCategories?.totalPages !== 1 ? (
                  <Pagination count={allChangelogCategories?.totalPages} page={page} onChange={(e, v) => setPage(v)} />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>

        <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
          <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
            <Filter className="me-1 w-4 h-4" />
            Filter
          </p>
          <hr className="border-[#a0d0eb] my-3"></hr>
          <div className="grid gap-y-3 max-w-full overflow-hidden">
            {/* <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Search
                </span>
                <label className="relative block w-full">
                  <input
                    className="w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    placeholder="Search for anything..."
                    type="text"
                    name="search"
                    value={filter.search}
                    onChange={(e) => setFilter({ search: e.target.value })}
                  />
                </label>
              </div> */}
            <div>
              <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                Limit
              </span>
              <div className="relative">
                <select
                  onChange={(e) => {
                    setLimit(e.target.value)
                  }}
                  name="verification_status"
                  className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                  value={limit}
                >
                  <option value="">All</option>
                  <option >10</option>
                  <option >20</option>
                  <option >50</option>
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>
            {/* <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Sort by
                </span>
                <select
                  name="receiver"
                  value={filter.a_type}
                  onChange={(e) => setFilter({ ...filter, a_type: e.target.value })}
                  className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                >
                  <option value="">All</option>
                  <option value="admin">Admin</option>
                  <option value="affiliate">Affiliate</option>
                  <option value="customer">Customer</option>
                </select>
              </div> */}

            {/* <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Sort By Store Status
                </span>
                <div>
                  <>
                    <select
                      onChange={(e) => {
                        if (e.target.value === "All") {
                          setFilter({ ...filter, store_status: "" });
                        } else {
                          setFilter({
                            ...filter,
                            store_status: e.target.value,
                          });
                        }
                      }}
                      name="verification_status"
                      className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                      value={store_status}
                    >
                      <option>All</option>
                      <option value="true">Enabled</option>
                      <option value="false">Disabled</option>
                    </select>
                  </>
                </div>
              </div> */}
          </div>
        </div>

      </div>
      {addCatPP && (
        <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
          <Popover
            open={addCatPP}
            className="h-screen"
            onClose={() => {
              setPopup({ ...popUp, addCatPP: false });
              setAddCategoryData({ ...addCategoryData, category_name: "" });
            }}
          >
            <div className="w-[400px] max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700">
              <div className="w-full flex justify-between">
                <h1 className="text-xl font-medium">Add Changelog Category</h1>
                <X
                  className="w-5 cursor-pointer"
                  onClick={() => {
                    setPopup({ ...popUp, addCatPP: false });
                    setAddCategoryData({
                      ...addCategoryData,
                      category_name: "",
                    });
                  }}
                />
              </div>
              <hr className="my-4"/>
              <div className="w-full contryCode">
                <label
                  className="flex items-center text-sm pb-2 font-semibold"
                  htmlFor="category_name"
                >
                  Category Name*
                  <Tooltip
                    title="This is the net sales price for your customers."
                    arrow
                    placement="right"
                    className="ml-2 -tracking-tighter"
                  ></Tooltip>
                </label>
                <input
                  className={
                    err && addCategoryData.category_name === ""
                      ? "w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none"
                      : "w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                  }
                  type="text"
                  min={1}
                  id="category_name"
                  name="category_name"
                  value={addCategoryData.category_name}
                  onChange={(e) => HandleInput(e)}
                  placeholder="Category name"
                />
                {err && addCategoryData.category_name === "" && (
                  <h1 className="text-red-500 text-sm">
                    Category name is required
                  </h1>
                )}
              </div>
              <button
                className="flex w-full mt-4 justify-center my-1 items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
                type="button"
                onClick={addNewCategory}
              >
                Add category
              </button>
            </div>
          </Popover>
        </div>
      )}
      {updateCatPP && (
        <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
          <Popover
            open={updateCatPP}
            className="h-screen"
            onClose={() => {
              setPopup({ ...popUp, updateCatPP: false });
              setUpdateData({ ...updateData, category_name: "" });
            }}
          >
            <div className="w-[400px] max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700">
              <div className="w-full flex items-center justify-between">
                <h1 className="text-xl font-medium">Update Changelog Category</h1>
                <X
                  className="w-4 cursor-pointer"
                  onClick={() => {
                    setPopup({ ...popUp, updateCatPP: false });
                    setUpdateData({
                      ...updateData,
                      category_name: "",
                    });
                  }}
                />
              </div>
              <hr className="my-4" />
              <div className="w-full contryCode">
                <label
                  className="flex items-center text-sm pb-1 font-semibold"
                  htmlFor="category_name"
                >
                  Category Name*
                  <Tooltip
                    title="This is the net sales price for your customers."
                    arrow
                    placement="right"
                    className="ml-2 -tracking-tighter"
                  ></Tooltip>
                </label>
                <input
                  className={
                    err && updateData?.category_name === ""
                      ? "w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none"
                      : "w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                  }
                  type="text"
                  min={1}
                  id="category_name"
                  name="category_name"
                  value={updateData?.category_name}
                  onChange={(e) => { setUpdateData({ ...updateData, category_name: e.target.value }); setErr(false) }}
                  placeholder="Category name"
                />
                {err && updateData?.category_name === "" && (
                  <h1 className="text-red-500 text-sm">
                    Category name is required
                  </h1>
                )}
              </div>
              <button
                className="flex w-full justify-center my-4 items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
                type="button"
                onClick={UpdateCategory}
              >
                Update category
              </button>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default Changelogcategories;
