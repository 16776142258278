import React from "react"
import { ArrowRight, RefreshCw } from 'feather-icons-react/build/IconComponents';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { Tooltip } from '@mui/material';
import Notification from "../Notification";
import DataTable from 'react-data-table-component';
import Loader from '../Loader/Loader';
import {
  CalendarFill,
} from 'react-bootstrap-icons';
import Breadcrumbs from "../../controllers/breadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getActivePlan, getLatestStores, getPlanExpiring, getTotalExtension } from "../../Redux/Action/dashboard.action";
const DashBoard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const originalDate = new Date();
  const { latestStores, activePlan, planexpiring, totalextension } = useSelector((state) => state.dashboardReducer);
  const [startDate, setStartDate] = useState(
    new Date(originalDate.getFullYear(), originalDate.getMonth() - 1, originalDate.getDate()),
  );
  const [endDate, setEndDate] = useState(new Date());
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  // get LatestStores
  const [latestStoresPending, setlatestStoresPending] = useState(true);
  const GetLatestStores = () => {
    try {
      dispatch(getLatestStores());
    } catch (error) {
      
    } finally {
      setlatestStoresPending(false)
    }
  };
  const latestStoresTableData = latestStores?.data;
  const latestStoresColumns = [
    {
      name: 'business name',
      width: '180px',
      cell: (row) => <span className='capitalize'>{row?.business_name}</span>,
      selector: (row) => row?.business_name,
      sortable: true,
    },
    {
      name: 'business category',
      cell: (row) => <span className='line-clamp-2'>{row?.business_category}</span>,
    },
    {
      name: 'tenant email',
      cell: (row) => <span className='line-clamp-2'>{row?.tenant_email}</span>,
    },
    {
      name: 'tenant name',
      cell: (row) => <span className='line-clamp-2'>{row?.tenant_name}</span>,
    },
  ];


  const [activePlanpending, setactivePlanpending] = useState(true);
  const GetActivePlan = () => {
    try {
      dispatch(getActivePlan());
    } catch (error) {

    } finally {
      setactivePlanpending(false)
    }
  };
  const activePlanTableData = activePlan;
  const activePlanColumns = [
    {
      name: 'name',
      width: '180px',
      cell: (row) => <span className='capitalize'>{row?.name}</span>,
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: 'plan type',
      cell: (row) => <span className='line-clamp-2'>{row?.plan_type}</span>,
    },
    {
      name: 'price',
      cell: (row) => <span className='line-clamp-2'>{row?.price}</span>,
    },
    {
      name: 'trial Period',
      cell: (row) => <span className='line-clamp-2'>{row?.trialPeriod}</span>,
    },

  ];

  const [extensionpending, setextensionpending] = useState(true);
  const GetTotalExtension = async () => {
    try {
      const startdate = moment(startDate).tz('Asia/Kolkata').format('YYYY-MM-DD')
      const enddate = moment(endDate).tz('Asia/Kolkata').format('YYYY-MM-DD')
      dispatch(getTotalExtension(startdate, enddate))
    } catch (error) {
      
    } finally {
      setextensionpending(false);
    }
  }
  const extensionTableData = totalextension?.data;
  const extensionColumns = [
    {
      name: 'extension name',
      width: '180px',
      cell: (row) => <span className='capitalize'>{row?.extension_name}</span>,
      selector: (row) => row?.extension_name,
      sortable: true,
    },
    {
      name: 'extension type',
      cell: (row) => <span className='line-clamp-2'>{row?.extension_type}</span>,
    },
    {
      name: <span className='w-full block'>description</span>,
      cell: (row) => <span className='w-full line-clamp-2'>{row?.short_description}</span>,
    },
  ];

  const [planexpiringPending, setplanexpiringPending] = useState(true);
  const GetPlanExpiring = async () => {
    try {
      dispatch(getPlanExpiring());
    } catch (error) {
      
    } finally {
      setplanexpiringPending(false)
    }
  }
  const planexpiringTableData = planexpiring?.data;
  const planexpiringColumns = [
    {
      name: 'tenant name',
      width: '180px',
      cell: (row) => <span className='capitalize'>{row?.tenant?.tenant_name}</span>,
      selector: (row) => row?.tenant?.tenant_name,
      sortable: true,
    },
    {
      name: 'tenant email',
      cell: (row) => <span className='line-clamp-2'>{row?.tenant?.tenant_email}</span>,
      minWidth: '200px'
    },
    {
      name: 'plan name',
      cell: (row) => <span className='line-clamp-2'>{row?.plan_name}</span>,
    },
    {
      name: 'satrt date',
      cell: (row) => <span className='line-clamp-2'>{moment(row?.createdAt).format('DD-MM-YYYY')}</span>,
      right: true
    },
    {
      name: 'end date',
      cell: (row) => <span className='line-clamp-2'>{moment(row?.end_date).format('DD-MM-YYYY')}</span>,
      right: true
    },
  ];
  useEffect(() => {
    GetTotalExtension()
  },[startDate, endDate])
  useEffect(() => {
    GetLatestStores()
    GetActivePlan()
    GetPlanExpiring()
  },[])
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-hidden pb-[65px]'>
        <div className='pb-4 flex items-center justify-between w-full'>
          <div>
            <Breadcrumbs crumbs={[{ name: 'Dashboard', path: `/dashboard` }]} />
            <h1 className='text-xl md:text-2xl font-semibold text-primary'>Dashboard</h1>
          </div>
          <div className='hidden sm:flex justify-end'>
            <div className='flex items-center bg-[#f5f5f5]'>
              <label className='relative flex items-center w-full cursor-pointer'>
                <span className='text-sm font-medium me-1'>From</span>
                <DatePicker
                  className='me-2 border cursor-pointer focus:border-gray-400 border-gray-300 outline-none'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  label='from'
                  dateFormat='dd-MM-yyyy'
                />
                <span className='absolute top-[50%] cursor-pointer -translate-y-[50%] inline-block right-5'>
                  <CalendarFill className='text-gray-600 w-3 h-3' />
                </span>
              </label>
              <label className='relative flex items-center w-full cursor-pointer'>
                <span className='text-sm font-medium me-1'>To</span>
                <DatePicker
                  className='border cursor-pointer focus:border-gray-400 border-gray-300 outline-none'
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  dateFormat='dd-MM-yyyy'
                />
                <span className='absolute top-[50%] cursor-pointer -translate-y-[50%] inline-block right-5'>
                  <CalendarFill className='text-gray-600 w-3 h-3' />
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 pb-4 gap-4'>
          <div className='bg-white shadow rounded-[10px] flex items-center justify-between p-3  text-primary font-medium group'>
            <div className='flex justify-center items-center w-14 h-14 bg-[#f5f5f5] rounded-full transition-all duration-300 transform group-hover:rotate-12'>
              <svg
                version="1.1"
                id="Icons"
                width={28}
                height={28}
                viewBox="0 0 32 32"
              >
                <path d="M25.7,5.9c-2.8-2.7-6.4-4-10.3-3.9C8.2,2.3,2.3,8.2,2,15.5c-0.2,6.1,3.5,11.6,9.2,13.7c0.5,0.2,1,0.3,1.4,0.3
	c0.9,0,1.7-0.3,2.4-0.8c1.2-0.8,1.9-2.2,1.9-3.6v-2.2c2.3-0.5,4-2.5,4-4.9v-4c0-0.6-0.4-1-1-1h-1v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3
	h-2v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3h-1c-0.6,0-1,0.4-1,1v4c0,2.4,1.7,4.4,4,4.9v2.2c0,0.8-0.4,1.5-1,2c-0.6,0.4-1.4,0.5-2.1,0.3
	C7,25.5,3.8,20.8,4,15.5C4.2,9.4,9.4,4.2,15.5,4c3.3-0.1,6.4,1.1,8.8,3.3C26.7,9.6,28,12.7,28,16c0,5-3.1,9.5-7.8,11.2
	c-0.5,0.2-0.8,0.8-0.6,1.3s0.8,0.8,1.3,0.6c5.4-2,9.1-7.3,9.1-13.1C30,12.2,28.5,8.6,25.7,5.9z"/>
              </svg>
            </div>
            <div className='text-right'>
              <p className='text-2xl'>
                {!totalextension?.data ? <>0</> : <>{totalextension?.data?.length}</>}
              </p>
              <p>Total Extension</p>
            </div>
          </div>
          <div className='bg-white shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
            <div className='flex justify-center items-center w-14 h-14 bg-[#f5f5f5] rounded-full transition-all duration-300 transform group-hover:rotate-12'>
              <svg
                fill="#000000"
                width={28}
                height={28}
                viewBox="0 0 32 32"
              ><path d="M 4 4 L 4 7.65625 L 1 11.65625 L 1 12 C 1 13.644531 2.355469 15 4 15 L 4 28 L 20 28 L 20 19 L 22 19 L 22 28 L 28 28 L 28 15 C 29.644531 15 31 13.644531 31 12 L 31 11.65625 L 28 7.65625 L 28 4 Z M 6 6 L 26 6 L 26 7 L 6 7 Z M 5.5 9 L 26.5 9 L 28.90625 12.21875 C 28.796875 12.652344 28.46875 13 28 13 C 27.445313 13 27 12.554688 27 12 L 25 12 C 25 12.554688 24.554688 13 24 13 C 23.445313 13 23 12.554688 23 12 L 21 12 C 21 12.554688 20.554688 13 20 13 C 19.445313 13 19 12.554688 19 12 L 17 12 C 17 12.554688 16.554688 13 16 13 C 15.445313 13 15 12.554688 15 12 L 13 12 C 13 12.554688 12.554688 13 12 13 C 11.445313 13 11 12.554688 11 12 L 9 12 C 9 12.554688 8.554688 13 8 13 C 7.445313 13 7 12.554688 7 12 L 5 12 C 5 12.554688 4.554688 13 4 13 C 3.53125 13 3.203125 12.652344 3.09375 12.21875 Z M 6 14.21875 C 6.53125 14.699219 7.234375 15 8 15 C 8.765625 15 9.46875 14.699219 10 14.21875 C 10.53125 14.699219 11.234375 15 12 15 C 12.765625 15 13.46875 14.699219 14 14.21875 C 14.53125 14.699219 15.234375 15 16 15 C 16.765625 15 17.46875 14.699219 18 14.21875 C 18.53125 14.699219 19.234375 15 20 15 C 20.765625 15 21.46875 14.699219 22 14.21875 C 22.53125 14.699219 23.234375 15 24 15 C 24.765625 15 25.46875 14.699219 26 14.21875 L 26 26 L 24 26 L 24 17 L 18 17 L 18 26 L 6 26 Z M 8 17 L 8 24 L 16 24 L 16 17 Z M 10 19 L 14 19 L 14 22 L 10 22 Z" /></svg>
            </div>
            <div className='text-right'>
              <p className='text-2xl'>
                {!latestStores?.data ? (
                  <>0</>
                ) : (
                  <>{latestStores?.data && latestStores?.data?.length}</>
                )}
              </p>
              <p> Latest Stores</p>
            </div>
          </div>
          <div className='bg-white shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
            <div className='flex justify-center items-center w-14 h-14 bg-[#f5f5f5] rounded-full transition-all duration-300 transform group-hover:rotate-12'>
              <svg
                width={28}
                height={28}
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                className='stroke-current text-black dark:text-gray-800 transform transition-transform duration-500 ease-in-out'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M13 7h8m0 0v8m0-8l-8 8-4-4-6 6'
                />
              </svg>
            </div>
            <div className='text-right'>
              <p className='text-2xl'>
                {!activePlan ? (
                  <>0</>
                ) : (
                  <>{activePlan && activePlan.length}</>
                )}
              </p>
              <p>Active Plan</p>
            </div>
          </div>
        </div>
        {/* ./Statistics Cards */}
        <div className='dashboard grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-4'>
          {/* Active Plan  */}
          {/* <SkeletonLoader /> */}
          <div className='relative shadow flex flex-col min-w-0 break-words bg-white dark:bg-gray-800 w-full rounded-[10px]'>
            <div className='rounded-t mb-0 px-0 border-0'>
              <div className='flex flex-wrap items-center justify-between px-4 py-2'>
                <h3 className='font-medium text-base text-black dark:text-gray-50'>
                  Active Plan
                </h3>
                <div className='flex justify-end items-center space-x-3'>
                  <Tooltip title='Refresh' arrow>
                    <button className=''>
                      <RefreshCw
                        className='w-4 h-4 block  text-gray-500 hover:text-gray-800 transition-all duration-150'
                        onClick={() => GetActivePlan()}
                      />
                    </button>
                  </Tooltip>
                  <button
                    className='text-link flex items-center text-xs hover:underline font-medium uppercase transition-all duration-200 space-x-2'
                    type='button'
                    onClick={() => navigate('/subscription-plans')}
                  >
                    View all
                    <ArrowRight className='w-3 h-3' />
                  </button>
                </div>
              </div>
              <div className='flex items-center overflow-x-auto rounded-[10px]'>
                <DataTable
                  columns={activePlanColumns?.length > 0 ? activePlanColumns : []}
                  data={activePlanTableData}
                  fixedHeader
                  highlightOnHover
                  progressPending={activePlanpending}
                  progressComponent={<Loader />}
                  className='rounded-[10px]'
                />
              </div>
            </div>
          </div>
          {/* ./Active Plan*/}

          {/*Latest Stores */}
          <div className='relative shadow flex flex-col min-w-0 break-words bg-white dark:bg-gray-800 w-full rounded-[10px]'>
            <div className='rounded-t mb-0 px-0 border-0'>
              <div className='flex flex-wrap items-center justify-between px-4 py-2'>
                <div className='relative w-full max-w-full flex-grow flex-1'>
                  <h3 className='font-medium text-base text-black dark:text-gray-50'>
                    Latest Stores
                  </h3>
                </div>
                <div className='flex items-center space-x-3 justify-end h-full'>
                  <Tooltip title='Refresh' arrow>
                    <button className=''>
                      <RefreshCw
                        className='w-4 h-4 block  text-gray-500 hover:text-gray-800 transition-all duration-150'
                        onClick={() => GetLatestStores()}
                      />
                    </button>
                  </Tooltip>
                  <button
                    className='text-link flex items-center text-xs hover:underline font-medium uppercase transition-all duration-200 space-x-2'
                    type='button'
                    onClick={() => navigate('/tenants')}
                  >
                    View all
                    <ArrowRight className='w-3 h-3' />
                  </button>
                </div>
              </div>
              <div className='block w-full overflow-x-auto rounded-[10px]'>
                <DataTable
                  columns={latestStoresColumns}
                  data={latestStoresTableData}
                  fixedHeader
                  highlightOnHover
                  progressPending={latestStoresPending}
                  progressComponent={<Loader />}
                />
              </div>
            </div>
          </div>
          {/* ./Latest Stores*/}

          {/* Stock Report */}
          <div className='relative shadow flex flex-col min-w-0 break-words bg-white dark:bg-gray-800 w-full rounded-[10px]'>
            <div className='rounded-t mb-0 px-0 border-0'>
              <div className='flex flex-wrap items-center px-4 py-2'>
                <div className='relative w-full max-w-full flex-grow flex-1'>
                  <h3 className='font-medium text-base text-black dark:text-gray-50'>
                    Plan Expiring
                  </h3>
                </div>
                <div className='flex justify-end space-x-3 items-center w-full max-w-full flex-grow flex-1 text-right'>
                  <Tooltip title='Refresh' arrow>
                    <button className=''>
                      <RefreshCw
                        className='w-4 h-4 text-gray-500 hover:text-gray-800 transition-all duration-150'
                        onClick={() => GetPlanExpiring()}
                      />
                    </button>
                  </Tooltip>
                  <button
                    className='text-link flex items-center text-xs hover:underline font-medium uppercase transition-all duration-200 space-x-2'
                    type='button'
                    onClick={() => navigate('/subscriptions')}
                  >
                    view all
                    <ArrowRight className='w-3 h-3' />
                  </button>
                </div>
              </div>
              <div className='block w-full overflow-x-auto rounded-[10px]'>
                <DataTable
                  columns={planexpiringColumns}
                  data={planexpiringTableData}
                  fixedHeader
                  highlightOnHover
                  progressPending={planexpiringPending}
                  progressComponent={<Loader />}
                />
              </div>
            </div>
          </div>
          {/* ./Stock Report */}
          {/* Customers */}
          <div className='relative shadow flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-white dark:bg-gray-800 w-full rounded-[10px] '>
            <div className='rounded-t mb-0 px-0 border-0'>
              <div className='flex flex-wrap items-center px-4 py-2'>
                <div className='relative w-full max-w-full flex-grow flex-1'>
                  <h3 className='font-medium text-base text-black dark:text-gray-50'>
                    Extension
                  </h3>
                </div>
                <div className='flex space-x-3 justify-end items-center w-full max-w-full flex-grow flex-1 text-right'>
                  <Tooltip title='Refresh' arrow>
                    <button className=''>
                      <RefreshCw
                        className='w-4 h-4 text-gray-600 ml-2 hover:text-gray-800 transition-all duration-150'
                        onClick={() => GetTotalExtension()}
                      />
                    </button>
                  </Tooltip>
                  <button
                    className='text-link flex items-center text-xs hover:underline font-medium uppercase transition-all duration-200 space-x-2'
                    type='button'
                    onClick={() => navigate('/apps')}
                  >
                    view all
                    <ArrowRight className='w-3 h-3' />
                  </button>
                </div>
              </div>
              <div className='block w-full overflow-x-auto rounded-[10px]'>
                <DataTable
                  columns={extensionColumns}
                  data={extensionTableData}
                  fixedHeader
                  highlightOnHover
                  progressPending={extensionpending}
                  progressComponent={<Loader />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default DashBoard;