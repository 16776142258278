import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { handleAddCompany } from '../../Redux/Action/company.action';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../controllers/breadCrumbs';

const AddCompany = () => {
  const [companyData, setCompanyData] = useState({
    cust_name: '',
    business_name: '',
    Industry: '',
    store_url: '',
    store_email: '',
    alternative_email: '',
    cust_email: '',
    store_contact: '',
    country_of_origin: '',
    Comments: '',
    group_id: '',
    cust_mobile: '',
    Subscribe: true
  })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addCompany = (e) => {
    e.preventDefault();
    dispatch(handleAddCompany(companyData)).then(() => {
      navigate('/company')
    }).catch((error) => {
      console.log(error);
    })
  }
  return (
    <div className="w-full pb-[64px]">
      <div>
        <Breadcrumbs crumbs={[
          { name: 'Dashboard', path: `/dashboard` },
          { name: 'Company', path: `/company` },
          { name: 'Add company', path: `/company/add-company` }
        ]} />
        <h1 className='text-xl md:text-2xl font-semibold text-primary'>Add Company</h1>
      </div>
      <div className="pb-4 overflow-x-hidden  h-full w-full">
        <form
          method="POST"
          className="w-[90%] lg:w-[70%] xl:w-[60%] bg-white border border-gray-300 p-5 rounded-[10px] mx-auto"
        >
          <div className="mb-4 flex w-full justify-between space-x-3">
            <div className='grid grid-cols-2 w-full gap-4'>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  Customer name
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="cust_name"
                  value={companyData?.cust_name}
                  placeholder="Enter customer name"
                  onChange={(e) => setCompanyData({ ...companyData, cust_name: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  business name
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="business_name"
                  value={companyData?.business_name}
                  placeholder="Enter business name"
                  onChange={(e) => setCompanyData({ ...companyData, business_name: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  Industry
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="Industry"
                  value={companyData?.Industry}
                  placeholder="Enter industry"
                  onChange={(e) => setCompanyData({ ...companyData, Industry: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  store URL
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="store_url"
                  value={companyData?.store_url}
                  placeholder="Enter store url"
                  onChange={(e) => setCompanyData({ ...companyData, store_url: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  store email
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="store_email"
                  value={companyData?.store_email}
                  placeholder="Enter store email"
                  onChange={(e) => setCompanyData({ ...companyData, store_email: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  alternative email
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="alternative_email"
                  value={companyData?.alternative_email}
                  placeholder="Enter alternative email"
                  onChange={(e) => setCompanyData({ ...companyData, alternative_email: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  customer email
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="cust_email"
                  value={companyData?.cust_email}
                  placeholder="Enter customer email"
                  onChange={(e) => setCompanyData({ ...companyData, cust_email: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  store contact
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="store_contact"
                  value={companyData?.store_contact}
                  placeholder="Enter store contact"
                  onChange={(e) => setCompanyData({ ...companyData, store_contact: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  contry of origin
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="country_of_origin"
                  value={companyData?.country_of_origin}
                  placeholder="Enter contry of origin"
                  onChange={(e) => setCompanyData({ ...companyData, country_of_origin: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  comments
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="Comments"
                  value={companyData?.Comments}
                  placeholder="Enter comments"
                  onChange={(e) => setCompanyData({ ...companyData, Comments: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  group id
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="group_id"
                  value={companyData?.group_id}
                  placeholder="Enter group id"
                  onChange={(e) => setCompanyData({ ...companyData, group_id: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  customer mobile number
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  name="cust_mobile"
                  value={companyData?.cust_mobile}
                  placeholder="Enter customer mobile number"
                  onChange={(e) => setCompanyData({ ...companyData, cust_mobile: e.target.value })}
                />
              </div>
              <div className="mb-4 h-[39px] border border-gray-300 rounded px-4 flex items-center">
                <label
                  className=" text-gray-700 text-sm font-medium"
                  htmlFor="Title"
                >
                  Subscribe :
                </label>
                <label className="relative flex ms-2 items-center cursor-pointer">
                  <input type="checkbox"
                    checked={companyData?.Subscribe}
                    id='flexSwitchCheckDefault'
                    name='brand'
                    onChange={(e) => setCompanyData({ ...companyData, Subscribe: !companyData?.Subscribe })}
                    className="sr-only inline-block peer" />
                  <div className="w-9 h-5 absolute bg-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
          <button className='btn text-sm text-white font-medium' onClick={(e) => addCompany(e)}>Add Company</button>
        </form>
      </div>
    </div>
  )
}

export default AddCompany