import axios from 'axios';

// Create an Axios instance with base URL and default headers
export const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Add request interceptor to include token and store ID headers
instance.interceptors.request.use(
  (config) => {
    // Try to retrieve the token from localStorage, then sessionStorage
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');

    // If the token exists, set the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add response interceptor for error logging
instance.interceptors.response.use(
  (response) => {
    // If the response is successful, return it
    return response;
  },
  (error) => {
    // Check if the error has a response (server responded with a status)
    if (error.response) {
      console.error('Response error:', error.response);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Request error:', error.request);
    } else {
      // Something happened in setting up the request
      console.error('General error:', error.message);
    }

    // Reject the promise to handle the error in the calling code
    return Promise.reject(error);
  }
);

export default instance;
