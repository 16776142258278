import {
    Autocomplete,
    Backdrop,
    CircularProgress,
    Dialog,
    TextField,
    Tooltip
} from "@mui/material";
import {
    ArrowLeft,
    Check,
    ChevronDown,
    Edit,
    X,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { QuestionCircleFill } from "react-bootstrap-icons";
import ReactQuill from "react-quill";
import { Link, useNavigate } from "react-router-dom";
import API from "../../../API";
import { GenerateSlugName } from "../../../controllers/GenerateSlugName";
import ImageUploader from "../../../controllers/ImageUploader";
import module from "../../../controllers/QuillToolbar";
import TextEditor from "../../../controllers/TextEditor";
import { Transition } from "../../../controllers/Transition";
import Breadcrumbs from "../../../controllers/breadCrumbs";
import Notification from "../../Notification";

function CreateWebsiteBlog() {
    const navigate = useNavigate();
    const [warn, setwarn] = useState(false);
    const [enableSlug, setEnableSlug] = useState(false);
    const [description, setDescription] = useState("");
    const [secondDescription, setSecondDescription] = useState("");
    const [title, setTitle] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [meta, setMeta] = useState({ meta_title: "", meta_description: "" });
    const { meta_description, meta_title } = meta;
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    const [blogTagsArray, setBlogTagsArray] = useState([]);
    const [blogTagsValue, setBlogTagsValue] = useState([]);

    const [generateAiPopup, setGenareteAiPopup] = useState(false);
    const [gerenateAiData, setGenerateDataAi] = useState({
        features: "",
    });
    const [AiLoader, setAiloader] = useState(false);
    const [generateDescriptionAiPopup, setGenareteDescriptionAiPopup] =
        useState(false);

    const handleChangeAiData = (e) => {
        setGenerateDataAi({ ...gerenateAiData, [e.target.name]: e.target.value });
    };

    const handleSubmitAiData = (e) => {
        e.preventDefault();
        generateDesc(gerenateAiData);
        setGenareteAiPopup(false);
        setGenerateDataAi({
            features: "",
        });
    };
    const generateDesc = async () => {
        setAiloader(true);
        try {
            let ApiCall = await API({
                url: "/admin/ai/keypoint_generate", method: 'post', data: {
                    name: gerenateAiData?.features,
                }
            });
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                setDescription(ApiCall.data.data);
                setGenareteDescriptionAiPopup(true);
            }
        } catch (error) {
            setAiloader(false);
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
        setAiloader(false);
    };

    // save Blog Values In Array
    const SaveblogTagsValue = () => {
        if (blogTagsValue.length > 0) {
            if (blogTagsValue.includes(",")) {
                let TagSeperator = blogTagsValue.split(",");
                let finalArray = [];
                let blogTags = [...blogTagsArray, ...TagSeperator];
                blogTags.filter((tag) => {
                    const searchRegex = new RegExp(`^${tag.trim()}$`, "i");
                    let exists = finalArray.some((finalTag) =>
                        searchRegex.test(finalTag)
                    );
                    if (!exists) {
                        finalArray.push(tag.trim());
                    }
                });
                setBlogTagsArray(finalArray);
            } else {
                const searchRegex = new RegExp(`^${blogTagsValue}$`, "i");
                const exists = blogTagsArray.some((tag) =>
                    searchRegex.test(tag)
                );
                if (!exists) {
                    setBlogTagsArray((prev) => [...prev, blogTagsValue]);
                }
            }
            setBlogTagsValue("");
        }
    };

    // remove Tag
    const RemoveTag = (index) => {
        if (index > -1) {
            // only splice array when item is found
            blogTagsArray.splice(index, 1); // 2nd parameter means remove one item only
        }
        setBlogTagsArray([...blogTagsArray]);
    };

    // image upload logic
    const [image, setimage] = useState();
    const handleSetImage = (value) => {
        setimage(value);
    };
    // get Parents Category
    const [Category, setCategory] = useState([]);
    const GetCategory = async () => {
        try {
            const data = await API({ url: `/super/admin/se_site/blogcategory/getSEBlogCategorys`, method: 'get' });
            if (data.status === 200 || data.status === 304) {
                setCategory(data?.data);
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
    };
    const [slugField, setSlugField] = useState(false);
    const [slugName, setSlugName] = useState();
    const handleSlug = async (slug) => {
        if (slug?.length) {
            setSlugField(true);
            setSlugName(await GenerateSlugName(slug));
        }
    };
    useEffect(() => {
        GetCategory();
    }, []);
    const [err, setErr] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const handleBlogCategory = (id, name) => {
        if (id) {
            const cat = selectedCategory.find((x) => x.id === id);
            if (!cat) {
                setSelectedCategory([...selectedCategory, { id, name }]);
            }
        }
    };
    // add Blog
    const HandleSubmit = async (e, key) => {
        e.preventDefault();
        if (title === "" || !image?.length) {
            setErr(true);
        } else {
            let body = {
                blogcategory: selectedCategory?.map((x) => x.id),
                title: title,
                image: image,
                description: secondDescription,
                blog_slug_name: slugName,
                sort_order: sortOrder,
                blog_tags: blogTagsArray?.filter((x) => x !== ""),
                ...meta,
            }
            if (key === "draft") {
                body = {
                    ...body,
                    is_draft: true
                }
            } else {
                body = { ...body}
            }
            try {
                const data = await API({
                    url: "/super/admin/se_site/blog/addSEBlog", method: 'post',
                    data: body
                });
                if (data.status === 200 || data.status === 304) {
                    navigate(`/site-data/blog`);
                }
            } catch (error) {
                setIsNotification(true);
                setNotificationMsg(error);
                setSeverity("error");
            }
        }
    };
    const [desErr, setDesErr] = useState(false);
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    const removeCategory = (id) => {
        const removeRelated = selectedCategory.filter((x) => x.id !== id);
        setSelectedCategory(removeRelated);
    };
    const handleEditorData = (value) => {
        setSecondDescription(value)
    }


    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}
            {AiLoader && (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={AiLoader}
                    className="flex flex-col"
                >
                    <CircularProgress color="inherit" />
                    <p>AI is generating your content.Please wait...</p>
                </Backdrop>
            )}
            <div className="h-auto overflow-y-auto pb-[64px]">
                <Breadcrumbs />
                <h1 className="text-xl md:text-2xl font-medium pb-4 text-primary">
                    Add new blog post
                </h1>
                <div className="flex justify-evenly p-4">
                    <form
                        method="POST"
                        className="space-y-4 w-[90%] lg:w-[80%] mx-auto"
                    >
                        <div className="bg-white group shadow  space-y-4 rounded-xl p-[25px]">
                            <div className="w-full">
                                <label
                                    htmlFor="title"
                                    className="flex items-center justify-between"
                                >
                                    <p className="text-[13px]">
                                        Blog Title
                                        <span className="text-red-500 ms-1">
                                            *
                                        </span>
                                    </p>
                                    {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                </label>
                                <input
                                    className={
                                        (err && title?.length === 0) || warn
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                    }
                                    type="text"
                                    name="title"
                                    // maxLength={60}
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        setwarn(false);
                                    }}
                                    placeholder="e.g. Blog about your latest products or deals"
                                    onBlur={() => handleSlug(title)}
                                />
                                {(err && title?.length === 0) || warn ? (
                                    <p className="text-red-500 text-xs">
                                        Please enter blog title
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {slugField && (
                                <div className="w-full">
                                    <label htmlFor="category_name">
                                        blog Slug name
                                        <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                    </label>
                                    <div
                                        className={`flex items-center justify-between w-full border ${err && enableSlug
                                            ? "border-red-500"
                                            : "border-gray-300"
                                            } rounded-[4px]`}
                                    >
                                        <input
                                            className="w-full  focus:outline-none"
                                            type="text"
                                            name="blog_slug_name"
                                            value={slugName}
                                            disabled={!enableSlug}
                                            onChange={(e) =>
                                                setSlugName(e.target.value)
                                            }
                                        />
                                        {enableSlug ? (
                                            <Check
                                                onClick={async () => {
                                                    setSlugName(
                                                        await GenerateSlugName(
                                                            slugName
                                                        )
                                                    );
                                                    setEnableSlug(!enableSlug);
                                                    setErr(false);
                                                }}
                                                className="text-green-500 p-1 h-8 w-8"
                                            />
                                        ) : (
                                            <Edit
                                                onClick={() =>
                                                    setEnableSlug(!enableSlug)
                                                }
                                                className="text-blue-500 p-1 h-7 w-7"
                                            />
                                        )}
                                    </div>
                                    {err && enableSlug && (
                                        <p className="text-xsm text-red-500">
                                            Please save this changes
                                        </p>
                                    )}
                                </div>
                            )}
                            <div className="mb-4">
                                <label htmlFor="description">
                                    Blog Parent Category
                                </label>
                                <Autocomplete
                                    className="autocomplete"
                                    options={
                                        Category?.data
                                            ? Category?.data?.records
                                            : []
                                    }
                                    getOptionLabel={(category) =>
                                        category.blogcategory_name
                                    }
                                    onChange={(e, v) =>
                                        v
                                            ? handleBlogCategory(
                                                v?._id,
                                                v?.blogcategory_name
                                            )
                                            : ""
                                    }
                                    renderInput={(Categories) => (
                                        <TextField
                                            placeholder="Select blog category"
                                            className="cursor-pointer"
                                            {...Categories}
                                        />
                                    )}
                                />
                                <div className="flex flex-wrap mt-1">
                                    {selectedCategory?.map((elem) => {
                                        return (
                                            <div className="bg-[#f5f5f5] mt-1 flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded">
                                                <span>{elem.name}</span>
                                                <X
                                                    className="h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer"
                                                    onClick={() =>
                                                        removeCategory(elem?.id)
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="mb-4">
                                <TextEditor label={'blog description'} handleEditorData={handleEditorData} defaultValue={secondDescription} />
                                <div className="flex items-center justify-between">
                                    {desErr ? (
                                        <p className="text-red-500 text-xs line-clamp-3">
                                            Please enter blog description
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                    <p className="text-xs mt-0 text-gray-400">
                                        of 5000 words allowed
                                    </p>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <label htmlFor="Description">
                                        Blog Tags
                                    </label>
                                    <p className="text-gray-500 font-normal text-xs">
                                        Use <b className="text-black">Enter</b>{" "}
                                        key to separate the tags
                                    </p>
                                </div>
                                <input
                                    className={`w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`}
                                    type="text"
                                    id="blog_tags"
                                    name="blog_tags"
                                    value={blogTagsValue}
                                    onChange={(e) =>
                                        setBlogTagsValue(e.target.value)
                                    }
                                    onKeyDown={(e) =>
                                        e.key === "Enter"
                                            ? SaveblogTagsValue()
                                            : ""
                                    }
                                    placeholder="e.g. Black"
                                />
                                <div className="flex mt-1 flex-wrap items-center">
                                    {blogTagsArray &&
                                        blogTagsArray.map((elem, index) => {
                                            if (elem !== "")
                                                return (
                                                    <span
                                                        key={index}
                                                        className="bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded"
                                                    >
                                                        {elem}
                                                        <X
                                                            className="h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer"
                                                            onClick={() =>
                                                                RemoveTag(index)
                                                            }
                                                        />
                                                    </span>
                                                );
                                        })}
                                </div>
                            </div>
                        </div>
                        <details
                            open
                            className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]"
                        >
                            <summary className="text-base flex items-center justify-between font-medium text-black">
                                <div>
                                    <span>Media</span>
                                    <span className="text-red-500 ms-1">
                                        *
                                    </span>
                                    <p className="text-xs text-gray-500 font-normal">
                                        Upload captivating image to make your
                                        blog stand out.
                                    </p>
                                </div>
                                <span className="transition group-open:rotate-180">
                                    <ChevronDown className="text-black w-4 h-5" />
                                </span>
                            </summary>
                            <ImageUploader
                                alreadyImage={image}
                                handleSetImage={handleSetImage}
                                folder="blog"
                                format="image"
                            />
                        </details>

                        <details className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]">
                            <summary
                                htmlFor="Meta Tag Title"
                                className="text-base flex items-center justify-between font-medium text-black"
                            >
                                <div>
                                    <span>Search engine listing</span>
                                    <p className="text-xs text-gray-500 font-normal">
                                        Add a title and description to see how
                                        this product might appear in a search
                                        engine listing
                                    </p>
                                </div>
                                <span className="transition group-open:rotate-180">
                                    <ChevronDown className="text-black w-4 h-5" />
                                </span>
                            </summary>
                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="Meta Tag Title"
                                        className="flex items-center justify-between"
                                    >
                                        Meta Title
                                    </label>
                                </div>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="text"
                                    id="Meta Tag Title"
                                    name="meta_title"
                                    value={meta_title}
                                    onChange={(e) => {
                                        setMeta({
                                            ...meta,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    placeholder="Enter meta title"
                                />
                            </div>

                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="Meta Tag description"
                                        className="flex items-center justify-between"
                                    >
                                        Meta Description
                                    </label>
                                </div>
                                <textarea
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="text"
                                    id="Meta Tag description"
                                    name="meta_description"
                                    value={meta_description}
                                    onChange={(e) => {
                                        setMeta({
                                            ...meta,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    placeholder="Enter meta description"
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="sort_order">sort order</label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="number"
                                    id="sort_order"
                                    name="sort_order"
                                    value={sortOrder}
                                    onChange={(e) => {
                                        setSortOrder(
                                            e.target.value <= 0
                                                ? 0
                                                : e.target.value
                                        );
                                    }}
                                    placeholder="Enter sort order"
                                />
                            </div>
                        </details>
                        <div className="flex items-center justify-between w-full">
                            <Link
                                to={`/site-data/blog`}
                                className="w-fit flex text-link font-medium hover:underline text-sm items-center "
                            >
                                <ArrowLeft className="w-3 mr-1" />
                                Go to blogs
                            </Link>
                            <div className="flex items-center space-x-2">
                                <button
                                    className={
                                        secondDescription && title && image?.length && slugName
                                            ? "inline-block secondary-btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                            : "font-medium text-sm text-link border-primary px-5 py-2 rounded border opacity-40 bg-white cursor-not-allowed"
                                    }
                                    type="button"
                                    onClick={(e) => HandleSubmit(e, "draft")}
                                    disabled={
                                        title?.length <= 0 ||
                                        !image?.length ||
                                        secondDescription?.length <=0 ||
                                        slugName?.length <= 0
                                    }
                                >
                                    Save Draft
                                </button>
                                <button
                                    className={
                                        secondDescription && title && image?.length && slugName
                                            ? "inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                            : "font-medium text-sm text-white opacity-40 btn cursor-not-allowed"
                                    }
                                    type="button"
                                    onClick={(e) => HandleSubmit(e, "publish")}
                                    disabled={
                                        title?.length <= 0 ||
                                        !image?.length ||
                                        secondDescription?.length <=0 ||
                                        slugName?.length <= 0
                                    }
                                >
                                    Add New Blog Post
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <Dialog
                    open={generateAiPopup}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setGenareteAiPopup(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className="w-full sm:w-[600px] rounded max-h-full">
                        <div className="relative secondary-bg-color shadow dark:bg-gray-700 p-4">
                            <div className="flex items-center justify-between  border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600">
                                <h6 className="capitalize text-primary font-medium">
                                    Generate Blog description
                                </h6>
                                <button
                                    type="button"
                                    className="close-btn rounded-md text-primary hover:text-primary-color transition-all duration-200 border-none"
                                    onClick={() => {
                                        setGenareteAiPopup(false);
                                    }}
                                >
                                    <X className="h-4 w-4" aria-hidden="true" />
                                </button>
                            </div>
                            <form
                                onKeyDown={(e) =>
                                    e.key === "Enter" && e.preventDefault()
                                }
                                onSubmit={handleSubmitAiData}
                            >
                                <div className="mb-4">
                                    <label className="flex" htmlFor="SKU ID">
                                        Features and keywords
                                        <Tooltip
                                            title="Add features and keywords for better description"
                                            arrow
                                            placement="right"
                                            className="ml-2 -tracking-tighter"
                                        >
                                            <QuestionCircleFill className="info-link cursor-pointer" />
                                        </Tooltip>
                                    </label>
                                    <textarea
                                        value={gerenateAiData?.features}
                                        name="features"
                                        onChange={handleChangeAiData}
                                        className="w-full border main-border-color rounded px-3 py-1.5 outline-none text-sm"
                                        placeholder="e.g. Organic cotten, relaxed fit"
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="btn px-5 py-2 text-sm text-white rounded"
                                >
                                    Done
                                </button>
                            </form>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    open={generateDescriptionAiPopup}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setGenareteDescriptionAiPopup(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className="w-[350px] md:w-[600px] max-h-full">
                        <div className="relative secondary-bg-color shadow dark:bg-gray-700 p-4">
                            <div className="flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600">
                                <h6 className="capitalize font-medium  text-primary">
                                    Generate Blog description
                                </h6>
                                <button
                                    type="button"
                                    className="close-btn"
                                    onClick={() => {
                                        setGenareteDescriptionAiPopup(false);
                                    }}
                                >
                                    <X className="h-4 w-4" aria-hidden="true" />
                                </button>
                            </div>
                            <form
                                onKeyDown={(e) =>
                                    e.key === "Enter" && e.preventDefault()
                                }
                            >
                                <div className="mb-4">
                                    <label className="flex" htmlFor="SKU ID">
                                        Features and keywords
                                        <Tooltip
                                            title="Add features and keywords for better description"
                                            arrow
                                            placement="right"
                                            className="ml-2 -tracking-tighter"
                                        >
                                            <QuestionCircleFill className="info-link cursor-pointer" />
                                        </Tooltip>
                                    </label>
                                    <ReactQuill
                                        modules={module}
                                        className={
                                            err && description?.length === 0
                                                ? "border rounded-[10px] border-red-500 w-full h-auto"
                                                : "w-full h-auto"
                                        }
                                        theme="snow"
                                        placeholder="Enter blog description"
                                        value={description}
                                        onBlur={() =>
                                            setMeta({
                                                ...meta,
                                                meta_description:
                                                    description
                                                        ?.replace(
                                                            /<[^>]*>/g,
                                                            " "
                                                        )
                                                        ?.split(" ")
                                                        .slice(0, 50)
                                                        .join(" ") || "",
                                            })
                                        }
                                        onChange={setDescription}
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <button
                                        type="button"
                                        className="btn px-5 py-2 text-white rounded"
                                        onClick={() => {
                                            setSecondDescription(description);
                                            setGenareteDescriptionAiPopup(
                                                false
                                            );
                                        }}
                                    >
                                        Done
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default CreateWebsiteBlog;
