import React, { useEffect, useState, useRef } from 'react';
import API from '../../../API';
import { Link, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import {
  PlusCircle,
  ArrowLeft,
  Trash,
  X,
  Check,
  Edit,
} from 'feather-icons-react/build/IconComponents';
import moment from 'moment-timezone';
import { CalendarFill } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import {
  TextField,
  Autocomplete,
  Tooltip,
  Popover,
} from '@mui/material';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import Compressor from 'compressorjs';
import { GenerateSlugName } from '../../../controllers/GenerateSlugName';
import { CountryCodes } from '../../../Assets/StaticData/countrycode';
import { ChevronDown, Plus, QuestionCircleFill, Trash3Fill } from 'react-bootstrap-icons';
import Notification from '../../Notification';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import GenerateUniqueFilename from '../../../controllers/GenerateUniqueFilename';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
import ImageUploader from '../../../controllers/ImageUploader';
const CreateDummyProduct = () => {
  const navigate = useNavigate();
  const [selectBran, setSelectedBrands] = useState('');
  const [ProductStats, setProductStats] = useState({
    selatedBundle: [],
    selatedRelated: [],
    selatedUpsell: [],
    selatedCrossell: [],
    selectCat: [],
  });
  const [productType, setProductType] = useState('single');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [err, setErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [selectTabs, setSelectedTabs] = useState([]);
  const [warn, setwarn] = useState(false);
  const [array, setarray] = useState([]);
  const [tags, setTags] = useState([]);
  const [enableSlug, setEnableSlug] = useState(false);
  const [TagsArray, setTagsArray] = useState([]);
  const [description, setDescription] = useState('');
  const [deletePopup, setDeletePopup] = useState({ index: '', visibility: false });
  const { selatedBundle, selatedRelated, selatedUpsell, selatedCrossell, selectCat } = ProductStats;
  const [affiliateData, setAffiliateData] = useState({
    button_text: "",
    affiliate_url: ""
  })
  const [ProdData, setProdData] = useState({
    product_name: '',
    description: '',
    shortdescription: '',
    sku_id: '',
    mrp: '',
    model: '',
    min_order_quantity: '1',
    product_return_policy: 'non_returnable',
    product_replacement_days: '7',
    selling_price: '',
    stock_status: 'in_stock',
    status_message: '',
    quantity: '',
    product_unit: '',
    video_url: '',
    product_badge: '',
    country_of_origin: '',
    length: 10,
    height: 10,
    breadth: 10,
    weight: 0.5,
  });
  const [meta, setMeta] = useState({ meta_description: '', meta_title: '' });
  const { meta_description, meta_title } = meta;
  const [isValidVideoLink, setisValidVideoLink] = useState(true);
  const HandleInput = (e) => {
    if (e.target.name === 'product_name' && warn) {
      setwarn(false);
    }
    if (e.target.name === 'video_url') {
      setisValidVideoLink(true)
    }
    setProdData({ ...ProdData, [e.target.name]: e.target.value });
  };

  // Check Video link is valid or not
  function isYouTubeLink(url) {
    const youtubeRegex = /<iframe.*?src="(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*?<\/iframe>/;
    if (!youtubeRegex.test(url)) {
      setisValidVideoLink(false)
    };
  }

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const {
    product_name,
    sku_id,
    mrp,
    min_order_quantity,
    product_return_policy,
    product_replacement_days,
    model,
    stock_status,
    status_message,
    selling_price,
    quantity,
    product_unit,
    product_badge,
    video_url,
    country_of_origin,
    length,
    height,
    breadth,
    weight,
  } = ProdData;

  // Category upload image
  const [catimage, setcatimage] = useState();
  const [catloader, setCatLoader] = useState(false);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const HandleCatImage = async (file) => {
    setCatLoader(true);
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    new Compressor(file, {
      quality: 0.6,
      async success(result) {
        const formdata = new FormData();
        formdata.append('file', result, fileName);
        formdata.append('folder', 'category');
        const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formdata});
        setCatLoader(false);
        setcatimage(res.data.data[0]);
      },
    });
  };

  const [Catdata, setCatData] = useState('');

  const HandleCatSubmit = async (e) => {
    e.preventDefault();
    if (Catdata === '' || !catimage) {
      setErr(true);
      setIsNotification(true);
      setNotificationMsg('Fill category data properly!');
      setSeverity('error');
    } else {
      try {
        let SubmitData = await API({ url: `/super/admin/dummydata/dummycategory/addDummyCategory`, method: 'post', data: {
          category_name: Catdata,
          image: catimage,
          category_slug_name: await GenerateSlugName(Catdata),
        }});
        if (SubmitData.status === 200 || SubmitData.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Category added successfully!');
          setSeverity('success');
          setAddCategory(false);
          setErr(false);
          HandleCategory(SubmitData?.data?.data?._id, SubmitData?.data?.data?.category_name);
          GetCategories();
          setCatData('');
          setcatimage('');
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        setAddCategory(false);
      }
    }
  };

  // Brand upload image
  const [brandimage, setBrandimage] = useState();
  const [brandloader, setBrandLoader] = useState(false);
  const HandleManufacturerImage = async (file) => {
    setBrandLoader(true);
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    new Compressor(file, {
      quality: 0.6,
      async success(result) {
        const formdata = new FormData();
        formdata.append('file', result, fileName);
        formdata.append('folder', 'category');
        const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formdata});
        setBrandLoader(false);
        setBrandimage(res.data.data[0]);
      },
    });
  };

  const [manufacturerdata, setManufacturerData] = useState('');

  const HandleManufacturerSubmit = async (e) => {
    e.preventDefault();
    let body = {
      brand_name: manufacturerdata,
      brand_logo: brandimage,
      brand_slug_name: await GenerateSlugName(manufacturerdata),
    };
    if (manufacturerdata === '' || !brandimage) {
      setErr(true);
      setIsNotification(true);
      setNotificationMsg('Fill category data properly!');
      setSeverity('error');
    } else {
      try {
        let SubmitData = await API({ url: `/super/admin/dummydata/dummybrand/addDummyManufacturer`, method: 'post', data: body});
        if (SubmitData.status === 200 || SubmitData.status === 304) {
          setSelectedBrands(SubmitData?.data?.data?._id, SubmitData?.data?.data?.brand_name);
          setErr(false);
          GetManufactures();
          setManufacturerData('');
          setBrandimage('');
          setAddBrand(false);
          setIsNotification(true);
          setNotificationMsg('Manufacturer added successfully');
          setSeverity('success');
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        setAddBrand(false);
      }
    }
  };

  // image upload logic
  const [loader, setLoader] = useState(false);
  const [image, setimage] = useState();
  const [imgErr, setImgErr] = useState(false);
  const [Extraimgloader, setextraImgLoader] = useState(false);
  const [Documentationloader, setDocumentationLoader] = useState(false);
  const [Documentationimage, setDocumentationimage] = useState();
  const [documentationErr, setdocumentationErr] = useState(false);

  const handleSetImage = (value) => {
    setimage(value)
}
  const HandleImages = async (imagess) => {
    if (array?.length + imagess?.length > 10) {
      setIsNotification(true);
      setNotificationMsg('you Cannot Add Greater Than 10 Images');
      setSeverity('success');
    } else {
      setextraImgLoader(true);
      const formData = new FormData();
      for (let i of imagess) {
        let fileName = i?.name;
        if (!fileName) {
          fileName = await GenerateUniqueFilename();
        }
        formData.append('file', i, fileName);
      }
      formData.append('folder', 'product');
      const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formData});
      setextraImgLoader(false);
      setarray([...array, ...res.data.data]);
    }
  };

  // Product Documentation
  const HandleDocumentation = async (file) => {
    setDocumentationLoader(true);
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    let parts = file?.name?.split('.');
    let Ext = parts[parts.length - 1];
    if (
      Ext === 'jpg' ||
      Ext === 'png' ||
      Ext === 'jpeg' ||
      Ext === 'webp' ||
      Ext === 'gif' ||
      (Ext === 'pdf' && file.size < 200000000)
    ) {
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'product');
      const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formData});
      setDocumentationimage(res.data.data[0]);
      setdocumentationErr(false);
    } else {
      setdocumentationErr(true);
    }
    setDocumentationLoader(false);
  };

  useEffect(() => {
    GetCategories();
    GetManufactures();
    GetAllProducts();
    // GetAllGroupProducts();
    GetAllTabs();
    GetGroupVarients();
  }, []);

  // remove perticular image from selected imagesss
  const removeImages = (index) => {
    if (window.confirm('Are You Sure To Remove This Image?')) {
      if (index > -1) {
        (
          // only splice array when item is found
          array.splice(index, 1)
        ); // 2nd parameter means remove one item only
      }
      setarray([...array]);
    }
  };
  // Get Varients Group
  const [varientGRP, setVarientGRP] = useState({});
  const GetGroupVarients = async () => {
    try {
      const data = await API({ url: '/super/admin/dummydata/dummygroupvariant/getDummyGroupVariants', method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setVarientGRP(data.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // varients group section
  let [groupVariant, setGroupVarient] = useState({});
  let [attributes, setAttributes] = useState([]);
  const HandleVarientimage = async (file) => {
    const varientData = new FormData();
    varientData.append('file', file);
    varientData.append('folder', 'category');
    const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: varientData});
    const img = await res.data.data[0];
    return img;
    // setVarient({ ...varient, varient_img: img[0] });
  };

  const GroupValueSet = () => {
    let updatedAttribute = [];
    if (groupVariant.group_value) {
      if (attributes.length === 0) {
        setAttributes([{ ...groupVariant, group_value: [groupVariant.group_value] }]);
      } else {
        let isGroupAlreadyExists = attributes.find((x) => x.group_name === groupVariant.group_name);
        if (isGroupAlreadyExists) {
          let isGroupValueExists = isGroupAlreadyExists.group_value.find(
            (x) => x === groupVariant.group_value,
          );
          if (!isGroupValueExists) {
            updatedAttribute = attributes.filter((x) => x.group_name !== groupVariant.group_name);
            isGroupAlreadyExists.group_value.push(groupVariant.group_value);
            setAttributes([...updatedAttribute, isGroupAlreadyExists]);
          }
        } else {
          setAttributes([
            ...attributes,
            { ...groupVariant, group_value: [groupVariant.group_value] },
          ]);
        }
      }

      setGroupVarient({ ...groupVariant, group_value: '' });
    }
  };

  const HandleVarientChange = (e) => {
    setGroupVarient({ ...groupVariant, [e.target.name]: e.target.value });
  };

  const [groupVariantData, setGroupVariantData] = useState([]);

  // multiply Variant Functionality
  const HandleMultipleVariants = () => {
    let attrs = [];
    for (let attributeObj of attributes) {
      attrs.push(attributeObj.group_value.map((v) => ({ [attributeObj.group_name]: v })));
    }
    if (attrs.length > 0) {
      attrs = attrs.reduce((a, b) => a.flatMap((d) => b.map((e) => ({ ...d, ...e }))));
      const groupData = [];
      for (let attr of attrs) {
        let object = Object.keys(attr);
        let values = Object.values(attr);
        let newArr = [];
        object.map((elem, index) => {
          newArr.push({
            group_name: elem,
            group_value: values[index],
          });
        });
        groupData.push({
          group: newArr,
        });
      }
      setGroupVariantData(groupData);
    }
  };

  useEffect(() => {
    GroupValueSet();
  }, [groupVariant]);
  useEffect(() => {
    HandleMultipleVariants();
  }, [attributes]);

  // Discount HandleSection
  const [Discount, setDiscount] = useState({
    dis_quantity: 1,
    discount_value: 0,
    product_discount_type: 'Select discount type',
  });
  const [Discounts, setDiscounts] = useState([]);
  const HandleDiscountChange = (e) => {
    setDisErr(false);
    setDiscount({ ...Discount, [e.target.name]: e.target.value });
  };
  const [disErr, setDisErr] = useState(false);
  const { product_discount_type, dis_quantity, discount_value } = Discount;
  const HandleDiscount = (e) => {
    e.preventDefault();
    if (
      product_discount_type <= 0 ||
      product_discount_type === 'Select discount type' ||
      dis_quantity === '' ||
      discount_value <= 0 ||
      disErr
    ) {
      setDisErr(true);
    } else {
      setDiscounts((prev) => [...prev, { ...Discount, start_date: startDate, end_date: endDate }]);
      setIsNotification(true);
      setNotificationMsg('Coupon Added SuccessFully');
      setSeverity('success');
    }
  };
  const [editDiscountbtn, setEditDiscountbtn] = useState({ visible: false, editIndex: '' });
  // Edit Product Discount
  const editDiscount = (e) => {
    e.preventDefault();
    if (
      product_discount_type <= 0 ||
      product_discount_type === 'Select discount type' ||
      dis_quantity === '' ||
      discount_value <= 0 ||
      disErr
    ) {
      setDisErr(true);
    } else {
      setDiscounts([
        ...Discounts.filter((x, index) => index !== editDiscountbtn.editIndex),
        Discount,
      ]);
      setDiscount({
        dis_quantity: 1,
        discount_value: 0,
        product_discount_type: 'Select discount type',
      });
      setEditDiscountbtn({ visible: false, editIndex: '' });
    }
    setIsNotification(true);
    setNotificationMsg('Discount Edited Successfully');
    setSeverity('success');
  };
  const RemoveProductDiscount = (e, ind) => {
    e.preventDefault();
    let UpdatedArray = Discounts?.filter((x, index) => index !== ind);
    setDiscounts(UpdatedArray);
  };

  const [specification, setSpecification] = useState({
    specification_field: '',
    specification_value: '',
  });
  const [specifications, setSpecifications] = useState([]);
  const HandleSpecificationChange = (e) => {
    setSpecification({ ...specification, [e.target.name]: e.target.value });
  };
  const HandleSpecification = (e) => {
    e.preventDefault();
    if (!specification.specification_field || !specification.specification_value) return;
    setSpecifications((prev) => [...prev, specification]);
    setSpecification({ specification_field: '', specification_value: '' });
  };
  const [allProduct, setAllProduct] = useState();
  const GetAllProducts = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyproduct/GetProductsListForCrossSell`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setAllProduct(data?.data?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  //MUI autocomplete filter for Category
  const filterOptionsForCategory = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.category_name?.toLowerCase()} ${option?.category_slug_name?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };

  // get Categories from client
  const [Categories, setCategories] = useState([]);
  const GetCategories = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummycategory/getDummyCategorys`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setCategories(data?.data?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  //get tabs from client
  const [allTab, setAllTab] = useState();
  const GetAllTabs = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyextratabs/GetExtraTabsForProduct`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setAllTab(data?.data?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // save Category Stats
  const HandleCategory = (id, name) => {
    if (id) {
      const cat = selectCat.find((x) => x.id === id);
      if (!cat) {
        setProductStats({ ...ProductStats, selectCat: [...selectCat, { id, name }] });
      }
    }
  };
  const handleRemovecategory = (id) => {
    const removecat = selectCat.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selectCat: removecat });
  };
  const HandleRelatedProduct = (id, name) => {
    if (id) {
      const pro = selatedRelated.find((x) => x.id === id);
      if (!pro) {
        setProductStats({ ...ProductStats, selatedRelated: [...selatedRelated, { id, name }] });
      }
    }
  };
  const handleRemoverelated = (id) => {
    const removeRelated = selatedRelated.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selatedRelated: removeRelated });
  };
  const HandleBundleProduct = (id, name) => {
    if (id) {
      const pro = selatedBundle.find((x) => x.id === id);
      if (!pro) {
        setProductStats({ ...ProductStats, selatedBundle: [...selatedBundle, { id, name }] });
      }
    }
  };
  const handleRemovebundle = (id) => {
    const removeBundle = selatedBundle.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selatedBundle: removeBundle });
  };
  const HandleUpsellProduct = (id, name) => {
    if (id) {
      const pro = selatedUpsell.find((x) => x.id === id);
      if (!pro) {
        setProductStats({ ...ProductStats, selatedUpsell: [...selatedUpsell, { id, name }] });
      }
    }
  };
  const handleRemoveUpsell = (id) => {
    const FilteredData = selatedUpsell.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selatedUpsell: FilteredData });
  };

  const HandleCrossellProduct = (id, name) => {
    if (id) {
      const pro = selatedCrossell.find((x) => x === id);
      if (!pro) {
        setProductStats({ ...ProductStats, selatedCrossell: [...selatedCrossell, { id, name }] });
      }
    }
  };
  const HandleRemoveCrossSell = (id) => {
    const FilteredData = selatedCrossell.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selatedCrossell: FilteredData });
  };

  const [Brands, setBrands] = useState([]);
  const GetManufactures = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummybrand/getSelectedManufacturer`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setBrands(data.data ? data.data : []);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // save selected Brand
  const HandleTabs = (id, name) => {
    if (id) {
      const tabs = selectTabs.find((x) => x.id === id);
      // HandleSubmit();
      if (!tabs) {
        setSelectedTabs([...selectTabs, { id, name }]);
      }
    }
  };
  const HandleRemoveTabs = (id) => {
    const filtered = selectTabs.filter((x) => x.id !== id);
    setSelectedTabs(filtered);
  };
  const [slugField, setSlugField] = useState(false);
  const [slugName, setSlugName] = useState();
  const handleSlug = async (slug) => {
    if (product_name?.length > 0) {
      setSlugField(true);
    }
    setSlugName(await GenerateSlugName(slug));
  };
  const [shortdescription, setShortDescription] = useState();
  //Submit form Data
  const HandleSubmit = async (e, key) => {
    e.preventDefault();
    let groupbody = {
      categories: selectCat?.map((x) => x.id),
      // product_slug_name: slugName,
      ...(key === "publish" ? { product_slug_name: slugName } : { draft_product_slug_name: slugName }),
      product_type: productType,
      product_name: product_name,
      description: description,
      short_description: shortdescription,
      sku_id: sku_id,
      mrp: mrp,
      stock_status: productType !== 'group' ? stock_status : 'in_stock',
      youtube_url: video_url,
      // product_launch_date: productType !== "affiliate" && timeDate ? timeDate : null,
      selling_price: selling_price,
      related_products: selatedRelated?.map((x) => x.id),
      up_sell: selatedUpsell?.map((x) => x.id),
      cross_sell: selatedCrossell?.map((x) => x.id),
      extratabs: selectTabs?.map((x) => x.id),
      product_badge: product_badge,
      quantity: productType !== 'group' ? quantity : '',
      product_unit: product_unit,
      min_order_quantity: min_order_quantity,
      country_of_origin: country_of_origin,
      product_return_policy,
      product_replacement_days,
      model: model,
      dimension: { length, breadth, height },
      weight: weight,
      variants: productType === 'variant' ? [...groupVariantData] : [],
      product_discount: productType === 'single' ? [...Discounts] : [],
      ...(selectBran ? { brand: [selectBran] } : ''),
      meta_description,
      meta_title,
      image: image,
      additional_images: [...array],
      product_tags: TagsArray?.length > 0 ? TagsArray : [],
      specification: [...specifications],
    };
    if (productType === 'group') {
      groupbody = {
        ...groupbody,
        grouped_product_ids: selatedBundle?.map((x) => x.id),
        extratabs: [],
      };
    } else if (productType === 'affiliate') {
      groupbody = {
        ...groupbody,
        button_text: affiliateData?.button_text,
        affiliate_url: affiliateData?.affiliate_url
      }
    } else if (Documentationimage) {
      groupbody = {
        ...groupbody,
        product_user_manual: Documentationimage
      }
    } else {
      groupbody = {
        ...groupbody,
      };
    }
    if (key === "publish") {
      if (
        product_name === '' ||
        mrp === '' ||
        image === '' ||
        Documentationimage === '' ||
        weight < 0.5 ||
        height < 10 ||
        breadth < 10 ||
        length < 10 ||
        (productType === "affiliate" ? affiliateData?.button_text === '' || affiliateData.affiliate_url === '' : false) ||
        enableSlug ||
        !image || !isValidVideoLink
      ) {
        setErr(true);
        if (weight < 0.5 || height < 10 || breadth < 10 || length < 10) {
          setIsNotification(true);
          setNotificationMsg('Please enter valid value of dimention!');
          setSeverity('error');
        }
      } else {
        if (!priceErr) {
          try {
            const data = await API({ url: '/super/admin/dummydata/dummyproduct/addDummyProduct', method: 'post', data: groupbody});
            if (data.status === 200 || data.status === 304) {
              navigate('/dummy-data/dummy-product');
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
        }
      }
    } else {
      if (product_name === '') {
        setErr(true)
      } else {
        if (!priceErr) {
          try {
            const data = await API({ url: '/super/admin/dummydata/dummyproduct/addProductInDraft', method: 'post', data: groupbody});
            if (data.status === 200 || data.status === 304) {
              navigate('/dummy-data/dummy-product');
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
        }
      }
    }

  };
  // remove item from Varient Data
  const HandleRemove = (index, e) => {
    e.preventDefault();
    const UpdateTable = groupVariantData.filter((elem, ind) => ind !== index);
    setGroupVariantData(UpdateTable);
  };

  // MUI Autocomplete Filter options
  const filterOptionsForProduct = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.product_name?.toLowerCase()} ${option?.sku_id?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };

  // drag And Drop
  const DragItem = useRef();
  const DragOverItem = useRef();
  // sorting DnD
  const HandleSort = (e) => {
    e.preventDefault();
    let images_array = [...array];
    const dragItemContent = images_array.splice(DragItem.current, 1)[0];
    images_array.splice(DragOverItem.current, 0, dragItemContent);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = images_array;
    setarray(FinalArray);
  };

  // Save Tags In Array
  const HandleArray = () => {
    if (tags.includes(',')) {
      let TagSeperator = tags.split(',');
      let finalArray = [];
      let productTags = [...TagsArray, ...TagSeperator];
      productTags.filter((tag) => {
        const searchRegex = new RegExp(`^${tag.trim()}$`, 'i');
        let exists = finalArray.some((finalTag) => searchRegex.test(finalTag));
        if (!exists) {
          finalArray.push(tag.trim());
        }
      });
      setTagsArray(finalArray);
    } else {
      const searchRegex = new RegExp(`^${tags}$`, 'i');
      const exists = TagsArray.some((tag) => searchRegex.test(tag));
      if (!exists) {
        setTagsArray((prev) => [...prev, tags]);
      }
    }
    setTags('');
  };

  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      // only splice array when item is found
      TagsArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setTagsArray([...TagsArray]);
  };
  const [addCategory, setAddCategory] = useState(false);

  const [addBrand, setAddBrand] = useState(false);
  const [isSpecification, setIsSpecification] = useState(false);
  const [isDimention, setIsDimention] = useState(false);
  const inputs = document.querySelectorAll('input[type="number"]');
  for (const input of inputs) {
    input.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [openYoutubeInfo, setOpenYoutubeInfo] = useState(false);

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const ShortquillRef = useRef(null)
  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
  };
  const handleLongDescQuillChange = (value) => {
    setDescription(value)
  };

  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const [descType, setDescType] = useState()
  const { htmlPP, code } = html
  const handleViewHtml = (e, type) => {
    e.preventDefault();
    setDescType(type)
    let quill;
    if (type === 1) {
      quill = quillRef.current.getEditor();
    }
    else {
      quill = ShortquillRef.current.getEditor()
    }
    const htmlContent = quill.root.innerHTML
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };

  const saveHTML = () => {
    setHtml({ ...html, htmlPP: false })
    if (descType === 1) {
      setDescription(code)
    }
    else {
      setShortDescription(code)
    }
    setDescType()
  }

  return (
    <>
      <div className='h-auto relative overflow-y-auto pb-[130px]'>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <div className='flex items-end justify-between w-full overflow-auto pb-4'>
          <div>
            <Breadcrumbs />
            <h1 className='text-xl md:text-2xl font-medium text-primary'>Add new product</h1>
          </div>
          <div className='flex '>
            <div className='flex items-center space-x-2'>
              <button
                className='flex items-center secondary-btn text-white text-sm font-medium py-2.5 px-16 rounded-[10px] transition duration-300'
                type='submit'
                onClick={(e) => HandleSubmit(e, "draft")}
              >
                Save draft
              </button>
              <button
                className='flex items-center btn text-white text-sm font-medium py-2.5 px-16 rounded-[10px] transition duration-300'
                type='submit'
                onClick={(e) => HandleSubmit(e, "publish")}
              >
                Add product
              </button>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap'>
          <form className='w-[90%] lg:w-[70%] xl:w-[60%] bg-transparent mx-auto rounded-lg'>
            <div className='relative mt-4 flex flex-col min-w-0 break-words bg-transparent w-full'>
              <div className='flex-auto'>
                <div>
                  <div id='link1'>
                    <div className='w-full space-y-4'>
                      <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                        <div className='w-full'>
                          <label
                            htmlFor='product_name'
                            className='flex items-center justify-between'
                          >
                            Product Title
                            <p className='text-xs text-gray-500 font-normal'>
                              only 60 characters allowed
                            </p>
                          </label>
                          <input
                            className={
                              err && product_name?.length === 0
                                ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                            }
                            type='text'
                            id='product_name'
                            name='product_name'
                            maxLength={60}
                            value={product_name}
                            onChange={(e) => {
                              HandleInput(e);
                              setwarn(false);
                            }}
                            placeholder='Enter product title'
                            onBlur={() => handleSlug(product_name)}
                          />
                          {err && product_name?.length === 0 ? (
                            <p className='text-red-500 text-xs'>Please enter product name</p>
                          ) : (
                            <></>
                          )}
                          {warn && (
                            <h1 className='text-sm text-red-500'>
                              Please enter product name to generate description
                            </h1>
                          )}
                        </div>
                        {slugField && (
                          <div className='w-full'>
                            <label htmlFor='product_slug_name'>Product Slug Name</label>
                            <div
                              className={`flex items-center justify-between w-full border ${err && enableSlug ? 'border-red-500' : 'border-gray-300'
                                } rounded-[4px]`}
                            >
                              <input
                                className='w-full focus:outline-none'
                                type='text'
                                name='product_slug_name'
                                value={slugName}
                                onChange={(e) => setSlugName(e.target.value)}
                                disabled={!enableSlug}
                                onBlur={async (e) =>
                                  setSlugName(await GenerateSlugName(e.target.value))
                                }
                              />
                              {enableSlug ? (
                                <Check
                                  onClick={async () => {
                                    setSlugName(await GenerateSlugName(slugName));
                                    setEnableSlug(!enableSlug);
                                    setErr(false);
                                  }}
                                  className='cursor-pointer text-green-500 p-1 h-8 w-8 focus:outline-none'
                                />
                              ) : (
                                <Edit
                                  onClick={() => setEnableSlug(!enableSlug)}
                                  className='cursor-pointer text-blue-500 p-1 h-7 w-7'
                                />
                              )}
                            </div>
                            {err && enableSlug && (
                              <p className='text-xsm text-red-500'>Please save this changes</p>
                            )}
                          </div>
                        )}
                        <div className='mb-4'>
                          <label>Product type</label>
                          <div className='relative'>
                            <select
                              className='w-full'
                              placeholder='Select product type'
                              onChange={(e) => setProductType(e.target.value)}
                            >
                              <option value='single' selected>
                                Single product
                              </option>
                              <option value='variant'>Variant product</option>
                              <option value='group'>Group product</option>
                              <option value='affiliate'>Affiliate product</option>
                            </select>
                            <div className='select-arrow'></div>
                          </div>
                        </div>
                        {allProduct &&
                          allProduct?.records?.length >= 0 &&
                          productType === 'group' ? (
                          <>
                            <div className='mb-4'>
                              <label htmlFor='Description'>Group/bundle product</label>

                              <Autocomplete
                                className='autocomplete'
                                filterOptions={filterOptionsForProduct}
                                options={allProduct?.records ? allProduct?.records : []}
                                getOptionLabel={(products) => products.product_name}
                                onChange={(e, v) => HandleBundleProduct(v?._id, v?.product_name)}
                                renderInput={(products) => (
                                  <TextField placeholder='Select bundle product' {...products} />
                                )}
                              />
                              <div className='flex flex-wrap mt-2'>
                                {ProductStats?.selatedBundle.map((elem, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                                    >
                                      <span>{elem.name}</span>
                                      <X
                                        className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                        onClick={() => handleRemovebundle(elem.id)}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div>
                          <div className='flex space-x-3 mb-1 justify-between'>
                            <label htmlFor='Description'>Product Short Description</label>
                            <div className='flex justify-between items-center'>

                              <section
                                className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                                onClick={(e) => handleViewHtml(e, 2)}
                              >
                                View HTML
                              </section>
                            </div>
                          </div>
                          <ReactQuill
                            modules={module}
                            ref={ShortquillRef}
                            className='w-full h-auto rounded-[10px]'
                            theme='snow'
                            name='description'
                            value={
                              shortdescription?.length &&
                              shortdescription?.split(' ').slice(0, 280).join(' ')
                            }
                            onChange={handleShortDescQuillChange}
                            placeholder='Enter description'
                          />

                          <p className='w-full text-xs mt-0 text-gray-400'>of 280 words allowed</p>
                        </div>
                        <div>
                          <div className='flex space-x-3 justify-between items-center mb-1'>
                            <label htmlFor='Description'>Product Long Description</label>
                            <div className='flex justify-between items-center'>
                              <section
                                className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                                onClick={(e) => handleViewHtml(e, 1)}
                              >
                                View HTML
                              </section>
                            </div>
                          </div>
                          <ReactQuill
                            modules={module}
                            className='w-full h-auto'
                            ref={quillRef}
                            theme='snow'
                            name='description'
                            value={
                              description?.length &&
                              description?.split(' ').slice(0, 5000).join(' ')
                            }
                            onChange={handleLongDescQuillChange}
                            placeholder='Enter description'
                          />
                          <div className='flex items-center justify-between'>
                            <p className='text-end text-xs mt-0 text-gray-400'>
                              of 5000 words allowed
                            </p>
                          </div>
                        </div>
                      </div>
                      <details
                        open
                        className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                      >
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                          Media
                          <span className='transition group-open:rotate-180'>
                            <ChevronDown className='text-black w-4 h-5' />
                          </span>
                        </summary>
                        <div className='w-full'>
                          <label htmlFor='product_name'>featured image</label>
                            <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} format="image" folder="product" />
                            {imgErr && (
                              <span className='text-sm bg-yellow-100 p-1 rounded-[10px]'>
                                <b>NOTE: </b> File Should be in jpg, jpeg, png format Max Size 10 MB
                              </span>
                            )}
                        </div>
                        <div className='w-full'>
                          <label htmlFor='product_name'>Additional images</label>
                          {array?.length === 0 ? (
                            <>
                              <label
                                htmlFor='additional-file'
                                className='flex items-center p-3 min-h-[100px] justify-center border border-gray-300 rounded cursor-pointer hover:bg-gray-50 border-dashed dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
                              >
                                <div className='text-center cursor-pointer'>
                                  <input
                                    id='additional-file'
                                    type='file'
                                    name='image'
                                    multiple
                                    onChange={(e) => HandleImages(e.target.files)}
                                    className='hidden'
                                  />
                                  <div className=''>
                                    {array?.length === 0 && !Extraimgloader ? (
                                      <>
                                        <svg
                                          className='w-7 h-7 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                                          aria-hidden='true'
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 20 16'
                                        >
                                          <path
                                            stroke='currentColor'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth={1}
                                            d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                                          />
                                        </svg>
                                        <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                                          <span className='font-medium'>Click to upload</span>
                                        </p>
                                        <p className='text-xs font-normal mb-1 text-gray-500 dark:text-gray-400'>
                                          Recommended size 800 x 800px
                                        </p>
                                      </>
                                    ) : Extraimgloader === true ? (
                                      <div className='w-[100px] mx-auto h-[100px] flex items-center justify-center mt-2 p-1 border border-gray-300'>
                                        <div className='nb-spinner'></div>
                                      </div>
                                    ) : (
                                      <div className='w-[100px] mx-auto h-[100px] flex items-center justify-center mt-2 p-1 border border-gray-300'>
                                        <img
                                          className='w-24 mx-auto border p-1 mt-1 border-gray-300'
                                          src={image}
                                          alt={image}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </label>
                            </>
                          ) : (
                            <div className='flex items-start min-h-[210px] space-x-4 p-3  border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 '>
                              {Extraimgloader === true ? (
                                <div className='w-full h-[180px] flex items-center justify-center'>
                                  <div className='nb-spinner'></div>
                                </div>
                              ) : (
                                <div>
                                  {array?.length !== 0 && (
                                    <div className='flex flex-wrap'>
                                      {array?.map((x, index) => {
                                        return (
                                          <>
                                            <div
                                              key={index}
                                              className='relative flex items-center justify-center rounded-[10px] mb-2 me-2 border w-[90px] h-[90px] hover:border-2 hover:border-black box-border'
                                            >
                                              <img
                                                src={x}
                                                alt={x}
                                                draggable
                                                onDragStart={(e) => (DragItem.current = index)}
                                                onDragEnter={(e) => (DragOverItem.current = index)}
                                                onDragEnd={HandleSort}
                                                onClick={() => {
                                                  setDeletePopup({
                                                    index: index,
                                                    visibility: true,
                                                  });
                                                }}
                                                className={
                                                  deletePopup?.visibility &&
                                                    deletePopup?.index === index
                                                    ? 'rounded-[10px] max-w-full max-h-full object-contain cursor-move hover:border-0'
                                                    : 'w-full max-h-full object-contain cursor-move'
                                                }
                                              ></img>
                                              {deletePopup.visibility &&
                                                deletePopup.index === index && (
                                                  <div className='text-white hover:border-0 border-3 top-0 right-0 absolute bg-black h-4 w-4 flex items-center justify-center'>
                                                    <X
                                                      className='text-white h-3 w-3 '
                                                      onClick={() => {
                                                        removeImages(index);
                                                        setDeletePopup({
                                                          index,
                                                          visibility: false,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                            </div>
                                          </>
                                        );
                                      })}
                                      <label className=' w-[90px] cursor-pointer h-[90px] border border-gray-300 hover:bg-gray-100 hover:border-gray-500 text-gray-500 hover:text-gray-800 rounded-[10px] flex items-center justify-center'>
                                        <div className='text-center text-sm'>
                                          <PlusCircle className='w-5 h-5 mx-auto' />
                                          <p className='text-sm'>Add more</p>
                                        </div>
                                        <input
                                          id='additional-file'
                                          type='file'
                                          name='image'
                                          multiple
                                          onChange={(e) => HandleImages(e.target.files)}
                                          className='hidden'
                                        />
                                      </label>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className='w-full'>
                          <label htmlFor='video_url' className='flex items-center'>
                            Youtube Video I-frame
                            <button className='ms-2' aria-describedby="youtubeinfo" variant="contained" onMouseEnter={(e) => { e.preventDefault(); setAnchorEl(e.currentTarget); setOpenYoutubeInfo(true) }}>
                              <QuestionCircleFill className='info-link cursor-pointer' />
                            </button>
                          </label>
                          <textarea
                            className={`w-full block mb-0 px-3 py-2 border ${!isValidVideoLink && !video_url === '' ? 'border-red-500' : 'border-gray-300'
                              } rounded-[10px] focus:outline-none`}
                            type='text'
                            id='video_url'
                            name='video_url'
                            value={video_url}
                            onChange={HandleInput}
                            onBlur={(e) => isYouTubeLink(e.target.value)}
                            placeholder='e.g. Please enter a YouTube I-frame link or Embed link.'
                          />
                          {!isValidVideoLink && !video_url === '' &&
                            <span className='text-xs block text-red-500'>Please enter valid video iframe</span>}
                        </div>
                      </details>
                      <details
                        open
                        className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                      >
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                          Pricing & Quantity
                          <span className='transition group-open:rotate-180'>
                            <ChevronDown className='text-black w-4 h-5' />
                          </span>
                        </summary>
                        <div className='flex w-full mb-4 space-x-4'>
                          <div className='w-full contryCode'>
                            <label htmlFor='mrp' className='flex items-center'>
                              MRP
                              <Tooltip
                                title={`Enter the manufacturer's recommended retail price (MRP) for the product.`}
                                arrow
                                placement='right'
                                className='ml-2 -tracking-tighter'
                              >
                                <QuestionCircleFill className='info-link cursor-pointer' />
                              </Tooltip>
                            </label>
                            <div className='flex items-center'>
                              <p
                                disabled
                                className='px-4 py-2 border-y border-l border-gray-300 text-sm w-fit rounded-s-[4px]'
                              >
                                ₹{' '}
                              </p>
                              <input
                                className={
                                  (err && !mrp?.length) || (priceErr && mrp <= 0)
                                    ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                    : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                }
                                type='number'
                                min={1}
                                id='mrp'
                                name='mrp'
                                value={mrp}
                                onChange={(e) => { HandleInput(e); setPriceErr(false) }}
                                placeholder='e.g. 1500'
                                onBlur={() => mrp <= 0 ? setPriceErr(true) : ''}
                              />
                            </div>
                            {err && !mrp?.length ? (
                              <p className='text-red-500 text-xs'>Please enter mrp</p>
                            ) : priceErr && mrp <= 0 ?
                              <p className='text-red-500 text-xs'>MRP should be greater then 0</p> : (
                                <></>
                              )}
                          </div>
                          {productType !== 'variant' && (
                            <div className='w-full contryCode'>
                              <label className='flex items-center' htmlFor='selling_price'>
                                Selling price
                                <Tooltip
                                  title='This is the net sales price for your customers.'
                                  arrow
                                  placement='right'
                                  className='ml-2 -tracking-tighter'
                                >
                                  <QuestionCircleFill className='info-link cursor-pointer' />
                                </Tooltip>
                              </label>
                              <div className='flex items-center'>
                                <p
                                  disabled
                                  className='px-4 py-2 border-y border-l border-gray-300 text-sm w-fit rounded-s-[4px]'
                                >
                                  ₹
                                </p>
                                <input
                                  className={
                                    priceErr && selling_price <= 0
                                      ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                      : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                  }
                                  type='number'
                                  min={1}
                                  id='selling_price'
                                  name='selling_price'
                                  value={selling_price}
                                  onChange={(e) => { HandleInput(e); setPriceErr(false) }}
                                  placeholder='e.g. 1000'
                                // onBlur={() => { selling_price <= 0 ? setPriceErr(true) : '' }}
                                />
                              </div>
                              {
                                priceErr && selling_price <= 0 ? <p className='text-red-500 text-xs'>Selling price should be greater then 0</p> : ''
                              }
                            </div>
                          )}
                        </div>
                        <div className='flex items-center mb-4 space-x-4 w-full'>
                          {productType !== 'variant' && productType !== 'group' && (
                            <div className='w-full'>
                              <label className='flex items-center' htmlFor='quantity'>
                                Quantity
                                <Tooltip
                                  title='How many products should be available for sale?'
                                  arrow
                                  placement='right'
                                  className='ml-2 -tracking-tighter'
                                >
                                  <QuestionCircleFill className='info-link cursor-pointer' />
                                </Tooltip>
                              </label>
                              <input
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='number'
                                min={1}
                                id='quantity'
                                name='quantity'
                                value={quantity}
                                onChange={HandleInput}
                                placeholder='e.g. 10,20,30'
                              />
                            </div>
                          )}
                          <div className='w-full'>
                            <label htmlFor='Product Unit' className='flex items-center'>
                              Product Unit
                              <Tooltip
                                title='Specify the unit of measurement for the product.'
                                arrow
                                placement='right'
                                className='ml-2 -tracking-tighter'
                              >
                                <QuestionCircleFill className='info-link cursor-pointer' />
                              </Tooltip>
                            </label>
                            <input
                              className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                              type='text'
                              id='Product Unit'
                              name='product_unit'
                              value={product_unit}
                              onChange={HandleInput}
                              placeholder='e.g. Pieces'
                            />
                          </div>
                        </div>
                      </details>
                      {productType === 'affiliate' && (
                        <details
                          open
                          className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                        >
                          <summary className='text-base flex items-center justify-between font-medium text-black'>
                            Affiliate Details
                            <span className='transition group-open:rotate-180'>
                              <ChevronDown className='text-black w-4 h-5' />
                            </span>
                          </summary>
                          <div className='flex items-center mb-4 space-x-4 w-full'>
                            <div className='w-full'>
                              <label className='flex items-center' htmlFor='quantity'>
                                affiliate Button text
                              </label>
                              <input
                                className={`w-full px-3 py-2 border ${err && affiliateData?.button_text === '' ? 'border-red-400' : 'border-gray-300'} rounded-[10px] focus:outline-none`}
                                type='text'
                                id='button_text'
                                name='button_text'
                                value={affiliateData?.button_text}
                                onChange={(e) =>
                                  setAffiliateData({
                                    ...affiliateData,
                                    button_text: e.target.value,
                                  })
                                }
                                placeholder='e.g. Get it Here'
                              />
                              {
                                err && affiliateData?.button_text === '' && <span className='text-xs text-red-500'>Button text is required</span>
                              }
                            </div>
                          </div>
                          <div className='flex items-center mb-4 space-x-4 w-full'>
                            <div className='w-full'>
                              <label className='flex items-center' htmlFor='quantity'>
                                affiliate url
                              </label>
                              <input
                                className={`w-full px-3 py-2 border ${err && affiliateData?.affiliate_url === '' ? 'border-red-400' : 'border-gray-300'} rounded-[10px] focus:outline-none`}
                                type='text'
                                id='affiliate_url'
                                name='affiliate_url'
                                value={affiliateData?.affiliate_url}
                                onChange={(e) =>
                                  setAffiliateData({
                                    ...affiliateData,
                                    affiliate_url: e.target.value,
                                  })
                                }
                                placeholder='e.g. Affiliate link'
                              />
                              {
                                err && affiliateData?.affiliate_url === '' && <span className='text-xs text-red-500'>Affiliate URL is required</span>
                              }
                            </div>
                          </div>
                        </details>
                      )}
                      {productType !== 'group' && (
                        <details className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'>
                          <summary className='text-base flex items-center justify-between font-medium text-black'>
                            <div>
                              Category & Brand
                              <p className='text-xs text-gray-500 font-normal'>
                                Manage the categories and brands of your products
                              </p>
                            </div>
                            <span className='transition group-open:rotate-180'>
                              <ChevronDown className='text-black w-4 h-5' />
                            </span>
                          </summary>
                          <div className='max-w-full mb-4'>
                            <label
                              className='flex items-center justify-between'
                              htmlFor='product_name'
                            >
                              <div className='flex items-center'>
                                Categories
                                <Tooltip
                                  title='Where should the product be available on your site?'
                                  arrow
                                  placement='right'
                                  className='ml-2 -tracking-tighter'
                                >
                                  <QuestionCircleFill className='info-link cursor-pointer' />
                                </Tooltip>
                              </div>
                              <button
                                className='text-link hover:underline font-medium flex items-center rounded-[10px] space-x-2 transition-all duration-200 text-sm '
                                type='button'
                                onClick={() => setAddCategory(true)}
                              >
                                <Plus />
                                Add new category
                              </button>
                            </label>
                            <Autocomplete
                              className='autocomplete'
                              filterOptions={filterOptionsForCategory}
                              options={Categories?.records ? Categories.records : []}
                              getOptionLabel={(category) => category.category_name}
                              onChange={(e, v) => HandleCategory(v?._id, v?.category_name)}
                              renderInput={(categories) => (
                                <TextField placeholder='Select categories' {...categories} />
                              )}
                            />
                            <div className='flex flex-wrap mt-1'>
                              {ProductStats?.selectCat?.map((elem, index) => {
                                return (
                                  <div
                                    key={index}
                                    className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                                  >
                                    <span>{elem.name}</span>
                                    <X
                                      className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                      onClick={() => handleRemovecategory(elem.id)}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className='mb-4 overflow-y-auto'>
                            <label
                              htmlFor='Description'
                              className='flex items-center justify-between'
                            >
                              <div className='flex items-center'>
                                Brand
                                <Tooltip
                                  title='Specify the brand associated with the product.'
                                  arrow
                                  placement='right'
                                  className='ml-2 -tracking-tighter'
                                >
                                  <QuestionCircleFill className='info-link cursor-pointer' />
                                </Tooltip>
                              </div>
                              <button
                                className='text-link hover:underline font-medium flex items-center rounded-[10px] space-x-2 transition-all duration-200 text-sm '
                                type='button'
                                onClick={() => setAddBrand(true)}
                              >
                                <Plus />
                                Add new brand
                              </button>
                            </label>
                            <Autocomplete
                              className='autocomplete'
                              options={Brands?.data ? Brands?.data : []}
                              getOptionLabel={(brand) => brand?.brand_name}
                              onChange={(e, v) =>
                                v ? setSelectedBrands(v?._id) : setSelectedBrands('')
                              }
                              renderInput={(brands) => (
                                <TextField
                                  placeholder='Select brand'
                                  className='cursor-pointer'
                                  {...brands}
                                />
                              )}
                            />
                          </div>

                          <div className='mb-4'>
                            <label htmlFor='Description'>Related Products</label>

                            <Autocomplete
                              className='autocomplete'
                              filterOptions={filterOptionsForProduct}
                              options={allProduct?.records ? allProduct.records : []}
                              getOptionLabel={(products) => products.product_name}
                              onChange={(e, v) => HandleRelatedProduct(v?._id, v?.product_name)}
                              renderInput={(products) => (
                                <TextField placeholder='Select related products' {...products} />
                              )}
                            />
                            <div className='flex flex-wrap mt-1'>
                              {ProductStats?.selatedRelated?.map((elem, index) => {
                                return (
                                  <div
                                    key={index}
                                    className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                                  >
                                    <span>{elem.name}</span>
                                    <X
                                      className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                      onClick={() => handleRemoverelated(elem.id)}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </details>
                      )}
                      <details className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'>
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                          <div>
                            Details
                            <p className='text-xs text-gray-500 font-normal'>
                              Add additional information of product
                            </p>
                          </div>
                          <span className='transition group-open:rotate-180'>
                            <ChevronDown className='text-black w-4 h-5' />
                          </span>
                        </summary>
                        <>
                          <div className='flex items-center justify-between grid-cols-3 space-x-4 mb-4'>
                            <div className='w-full'>
                              <label htmlFor='SKU ID'>Sku Id</label>
                              <input
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='text'
                                id='sku_id'
                                name='sku_id'
                                value={sku_id}
                                onChange={HandleInput}
                                placeholder='e.g. 10123'
                              />
                            </div>
                            <div className='w-full'>
                              <label className='flex items-center' htmlFor='min_order_quantity'>
                                Minimum Order Quantity
                                <Tooltip
                                  title='The minimum quantity required to buy this product (set to 1 to disable this feature). E.g. if set to 3, customers will be able to purchase the product only if they take at least 3 in quantity.'
                                  arrow
                                  placement='right'
                                  className='ml-2 -tracking-tighter'
                                >
                                  <QuestionCircleFill className='info-link cursor-pointer' />
                                </Tooltip>
                              </label>
                              <input
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='number'
                                min={1}
                                id='min_order_quantity'
                                name='min_order_quantity'
                                value={min_order_quantity}
                                onChange={HandleInput}
                                placeholder='e.g. 1'
                              />
                            </div>
                          </div>

                          <div className='flex items-center justify-between grid-cols-3 space-x-4 mb-4'>
                            <div className='w-full'>
                              <label htmlFor='model'>Model</label>
                              <input
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='text'
                                id='model'
                                name='model'
                                value={model}
                                onChange={HandleInput}
                                placeholder='e.g. Model name'
                              />
                            </div>
                            <div className='w-full'>
                              <label htmlFor='product_badge'>Product Badge</label>
                              <input
                                type='text'
                                maxLength={20}
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                name='product_badge'
                                value={product_badge}
                                onChange={HandleInput}
                                placeholder='e.g. Trending product'
                              />
                            </div>
                            <div className='w-full'>
                              <label htmlFor='CountryOfOrigin'>Country Of Origin</label>
                              <div>
                                <Autocomplete
                                  className='autocomplete'
                                  options={CountryCodes ? CountryCodes : []}
                                  getOptionLabel={(x) => x.name}
                                  onChange={(e, v) =>
                                    setProdData({
                                      ...ProdData,
                                      country_of_origin: v?.name ? v.name : '',
                                    })
                                  }
                                  renderInput={(name) => (
                                    <TextField
                                      placeholder='Select country of origin'
                                      defaultValue='IN'
                                      className='select-none cursor-pointer border-black'
                                      {...name}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='flex justify-between space-x-4 mb-4 w-full'>
                            {
                              productType !== 'group' &&
                              <div className='w-full'>
                                <label htmlFor='Stock'>Stock Status</label>
                                <div className='relative'>
                                  <select
                                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] outline-none'
                                    name='stock_status'
                                    value={stock_status}
                                    onChange={HandleInput}
                                  >
                                    <option selected>in_stock</option>
                                    <option>out_of_stock</option>
                                    <option>available</option>
                                  </select>
                                  <div className='select-arrow'></div>
                                </div>
                              </div>
                            }
                            <div className='w-full'>
                              <label htmlFor='status_message'>Status Message</label>
                              <input
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='text'
                                name='status_message'
                                value={status_message}
                                onChange={HandleInput}
                                placeholder='e.g. Product Stock will be Available In 7 Days'
                              />
                            </div>
                            <div className='w-full'>
                              <label htmlFor='product_return_policy'>Product Return Status</label>
                              <div className='relative'>
                                <select
                                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] outline-none'
                                  name='product_return_policy'
                                  value={product_return_policy}
                                  onChange={HandleInput}
                                >
                                  <option>non_returnable</option>
                                  <option>returnable</option>
                                </select>
                                <div className='select-arrow'></div>
                              </div>
                            </div>
                            {product_return_policy === 'non_returnable' ? (
                              <></>
                            ) : (
                              <div className='w-full'>
                                <label htmlFor='product_replacement_days'>
                                  Product replacement validity
                                </label>

                                <input
                                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                  type='number'
                                  id='product_replacement_days'
                                  name='product_replacement_days'
                                  value={product_replacement_days}
                                  onChange={HandleInput}
                                  placeholder='e.g. 2'
                                />
                              </div>
                            )}
                          </div>

                          {allProduct &&
                            allProduct?.records?.length >= 0 &&
                            productType !== 'group' ? (
                            <div className='flex items-start justify-between grid-cols-3 space-x-4 mb-4'>
                              <div className='w-full'>
                                <label htmlFor='product_name'>Upsell Products</label>
                                <Autocomplete
                                  className='autocomplete'
                                  filterOptions={filterOptionsForProduct}
                                  options={allProduct?.records}
                                  getOptionLabel={(product) => product.product_name}
                                  onChange={(e, v) => HandleUpsellProduct(v?._id, v?.product_name)}
                                  renderInput={(products) => (
                                    <TextField placeholder='Select upsell products' {...products} />
                                  )}
                                />
                                <div className='flex flex-wrap mt-1'>
                                  {ProductStats?.selatedUpsell?.map((elem, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                                      >
                                        <span>{elem.name}</span>
                                        <X
                                          className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                          onClick={() => handleRemoveUpsell(elem.id)}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className='w-full'>
                                <label htmlFor='product_name'>Cross Products</label>
                                <Autocomplete
                                  className='autocomplete'
                                  filterOptions={filterOptionsForProduct}
                                  options={allProduct?.records ? allProduct.records : []}
                                  getOptionLabel={(product) => product.product_name}
                                  onChange={(e, v) =>
                                    HandleCrossellProduct(v?._id, v?.product_name)
                                  }
                                  renderInput={(products) => (
                                    <TextField
                                      placeholder='Select cross sell products'
                                      {...products}
                                    />
                                  )}
                                />
                                <div className='flex flex-wrap mt-1'>
                                  {ProductStats?.selatedCrossell?.map((elem, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                                      >
                                        <span>{elem.name}</span>
                                        <X
                                          className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                          onClick={() => HandleRemoveCrossSell(elem.id)}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              {allProduct && allTab && allTab.records ? (
                                <div className='w-full flex items-center justify-between grid-cols-2 space-x-4 '>
                                  <div className='w-full '>
                                    <label htmlFor='product_name'>Custom Tabs</label>
                                    <Autocomplete
                                      className='autocomplete'
                                      options={allTab?.records}
                                      getOptionLabel={(tab) => tab.tab_title}
                                      onChange={(e, v) => HandleTabs(v?._id, v?.tab_title)}
                                      renderInput={(tabs) => (
                                        <TextField placeholder='Select custom tabs' {...tabs} />
                                      )}
                                    />
                                    <div className='flex flex-wrap mt-1'>
                                      {selectTabs?.map((elem, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                                          >
                                            <span>{elem.name}</span>
                                            <X
                                              className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                              onClick={() => HandleRemoveTabs(elem.id)}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* documentation */}
                          <div className='w-full xl:w-full mb-4'>
                            <label htmlFor='product_name'>Product Documentation</label>
                            <label
                              htmlFor='dropzone-file'
                              className='flex flex-col items-center p-3 justify-center border border-dashed border-gray-300 rounded cursor-pointer hover:bg-gray-50'
                            >
                              <label className='text-center font-medium text-sm cursor-pointer'>
                                <input
                                  type='file'
                                  name='image'
                                  onChange={(e) => HandleDocumentation(e.target.files[0])}
                                  className='hidden'
                                />
                                <div className=''>
                                  {!Documentationimage && !Documentationloader ? (
                                    <>
                                      <svg
                                        className='w-8 h-8 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                                        aria-hidden='true'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 20 16'
                                      >
                                        <path
                                          stroke='currentColor'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          strokeWidth={2}
                                          d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                                        />
                                      </svg>
                                      <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                                        <span className='font-medium'>Click to upload</span>
                                      </p>
                                      <p className='text-xs mb-1 font-normal text-gray-500 dark:text-gray-400'>
                                        PDF, SVG, PNG, JPG or GIF
                                      </p>
                                    </>
                                  ) : Documentationloader === true ? (
                                    <div className='nb-spinner'></div>
                                  ) : (
                                    <img
                                      className='w-24 border mx-auto p-1 mt-2 border-gray-300'
                                      src={Documentationimage}
                                      alt={Documentationimage}
                                    />
                                  )}
                                </div>
                              </label>
                              {documentationErr && (
                                <span className='text-sm bg-yellow-100 p-1 rounded-[10px]'>
                                  <b>NOTE: </b> File Should be in jpg, jpeg, png, pdf format Max
                                  Size 10 MB
                                </span>
                              )}
                            </label>
                          </div>
                          {/* coutdown */}
                          {/* {
                            productType !== "affiliate" &&
                            <div className='w-full mb-4'>
                              <label htmlFor='CountryOfOrigin'>Product Launch Date</label>
                              <label className='relative w-full block cursor-pointer'>
                                <DatePicker
                                  className='border border-gray-300 focus:outline-none focus:border-gray-400 w-full'
                                  showTimeSelect
                                  placeholderText='Select product launch date'
                                  minDate={new Date()}
                                  minTime={new Date(0, 0, 0, 0, 0)}
                                  maxTime={new Date(0, 0, 0, 23, 30)}
                                  selected={timeDate}
                                  onChange={(date) => setTimeDate(date)}
                                  dateFormat='MMMM d, yyyy h:mm aa'
                                />
                                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                                  <CalendarFill className='text-gray-600 w-3 h-3' />
                                </span>
                              </label>
                            </div>
                          } */}
                          <div className='mb-4'>
                            <div className='flex items-center justify-between'>
                              <label
                                className='flex items-center'
                                htmlFor='product_tags'
                              >
                                <p className='flex items-center'>
                                  Product Tags
                                  <Tooltip
                                    title='Tags facilitate the product search for customers using the search bar.'
                                    arrow
                                    placement='right'
                                    className='ml-2 -tracking-tighter'
                                  >
                                    <QuestionCircleFill className='info-link cursor-pointer' />
                                  </Tooltip>
                                </p>
                              </label>
                              <p className='text-gray-500 font-normal text-xs'>
                                Use <b className='text-black'>Enter</b> key to separate the tags
                              </p>
                            </div>
                            <input
                              className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                              type='text'
                              id='product_tags'
                              name='product_tags'
                              value={tags}
                              onChange={(e) => setTags(e.target.value)}
                              onKeyDown={(e) => (e.key === 'Enter' ? HandleArray() : '')}
                              placeholder='e.g. Tag1,Tag2'
                            />
                            <div className='flex flex-wrap items-center mt-1'>
                              {TagsArray &&
                                TagsArray.map((elem, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                                    >
                                      {elem}
                                      <X
                                        className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                        onClick={() => RemoveTag(index)}
                                      />
                                    </span>
                                  );
                                })}
                            </div>
                          </div>
                          <div className='mb-4'>
                            <div className='w-full px-3 py-2 border border-gray-300 rounded-[10px]'>
                              <label htmlFor='specification'>Product Specification</label>
                              <hr className='border-gray-200 my-3'></hr>
                              {!isSpecification && (
                                <p
                                  className='flex items-center space-x-2 ml-4 text-link font-medium hover:underline cursor-pointer'
                                  onClick={() => setIsSpecification(true)}
                                >
                                  <Plus />
                                  Add specification option
                                </p>
                              )}
                              {isSpecification && (
                                <>
                                  <form>
                                    <div className='flex items-start mb-1'>
                                      <textarea
                                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none mr-2'
                                        type='text'
                                        id='specification_field'
                                        name='specification_field'
                                        value={specification.specification_field}
                                        onChange={HandleSpecificationChange}
                                        placeholder='e.g. battery'
                                        required
                                      />
                                      <textarea
                                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none mr-2 '
                                        type='text'
                                        id='specification_value'
                                        name='specification_value'
                                        value={specification.specification_value}
                                        onChange={HandleSpecificationChange}
                                        placeholder='e.g. 5000mh'
                                        required
                                      />
                                      <Tooltip title='Remove' arrow>
                                        <button onClick={() => setIsSpecification(false)}>
                                          <X className='w-4 h-4 cursor-pointer' />
                                        </button>
                                      </Tooltip>
                                    </div>
                                    <button
                                      type='button'
                                      onClick={(e) => HandleSpecification(e)}
                                      className='inline-block secondary-btn text-white text-sm font-medium mt-2 py-2 px-8 rounded-[10px] transition duration-300'
                                    >
                                      Add specification
                                    </button>
                                  </form>
                                  <div className='mt-3 space-y-1'>
                                    {specifications.map((elem, index) => {
                                      return (
                                        <>
                                          <div className='flex items-center'>
                                            <input
                                              key={index}
                                              type='text'
                                              name='specification_field'
                                              className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none mr-2 mt-1'
                                              defaultValue={elem.specification_field}
                                              onChange={(e) =>
                                              (specifications[index].specification_field =
                                                e.target.value)
                                              }
                                            />
                                            <input
                                              key={index}
                                              type='text'
                                              name='specification_value'
                                              className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none mr-2 mt-1'
                                              defaultValue={elem.specification_value}
                                              onChange={(e) =>
                                              (specifications[index].specification_value =
                                                e.target.value)
                                              }
                                            />
                                            <Tooltip title='Delete' arrow>
                                              <button className=''>
                                                <Trash3Fill
                                                  className='cursor-pointer w-4 h-4 ms-2 text-gray-500 hover:text-gray-800'
                                                  onClick={() => {
                                                    const selectedSpec = specifications.filter(
                                                      (val, key) => key !== index,
                                                    );
                                                    setSpecifications(selectedSpec);
                                                  }}
                                                />
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className='mb-4'>
                            <div className='w-full px-3 py-2 border border-gray-300 rounded-[10px]'>
                              <div className='flex items-center justify-between'>
                                <label htmlFor='specification'>Product Dimensions</label>
                                {isDimention && (
                                  <Tooltip title='Remove' arrow>
                                    <button onClick={() => setIsDimention(false)}>
                                      <X className='w-4 h-4 cursor-pointer' />
                                    </button>
                                  </Tooltip>
                                )}
                              </div>
                              <hr className='border-gray-200 my-3'></hr>
                              {!isDimention && (
                                <p
                                  className='flex items-center space-x-2 ml-4 text-link font-medium hover:underline cursor-pointer'
                                  onClick={() => setIsDimention(true)}
                                >
                                  <Plus />
                                  Add dimension option
                                </p>
                              )}
                              {isDimention && (
                                <>
                                  <div className='flex items-center justify-between grid-cols-2 space-x-4 mb-4'>
                                    <div className='w-full'>
                                      <label htmlFor='length'>Length (in cm)</label>
                                      <input
                                        className={
                                          err && length < 10
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                        }
                                        type='number'
                                        min={1}
                                        id='length'
                                        name='length'
                                        value={length}
                                        onChange={HandleInput}
                                        placeholder='e.g. 100'
                                      />
                                      {err && length < 10 ? (
                                        <p className='text-red-500 text-xs'>
                                          Length must be greater than or equal to 10
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className='w-full'>
                                      <label htmlFor='height'>Height (in cm)</label>
                                      <input
                                        className={
                                          err && height < 10
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                        }
                                        type='number'
                                        min={1}
                                        id='height'
                                        name='height'
                                        value={height}
                                        onChange={HandleInput}
                                        placeholder='e.g. 100'
                                      />
                                      {err && height < 10 ? (
                                        <p className='text-red-500 text-xs'>
                                          Height must be greater than or equal 10
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  <div className='flex items-center justify-between grid-cols-2 space-x-4 mb-4'>
                                    <div className='w-full'>
                                      <label htmlFor='breadth'>Breadth (in cm)</label>
                                      <input
                                        className={
                                          err && breadth < 10
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                        }
                                        type='number'
                                        min={1}
                                        id='breadth'
                                        name='breadth'
                                        value={breadth}
                                        onChange={HandleInput}
                                        placeholder='e.g. 100'
                                      />
                                      {err && breadth < 10 ? (
                                        <p className='text-red-500 text-xs'>
                                          Breadth must be greater than or equal 10
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className='w-full'>
                                      <label htmlFor='weight'>Weight (in kg)</label>
                                      <input
                                        className={
                                          err && weight < 0.5
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                        }
                                        type='number'
                                        min={1}
                                        id='weight'
                                        name='weight'
                                        value={weight}
                                        onChange={HandleInput}
                                        placeholder='e.g. 1'
                                      />
                                      {err && weight < 0.5 ? (
                                        <p className='text-red-500 text-xs'>
                                          Weight must be greater than or equal 0.5
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      </details>
                      {productType === 'variant' && productType !== 'affiliate' && (
                        <details className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'>
                          <summary className='text-base flex items-center justify-between font-medium text-black'>
                            <div>
                              Variants
                              <p className='text-xs text-gray-500 font-normal'>
                                Enhance your product catalog with variants
                              </p>
                            </div>
                            <span className='transition group-open:rotate-180'>
                              <ChevronDown className='text-black w-4 h-5' />
                            </span>
                          </summary>
                          <>
                            <div className='mb-4'>
                              <label htmlFor='group_name'>Group name</label>
                              <div className='relative'>
                                <select
                                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                  id='group_name'
                                  name='group_name'
                                  value={groupVariant.group_name}
                                  onChange={HandleVarientChange}
                                  placeholder='e.g. Blue,black,red'
                                >
                                  <option>Please select</option>

                                  {varientGRP.data &&
                                    varientGRP.data.records.map((elem, index) => {
                                      return (
                                        <>
                                          <option key={index}>{elem.group_name}</option>
                                        </>
                                      );
                                    })}
                                </select>
                                <div className='select-arrow'></div>
                              </div>
                            </div>
                            <div className='mb-4'>
                              <label htmlFor='group_value'>Group value</label>
                              <div className='relative'>
                                <select
                                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                  id='group_value'
                                  name='group_value'
                                  value={groupVariant.group_value}
                                  onChange={HandleVarientChange}
                                  placeholder='e.g. Blue,black,red'
                                >
                                  <option>Select Group Value</option>
                                  {varientGRP.data &&
                                    varientGRP.data.records.map((x) => {
                                      if (x.group_name === groupVariant.group_name) {
                                        return (
                                          x.group_value &&
                                          x.group_value.map((y, index) => {
                                            return (
                                              <>
                                                <option key={index}>{y}</option>
                                              </>
                                            );
                                          })
                                        );
                                      }
                                    })}
                                </select>
                                <div className='select-arrow'></div>
                              </div>
                            </div>
                            {groupVariantData && groupVariantData.length > 0 && (
                              <div className='varient-table'>
                                <div className='flex flex-col'>
                                  <div className='overflow-x-auto'>
                                    <div className='inline-block min-w-full py-2'>
                                      <div className='overflow-hidden rounded-[10px]'>
                                        <table className='w-full border rounded-[10px] block border-gray-300 text-center text-sm font-light dark:border-neutral-500  bg-white'>
                                          <thead className='border-b border-gray-300 font-medium dark:border-neutral-500 bg-[#f5f5f5]'>
                                            <tr>
                                              <th
                                                scope='col'
                                                className='border-r border-gray-300 px-6 py-2.5 font-medium dark:border-neutral-500'
                                              >
                                                Variant
                                              </th>
                                              <th
                                                scope='col'
                                                className='border-r border-gray-300 px-6 py-2.5 font-medium dark:border-neutral-500'
                                              >
                                                Image
                                              </th>
                                              <th
                                                scope='col'
                                                className='border-r border-gray-300 px-6 py-2.5 font-medium dark:border-neutral-500'
                                              >
                                                Price
                                              </th>
                                              <th
                                                scope='col'
                                                className='border-r border-gray-300 px-6 py-2.5 font-medium dark:border-neutral-500'
                                              >
                                                Quantity
                                              </th>
                                              <th
                                                scope='col'
                                                className='border-r border-gray-300 px-6 py-2.5 font-medium dark:border-neutral-500'
                                              >
                                                Sku id
                                              </th>
                                              <th
                                                scope='col'
                                                className='border-r border-gray-300 px-6 py-2.5 font-medium dark:border-neutral-500'
                                              >
                                                Remove
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {groupVariantData.map((data, index) => {
                                              return (
                                                <tr
                                                  key={index}
                                                  className='border-b dark:border-neutral-500'
                                                >
                                                  <td className='whitespace-nowrap border-r border-gray-300 px-6 py-4 dark:border-neutral-500 font-medium capitalize'>
                                                    {data.group.map((x, i) => {
                                                      return i === 0
                                                        ? `${x.group_value}`
                                                        : ` / ${x.group_value}`;
                                                    })}
                                                  </td>
                                                  <td className='whitespace-nowrap border-r border-gray-300 px-6 py-4 w-32 dark:border-neutral-500'>
                                                    <input
                                                      type='file'
                                                      onChange={async (e) =>
                                                      (groupVariantData[index].image =
                                                        await HandleVarientimage(
                                                          e.target.files[0],
                                                        ))
                                                      }
                                                    />
                                                  </td>
                                                  <td className='whitespace-nowrap border-r border-gray-300 px-6 py-4 dark:border-neutral-500 capitalize'>
                                                    {/* {data.price} */}
                                                    <input
                                                      type='number'
                                                      min={1}
                                                      placeholder='e.g. 500'
                                                      value={data.price}
                                                      onChange={(e) =>
                                                      (groupVariantData[index].price =
                                                        e.target.value)
                                                      }
                                                      className=' p-2 border border-gray-300 outline-none focus:border-gray-400 bg-[#f5f5f5]'
                                                    />
                                                  </td>
                                                  <td className='whitespace-nowrap border-r border-gray-300 px-6 py-4 dark:border-neutral-500 '>
                                                    <input
                                                      type='number'
                                                      min={1}
                                                      placeholder='Quantity'
                                                      value={data.quantity}
                                                      onChange={(e) =>
                                                      (groupVariantData[index].quantity =
                                                        e.target.value)
                                                      }
                                                      className=' p-2 border border-gray-300 outline-none focus:border-gray-400 bg-[#f5f5f5]'
                                                    />
                                                  </td>
                                                  <td className='whitespace-nowrap border-r border-gray-300 px-6 py-4 dark:border-neutral-500 '>
                                                    <input
                                                      type='text'
                                                      placeholder='Sku Id'
                                                      value={data.sku_id}
                                                      onChange={(e) =>
                                                      (groupVariantData[index].sku_id =
                                                        e.target.value)
                                                      }
                                                      className=' p-2 border border-gray-300 outline-none focus:border-gray-400 bg-[#f5f5f5]'
                                                    />
                                                  </td>
                                                  <td className='whitespace-nowrap border-r border-gray-300 py-4 dark:border-neutral-500 font-medium'>
                                                    <button
                                                      href='#'
                                                      className='text-red-500 text-xs'
                                                      onClick={(e) => HandleRemove(index, e)}
                                                    >
                                                      <Trash className='w-5 h-5' />
                                                    </button>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        </details>
                      )}
                      {productType === 'single' && productType !== 'affiliate' && (
                        <details className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'>
                          <summary className='text-base flex items-center justify-between font-medium text-black'>
                            <div>
                              Discounts
                              <p className='text-xs text-gray-500 font-normal'>
                                Unlock customer's savings with exclusive discounts
                              </p>
                            </div>
                            <span className='transition group-open:rotate-180'>
                              <ChevronDown className='text-black w-4 h-5' />
                            </span>
                          </summary>
                          <>
                            <div className='mb-4'>
                              <label htmlFor='quantity'>Product Discount Quantity</label>
                              <input
                                className={`w-full px-3 py-2 ${disErr && Discount.quantity <= 0
                                  ? 'border-2 border-red-500 '
                                  : 'border border-gray-300 '
                                  } rounded-[10px] focus:outline-none `}
                                type='number'
                                min={1}
                                id='quantity'
                                name='dis_quantity'
                                value={Discount.dis_quantity}
                                onChange={HandleDiscountChange}
                                placeholder='e.g. 1,5,10'
                              />
                              {disErr && Discount.dis_quantity <= 0 && (
                                <p className='text-red-500 text-xs'>
                                  Please enter valid discount quantity
                                </p>
                              )}
                            </div>
                            <div className='flex items-center justify-between mb-4 space-x-4'>
                              <div className='w-full'>
                                <label htmlFor='product_discount_type'>Product Discount Type</label>
                                <div className='relative'>
                                  <select
                                    name='product_discount_type'
                                    className={`w-full px-3 py-2 ${disErr && Discount.dis_quantity <= 0
                                      ? 'border-2 border-red-500 '
                                      : 'border border-gray-300 '
                                      } rounded-[10px] focus:outline-none `}
                                    value={Discount.product_discount_type}
                                    onChange={HandleDiscountChange}
                                  >
                                    <option>Select discount type</option>
                                    <option>amount</option>
                                    <option>percentage</option>
                                  </select>
                                  <div className='select-arrow'></div>
                                </div>
                                {disErr &&
                                  Discount.product_discount_type === 'Select discount type' && (
                                    <p className='text-red-500 text-xs'>
                                      Please Select valid discount type
                                    </p>
                                  )}
                              </div>
                              <div className='w-full contryCode'>
                                <label htmlFor='discount_value'>
                                  Product Discount Price/Percentage
                                </label>
                                <div className='flex items-center'>
                                  <p
                                    disabled
                                    className='px-4 py-2 border-y border-l border-gray-300 text-sm w-fit rounded-s-[4px]'
                                  >
                                    {Discount.product_discount_type === 'percentage' ? '%' : '₹'}
                                  </p>
                                  <input
                                    className={`w-full px-3 py-2 ${disErr &&
                                      Number(selling_price) < Number(Discount.discount_value)
                                      ? 'border border-red-500 '
                                      : 'border border-gray-300 '
                                      } rounded-[10px] focus:outline-none `}
                                    type='number'
                                    min={1}
                                    id='discount_value'
                                    name='discount_value'
                                    value={Discount.discount_value}
                                    onBlur={(e) => {
                                      if (selling_price < e.target.value) {
                                        setDisErr(true);
                                      }
                                    }}
                                    onChange={HandleDiscountChange}
                                    placeholder='Enter your discount price/percentage'
                                  />
                                </div>
                                {disErr &&
                                  Number(selling_price) < Number(Discount.discount_value) && (
                                    <p className='text-red-500 text-xs'>
                                      Discount value should be less than Selling price.
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className='flex w-full space-x-4 mb-4'>
                              <div className='w-full'>
                                <label>start date</label>
                                <label className='relative w-full block cursor-pointer'>
                                  <DatePicker
                                    className='border w-full focus:border-gray-400 border-gray-300 outline-none'
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat='dd-MM-yyyy'
                                  />
                                  <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                                    <CalendarFill className='text-gray-600 w-3 h-3' />
                                  </span>
                                </label>
                              </div>
                              <div className='w-full'>
                                <label>end date</label>
                                <label className='relative block w-full cursor-pointer'>
                                  <DatePicker
                                    className='border w-full focus:border-gray-400 border-gray-300 outline-none'
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={startDate}
                                    dateFormat='dd-MM-yyyy'
                                  />
                                  <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                                    <CalendarFill className='text-gray-600 w-3 h-3' />
                                  </span>
                                </label>
                              </div>
                            </div>
                            {editDiscountbtn.visible ? (
                              <button
                                onClick={editDiscount}
                                className='inline-block secondary-btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                              >
                                Edit discount coupon
                              </button>
                            ) : (
                              <button
                                onClick={HandleDiscount}
                                className='inline-block secondary-btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                              >
                                Add discount coupon
                              </button>
                            )}
                            {Discounts?.length > 0 && (
                              <div className='overflow-hidden w-full block rounded-[4px]'>
                                <table className='w-full rounded-[4px] border border-gray-300'>
                                  <thead className='w-full'>
                                    <tr className='bg-[#f5f5f5] min-w-full rounded border-b border-gray-300'>
                                      <th className='px-2 py-2.5 border-r border-gray-300 cursor-pointer text-sm font-medium text-black'>
                                        Type
                                      </th>
                                      <th className='px-2 py-2.5 border-r border-gray-300 cursor-pointer text-sm font-medium text-black'>
                                        Value
                                      </th>

                                      <th className='px-2 py-2.5 border-r border-gray-300 cursor-pointer text-sm font-medium text-black'>
                                        Quantity
                                      </th>
                                      <th className='px-2 py-2.5 border-r border-gray-300 cursor-pointer text-sm font-medium text-black'>
                                        Start Date
                                      </th>
                                      <th className='px-2 py-2.5 border-r border-gray-300 cursor-pointer text-sm font-medium text-black'>
                                        End Date
                                      </th>
                                      <th className='px-2 py-2.5 border-r border-gray-300 cursor-pointer text-sm font-medium text-black'>
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Discounts?.map((elem, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          className='text-center border-b border-gray-300 text-sm text-gray-600'
                                        >
                                          <td className='border border-gray-300'>
                                            {elem.product_discount_type}{' '}
                                          </td>
                                          <td className='border border-gray-300'>
                                            {elem.discount_value}{' '}
                                          </td>
                                          <td className='border border-gray-300'>
                                            {elem.dis_quantity}{' '}
                                          </td>
                                          <td className='border border-gray-300'>
                                            {moment(elem.start_date)
                                              .tz('Asia/Kolkata')
                                              .format('DD-MM-YYYY')}{' '}
                                          </td>
                                          <td className='border border-gray-300'>
                                            {moment(elem.end_date)
                                              .tz('Asia/Kolkata')
                                              .format('DD-MM-YYYY')}{' '}
                                          </td>
                                          <td className='border space-x-3 border-gray-300 p-2'>
                                            <button
                                              href='#'
                                              className='text-blue-500 py-2 font-medium'
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setEditDiscountbtn({
                                                  editIndex: index,
                                                  visible: true,
                                                });
                                                setDiscount({
                                                  dis_quantity: elem?.dis_quantity,
                                                  discount_value: elem.discount_value,
                                                  product_discount_type:
                                                    elem?.product_discount_type,
                                                });
                                              }}
                                            >
                                              <Edit className='w-4 h-4' />
                                            </button>
                                            <button
                                              href='#'
                                              className='text-red-500 py-2 font-medium'
                                              onClick={(e) => RemoveProductDiscount(e, index)}
                                            >
                                              <Trash className='w-4 h-4' />
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </>
                        </details>
                      )}
                      <details className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'>
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                          <div>
                            Search engine listing
                            <p className='text-xs text-gray-500 font-normal'>
                              Add a title and description to see how this product might appear in a
                              search engine listing
                            </p>
                          </div>
                          <span className='transition group-open:rotate-180'>
                            <ChevronDown className='text-black w-4 h-5' />
                          </span>
                        </summary>
                        <>
                          <div className='mb-4'>
                            <div className='flex items-center justify-between'>
                              <label htmlFor='Meta Tag Title'>Meta Title</label>
                            </div>
                            <input
                              className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                              type='text'
                              id='Meta Tag Title'
                              name='meta_title'
                              value={meta_title}
                              onChange={(e) => {
                                setMeta({ ...meta, [e.target.name]: e.target.value });
                              }}
                              placeholder='Enter meta title'
                            />
                          </div>
                          <div className='mb-4'>
                            <div className='flex items-center justify-between'>
                              <label htmlFor='Meta Tag description'>Meta Description</label>
                            </div>
                            <textarea
                              className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                              type='text'
                              id='Meta Tag description'
                              name='meta_description'
                              value={meta_description}
                              onChange={(e) => {
                                setMeta({ ...meta, [e.target.name]: e.target.value });
                              }}
                              placeholder='Enter meta description'
                            />
                          </div>
                        </>
                      </details>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <Link
          to={`/products`}
          className='w-fit mt-2 flex text-link font-medium hover:underline text-sm items-center '
        >
          <ArrowLeft className='w-3 mr-1' />
          Go to Products
        </Link>
          </form>
        </div>
        {addBrand && (
          <div className='h-full fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
            <Popover
              open={addBrand}
              className='h-screen'
              onClose={() => setAddBrand(false)}
            >
              <div className='p-5 w-[500px]'>
                <div className='flex items-center justify-between'>
                  <h3 className='text-xl text-black font-medium'>Add Brand</h3>
                  <button
                    onClick={() => setAddBrand(false)}
                    className='transition-all duration-300 text-link'
                  >
                    <X className='w-4 h-4' />
                  </button>
                </div>
                <hr className='my-4 border-gray-300'></hr>
                <form
                  method='POST'
                  className='w-full'
                  onSubmit={(e) => HandleSubmit(e)}
                >
                  <div className='mb-4'>
                    <label
                      className='flex text-gray-700 text-sm font-medium mb-1'
                      htmlFor='brand_name'
                    >
                      Manufacture Name
                    </label>
                    <input
                      className={`w-full px-3 py-2 border ${err && !manufacturerdata
                        ? 'border-red-500'
                        : 'border-gray-300 '
                        } rounded-[10px]  focus:outline-none`}
                      type='text'
                      id='brand_name'
                      name='brand_name'
                      value={manufacturerdata}
                      onChange={(e) => setManufacturerData(e.target.value)}
                      placeholder='ex.Tech'
                      required
                    />
                    {err && !manufacturerdata && (
                      <span className='text-red-500 text-sm'>
                        *Brand name is required
                      </span>
                    )}
                  </div>

                  <div className='mb-4'>
                    <label
                      className='flex text-gray-700 text-sm font-medium mb-1'
                      htmlFor='image'
                    >
                      Manufacture Image
                    </label>
                    <label className='text-sm cursor-pointer leading-6 text-gray-600'>
                      <input
                        type='file'
                        name='image'
                        onChange={(e) =>
                          HandleManufacturerImage(e.target.files[0])
                        }
                      />
                      <div className='mt-2'>
                        {err && !brandimage && (
                          <h1 className='text-red-500'>Please enter image</h1>
                        )}
                        {brandloader === true && !brandimage ? (
                          <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                            <div className='nb-spinner'></div>
                          </div>
                        ) : brandimage ? (
                          <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                            <img
                              className='max-w-full max-h-full'
                              src={brandimage}
                              alt={brandimage}
                            />
                          </div>
                        ) : (
                          <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                            <img
                              className='max-w-full max-h-full'
                              src={NOPHOTO_IMAGE}
                              alt={NOPHOTO_IMAGE}
                            />
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <button
                    className='w-full block btn text-white text-sm font-medium py-2 px-3 rounded-[10px] transition duration-300'
                    type='submit'
                    onClick={HandleManufacturerSubmit}
                  >
                    Add Manufacture
                  </button>
                </form>
              </div>
            </Popover>
          </div>
        )}
        {addCategory && (
          <div className='h-full fixed inset-0 bg-black bg-opacity-60 transition-opacity -top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
            <Popover
              open={addCategory}
              className='h-screen'
              onClose={() => setAddCategory(false)}
            >
              <div className='p-5 w-[500px]'>
                <div className='flex items-center justify-between'>
                  <h3 className='text-xl text-black font-medium'>Add category</h3>
                  <button
                    onClick={() => setAddCategory(false)}
                    className='transition-all duration-300 text-link'
                  >
                    <X className='w-4 h-4' />
                  </button>
                </div>
                <hr className='my-4 border-gray-300'></hr>
                <form method='get' className='w-full'>
                  <div className='mb-4'>
                    <label htmlFor='category_name'>category Name</label>
                    <input
                      className={`w-full px-3 py-2 border ${err && !Catdata ? 'border-red-500' : 'border-gray-300 '
                        } rounded-[10px]  focus:outline-none`}
                      type='text'
                      id='category_name'
                      name='category_name'
                      value={Catdata}
                      onChange={(e) => setCatData(e.target.value)}
                      placeholder='e.g. Tech'
                      required
                    />
                    {err && !Catdata && (
                      <span className='text-red-500 text-sm'>
                        *Category name is required
                      </span>
                    )}
                  </div>
                  <label className='mb-4'>
                    <label htmlFor='image'>category Image</label>
                    <input
                      type='file'
                      name='image'
                      onChange={(e) => {
                        e.preventDefault();
                        HandleCatImage(e.target.files[0]);
                      }}
                      required
                    />
                    <div className='mt-2 cursor-pointer'>
                      {err && !catimage && (
                        <h1 className='text-red-500'>Please enter image</h1>
                      )}

                      {catloader === true ? (
                        <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                          <div className='nb-spinner'></div>
                        </div>
                      ) : catimage ? (
                        <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                          <img
                            className='max-w-full max-h-full'
                            src={catimage && catimage}
                            alt={catimage && catimage}
                          />
                        </div>
                      ) : (
                        <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                          <img className='max-w-full max-h-full' src={NOPHOTO_IMAGE} alt={NOPHOTO_IMAGE}/>
                        </div>
                      )}
                    </div>
                  </label>
                  <button
                    className='w-full block btn text-white text-sm font-medium py-2 px-3 rounded-[10px] transition duration-300'
                    type='button'
                    onClick={(e) => HandleCatSubmit(e)}
                  >
                    Add category
                  </button>
                </form>
              </div>
            </Popover>
          </div>
        )}
        
      </div>
      {
        openYoutubeInfo &&
        <Popover
          id={"youtubeinfo"}
          className='youtubeInfo'
          open={openYoutubeInfo}
          anchorEl={anchorEl}
          onClose={() => setOpenYoutubeInfo(false)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <div className='p-4 text-sm'>
            <p><b>1.</b> Open the YouTube video you want to share.</p>
            <br></br>
            <p><b>2.</b> Look for the "Share" button below the video. It's usually located just below the video player.</p>
            <br></br>
            <p><b>3.</b> Click on the "Share" button. A menu will pop up with different sharing options.</p>
            <br></br>
            <p><b>4.</b> In the menu, you'll see an option called "Embed." Click on that.</p>
            <br></br>
            <p><b>5.</b> Once you click on "Embed," you'll see a box with some code inside it.</p>
            <br></br>
            <p><b>6.</b> Right next to the code, there's a button that says "Copy." Click on that button.</p>
          </div>
        </Popover>
      }
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
          // onClose={() => {
          //   setHtmlPP(false);
          // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] h-96  mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X onClick={() => {
                    if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                      setHtml({ ...html, htmlPP: false })
                      setDescType()
                    }
                  }} />
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({ ...html, code: e.target.value })}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              </div>
              <button
                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

export default CreateDummyProduct;
