import { GET_ALL_CHANGELOG_CATEGORIES, GET_CHANGELOG, GET_PERTICULAR_LOG } from "../action.type"
const initialState={
    allChangelogCategories:null,
    allChangelog:null,
    perticularLog:null
}

export const changelogReducer=(state=initialState,action)=>{
    switch(action.type){
        case GET_ALL_CHANGELOG_CATEGORIES :
            return {
                ...state,
                allChangelogCategories:action.payload?.data?.data
            }

            case GET_CHANGELOG:
                return {
                    ...state,
                    allChangelog:action.payload?.data?.data
                }
            case GET_PERTICULAR_LOG:
                return {
                    ...state,
                    perticularLog:action.payload?.data?.data
                }
            default:return state
    }

}