import React, { useEffect, useState, useRef } from 'react';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ChevronDown, TrashFill } from 'react-bootstrap-icons';
import API from '../../../API';
import { ArrowLeft, Edit, Move, Trash } from 'feather-icons-react/build/IconComponents';
import Loader from '../../Loader/Loader';
import DataTable from 'react-data-table-component';
const UpdateDummyHeaderGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const params = useParams();
  const navigate = useNavigate();
  const [groupTitle, setGroupTitle] = useState();
  const [groupLink, setGroupLink] = useState()
  const [linkColumn, setLinkColumn] = useState(1);
  const [dataErr, setDataErr] = useState(false);
  const [pageData, setPageData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [filterData, setFilterData] = useState();
  const [allBlogCategory, setAllBlogCategory] = useState();
  const [portfolio, setPortfolioData] = useState();
  const [addList, setAddList] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [removeList, setRemoveList] = useState([]);
  const [isEdit, setIsEdit] = useState(false)
  const [editId, setEditId] = useState()
  const [customData, setCustomData] = useState({
    section_name: '',
    section_link: '',
    redirect_type: 'new'
  });
  const [storeCustomData, setStoreCustomData] = useState([])
  const [customDataErr, setCustomDataErr] = useState(false)
  const [storeList, setStoreList] = useState([]);
  const getPerticularSectionData = async () => {
    try {
      const getHeaderGroupById = await API({ url: `/super/admin/dummydata/dynamicdummyheader/getHeaderGroup/${params?.groupId}`, method: 'get' });
      setGroupTitle(getHeaderGroupById?.data?.data?.header_section_title);
      setGroupLink(getHeaderGroupById?.data?.data?.header_section_redirect_link)
      setStoreList(getHeaderGroupById?.data?.data?.header);
      setLinkColumn(getHeaderGroupById?.data?.data?.column)
      setStoreCustomData(getHeaderGroupById?.data?.data?.header?.filter((x) => x.section_type === "custom"))
    } catch (error) {
      console.log(error);
    }
  };
  const getPageData = async () => {
    try {
      const getPages = await API({ url: `/super/admin/dummydata/dummypages/getDummyPages`, method: 'get' });
      setPageData(getPages?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getCategoryData = async () => {
    try {
      const getCategories = await API({ url: `/super/admin/dummydata/dummycategory/getDummyCategorys`, method: 'get' });
      setCategoryData(getCategories?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const GetAllBlogCategories = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyblogcategory/getDummyBlogCategorys`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setAllBlogCategory(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetAllPortfolioCategories = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyportfoliocategory/getDummyPortfolioCategorys`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setPortfolioData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPerticularSectionData();
    getPageData();
    getCategoryData();
    GetAllBlogCategories();
    GetAllPortfolioCategories();
  }, []);
  const handleCheck = (slug) => {
    const check = storeList?.some((x) => x?.section_link === slug && x?.section_type !== "custom");
    return check
  }
  const handleAddCustomData = async (e) => {
    e.preventDefault();
    if (customData?.section_name === '' || customData?.section_link === '') {
      setCustomDataErr(true)
    } else {
      setCustomDataErr(false)
      if (isEdit) {
        try {
          await API({ url: `/super/admin/dummydata/dynamicdummyheader/updateHeader/${editId}`, method: 'put', data: customData})
          getPerticularSectionData();
          setIsEdit(false);
          setCustomData({
            section_name: '',
            section_link: '',
            redirect_type: 'new'
          })
          setEditId('')
          setIsNotification(true)
          setNotificationMsg("Link udated successfully!")
          setSeverity('success')
        } catch (error) {
          setIsNotification(true)
          setNotificationMsg(error)
          setSeverity('error')
        }
      } else {
        try {
          await API({ url: `/super/admin/dummydata/dynamicdummyheader/addHeader`, method: 'post', data: {
            header_group_id: params?.groupId,
            header: [...storeList, { section_name: customData?.section_name, section_link: customData?.section_link, section_type: 'custom', redirect_type: customData?.redirect_type }]
          }})
          getPerticularSectionData();
          setCustomData({
            section_name: '',
            section_link: '',
            redirect_type: 'new'
          })
        } catch (error) {
          setIsNotification(true)
          setNotificationMsg(error)
          setSeverity('error')
        }
      }
    }
  }
  const handleSelectProduct = async (name, slugname, type, category_id) => {
    if (slugname) {
      const existList = storeList?.find((x) => x?.section_link === slugname && x?.section_type !== "custom");
      if (!existList) {
        setStoreList([...storeList, { section_name: name, section_link: slugname, sort_order: storeList?.length, section_type: type, category_id }])
      } else if (existList && existList.section_type !== "custom") {
        const filteredList = storeList?.filter((x) => {
          if (x?.section_type === "custom") {
            return x;
          } else if (x.section_link !== slugname) {
            return x;
          }
        });
        setStoreList(filteredList)
      }
    }
  }

  const handleEditSection = async (e) => {
    e.preventDefault();
    if (!groupTitle) {
      setDataErr(true);
    } else {
      setDataErr(false);
      try {
        await API({ url: `/super/admin/dummydata/dynamicdummyheader/updateHeaderGroup/${params?.groupId}`, method: 'put', data: {
          header_section_title: groupTitle,
          header_section_redirect_link: groupLink,
          column: linkColumn
        }});
        navigate(`/dummy-data/module-setting/header-menu`);
      } catch (error) {
        setIsNotification(false);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const handleDelete = async (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure to remove this header link ?")) {
      try {
        await API({ url: `/super/admin/dummydata/dynamicdummyheader/deleteHeader`, method: 'post', data: {
          id: [id],
        }})
        getPerticularSectionData()
      } catch (error) {
        setIsNotification(false)
        setNotificationMsg(error)
        setSeverity('error')
      }
    }
  }
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...storeList];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = _sort.map((list, index) => { return { ...list, sort_order: index } });
    setStoreList(FinalArray);
  };
  const handleApplyChanges = async (e) => {
    e.preventDefault();
    try {
      handleEditSection(e);
      await API({ url: `/super/admin/dummydata/dynamicdummyheader/addHeader`, method: 'post', data: {
        header_group_id: params?.groupId,
        header: storeList
      }})
      getPerticularSectionData();
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity('error')
    }
  }
  const columns = [
    {
      name: '',
      width: '30px',
      padding: '0px',
      cell: (row, index) => (
        <div
          className='cursor-move block w-full'
          draggable
          onDragStart={(e) => (DragItem.current = index)}
          onDragEnter={(e) => (DragOverItem.current = index)}
          onDragEnd={HandleSort}
        >
          <Move className='cursor-move w-4' />
        </div>
      ),
    },
    {
      name: 'section name',
      cell: (row) => (
        <span
          className='line-clamp-2'
          dangerouslySetInnerHTML={{ __html: row.section_name }}
        ></span>
      ),
      minWidth: '300px',
    },
    {
      name: <span className='w-full text-right'>Action</span>,
      width: '100px',
      cell: (row) => (
        <div className='space-x-2 w-full text-right'>
          <button
            onClick={(e) => {
              handleDelete(e, row?._id);
            }}
          >
            <Trash className='w-[17px] h-[17px] text-red-500' />
          </button>
        </div>
      ),
    },
  ];
  const handleEditCustomData = async (e, elem) => {
    e.preventDefault();
    setIsEdit(true);
    setCustomData({
      section_name: elem?.section_name,
      section_link: elem?.section_link,
      redirect_type: elem?.redirect_type
    })
    setEditId(elem?._id)
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='w-full'>
          <Breadcrumbs />
          <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>
            Edit Header Menu
          </h3>
        </div>
        <div className='flex space-x-4 justify-between'>
          <form className='w-[90%] lg:w-[70%] xl:w-[60%] space-y-4'>
            <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='image'>header menu Title</label>
                <input
                  type='text'
                  className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${dataErr ? 'border-red-500' : ''
                    }`}
                  placeholder='e.g. Categories'
                  value={groupTitle}
                  onChange={(e) => setGroupTitle(e.target.value)}
                />
                {dataErr && !groupTitle && (
                  <p className='text-xs text-red-500'>Please enter menu name</p>
                )}
              </div>
              <div className='mb-4'>
                <label htmlFor='image'>
                  section redirect URL
                </label>
                <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm`} placeholder='e.g. /product' value={groupLink} onChange={(e) => setGroupLink(e.target.value)} />
              </div>
              <div className='mb-4'>
                <label htmlFor='image'>Menu Link column</label>
                <input
                  type='number'
                  className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm`}
                  placeholder='e.g. Categories'
                  value={linkColumn}
                  onChange={(e) => setLinkColumn(e.target.value)}
                />
              </div>
              <div className='mb-4'>
                <details
                  open
                  className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base flex items-center justify-between text-black'>
                    Content pages
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='text-black w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                    {pageData?.records?.map((elem) => {
                      return (
                        <label className='flex items-center space-x-4'>
                          <input
                            type='checkbox'
                            value={elem?._id}
                            checked={handleCheck(`/page/${elem?.page_slug_name}`)}
                            onClick={(e) =>
                              handleSelectProduct(
                                elem?.pages_name,
                                `/page/${elem?.page_slug_name}`,
                                elem?._id,
                              )
                            }
                            className='block w-4 h-4'
                          />
                          <p className='text-sm text-black font-normal break-all'>
                            {elem?.pages_name}
                          </p>
                        </label>
                      );
                    })}
                  </div>
                </details>
              </div>
              <div className='mb-4'>
                <details
                  open
                  className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base flex items-center justify-between text-black'>
                    Portfolio Categories
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='text-black w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                    {portfolio?.data?.records?.map((elem) => {
                      return (
                        <label className='flex items-center space-x-4'>
                          <input
                            type='checkbox'
                            value={elem?._id}
                            checked={handleCheck(`/portfolio/portfolio-category/${elem?.portfolio_category_slug_name}`)}
                            onClick={(e) =>
                              handleSelectProduct(
                                elem?.portfoliocategory_name,
                                `/portfolio/portfolio-category/${elem?.portfolio_category_slug_name}`,
                                elem?._id,
                              )
                            }
                            className='block w-4 h-4'
                          />
                          <p className='text-sm text-black font-normal break-all'>
                            {elem?.portfoliocategory_name}
                          </p>
                        </label>
                      );
                    })}
                  </div>
                </details>
              </div>

              <div className='mb-4'>
                <details
                  open
                  className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base flex items-center justify-between text-black'>
                    Categories
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='text-black w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                    {categoryData?.records?.map((elem) => {
                      return (
                        <label className='flex items-center space-x-4'>
                          <input
                            type='checkbox'
                            value={elem?._id}
                            checked={handleCheck(`/category/${elem?.category_slug_name}`)}
                            onClick={(e) =>
                              handleSelectProduct(
                                elem?.category_name,
                                `/category/${elem?.category_slug_name}`,
                                elem?._id,
                              )
                            }
                            className='block w-4 h-4'
                          />
                          <p className='text-sm text-black font-normal break-all'>
                            {elem?.category_name}
                          </p>
                        </label>
                      );
                    })}
                  </div>
                </details>
              </div>
              <div className='mb-4'>
                <details
                  open
                  className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base flex items-center justify-between text-black'>
                    Blog Categories
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='text-black w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                    {allBlogCategory?.data?.records?.map((elem) => {
                      return (
                        <label className='flex items-center space-x-4'>
                          <input
                            type='checkbox'
                            value={elem?._id}
                            checked={handleCheck(`/blog/blog-category/${elem?.blogcategory_name}`)}
                            onClick={(e) =>
                              handleSelectProduct(
                                elem?.blogcategory_name,
                                `/blog/blog-category/${elem?.blog_category_slug_name}`,
                                elem?._id,
                              )
                            }
                            className='block w-4 h-4'
                          />
                          <p className='text-sm text-black font-normal break-all'>
                            {elem?.blogcategory_name}
                          </p>
                        </label>
                      );
                    })}
                  </div>
                </details>
              </div>
              <details
                open
                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
              >
                <summary className='text-base flex items-center justify-between text-black'>
                  Custom content
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className='text-black w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full pt-4  border-t'>

                  <>
                    <div className='mb-4'>
                      <label htmlFor='image'>
                        Title
                      </label>
                      <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${customDataErr && customData?.section_name === "" ? 'border-red-500' : ''}`} placeholder='e.g. Google' value={customData?.section_name} onChange={(e) => setCustomData({ ...customData, section_name: e.target.value })} />
                      {
                        customDataErr && customData?.section_name === "" && <p className='text-xs text-red-500'>Please enter title</p>
                      }
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='image'>
                        URL
                      </label>
                      <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${customDataErr && customData?.section_link === "" ? 'border-red-500' : ''}`} placeholder='e.g. https://google.com' value={customData?.section_link} onChange={(e) => setCustomData({ ...customData, section_link: e.target.value })} />
                      {
                        customDataErr && customData?.section_link === "" && <p className='text-xs text-red-500'>Please enter url</p>
                      }
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='image'>
                        redirect type
                      </label>
                      <div className='relative'>
                        <select value={customData?.redirect_type} onChange={(e) => setCustomData({ ...customData, redirect_type: e.target.value })} className='w-full border border-gray-300'>
                          <option value="new">New tab</option>
                          <option value="current">Current tab</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                    <button onClick={(e) => handleAddCustomData(e)} className='secondary-btn text-sm inline-block'>Add custom data</button>
                  </>
                  {
                    storeCustomData?.map((elem) => {
                      return (
                        <div className='border border-gray-300 flex items-center justify-between p-3 rounded mt-4'>
                          <div>
                            <p className='text-sm'><span className='font-medium'>Title : </span><span>{elem?.section_name}</span></p>
                            <p className='text-sm'><span className='font-medium'>URL : </span><span>{elem?.section_link}</span></p>
                          </div>
                          <div className='space-x-2'>
                            <button onClick={(e) => { handleEditCustomData(e, elem) }}><Edit className='w-[17px] h-[17px] text-blue-500' /></button>
                            <button onClick={(e) => {
                              handleDelete(e, elem._id);
                            }}><Trash className='w-[17px] h-[17px] text-red-500' /></button>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </details>
            </div>

          </form>
          {storeList?.length > 0 ? (
            <div className='w-[90%] lg:w-[70%] xl:w-[60%] space-y-4'>
              <details
                className={
                  selectedRowsId?.length === 0
                    ? `opacity-60 cursor-not-allowed group w-[200px] relative`
                    : `cursor-pointer group w-[200px] relative`
                }
              >
                <summary className='flex bg-white  border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
                  <span> Bulk Selection</span>
                  <span
                    className={
                      selectedRowsId?.length === 0
                        ? 'transition group-open:rotate-0'
                        : 'transition group-open:rotate-180'
                    }
                  >
                    <svg
                      fill='none'
                      height={14}
                      shapeRendering='geometricPrecision'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      width={14}
                    >
                      <path d='M6 9l6 6 6-6' />
                    </svg>
                  </span>
                </summary>
                <div
                  className={
                    selectedRowsId?.length === 0
                      ? `hidden`
                      : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
                  }
                >
                  <button
                    className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center'
                    onClick={(e) => {
                      handleDelete(e);
                    }}
                  >
                    <TrashFill className='w-3.5 h-3.5' />
                    <span>Delete selection</span>
                  </button>
                </div>
              </details>





              <div className='w-full inline-block border border-gray-300 rounded-[15px] overflow-x-auto overflow-y-hidden h-auto'>
                <DataTable
                  columns={columns}
                  data={storeList}
                  selectableRows
                  onSelectedRowsChange={({ selectedRows }) => {
                    setSelectedRowsId(selectedRows?.map((x) => x._id));
                  }}
                  selectableRowsHighlight
                  highlightOnHover
                  progressComponent={<Loader />}
                />
              </div>



            </div>
          ) : (
            <></>
          )}

        </div>

        <div className='w-full flex items-center justify-between lg:w-[70%] xl:w-[80%] mx-auto'>
          <Link
            to={`/dummy-data/module-setting/header-menu`}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            Go to header menu
          </Link>
          <button
            onClick={(e) => {
              handleApplyChanges(e);
            }}
            className='btn text-sm text-white'
          >
            Apply Changes
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdateDummyHeaderGroup;
