import { GET_ACTIVE_PLAN, GET_LATEST_STORE, GET_PLAN_EXPIRED, GET_TOTAL_EXTENSION } from "../action.type";

const initialState={
    latestStoresLoader: false,
    latestStores: [],
    activePlan: [],
    planexpiring: [],
    totalextension: []
}                                 

export const dashboardReducer=(state=initialState, action)=> {
    switch (action.type) {
      case GET_LATEST_STORE:
        return {
            ...state,
            latestStoresLoader: false,
            latestStores: action.payload
        };

      case GET_ACTIVE_PLAN:
        return {
            ...state,
            activePlan: action.payload
        };

      case GET_PLAN_EXPIRED:
        return {
            ...state,
            planexpiring: action.payload
        };

      case GET_TOTAL_EXTENSION:
        return {
            ...state,
            totalextension: action.payload
        };
      
      default:
        return state;
    }
  }
