import React, { useState, useRef } from 'react';
import API from '../../../API';
import { ArrowLeft, Check, ChevronDown, Edit } from 'feather-icons-react/build/IconComponents'
import { Link, useNavigate } from 'react-router-dom';
import {
  Popover,
} from '@mui/material';
import { GenerateSlugName } from '../../../controllers/GenerateSlugName';
import { X } from 'feather-icons-react/build/IconComponents';
import ReactQuill from 'react-quill';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import ImageUploader from '../../../controllers/ImageUploader';
const CreateDummyManufacturer = () => {
  const navigate = useNavigate();
  const [warn, setwarn] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [enableSlug, setEnableSlug] = useState(false)
  // image upload logic
  const [mData, setmData] = useState({
    brand_name: '',
    meta_title: '',
    meta_description: ''
  });
  const [description, setDescription] = useState('')
  const [sortOrder, setSortOrder] = useState('');
  const [image, setimage] = useState();
  const handleSetImage = (value) => {
    setimage(value)
} 
  const [slugField, setSlugField] = useState(false)
  const [slugName, setSlugName] = useState();
  const handleSlug = async (slug) => {
    if (slug?.length) {
      setSlugField(true)
      setSlugName(await GenerateSlugName(slug));
    }
  }
  const HandleInput = (e) => {
    setmData({ ...mData, [e.target.name]: e.target.value });
  };
//   const handleSelectProduct = (id, name) => {
//     if (id) {
//       const pro = selectedProduct?.find((x) => x.id === id);
//       if (!pro) {
//         setSelectedProduct([...selectedProduct, { id, name }]);
//       }
//     }
//   }
//   const handleRemoveProduct = (id) => {
//     const removeProduct = selectedProduct.filter((x) => x?.id !== id);
//     setSelectedProduct(removeProduct);
//   };
  const [err, setErr] = useState(false)
  const { brand_name, meta_title, meta_description } = mData;
  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (brand_name === '' || image === '' || enableSlug) {
      setErr(true)
    } else {
      setErr(false)
      try {
        const data = await API({ url: '/super/admin/dummydata/dummybrand/addDummyManufacturer', method: 'post', data: {
          brand_name: brand_name,
          brand_logo: image,
          description: description,
          brand_slug_name: slugName,
          sort_order:sortOrder,
          meta_title,
          meta_description,
          //   id: selectedProduct?.map((x) => x.id),
        }});
        if (data.status === 200 || data.status === 304) {
          navigate('/dummy-data/dummy-Manufacturer');
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  //MUI autocomplete filter for product
//   const filterOptionsForProduct = (options, { inputValue }) => {
//     const normalizedInputValue = inputValue?.toLowerCase();
//     return options.filter((option) => {
//       const combinedFields = `${option?.product_name?.toLowerCase()} ${option?.product_slug_name?.toLowerCase()} ${option?.sku_id?.toLowerCase()}`;
//       return combinedFields.includes(normalizedInputValue);
//     });
//   };

    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const handleQuillChange = (value) => {
      setDescription(value);
    };
  
  const [html, setHtml] = useState({htmlPP:false,code:''});
  const {htmlPP,code}=html
    const handleViewHtml = (e) => {
      e.preventDefault();
      const quill = quillRef.current.getEditor();
      const htmlContent = quill.root.innerHTML
      const formattedHtml = formatCode(htmlContent);
      setHtml({htmlPP:true,code:formattedHtml}); // Log or use the HTML content as needed
    };
  
    const saveHTML=()=>{
      setHtml({...html,htmlPP:false})
      setDescription(code)
    }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
    
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <Breadcrumbs />
        <h1 className='text-xl md:text-2xl font-medium pb-4 text-primary'>Add Manufacturer</h1>
        <div className='flex justify-evenly'>
          <form
            method='POST'
            className='w-[80%] space-y-4 sm:w-[70%] xl:w-[50%] mx-auto'
          >
            <div className='bg-white p-[25px] shadow rounded-xl'>
              <div className='mb-4'>
                <label
                  htmlFor='brand_name'
                  className='flex items-center justify-between'
                >
                  <p className='text-[13px]'>manufacture name<span className='text-red-500 ms-1'>*</span></p>
                  <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p>
                </label>
                <input
                  className={(err && brand_name?.length === 0) || warn ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none` : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`}
                  type='text'
                  id='brand_name'
                  name='brand_name'
                  maxLength={60}
                  value={mData.brand_name}
                  onChange={(e) => { HandleInput(e); brand_name?.length > 0 && setwarn(false); setErr(false) }}
                  placeholder='Enter manufacturer name'
                  onBlur={() => handleSlug(mData?.brand_name)}
                />
                {
                  (err && brand_name?.length === 0) || warn ? <p className='text-xs text-red-500'>Please enter manufacturer name</p> : <></>
                }
              </div>
              {
                slugField &&
                <div className='mb-4'>
                  <label htmlFor='category_name'>
                    manufacture Slug name
                  </label>
                  <div className={`flex items-center justify-between w-full border ${err && enableSlug ? 'border-red-500' : 'border-gray-300'} rounded-[4px]`}>
                    <input
                      className="w-full focus:outline-none"
                      type='text'
                      name='manufacture_slug_name'
                      value={slugName}
                      disabled={!enableSlug}
                      onChange={(e) => { setSlugName(e.target.value) }}
                    />
                    {
                      enableSlug ?
                        <Check onClick={async () => { setSlugName(await GenerateSlugName(slugName)); setEnableSlug(!enableSlug); setErr(false) }} className='text-green-500 p-1 h-8 w-8' /> :
                        <Edit onClick={() => setEnableSlug(!enableSlug)} className='text-blue-500 p-1 h-7 w-7' />
                    }
                  </div>
                  {err && enableSlug && <p className='text-xsm text-red-500'>Please save this changes</p>}
                </div>
              }

              <div className='w-full mb-4'>
                <label htmlFor='image'>
                <p className='text-[13px]'>manufacture image<span className='text-red-500 ms-1'>*</span></p>
                </label>
                <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} folder="manufacture" format="image" />
              </div>
              <div className='mb-4'>
                <div className='flex space-x-3 justify-between items-center mb-1'>
                  <label
                    htmlFor='Description'
                  >
                    Description
                  </label>
                  <div>
                  <section
                    className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                    onClick={handleViewHtml}
                  >
                    View HTML
                  </section>
                  </div>
                </div>
                <ReactQuill
                  modules={module}
                  ref={quillRef}
                  className={'w-full h-auto'}
                  theme='snow'
                  name='description'
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={handleQuillChange}
                  placeholder='Enter description'
                />
              </div>
            </div>
            {/* <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
              <summary
                className='text-base flex items-center justify-between font-medium text-black'
              >
                <div>
                  Products
                  <p className='text-xs text-gray-500 font-normal'>Select product for add in this manufacture</p>
                </div>
                <span className="transition group-open:rotate-180">
                  <ChevronDown className="text-black w-4 h-5" />
                </span>
              </summary>
              <div className='mb-4'>
                <Autocomplete
                  disableClearable
                  className='autocomplete'
                  options={getProduct?.records ? getProduct.records : []}
                  filterOptions={filterOptionsForProduct}
                  getOptionLabel={(product) => product.product_name}
                  onChange={(e, v) => handleSelectProduct(v._id, v?.product_name)
                  }
                  renderInput={(Products) => (
                    <TextField
                      placeholder='Select products'
                      className='cursor-pointer text-black placeholder:text-black'
                      {...Products}
                    />
                  )}
                />
                <div className='flex flex-wrap mt-2'>
                  {selectedProduct?.map((elem, index) => {
                    return (
                      <div
                        key={index}
                        className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                      >
                        <span>{elem.name}</span>
                        <X
                          className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                          onClick={() => handleRemoveProduct(elem.id)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </details> */}
            <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
              <summary
                className='text-base flex items-center justify-between font-medium text-black'
              >
                <div>
                  Search engine listing
                  <p className='text-xs text-gray-500 font-normal'>Add a title and description to see how this product might appear in a search engine listing</p>
                </div>
                <span className="transition group-open:rotate-180">
                  <ChevronDown className="text-black w-4 h-5" />
                </span>
              </summary>
              <div className='w-full'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='meta_title'
                    className='flex items-center justify-between'
                  >
                    Meta Title
                  </label>
                </div>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='text'
                  id='meta_title'
                  name='meta_title'
                  value={mData?.meta_title}
                  onChange={HandleInput}
                  placeholder='Enter meta title'
                />
              </div>
              <div className='w-full'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='meta_description'
                    className='flex items-center justify-between'
                  >
                    Meta Description
                  </label>
                </div>
                <textarea
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='text'
                  id='meta_description'
                  name='meta_description'
                  value={mData.meta_description}
                  onChange={HandleInput}
                  placeholder='Enter meta description'
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='sort_order'>
                  sort order
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='number'
                  id='sort_order'
                  name='sort_order'
                  value={sortOrder}
                  onChange={(e) => { setSortOrder(e.target.value<=0 ? 0 : e.target.value)}}
                  placeholder='Enter sort order'
                />
              </div>
            </details>

            <div className='flex items-center justify-between w-full'>
              <Link to={`/manufacturers`} className='w-fit flex text-link font-medium hover:underline text-sm items-center '><ArrowLeft className='w-3 mr-1' />Go to manufacturer</Link>
              <button
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                type='submit'
                onClick={HandleSubmit}
              >
                Add New Manufacturer
              </button>
            </div>
          </form>
        </div>
      </div>
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
            // onClose={() => {
            //   setHtmlPP(false);
            // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] h-96  mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X onClick={()=>{
                    if(window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")){
                      setHtml({...html,htmlPP:false})
                    }
                  }}/>
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea 
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({...html,code:e.target.value})}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              </div>
              <button
                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

export default CreateDummyManufacturer;
