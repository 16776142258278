import React, { useEffect, useRef, useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import module, { formatCode } from '../../../controllers/QuillToolbar';
import ReactQuill from 'react-quill';
import API from '../../../API';
import Compressor from 'compressorjs';
import GenerateUniqueFilename from '../../../controllers/GenerateUniqueFilename';
import { HexColorPicker } from "react-colorful";
import { Popover } from '@mui/material';
import { Edit, Move, PlusCircle, Trash, X } from 'feather-icons-react/build/IconComponents';
import { useNavigate, useParams } from 'react-router-dom';
import Notification from '../../Notification';
import Loader from '../../Loader/Loader';
import DataTable from 'react-data-table-component';
import { TrashFill } from 'react-bootstrap-icons';
import NoDataFound from '../../Main/NoDataFound';
import ImageUploader from '../../../controllers/ImageUploader';

const UpdateDummyBannerGroup = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [nodata, setNodata] = useState(false)
    const [bannerParallax, setBannerParallax] = useState(false)
    const [isNotification, setIsNotification] = useState(false)
    const [notificationMsg, setNotificationMsg] = useState('')
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false)
        setNotificationMsg('')
        setSeverity('')
    }
    const [status, setstatus] = useState(true);
    const [groupTitle, setGroupTitle] = useState();
    const [imgErr, setImgErr] = useState(false);
    const [pending, setPending] = useState(false)
    const [image, setimage] = useState();
    const [value, setValue] = useState();
    const [loader, setLoader] = useState(false);
    const [isVisibleButton, setIsVisibleButton] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState();
    const [bannerLimit, setBannerLimit] = useState(1)
    const [addBanner, setAddBanner] = useState(false);
    const [selectedRowsId, setSelectedRowsId] = useState([]);
    const [bannerData, setBannerData] = useState({
        banner_button_color: '#818181',
        banner_button_text: '',
        banner_button_text_color: '#f1f1f1',
        banner_button_link: '',
        banner_button_hover_color: '#f1f1f1',
        banner_button_hover_text_color: '#818181',
        banner_button_redius: '',
        text_position: 'left'
    })
    useEffect(() => {
        handleGetPerticularBannerGroup();
    }, [])
    const handleGetPerticularBannerGroup = async () => {
        try {
            setNodata(false)
            const apiCall = await API({ url: `/super/admin/dummydata/dynamicdummybanner/getBannerGroup/${params?.groupId}`, method: 'get' })
            setGroupTitle(apiCall?.data?.data?.banner_title);
            setBannerLimit(apiCall?.data?.data?.banner_limit);
            setBannerParallax(apiCall?.data?.data?.banner_parallax === "true" ? true : false)
            setstatus(apiCall?.data?.data?.status)
            setStoreBanners(apiCall?.data?.data?.banners)
        } catch (error) {
            setNodata(true)
        }
    }
    const [dataErr, setDataErr] = useState(false);
    const handleSetImage = (value) => {
        setimage(value)
    }
    const [storeBanners, setStoreBanners] = useState([])
    const handleAddBanner = async () => {
        if (!image) {
            setDataErr(true)
        } else {
            setDataErr(false)
            let bannerBody;
            if (isVisibleButton) {
                bannerBody = {
                    banner_text: value,
                    banner_img: image,
                    banner_button_color: bannerData?.banner_button_color,
                    banner_button_text: bannerData?.banner_button_text,
                    banner_button_text_color: bannerData?.banner_button_text_color,
                    banner_button_link: bannerData?.banner_button_link,
                    banner_button_hover_color: bannerData?.banner_button_hover_color,
                    banner_button_hover_text_color: bannerData?.banner_button_hover_text_color,
                    banner_button_redius: bannerData?.banner_button_redius,
                    text_position: bannerData?.text_position
                }
            } else {
                bannerBody = {
                    banner_text: value,
                    banner_img: image,
                    text_position: bannerData?.text_position
                }
            }
            if (!isEdit) {
                try {
                    await API({ url: `/super/admin/dummydata/dynamicdummybanner/addBanner`, method: 'post', data: {
                        banner_group_id: params?.groupId,
                        banner: bannerBody
                    }})
                    setBannerData({
                        banner_button_color: '#818181',
                        banner_button_text: '',
                        banner_button_text_color: '#f1f1f1',
                        banner_button_link: '',
                        banner_button_hover_color: '#f1f1f1',
                        banner_button_hover_text_color: '#818181',
                        banner_button_redius: '',
                        text_position: 'left'
                    })
                    setValue();
                    setimage();
                    setIsVisibleButton(false)
                    setAddBanner(false);
                    handleGetPerticularBannerGroup()
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    await API({ url: `/super/admin/dummydata/dynamicdummybanner/updateBanner/${editId}`, method: 'put', data: bannerBody});
                    setIsEdit(false);
                    setAddBanner(false);
                    setBannerData({
                        banner_button_color: '#818181',
                        banner_button_text: '',
                        banner_button_text_color: '#f1f1f1',
                        banner_button_link: '',
                        banner_button_hover_color: '#f1f1f1',
                        banner_button_hover_text_color: '#818181',
                        banner_button_redius: '',
                        text_position: 'left'
                    })
                    setNodata(true)
                    setIsVisibleButton(false)
                    setValue();
                    setimage();
                    handleGetPerticularBannerGroup();
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }
    const handleEditBanner = async (e, elem) => {
        e.preventDefault();
        setEditId(elem?._id)
        setValue(elem?.banner_text);
        setimage(elem?.banner_img);
        setBannerData({
            banner_button_color: elem?.banner_button_color,
            banner_button_text: elem?.banner_button_text,
            banner_button_link: elem?.banner_button_link,
            banner_button_text_color: elem?.banner_button_text_color,
            banner_button_hover_color: elem?.banner_button_hover_color,
            banner_button_hover_text_color: elem?.banner_button_hover_text_color,
            banner_button_redius: elem?.banner_button_redius,
            text_position: elem?.text_position
        })
        setIsVisibleButton(elem?.banner_button_text?.length > 0 ? true : false)
        setAddBanner(true);
        setIsEdit(true)
    }
    const handleEditBannerGroup = async (e) => {
        e.preventDefault();
        if (!groupTitle) {
            setDataErr(true)
        } else {
            setDataErr(false)
            try {
                await API({ url: `/super/admin/dummydata/dynamicdummybanner/updateBannerGroup/${params?.groupId}`, method: 'put', data: {
                    banner_title: groupTitle,
                    banner_limit: bannerLimit,
                    banner_parallax: bannerParallax,
                    banners: storeBanners,
                    status: status
                }})
                navigate('/dummy-data/module-setting/banner-group')
            } catch (error) {
                setIsNotification(true)
                setNotificationMsg(error)
                setSeverity('error')
            }
        }
    }
    const handleDelete = async (e, id) => {
        e.preventDefault();
        if (window.confirm('Are You Sure To Remove This banner?')) {
            try {
                const data = await API({ url: `/super/admin/dummydata/dynamicdummybanner/deleteBanner`, method: 'post', data: {
                    id: selectedRowsId?.length > 0 ? selectedRowsId : [id]
                }});
                if (data.status === 200 || data.status === 304) {
                    setIsNotification(true)
                    setNotificationMsg('Banner Removed SuccesFully!')
                    setSeverity('success')
                    handleGetPerticularBannerGroup();
                }
            } catch (error) {
                setIsNotification(true)
                setNotificationMsg(error)
                setSeverity("error")
            }
        }
    };
    const UpdateBannerStatus = async (id, value) => {
        try {
            const data = await API({ url: `/super/admin/dummydata/dynamicdummybanner/updateBanner/${id}`, method: 'put', data: {
                banner_status: value,
            }});
            if (data.status === 200 || data.status === 304) {
                handleGetPerticularBannerGroup()
            }
        } catch (error) {
            setNodata(true)
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity("error")
        }
    }
    const [changeButtonColor, setChangeButtonColor] = useState(false)
    const [changeButtonTextColor, setChangeButtonTextColor] = useState(false)
    const [changeButtonHoverColor, setChangeButtonHoverColor] = useState(false)
    const [changeButtonHoverTextColor, setChangeButtonHoverTextColor] = useState(false)
    const sortBanner = async (index, id) => {
        try {
            await API({ url: `/super/admin/dummydata/dynamicdummybanner/updateBanner/${id}`, method: 'put', data: {
                newIndex: index === 0 ? 0 : Number(index),
            }});
            handleGetPerticularBannerGroup();
        } catch (error) {
            setNodata(true)
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    };
    const DragItem = useRef();
    const DragOverItem = useRef();
    const HandleSort = (e) => {
        e.preventDefault();
        let _sort = [...storeBanners];
        const dragItemContent = _sort.splice(DragItem.current, 1)[0];
        _sort.splice(DragOverItem.current, 0, dragItemContent);
        sortBanner(DragOverItem.current, dragItemContent._id);
        DragItem.current = null;
        DragOverItem.current = null;
        let FinalArray = { storeBanners: _sort };
        setStoreBanners(FinalArray);
    };
    const columns = [
        {
            name: '',
            width: '30px',
            padding: '0px',
            cell: (row, index) => (
                <div
                    className='cursor-move drag-btn block w-full'
                    draggable
                    onDragStart={(e) => (DragItem.current = index)}
                    onDragEnter={(e) => (DragOverItem.current = index)}
                    onDragEnd={HandleSort}
                >
                    <Move className='cursor-move w-4' />
                </div>
            ),
        },
        {
            name: 'Image',
            width: '150px',
            cell: (row) => <img width={80} height={50} src={row.banner_img} className='p-1 max-w-full max-h-full' />,
        },
        {
            name: 'banner Text',
            cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.banner_text }}></span>,
            sortable: true,
            minWidth: '300px'
        },
        {
            name: 'button Text',
            cell: (row) => <span className='line-clamp-2'>{row.banner_button_text}</span>,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Link',
            cell: (row) => <span className='line-clamp-1' dangerouslySetInnerHTML={{ __html: row.banner_button_link || '-' }}></span>,
            minWidth: '150px'
        },
        {
            name: 'Status',
            width: '100px',
            cell: row => <input
                className="mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={row.banner_status}
                name='banner'
                onChange={() => {
                    UpdateBannerStatus(row?._id, !row.banner_status)
                }}
            />,
        },
        {
            name: <span className='w-full text-right'>Action</span>,
            width: '100px',
            cell: (row) => <div className='space-x-2 w-full text-right'><button onClick={(e) => handleEditBanner(e, row)}><Edit className='w-[17px] h-[17px] text-blue-500' /></button>
                <button onClick={(e) => {
                    handleDelete(e, row?._id);
                }}><Trash className='w-[17px] h-[17px] text-red-500' /></button>
            </div>
        }
    ]
    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const handleQuillChange = (value) => {
        setValue(value);
    };

    const [html, setHtml] = useState({ htmlPP: false, code: '' });
    const { htmlPP, code } = html
    const handleViewHtml = (e) => {
        e.preventDefault();
        const quill = quillRef.current.getEditor();
        const htmlContent = quill.root.innerHTML
        const formattedHtml = formatCode(htmlContent);
        setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
    };

    const saveHTML = () => {
        setHtml({ ...html, htmlPP: false })
        setValue(code)
    }

    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            {
                nodata ? <NoDataFound /> :
                    <div className='h-auto overflow-y-auto pb-[64px]'>
                        <div className='w-full'>
                            <Breadcrumbs />
                            <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Edit Banner Group</h3>
                        </div>
                        <form className='w-[90%] sm:w-[80%] xl:w-[70%] mx-auto'>
                            <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
                                <div className='mb-4'>
                                    <label htmlFor='image'>
                                        Banner Group Title
                                    </label>
                                    <input type='text' className='border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm' placeholder='Banner group title' value={groupTitle} onChange={(e) => setGroupTitle(e.target.value)} />
                                    {
                                        dataErr && <p className='text-xs text-red-500'>Please enter group name</p>
                                    }
                                </div>
                                <div className='grid w-full grid-cols-2 mb-4 gap-4'>
                                    <div className='mb-4 flex items-center'>
                                        <label htmlFor='image'>
                                            Banner Parallax :
                                        </label>
                                        <input
                                            className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                            type='checkbox'
                                            role='switch'
                                            id='flexSwitchCheckDefault'
                                            checked={bannerParallax}
                                            name='banner'
                                            onChange={() => {
                                                setBannerParallax(!bannerParallax)
                                                !bannerParallax && setBannerLimit(1)
                                            }}
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor='image'>
                                            Banner limit
                                        </label>
                                        <div className="relative">
                                            <select disabled={bannerParallax} value={bannerLimit} onChange={(e) => setBannerLimit(e.target.value)} className='w-full border border-gray-300 focus:border-gray-500 rounded py-1 outline-none px-3' >
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                            </select>
                                            <div className="select-arrow"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-4 flex items-center'>
                                    <label htmlFor='image'>
                                        status :
                                    </label>
                                    <input
                                        className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                        type='checkbox'
                                        role='switch'
                                        id='flexSwitchCheckDefault'
                                        checked={status === true}
                                        name='banner'
                                        onChange={() => setstatus(!status)}
                                    />
                                </div>
                                <hr className='border-t border-gray-200 my-4'></hr>
                                <div className='flex items-end justify-between'>
                                    <details
                                        className={
                                            selectedRowsId?.length === 0
                                                ? `opacity-60 cursor-not-allowed group w-[200px] relative`
                                                : `cursor-pointer group w-[200px] relative`
                                        }
                                    >
                                        <summary className='flex bg-white  border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
                                            <span> Bulk Selection</span>
                                            <span
                                                className={
                                                    selectedRowsId?.length === 0
                                                        ? 'transition group-open:rotate-0'
                                                        : 'transition group-open:rotate-180'
                                                }
                                            >
                                                <svg
                                                    fill='none'
                                                    height={14}
                                                    shapeRendering='geometricPrecision'
                                                    stroke='currentColor'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth='1.5'
                                                    viewBox='0 0 24 24'
                                                    width={14}
                                                >
                                                    <path d='M6 9l6 6 6-6' />
                                                </svg>
                                            </span>
                                        </summary>
                                        <div
                                            className={
                                                selectedRowsId?.length === 0
                                                    ? `hidden`
                                                    : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
                                            }
                                        >
                                            <button
                                                className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center'
                                                onClick={(e) => { handleDelete(e) }}
                                            >
                                                <TrashFill className='w-3.5 h-3.5' />
                                                <span>Delete selection</span>
                                            </button>
                                        </div>
                                    </details>
                                    <button className='text-link inline-flex items-center  mb-0 underline text-right font-medium text-sm text-white' onClick={(e) => { e.preventDefault(); setAddBanner(true) }}><PlusCircle className="w-4 me-2 h-4" />Add banner</button>
                                </div>
                                <div className='w-full inline-block border border-gray-300 rounded-[4px] overflow-x-auto overflow-y-hidden'>
                                    <DataTable
                                        columns={columns}
                                        data={storeBanners}
                                        selectableRows
                                        onSelectedRowsChange={({ selectedRows }) => { setSelectedRowsId((selectedRows?.map((x) => x._id))) }}
                                        selectableRowsHighlight
                                        highlightOnHover
                                        progressComponent={<Loader />}
                                    />
                                </div>
                                <hr className='border-t border-gray-200 my-4'></hr>
                                <button className='btn text-sm text-white' onClick={(e) => handleEditBannerGroup(e)}>Edit banner group</button>
                            </div>
                        </form>
                        {
                            addBanner &&
                            <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
                                <Popover
                                    open={addBanner}
                                    className="h-screen popup-content"
                                    onClose={() => {
                                        setAddBanner(false);
                                    }}
                                >
                                    <form className='bg-white w-full border border-gray-300  space-y-4 rounded p-5'>
                                        <div className='flex border-b border-gray-300 pb-3 items-center justify-between'>
                                            <h3 className='font-medium text-xl'>Add banner</h3>
                                            <button onClick={(e) => { e.preventDefault(); setAddBanner(false) }}><X className="w-5 h-4" /></button>
                                        </div>
                                        <div className='mb-4'>

                                            <div className='flex justify-between items-center'>
                                                <label htmlFor='image'>
                                                    Banner text
                                                </label>
                                                <section
                                                    className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                                                    onClick={handleViewHtml}
                                                >
                                                    View HTML
                                                </section>
                                            </div>


                                            <ReactQuill
                                                modules={module}
                                                ref={quillRef}
                                                className='w-full h-auto'
                                                theme='snow'
                                                value={value}
                                                onChange={handleQuillChange}
                                            />
                                        </div>
                                        <div className='mb-4'>
                                            <label htmlFor='image'>
                                                Banner image
                                            </label>
                                            <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} folder="banner" format="image" />
                                        </div>
                                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 w-full mb-4'>
                                            <div className='border w-full border-gray-300 flex items-center space-x-2 rounded px-4 py-1 text-sm'>
                                                <label className='text-[13px] w-[120px] font-[500] text-[#484848] capitalize'>text position</label>
                                                <div className='flex w-full text-black items-center text-sm justify-between px-4'>
                                                    <span className={`${bannerData?.text_position === "left" ? "bg-[#f5f5f5] rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5" : 'cursor-pointer block w-full text-center py-1.5 text-xs'}`} onClick={() => setBannerData({ ...bannerData, text_position: 'left' })}>Left</span>
                                                    <span className={`${bannerData?.text_position === "center" ? "bg-[#f5f5f5] rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5" : 'cursor-pointer block w-full text-center py-1.5 text-xs'}`} onClick={() => setBannerData({ ...bannerData, text_position: 'center' })}>Center</span>
                                                    <span className={`${bannerData?.text_position === "right" ? "bg-[#f5f5f5] rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5" : 'cursor-pointer block w-full text-center py-1.5 text-xs'}`} onClick={() => setBannerData({ ...bannerData, text_position: 'right' })}>Right</span>
                                                </div>
                                            </div>
                                            <div className='w-full border border-gray-300 flex items-center space-x-2 rounded px-4 py-2 text-sm'>
                                                <p className='text-[13px] font-[500] text-[#484848] capitalize'>
                                                    Visible banner button -
                                                </p>
                                                <input
                                                    className="mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                                    type='checkbox'
                                                    role='switch'
                                                    id='flexSwitchCheckDefault'
                                                    checked={isVisibleButton === true}
                                                    name='sliderbutton'
                                                    onChange={() => setIsVisibleButton(!isVisibleButton)}
                                                />
                                            </div>
                                        </div>
                                        {
                                            isVisibleButton ?
                                                <>
                                                    <div className='flex space-x-4 w-full mb-4'>
                                                        <div className='w-full'>
                                                            <label htmlFor='image'>
                                                                banner button text
                                                            </label>
                                                            <input name='banner_button_text' className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' value={bannerData?.banner_button_text} onChange={(e) => setBannerData({ ...bannerData, banner_button_text: e.target.value })} placeholder='e.g. Click here' />
                                                        </div>
                                                        <div className='w-full'>
                                                            <label htmlFor='image'>
                                                                banner button link
                                                            </label>
                                                            <input name='banner_button_link' value={bannerData?.banner_button_link} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='Add link' onChange={(e) => setBannerData({ ...bannerData, banner_button_link: e.target.value })} />
                                                        </div>
                                                        <div className='w-full'>
                                                            <label htmlFor='image'>
                                                                banner button border radius
                                                            </label>
                                                            <input type='number' name='banner_button_link' value={bannerData?.banner_button_redius} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='e.g. 4' onChange={(e) => setBannerData({ ...bannerData, banner_button_redius: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 w-full mb-4'>
                                                        <div className='w-full'>
                                                            <label htmlFor='image'>
                                                                banner button color
                                                            </label>
                                                            <div className='relative'>
                                                                <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                                                    <div className='w-[30px] h-[30px] rounded cursor-pointer' onClick={() => setChangeButtonColor(!changeButtonColor)} style={{ backgroundColor: bannerData?.banner_button_color }}></div>
                                                                    <span className='text-sm font-medium text-gray-500'>{bannerData?.banner_button_color}</span>
                                                                </div>
                                                                {
                                                                    changeButtonColor &&
                                                                    <HexColorPicker color={bannerData?.banner_button_color} onChange={(e) => setBannerData({ ...bannerData, banner_button_color: e })} onBlur={() => setChangeButtonColor(false)} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='w-full'>
                                                            <label htmlFor='image'>
                                                                button text color
                                                            </label>
                                                            <div className='relative'>
                                                                <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                                                    <div className='w-[30px] h-[30px] rounded' onClick={() => setChangeButtonTextColor(!changeButtonTextColor)} style={{ backgroundColor: bannerData?.banner_button_text_color }}></div>
                                                                    <span className='text-sm font-medium text-gray-500'>{bannerData?.banner_button_text_color}</span>
                                                                </div>
                                                                {
                                                                    changeButtonTextColor &&
                                                                    <HexColorPicker color={bannerData?.banner_button_text_color} onChange={(e) => setBannerData({ ...bannerData, banner_button_text_color: e })} onBlur={() => setChangeButtonTextColor(false)} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 w-full mb-4'>
                                                        <div className='w-full'>
                                                            <label htmlFor='image'>
                                                                banner button hover color
                                                            </label>
                                                            <div className='relative'>
                                                                <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                                                    <div className='w-[30px] h-[30px] rounded cursor-pointer' onClick={() => setChangeButtonHoverColor(!changeButtonHoverColor)} style={{ backgroundColor: bannerData?.banner_button_hover_color }}></div>
                                                                    <span className='text-sm font-medium text-gray-500'>{bannerData?.banner_button_hover_color}</span>
                                                                </div>
                                                                {
                                                                    changeButtonHoverColor &&
                                                                    <HexColorPicker color={bannerData?.banner_button_hover_color} onChange={(e) => setBannerData({ ...bannerData, banner_button_hover_color: e })} onBlur={() => setChangeButtonHoverColor(false)} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='w-full'>
                                                            <label htmlFor='image'>
                                                                button hover text color
                                                            </label>
                                                            <div className='relative'>
                                                                <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                                                    <div className='w-[30px] h-[30px] rounded' onClick={() => setChangeButtonHoverTextColor(!changeButtonHoverTextColor)} style={{ backgroundColor: bannerData?.banner_button_hover_text_color }}></div>
                                                                    <span className='text-sm font-medium text-gray-500'>{bannerData?.banner_button_hover_text_color}</span>
                                                                </div>
                                                                {
                                                                    changeButtonHoverTextColor &&
                                                                    <HexColorPicker color={bannerData?.banner_button_hover_text_color} onChange={(e) => setBannerData({ ...bannerData, banner_button_hover_text_color: e })} onBlur={() => setChangeButtonHoverTextColor(false)} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : <></>
                                        }
                                        <hr className='border-t border-gray-200 mt-3'></hr>
                                        <button onClick={(e) => { e.preventDefault(); handleAddBanner() }} className='btn text-sm text-white'>
                                            {isEdit ? "Edit banner" : "Add banner"}
                                        </button>
                                    </form>
                                </Popover>
                            </div>
                        }
                        {htmlPP && (
                            <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
                                <Popover
                                    open={htmlPP}
                                    className='h-screen popup-content'
                                // onClose={() => {
                                //   setHtmlPP(false);
                                // }}
                                >
                                    <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
                                        <div className='w-[35rem] h-96  mb-4'>
                                            <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                                                Edit HTML:
                                                <X onClick={() => {
                                                    if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                                                        setHtml({ ...html, htmlPP: false })
                                                    }
                                                }} />
                                            </label>
                                            <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                                                <textarea
                                                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                                    type='text'
                                                    rows={16}
                                                    value={code}
                                                    onChange={(e) => setHtml({ ...html, code: e.target.value })}
                                                    placeholder='<p>Code here<p/>'
                                                />
                                            </label>
                                        </div>
                                        <button
                                            className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                                            onClick={saveHTML}
                                        >
                                            save
                                        </button>
                                    </div>
                                </Popover>
                            </div>
                        )}
                    </div>
            }
        </>
    )
}

export default UpdateDummyBannerGroup