import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import { Edit } from 'feather-icons-react/build/IconComponents'
import { useNavigate } from 'react-router-dom'
import Loader from '../../Loader/Loader'
import DataTable from 'react-data-table-component'
import API from '../../../API'

const DummyLayoutTable = () => {
    const navigate = useNavigate();
    const [layouts,setLayouts] = useState();
    const getLayoutName = async () => {
        const Apicall = await API({ url: `/super/admin/dummydata/dummylayout/getLayouts`, method: 'get' });
        setLayouts(Apicall?.data?.data);
    }
    useEffect(() => {
        getLayoutName();
    }, [])
    const columns = [
        {
            name: 'layout name',
            cell: (row) => <span className='line-clamp-2'>{row?.layout_title}</span>,
            sortable: true,
            minWidth: '300px'
        },
        {
            name: <span className='w-full text-right'>Action</span>,
            width: '100px',
            cell: (row) => <div className='space-x-2 w-full text-right'>
                <button onClick={() => navigate(`/dummy-data/dummy-layout/${row?._id}`)}><Edit className='w-[17px] h-[17px] text-blue-500' /></button>
            </div>
        }
    ]
    return (
        <div className='h-auto overflow-y-auto pb-[64px]'>
            <div className='flex items-end justify-between w-full pb-4'>
                <div>
                    <Breadcrumbs />
                    <h1 className='text-xl md:text-2xl font-medium text-primary'>Layout</h1>
                </div>
            </div>
            <div className='w-full inline-block border border-gray-300 rounded-[10px] overflow-x-auto overflow-y-hidden'>
                <DataTable
                    columns={columns}
                    data={layouts}
                    onRowClicked={(row) => navigate(`/dummy-data/dummy-layout/${row?._id}`)}
                    selectableRowsHighlight
                    highlightOnHover
                    progressComponent={<Loader />}
                />
            </div>
        </div>
    )
}

export default DummyLayoutTable