import { useState } from 'react';
import { Search } from 'feather-icons-react/build/IconComponents';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import { useNavigate } from 'react-router-dom';
import { DummyData } from '../../../Assets/StaticData/DummyData';

const  DummyDataCount = () => {
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const [search, setSearch] = useState('')
    const handleClose = () => {
      setIsNotification(false);
      setNotificationMsg('');
      setSeverity('');
    };
    const navigate = useNavigate();
    let filteredDummyDatas = [];
    filteredDummyDatas = DummyData.filter((dummy) => {
      const regex = new RegExp(search, 'i'); // 'i' flag for case-insensitive search
      return dummy.name.match(regex) || dummy.discripion.match(regex);
    });
    return (
      <>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <div className='h-auto overflow-y-auto pb-[64px]'>
          <Breadcrumbs />
          <h1 className='text-xl md:text-2xl font-medium block pb-4 text-primary'>Modules setting</h1>
          <div className='relative w-full lg:w-[50%] bg-white mb-4'>
            <input type="text" className='w-full outline-none focus:border-gray-500 border border-gray-300 rounded' placeholder="Search modules..." value={search} onChange={(e) => setSearch(e.target.value)} />
            <Search className="w-4 h-4 absolute right-3 top-[50%] -translate-y-[50%]" />
          </div>
          <div className='w-full border border-gray-300 rounded overflow-auto'>
            <table className='block'>
              <thead className='w-full'>
                <tr className='h-[50px] text-gray-700 align-middle border-b bg-white border-b-gray-300 text-xs uppercase font-bold'>
                  <td className='px-4 min-w-[400px]'>Module Name</td>
                  <td className='px-4 w-full text-right'>Action</td>
                </tr>
              </thead>
              <tbody>
                {
                  filteredDummyDatas?.length > 0 ?
                  filteredDummyDatas?.map((elem) => {
                      return (
                        <tr
                          className='w-full border-b border-gray-200 cursor-pointer bg-white hover:bg-[#fafafa]'
                          onClick={() => navigate(elem?.redirect)}
                        >
                          <td className='w-full p-4'>
                            <span>{elem?.name}</span>
                            <p className='text-gray-500 text-sm'>{elem?.discripion}</p>
                          </td>
                          <td className='w-full text-right p-4'>
                            <button className='secondary-btn text-[13px]'>Configure</button>
                          </td>
                        </tr>
                      )
                    }) : <tr>
                      <td className='bg-white w-full'>
                        <p className='p-4 text-center text-base w-full'>No records found!</p>
                      </td>
                      <td className='bg-white w-full'></td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
}
export default DummyDataCount;