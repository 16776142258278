import { GET_COMPANY_DATA, GET_PERTICULER_COMPANY_DATA } from "../action.type";

const initialState = {
    allCompany: null,
    perticulerCompany: null
}

export const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY_DATA:
            return {
                ...state,
                allCompany: action?.payload,
            };
        case GET_PERTICULER_COMPANY_DATA:
            return {
                ...state,
                perticulerCompany: action?.payload
            }
        default:
            return state;
    }
}
