import React, { useEffect, useRef, useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import module, { formatCode } from '../../../controllers/QuillToolbar';
import ReactQuill from 'react-quill';
import API from '../../../API';
import { HexColorPicker } from "react-colorful";
import { Popover } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import { useNavigate, useParams } from 'react-router-dom';
import Notification from '../../Notification';
import NoDataFound from '../../Main/NoDataFound';

const UpdateCmsOffer = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [isNotification, setIsNotification] = useState(false)
    const [notificationMsg, setNotificationMsg] = useState('')
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false)
        setNotificationMsg('')
        setSeverity('')
    }
    const [nodata, setNodata] = useState(false)
    const [value, setValue] = useState();
    const [cmsOfferData, setCmsOfferData] = useState({
        cms_offer_title: '',
        status: true,
        cms_offer_text: value,
        cms_offer_button_color: '#818181',
        cms_offer_button_text: '',
        cms_offer_button_text_color: '#000000',
        cms_offer_button_link: '',
        cms_offer_button_hover_text_color: '#818181',
        cms_offer_button_hover_color: '#000000',
        cms_offer_button_redius: '',
        text_position: 'left'
    })
    
    useEffect(() => {
        handleGetPerticularCmsOffer();
    }, [])
    const handleGetPerticularCmsOffer = async () => {
        try {
            const apiCall = await API({ url: `/super/admin/dummydata/dynamicdummycmsoffer/getCmsOffer/${params?.groupId}`, method: 'get' })
            const data = apiCall?.data?.data;
            setValue(data?.cms_offer?.cms_offer_text);
            setCmsOfferData({
                cms_offer_title: data?.cms_offer_title,
                status: data?.status,
                cms_offer_button_color: data?.cms_offer?.cms_offer_button_color || '#818181',
                cms_offer_button_text: data?.cms_offer?.cms_offer_button_text,
                cms_offer_button_text_color: data?.cms_offer?.cms_offer_button_text_color || '#000000',
                cms_offer_button_link: data?.cms_offer?.cms_offer_button_link,
                cms_offer_button_hover_text_color: data?.cms_offer?.cms_offer_button_hover_text_color || '#818181',
                cms_offer_button_hover_color: data?.cms_offer?.cms_offer_button_hover_color || '#000000',
                cms_offer_button_redius: data?.cms_offer?.cms_offer_button_redius,
                text_position: data?.cms_offer?.text_position
            });
        } catch (error) {
            setNodata(true)
            console.log(error);
        }
    }
    const [dataErr, setDataErr] = useState(false);
    const handleEditCmsOffer = async (e) => {
        e.preventDefault();
        if (!cmsOfferData?.cms_offer_title) {
            setDataErr(true)
        } else {
            setDataErr(false)
            try {
                await API({ url: `/super/admin/dummydata/dynamicdummycmsoffer/updateCmsOffer/${params?.groupId}`, method: 'put', data: {
                    cms_offer_title: cmsOfferData?.cms_offer_title,
                    status: cmsOfferData?.status,
                    cms_offer: {
                        cms_offer_text: value,
                        cms_offer_button_text: cmsOfferData?.cms_offer_button_text,
                        cms_offer_button_link: cmsOfferData?.cms_offer_button_link,
                        cms_offer_button_color: cmsOfferData?.cms_offer_button_color,
                        cms_offer_button_text_color: cmsOfferData?.cms_offer_button_text_color,
                        cms_offer_button_hover_color: cmsOfferData?.cms_offer_button_hover_color,
                        cms_offer_button_hover_text_color: cmsOfferData?.cms_offer_button_hover_text_color,
                        cms_offer_button_redius: cmsOfferData?.cms_offer_button_redius,
                        text_position: cmsOfferData?.text_position
                    }
                }})
                navigate('/dummy-data/module-setting/cms-offer')
            } catch (error) {
                setIsNotification(true)
                setNotificationMsg(error)
                setSeverity('error')
            }
        }
    }

    const [changeButtonColor, setChangeButtonColor] = useState(false)
    const [changeButtonTextColor, setChangeButtonTextColor] = useState(false)
    const [changeButtonHoverColor, setChangeButtonHoverColor] = useState(false)
    const [changeButtonHoverTextColor, setChangeButtonHoverTextColor] = useState(false)

    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const handleQuillChange = (value) => {
        setValue(value);
    };

    const [html, setHtml] = useState({ htmlPP: false, code: '' });
    const { htmlPP, code } = html
    const handleViewHtml = (e) => {
        e.preventDefault();
        const quill = quillRef.current.getEditor();
        const htmlContent = quill.root.innerHTML
        const formattedHtml = formatCode(htmlContent);
        setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
    };

    const saveHTML = () => {
        setHtml({ ...html, htmlPP: false })
        setValue(code)
    }
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            {nodata ? <NoDataFound /> :
                <div className='h-auto overflow-y-auto pb-[64px]'>
                    <div className='w-full'>
                        <Breadcrumbs />
                        <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Edit Cms Offer Title</h3>
                    </div>
                    <form className='w-[90%] sm:w-[80%] xl:w-[70%] mx-auto'>
                        <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
                            <div className='mb-4'>
                                <label htmlFor='cms_offer_title'>
                                    Cms Offer Title
                                </label>
                                <input type='text' className='border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm' placeholder='cms offer title' value={cmsOfferData?.cms_offer_title} onChange={(e) => setCmsOfferData({...cmsOfferData, cms_offer_title: e.target.value})} />
                                {
                                    dataErr && <p className='text-xs text-red-500'>Please cms offer title</p>
                                }
                            </div>
                            <div className='mb-4 flex items-center'>
                                <label htmlFor='image'>
                                    status :
                                </label>
                                <input
                                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                    type='checkbox'
                                    role='switch'
                                    id='flexSwitchCheckDefault'
                                    checked={cmsOfferData.status}
                                    name='status'
                                    onChange={() => setCmsOfferData({...cmsOfferData, status: !cmsOfferData.status})}
                                />
                            </div>
                            <div className='mb-4'>
                                <div className='flex justify-between items-center'>
                                    <label htmlFor='image'>
                                        Cms Offer Text
                                    </label>
                                    <section
                                        className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                                        onClick={handleViewHtml}
                                    >
                                        View HTML
                                    </section>
                                </div>

                                <ReactQuill
                                    modules={module}
                                    ref={quillRef}
                                    className='w-full h-auto'
                                    theme='snow'
                                    value={value}
                                    onChange={handleQuillChange}
                                />
                            </div>
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 w-full mb-4'>
                                <div className='border w-full border-gray-300 flex items-center space-x-2 rounded px-4 py-1 text-sm'>
                                <label className='text-[13px] w-[120px] font-[500] text-[#484848] capitalize'>text position</label>
                                    <div className='flex w-full text-black items-center text-sm justify-between px-4'>
                                        <span className={`${cmsOfferData?.text_position === "left" ? "bg-[#f5f5f5] rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5" : 'cursor-pointer block w-full text-center py-1.5 text-xs'}`} onClick={() => setCmsOfferData({ ...cmsOfferData, text_position: 'left' })}>Left</span>
                                        <span className={`${cmsOfferData?.text_position === "center" ? "bg-[#f5f5f5] rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5" : 'cursor-pointer block w-full text-center py-1.5 text-xs'}`} onClick={() => setCmsOfferData({ ...cmsOfferData, text_position: 'center' })}>Center</span>
                                        <span className={`${cmsOfferData?.text_position === "right" ? "bg-[#f5f5f5] rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5" : 'cursor-pointer block w-full text-center py-1.5 text-xs'}`} onClick={() => setCmsOfferData({ ...cmsOfferData, text_position: 'right' })}>Right</span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex space-x-4 w-full mb-4'>
                                <div className='w-full'>
                                    <label htmlFor='button_text'>
                                        button text
                                    </label>
                                    <input name='button_text' value={cmsOfferData?.cms_offer_button_text} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' onChange={(e) => setCmsOfferData({ ...cmsOfferData, cms_offer_button_text: e.target.value })} placeholder='e.g. Click here' />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor='button_link'>
                                        button link
                                    </label>
                                    <input name='button_link' value={cmsOfferData?.cms_offer_button_link} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='Add link' onChange={(e) => setCmsOfferData({ ...cmsOfferData, cms_offer_button_link: e.target.value })} />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor='button_redius'>
                                        button border radius
                                    </label>
                                    <input type='number' name='button_redius' value={cmsOfferData?.cms_offer_button_redius} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='e.g. 4' onChange={(e) => setCmsOfferData({ ...cmsOfferData, cms_offer_button_redius: e.target.value })} />
                                </div>
                            </div>
                            <div className='flex space-x-4 w-full mb-4'>
                                <div className='w-full'>
                                    <label htmlFor='button_color'>
                                        button color
                                    </label>
                                    <div className='relative'>
                                        <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                            <div className='w-[30px] h-[30px] rounded cursor-pointer' onClick={() => setChangeButtonColor(!changeButtonColor)} style={{ backgroundColor: cmsOfferData?.cms_offer_button_color }}></div>
                                            <span className='text-sm font-medium text-gray-500'>{cmsOfferData?.cms_offer_button_color}</span>
                                        </div>
                                        {
                                            changeButtonColor &&
                                            <HexColorPicker color={cmsOfferData?.cms_offer_button_color} onChange={(e) => setCmsOfferData({ ...cmsOfferData, cms_offer_button_color: e })} onBlur={() => setChangeButtonColor(false)} />
                                        }
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <label htmlFor='button_text_color'>
                                        button text color
                                    </label>
                                    <div className='relative'>
                                        <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                            <div className='w-[30px] h-[30px] rounded' onClick={() => setChangeButtonTextColor(!changeButtonTextColor)} style={{ backgroundColor: cmsOfferData?.cms_offer_button_text_color }}></div>
                                            <span className='text-sm font-medium text-gray-500'>{cmsOfferData?.cms_offer_button_text_color}</span>
                                        </div>
                                        {
                                            changeButtonTextColor &&
                                            <HexColorPicker color={cmsOfferData?.cms_offer_button_text_color} onChange={(e) => setCmsOfferData({ ...cmsOfferData, cms_offer_button_text_color: e })} onBlur={() => setChangeButtonTextColor(false)} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='flex space-x-4 w-full mb-4'>
                                <div className='w-full'>
                                    <label htmlFor='button_hover_color'>
                                        button hover color
                                    </label>
                                    <div className='relative'>
                                        <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                            <div className='w-[30px] h-[30px] rounded cursor-pointer' onClick={() => setChangeButtonHoverColor(!changeButtonHoverColor)} style={{ backgroundColor: cmsOfferData?.cms_offer_button_hover_color }}></div>
                                            <span className='text-sm font-medium text-gray-500'>{cmsOfferData?.cms_offer_button_hover_color}</span>
                                        </div>
                                        {
                                            changeButtonHoverColor &&
                                            <HexColorPicker color={cmsOfferData?.cms_offer_button_hover_color} onChange={(e) => setCmsOfferData({ ...cmsOfferData, cms_offer_button_hover_color: e })} onBlur={() => setChangeButtonHoverColor(false)} />
                                        }
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <label htmlFor='button_hover_text_color'>
                                        button hover text color
                                    </label>
                                    <div className='relative'>
                                        <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                            <div className='w-[30px] h-[30px] rounded' onClick={() => setChangeButtonHoverTextColor(!changeButtonHoverTextColor)} style={{ backgroundColor: cmsOfferData?.cms_offer_button_hover_text_color }}></div>
                                            <span className='text-sm font-medium text-gray-500'>{cmsOfferData?.cms_offer_button_hover_text_color}</span>
                                        </div>
                                        {
                                            changeButtonHoverTextColor &&
                                            <HexColorPicker color={cmsOfferData?.cms_offer_button_hover_text_color} onChange={(e) => setCmsOfferData({ ...cmsOfferData, cms_offer_button_hover_text_color: e })} onBlur={() => setChangeButtonHoverTextColor(false)} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <button className='btn text-sm text-white' onClick={(e) => handleEditCmsOffer(e)}>Edit cms offer</button>
                        </div>
                    </form>

                </div>}
            {htmlPP && (
                <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
                    <Popover
                        open={htmlPP}
                        className='h-screen popup-content'
                    // onClose={() => {
                    //   setHtmlPP(false);
                    // }}
                    >
                        <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
                            <div className='w-[35rem] h-96  mb-4'>
                                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                                    Edit HTML:
                                    <X onClick={() => {
                                        if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                                            setHtml({ ...html, htmlPP: false })
                                        }
                                    }} />
                                </label>
                                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                                    <textarea
                                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                        type='text'
                                        rows={16}
                                        value={code}
                                        onChange={(e) => setHtml({ ...html, code: e.target.value })}
                                        placeholder='<p>Code here<p/>'
                                    />
                                </label>
                            </div>
                            <button
                                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                                onClick={saveHTML}
                            >
                                save
                            </button>
                        </div>
                    </Popover>
                </div>
            )}
        </>
    )
}

export default UpdateCmsOffer