import React, { useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import API from '../../../API';
import { Popover } from '@mui/material';
import { PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import DataTable from 'react-data-table-component';
import { useEffect } from 'react';
import Notification from '../../Notification';

const CreateDummyProductGroup = () => {
    const [isNotification, setIsNotification] = useState(false)
    const [notificationMsg, setNotificationMsg] = useState('')
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false)
        setNotificationMsg('')
        setSeverity('')
    }
    const navigate = useNavigate();
    const [productGroupTitle, setProductGroupTitle] = useState('')
    const [productData, setProductData] = useState({
        button_link: '/product',
        product_limit: '4',
        carousel_status: true,
        status: true,
        product_image_width: '',
        product_image_height: '',
        carousel_speed_ms: '',
        auto_play: false,
        pause_on_hover: false,
        loop_forever: false,
        button_text: '',
        filter_type: ''
    })
    const [addProduct, setAddProduct] = useState(false);
    const [dataErr, setDataErr] = useState(false);
    const handleAddProduct = () => {
        setAddProduct(false)
    }
    const [selectedProduct, setSelectedProduct] = useState([])
    const handleSelectProduct = (product, name, image) => {
        if (product) {
            const existProduct = selectedProduct?.find((x) => x?.product === product);
            if (!existProduct) {
                setSelectedProduct([...selectedProduct, { product, name, image }])
            } else {
                const removeSelected = selectedProduct?.filter((x) => x?.product !== product)
                setSelectedProduct(removeSelected);
            }
        }
    }
    const [allProduct, setAllProduct] = useState([])
    const [search, setSearch] = useState('')
    const GetProduct = async () => {
        try {
            const apiCall = await API({ url: `/super/admin/dummydata/dummyproduct/getDummyProducts`, method: 'get', params: { searchkey: search }});
            if (apiCall.status === 200 || apiCall.status === 304) {
                setAllProduct(apiCall?.data?.data?.records)
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    };
    useEffect(() => {
        if (addProduct === true && (search?.length === 0 || search?.length >= 3)) {
            GetProduct();
        }
    }, [addProduct, search])
    const handleAddProductGroup = async (e) => {
        e.preventDefault();
        if (productGroupTitle?.length === 0) {
            setDataErr(true)
        } else {
            setDataErr(false)
            let body = {
                product_section_title: productGroupTitle,
                button_text: productData?.button_text,
                button_link: productData?.button_link,
                product_limit: productData?.product_limit,
                carousel_status: productData?.carousel_status,
                status: productData?.status,
                carousel_speed_ms: productData?.carousel_speed_ms,
                loop_forever: productData?.loop_forever,
                auto_play: productData?.auto_play,
                pause_on_hover: !productData?.auto_play ? false : productData?.pause_on_hover,
                products: selectedProduct
            };
            if (productData?.product_image_width?.length > 0 || productData?.product_image_height?.length > 0) {
                body = {
                    ...body,
                    product_image_width: productData?.product_image_width,
                    product_image_height: productData?.product_image_height,
                }
            } else {
                body = {
                    ...body,
                }
            }
            try {
                await API({ url: `/super/admin/dummydata/dynamicdummyproduct/addProductGroup`, method: 'post', data: body})
                navigate('/dummy-data/module-setting/product-group')
            } catch (error) {
                console.log(error);
            }
        }
    }
    const columns = [
        {
            name: 'Image',
            width: '150px',
            cell: (row) => <img width={80} height={50} src={row.image} alt={row.image} className='p-1 max-w-full max-h-full object-contain' />,
        },
        {
            name: 'product name',
            cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.name }}></span>,
            minWidth: '250px'
        },
    ]

    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className='h-auto overflow-y-auto pb-[64px]'>
                <div className='w-full'>
                    <Breadcrumbs />
                    <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Add New Product Group</h3>
                </div>
                <form className='w-[90%] space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'>
                    <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
                        <div className='w-full mb-4'>
                            <label htmlFor='group title'>
                                Product group title
                            </label>
                            <input
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='text'
                                id='group title'
                                name='group title'
                                value={productGroupTitle}
                                onChange={(e) => setProductGroupTitle(e.target.value)}
                                placeholder='Enter title....'
                            />
                            {
                                dataErr && productGroupTitle?.length === 0 && <p className='text-xs text-red-500'>Please enter group title</p>
                            }
                        </div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full'>
                            <div className='w-full'>
                                <label>Product Limit</label>
                                <div className='relative'>
                                    <select className='w-full' value={productData?.product_limit} onChange={(e) => setProductData({ ...productData, product_limit: e.target.value })}>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                    </select>
                                    <div className='select-arrow'></div>
                                </div>
                            </div>

                            <div className='w-full'>
                                <label>carousel speed ms</label>
                                <div className='relative'>
                                    <input value={productData?.carousel_speed_ms} disabled={productData?.carousel_status === 'disabled'} onChange={(e) => setProductData({ ...productData, carousel_speed_ms: e.target.value })} className='w-full border border-gray-300 px-4 py-2 rounded text-sm outline-none focus:border-gray-500' placeholder='e.g. 5000' />
                                </div>
                            </div>
                            {
                                productData?.auto_play &&
                                <div className='mb-4 h-[39px] border border-gray-300 rounded px-4 flex items-center'>
                                    <p className='text-[12px] font-medium text-[#484848]'>pause on hover</p>
                                    <input
                                        className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                        type='checkbox'
                                        role='switch'
                                        id='flexSwitchCheckDefault'
                                        checked={productData?.pause_on_hover}
                                        name='pause_on_hover'
                                        onChange={(e) => {
                                            setProductData({ ...productData, pause_on_hover: !productData?.pause_on_hover })
                                        }}
                                    />
                                </div>
                            }
                            <div className='w-full'>
                                <label htmlFor='image'>
                                    button text
                                </label>
                                <input value={productData?.button_text} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' onChange={(e) => setProductData({ ...productData, button_text: e.target.value })} placeholder='e.g. Explore All' />
                            </div>
                            <div className='w-full'>
                                <label htmlFor='image'>
                                    button link
                                </label>
                                <input value={productData?.button_link} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='Add link' onChange={(e) => setProductData({ ...productData, button_link: e.target.value })} />
                            </div>
                            <div className='w-full'>
                                <label>product image width</label>
                                <div className='relative'>
                                    <input value={productData?.product_image_width} onChange={(e) => setProductData({ ...productData, product_image_width: e.target.value })} className='w-full border border-gray-300 px-4 py-2 rounded text-sm outline-none focus:border-gray-500' placeholder='e.g. 318' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <label>product image height</label>
                                <div className='relative'>
                                    <input value={productData?.product_image_height} onChange={(e) => setProductData({ ...productData, product_image_height: e.target.value })} className='w-full border border-gray-300 px-4 py-2 rounded text-sm outline-none focus:border-gray-500' placeholder='e.g. 192' />
                                </div>
                            </div>
                            <div className='mb-4 h-[39px] border border-gray-300 rounded px-4 flex items-center'>
                                <p className='text-[12px] font-medium text-[#484848]'>Carousel Status</p>
                                <input
                                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                    type='checkbox'
                                    role='switch'
                                    id='flexSwitchCheckDefault'
                                    checked={productData?.carousel_status}
                                    name='carousel_status'
                                    onChange={(e) => {
                                        setProductData({ ...productData, carousel_status: !productData?.carousel_status })
                                    }}
                                />
                            </div>
                            <div className='mb-4 h-[39px] border border-gray-300 rounded px-4 flex items-center'>
                                <p className='text-[12px] font-medium text-[#484848]'>Carousel Loop</p>
                                <input
                                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                    type='checkbox'
                                    role='switch'
                                    id='flexSwitchCheckDefault'
                                    checked={productData?.loop_forever}
                                    name='loop_forever'
                                    onChange={(e) => {
                                        setProductData({ ...productData, loop_forever: !productData?.loop_forever })
                                    }}
                                />
                            </div>
                            <div className='mb-4 h-[39px] border border-gray-300 rounded px-4 flex items-center'>
                                <p className='text-[12px] font-medium text-[#484848]'>Autoplay</p>
                                <input
                                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                    type='checkbox'
                                    role='switch'
                                    id='flexSwitchCheckDefault'
                                    checked={productData?.auto_play}
                                    name='auto_play'
                                    onChange={(e) => {
                                        setProductData({ ...productData, auto_play: !productData?.auto_play })
                                    }}
                                />
                            </div>
                            <div className='mb-4 h-[39px] border border-gray-300 rounded px-4 flex items-center'>
                                <p className='text-[12px] font-medium text-[#484848]'>Product Group Status</p>
                                <input
                                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                    type='checkbox'
                                    role='switch'
                                    id='flexSwitchCheckDefault'
                                    checked={productData?.status}
                                    name='status'
                                    onChange={(e) => {
                                        setProductData({ ...productData, status: !productData?.status })
                                    }}
                                />
                            </div>
                        </div>
                        <hr className='border-t border-gray-200 my-4'></hr>
                        <button className='text-link underline w-full text-right flex items-center justify-end font-medium text-sm text-white' onClick={(e) => { e.preventDefault(); setAddProduct(true) }}><PlusCircle className="w-4 me-2 h-4" />Add Products</button>
                        <div className='w-full inline-block border border-gray-300 rounded-[4px] overflow-x-auto overflow-y-hidden'>
                            <DataTable
                                columns={columns}
                                data={selectedProduct}
                                selectableRowsHighlight
                                highlightOnHover
                                progressComponent={<Loader />}
                            />
                        </div>
                        <hr className='border-t border-gray-200 my-4'></hr>
                        <button className='btn text-sm text-white' onClick={(e) => handleAddProductGroup(e)}>Add Product group</button>
                    </div>
                </form>
                {
                    addProduct ?
                        <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
                            <Popover
                                open={addProduct}
                                className="h-screen popup-content"
                                onClose={() => {
                                    setAddProduct(false);
                                }}
                            >
                                <div className='bg-white w-full lg:w-[600px] border border-gray-300  space-y-4 rounded p-5'>
                                    <div className='flex border-b border-gray-300 pb-3 items-center justify-between'>
                                        <h3 className='font-medium text-xl'>Select products</h3>
                                        <button onClick={(e) => { e.preventDefault(); setAddProduct(false) }}><X className="w-5 h-4" /></button>
                                    </div>
                                    <input value={search} onChange={(e) => setSearch(e.target.value)} className='w-full border border-gray-300 px-4 py-2 rounded outline-none focus:border-gray-500 text-sm' placeholder='Search products..' />
                                    <div className='h-[450px] overflow-y-auto divide-y'>
                                        {
                                            allProduct?.map((elem) => {
                                                return (
                                                    <label className='flex py-2 items-start space-x-4'>
                                                        <input type='checkbox' checked={selectedProduct?.find((x) => x.product === elem?._id)} value={elem?._id} onClick={(e) => handleSelectProduct(elem?._id, elem?.product_name, elem?.image)} className='block mt-1 w-4 h-4' />
                                                        <div className='flex space-x-2'>
                                                            <div className='w-[60px] h-[60px] p-1 border border-gray-200 flex items-center justify-center'>
                                                                <img src={elem?.image} alt={elem?.image} className='max-w-full max-h-full' />
                                                            </div>
                                                            <p className='text-base break-all'>{elem?.product_name}</p>
                                                        </div>
                                                    </label>
                                                )
                                            })
                                        }
                                    </div>
                                    <button className='btn text-sm text-white' onClick={() => handleAddProduct()}>Add products</button>
                                </div>
                            </Popover>
                        </div> : <></>
                }

            </div>
        </>
    )
}

export default CreateDummyProductGroup