import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Edit, Filter, Trash, Move, PlusCircle } from 'feather-icons-react/build/IconComponents';
import API from '../../../API';
import { Pagination, Tooltip } from '@mui/material';
import DataTable from 'react-data-table-component';
import Loader from '../../Loader/Loader';
import { CaretDownFill, CaretUpFill, TrashFill } from 'react-bootstrap-icons';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import { getInitialPage, replacePageInUrl } from '../../../controllers/PageHandleFromQuery';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';

const DummyManufacturerTable = () => {
  const navigate = useNavigate();
  const [manData, setManData] = useState([]);
  const location = useLocation()
  const [page, setPage] = useState(getInitialPage());
  const [search, setSearch] = useState(location?.state ? location.state : '');
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [selectedRowsId, setSelectedRowsId] = useState([])
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  useEffect(() => {
    replacePageInUrl(page)
  }, [page])
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetManufacturers();
  };
  const [pending, setPending] = useState(true)
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  var startRecord = (manData?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(manData?.data?.currentPage * limit, manData?.data?.totalRecords);

  const GetManufacturers = async () => {
    try {
      const data = await API({ url:
        `/super/admin/dummydata/dummybrand/getDummyManufacturers`, method: 'get', 
        params: { page, limit, searchkey: search?.length > 2 ? search : '', sort_by: sortBy, order_by : orderBy.orderByValue }
      });
      if (data.status === 200 || data.status === 304) {
        setManData(data.data);
        setPending(false)
        if (Number(startRecord) > Number(data.data?.data?.totalRecords)) {
          setPage(page - 1)
        }
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };

  useEffect(() => {
    if (!search === '' || search?.length > 2) {
      GetManufacturers()
    }
  }, [search])

  useEffect(() => {
    GetManufacturers();
  }, [page, limit]);
  const tableData = manData?.data?.records;

  // Drag And Drop Functionality
  const DragItem = useRef();
  const DragOverItem = useRef();
  const sortProduct = async (index, id) => {
    try {
      const apiCall = await API({ url: `/super/admin/dummydata/dummybrand/updateDummyManufacturer/${id}`, method: 'put', data: {
        newIndex: (index === 0 && page === 1) ? 0 : page !== 1 ? Number(index) + Number(limit) * Number(page - 1) : Number(index),
      }})
      if (apiCall.status === 200) {
        GetManufacturers()
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...manData?.data?.records];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    sortProduct(DragOverItem.current, dragItemContent._id)
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = { data: { records: _sort } }
    setManData(FinalArray);
  };

  // delete Manufacturer
  const HandleDelete = async (id, b_name) => {
    if (window.confirm(`Are You Sure To Delete manufacture?`)) {
      try {
        await API({ url: `/super/admin/dummydata/dummybrand/deleteDummyManufacturer`, method:"post", data: {
          id: selectedRowsId?.length > 0 ? selectedRowsId : [id]
        }});
        GetManufacturers()
        // setPage(Number(page)-1)
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };

  const SelectManufacturer = async (id, action) => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummybrand/updateDummyManufacturer/${id}`, method: 'put', data: {
        is_selected: action === "add" ? true : false,
      }});
      if (data.status === 200 || data.status === 304) {
        GetManufacturers();
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(true);

  const columns = [
    {
      name: '',
      width: '30px',
      padding: '0px',
      cell: (row, index) => <div className='cursor-move drag-btn text-center w-full' draggable
        onDragStart={(e) => (DragItem.current = index)}
        onDragEnter={(e) => (DragOverItem.current = index)}
        onDragEnd={HandleSort}>
        <Move className='cursor-move w-4' />
      </div>
    },
    {
      name: 'logo',
      width: "100px",
      cell: (row) => <img width={50} height={50}
        onError={(e) => { e.target.src = NOPHOTO_IMAGE }}
        src={row.brand_logo || NOPHOTO_IMAGE}
        alt={row.brand_logo || NOPHOTO_IMAGE}
        className='p-1' />,
    },
    {
      name: (
        <div className='flex items-center'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'brand_name' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('brand_name') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('brand_name')}>
            Name
            {
              iconVisible && sortBy === 'brand_name' ?
                !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
            }
          </button>
        </div>
      ),
      minWidth: '200px',
      cell: row => <span className='capitalize'>{row.brand_name}</span>,
    },
    {
      name: 'description',
      cell: row => <span className='line-clamp-1' dangerouslySetInnerHTML={{ __html: row.description?.replace(/<img[^>]*>/g, '') }}></span>,
    },
    {
      name: <span className='block text-right w-full'>Action</span>,
      width: '100px',
      cell: (row) => <div className='space-x-2 flex w-full justify-end '>
        <div>
          <button
            href='#'
            className='text-xs font-thin text-blue-500 hover:text-blue-600'
            onClick={() => {
              navigate(`/dummy-data/dummy-Manufacturer/update-dummy-Manufacturer/${row?.brand_slug_name}`, {
                state: { row },
              });
            }}
          >
            <div>
              <Tooltip title='Edit' arrow>
                <button className=''>
                  <Edit className='w-[17px] h-[17px]' />
                </button>
              </Tooltip>
            </div>
          </button>
        </div>
        <div>
          <button
            href='#'
            className='text-xs font-thin text-red-500 hover:text-red-600'
            onClick={() => HandleDelete(row._id, row.brand_name)}
          >
            <div>
              <Tooltip title='Delete' arrow>
                <button className=''>
                  <Trash className='w-[17px] h-[17px]' />
                </button>
              </Tooltip>
            </div>
          </button>
        </div>
      </div>
    },
    {
      name: <div className='flex justify-end w-full pe-2'>Visibility</div>,
      width: '120px',
      cell: (row) =>
        <div className='flex justify-end w-full'>
          <input
            className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
            type='checkbox'
            role='switch'
            checked={row?.is_selected}
            id='flexSwitchCheckDefault'
            name='brand'
            onChange={() => SelectManufacturer(row._id, row?.is_selected ? 'remove' : 'add')}
          />
        </div>
    },
  ]
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full overflow-auto pb-4'>
          <div>
            <Breadcrumbs />
            <h1 className='text-xl md:text-2xl font-medium text-primary'>Manufacturers </h1>
          </div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
              onClick={() => navigate('/dummy-data/dummy-Manufacturer/add-dummy-Manufacturer')}
            >
              <PlusCircle className="w-[18px] h-[18px] me-2" />
              Add New Manufacturer
            </button>
          </div>
        </div>
        <div className='flex justify-between space-x-3 w-full relative'>
          <div className='w-full space-y-1.5'>
            <div className='flex items-center justify-between'>
              <details className={(selectedRowsId?.length === 0) ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative` : `cursor-pointer group w-[200px] mb-1.5 relative`}>
                <summary className='flex bg-white border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
                  <span> Bulk Selection</span>
                  <span className={(selectedRowsId?.length === 0) ? 'transition group-open:rotate-0' : 'transition group-open:rotate-180'}>
                    <svg
                      fill='none'
                      height={14}
                      shapeRendering='geometricPrecision'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      width={14}
                    >
                      <path d='M6 9l6 6 6-6' />
                    </svg>
                  </span>
                </summary>
                <div className={(selectedRowsId?.length === 0) ? `hidden` : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`}>
                  <button className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center' onClick={() => HandleDelete()}>
                    <TrashFill className='w-3.5 h-3.5' />
                    <span>Delete selection</span>
                  </button>
                </div>
              </details>
              <button
                className={'filter-btn'}
                onClick={() => setFilter(!filter)}
              >
                <Tooltip title='Filter' arrow>
                  <button className=''>
                    <Filter className='h-5 w-5' />
                  </button>
                </Tooltip>
              </button>
            </div>
            <div className={filter ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative' : 'flex w-full h-full overflow-hidden relative'}>
              <div className='w-full'>
                <div className='w-full  transition-all duration-100 inline-block mb-2.5 border border-gray-300 rounded-[10px] overflow-x-auto overflow-y-hidden'>
                  <DataTable
                    columns={columns}
                    data={tableData}
                    selectableRows
                    onSelectedRowsChange={({ selectedRows }) => { setSelectedRowsId((selectedRows?.map((x) => x._id))) }}
                    selectableRowsHighlight
                    highlightOnHover
                    progressPending={pending}
                    progressComponent={<Loader />}
                  />
                </div>
                {/* Paginator */}
                {manData.data ? (
                  <div className='bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                    <div className='hidden sm:block'>
                      <p className='flex w-full text-black space-x-4'>
                        <span className='text-sm font-medium'>
                          {
                            limit === "all" ? `${manData?.data?.totalRecords} Records` :
                              `${startRecord} - ${endRecord} of ${manData?.data?.totalRecords}`
                          }
                        </span>
                      </p>
                    </div>
                    <div>
                      {
                        manData?.data?.totalPages !== 1 ?
                          <Pagination
                            count={manData?.data?.totalPages}
                            page={page}
                            onChange={(e, v) => setPage(v)}
                          /> : <></>
                      }
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filter ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
                <p className='flex text-gray-600 font-semibold uppercase text-sm m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  Filter
                </p>
                <hr className='border-gray-300 my-3'></hr>
                <div className='grid gap-y-5'>
                  <div>
                    <span className='text-sm text-gray-600 font-medium mb-1 inline-block'>
                      Search
                    </span>
                    <label className='relative block w-full'>
                      <input
                        className='w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-[10px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                        placeholder='Search Manufacturers'
                        type='text'
                        name='search'
                        value={search}
                        onChange={(e) => { setSearch(e.target.value); setPage(1) }}
                      />
                    </label>
                  </div>
                  <div>
                    <span className='text-sm text-gray-600 font-medium mb-1 inline-block'>
                      Set items limit
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border border-gray-300 p-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => { e.target.value === "all" ? setLimit('all') : setLimit(e.target.value); setPage(1) }}
                      >
                        {manData?.data?.totalRecords > 10 ? <><option value='10'>10</option></> : <></>}
                        {manData?.data?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                        {manData?.data?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                        <option value='all'>All</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DummyManufacturerTable;
