import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppStatus,
  updateSystemStatus,
} from "../../Redux/Action/status.action";
import Loader from "../Loader/Loader";
import Notification from "../Notification/Notification";
import Breadcrumbs from '../../controllers/breadCrumbs';
const AllStatus = () => {
  const dispatch = useDispatch();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState();
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg("");
    setSeverity("");
  };
  const { allSystemStatus } = useSelector((state) => state.systemStatusReducer);
  let possibleStatus = [
    "No known issues",
    "Maintenance",
    "Degraded",
    "Partial Outage",
    "Outage",
  ];
  const [allStatus, setAllStatus] = useState({
    Support: "No known issues",
    ThirdPartyServices: "No known issues",
    API: "No known issues",
    Storefront: "No known issues",
    ReportAndDashboards: "No known issues",
    Checkout: "No known issues",
    admin: "No known issues",
  });
  useEffect(() => {
    dispatch(getAppStatus());
  }, [dispatch]);

  useEffect(() => {
    if (allSystemStatus !== null) {
      setAllStatus({
        ...allSystemStatus,
      });
    }
  }, [allSystemStatus]);
  return (
    <div className="w-full pb-[64px]">
      {isNotification && notificationMsg ? (
        <Notification
          message={notificationMsg}
          close={handleClose}
          severity={severity}
        />
      ) : (
        <></>
      )}
      <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'System status', path: `/all-status` },
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>System Status</h1>
        </div>
        <button
          className="flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
          onClick={() => {
            dispatch(updateSystemStatus(allSystemStatus?._id, allStatus)).then(
              () => {
                setIsNotification(true);
                setNotificationMsg("Status Updated");
                setSeverity("success");
              }
            );
          }}
        >
          Apply Changes
        </button>
      </div>
      {allSystemStatus ? (
        <div className="w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]">
          <table className="w-full text-left">
            <thead className="h-[45px]">
              <tr>
                <th className="cursor-pointer uppercase font-semibold min-w-[300px] px-4">Status</th>
                <th className="cursor-pointer uppercase font-semibold">description</th>
                <th className="cursor-pointer uppercase font-semibold w-[200px] text-right pe-4">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600">
                <td className="p-3">Support</td>
                <td>No known issues</td>
                <td>
                  <div className="relative me-3">
                    <select
                      value={allStatus?.Support || allSystemStatus?.Support}
                      onChange={(e) =>
                        setAllStatus({ ...allStatus, Support: e.target.value })
                      }
                      className="block w-full border border-gray-300 px-2 py-1 text-sm cursor-pointer outline-none rounded-[10px]"
                    >
                      {possibleStatus?.map((x, index) => {
                        return <option key={index}>{x}</option>;
                      })}
                    </select>
                    <div className="select-arrow"></div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600">
                <td className="p-3">ThirdPartyServices</td>
                <td>No known issues</td>
                <td>
                  <div className="relative me-3">
                    <select
                      value={
                        allStatus?.ThirdPartyServices ||
                        allSystemStatus?.ThirdPartyServices
                      }
                      onChange={(e) =>
                        setAllStatus({
                          ...allStatus,
                          ThirdPartyServices: e.target.value,
                        })
                      }
                      className="block mx-auto border w-[180px] border-gray-300 px-2 py-1 text-sm cursor-pointer outline-none rounded-[10px]"
                    >
                      {possibleStatus?.map((x, index) => {
                        return <option key={index}>{x}</option>;
                      })}
                    </select>
                    <div className="select-arrow"></div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600">
                <td className="p-3">API</td>
                <td>No known issues</td>
                <td>
                  <div className="relative me-3">
                    <select
                      value={allStatus?.API || allSystemStatus?.API}
                      onChange={(e) =>
                        setAllStatus({ ...allStatus, API: e.target.value })
                      }
                      className="block mx-auto border w-[180px] border-gray-300 px-2 py-1 text-sm cursor-pointer outline-none rounded-[10px]"
                    >
                      {possibleStatus?.map((x, index) => {
                        return <option key={index}>{x}</option>;
                      })}
                    </select>
                    <div className="select-arrow"></div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600">
                <td className="p-3">Storefront</td>
                <td>No known issues</td>
                <td>
                  <div className="relative me-3">
                    <select
                      value={allStatus?.Storefront || allSystemStatus?.Storefront}
                      onChange={(e) =>
                        setAllStatus({ ...allStatus, Storefront: e.target.value })
                      }
                      className="block mx-auto border w-[180px] border-gray-300 px-2 py-1 text-sm cursor-pointer outline-none rounded-[10px]"
                    >
                      {possibleStatus?.map((x, index) => {
                        return <option key={index}>{x}</option>;
                      })}
                    </select>
                    <div className="select-arrow"></div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600">
                <td className="p-3">ReportAndDashboards</td>
                <td>No known issues</td>
                <td>
                  <div className="relative me-3">
                    <select
                      value={
                        allStatus?.ReportAndDashboards ||
                        allSystemStatus?.ReportAndDashboards
                      }
                      onChange={(e) =>
                        setAllStatus({
                          ...allStatus,
                          ReportAndDashboards: e.target.value,
                        })
                      }
                      className="block mx-auto border w-[180px] border-gray-300 px-2 py-1 text-sm cursor-pointer outline-none rounded-[10px]"
                    >
                      {possibleStatus?.map((x, index) => {
                        return <option key={index}>{x}</option>;
                      })}
                    </select>
                    <div className="select-arrow"></div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600">
                <td className="p-3">Checkout</td>
                <td>No known issues</td>
                <td>
                  <div className="relative me-3">
                    <select
                      value={allStatus?.Checkout || allSystemStatus?.Checkout}
                      onChange={(e) =>
                        setAllStatus({ ...allStatus, Checkout: e.target.value })
                      }
                      className="block mx-auto border w-[180px] border-gray-300 px-2 py-1 text-sm cursor-pointer outline-none rounded-[10px]"
                    >
                      {possibleStatus?.map((x, index) => {
                        return <option key={index}>{x}</option>;
                      })}
                    </select>
                    <div className="select-arrow"></div>
                  </div>
                </td>
              </tr>
              <tr className="bg-white cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600">
                <td className="p-3">admin</td>
                <td>No known issues</td>
                <td>
                  <div className="relative me-3">
                    <select
                      value={allStatus?.admin || allSystemStatus?.admin}
                      onChange={(e) =>
                        setAllStatus({ ...allStatus, admin: e.target.value })
                      }
                      className="block mx-auto border w-[180px] border-gray-300 px-2 py-1 text-sm cursor-pointer outline-none rounded-[10px]"
                    >
                      {possibleStatus?.map((x, index) => {
                        return <option key={index}>{x}</option>;
                      })}
                    </select>
                    <div className="select-arrow"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default AllStatus;
