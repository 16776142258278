import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronDown } from 'react-bootstrap-icons';
import API from '../../../API';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
const CreateDummyHeaderGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [groupTitle, setGroupTitle] = useState();
  const [groupLink, setGroupLink] = useState()
  const [linkColumn, setLinkColumn] = useState(1);
  const [dataErr, setDataErr] = useState(false);
  const [pageData, setPageData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [allBlogCategory, setAllBlogCategory] = useState();
  const [customDataErr, setCustomDataErr] = useState(false)
  const [portfolioData, setPortfolioData] = useState();
  const [storeCustomData, setStoreCustomData] = useState([])
  const [customData, setCustomData] = useState({
    section_name: '',
    section_link: '',
    section_type: 'custom',
    redirect_type: 'new',
  });
  const getPageData = async () => {
    try {
      const getPages = await API({ url: `/super/admin/dummydata/dummypages/getDummyPages`, method: 'get' });
      setPageData(getPages?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getCategoryData = async () => {
    try {
      const getCategories = await API({ url: `/super/admin/dummydata/dummycategory/getDummyCategorys`, method: 'get' });
      setCategoryData(getCategories?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllBlogCategories = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyblogcategory/getDummyBlogCategorys`, method: 'get' });
      if (data.status === 200 || data.status === 304) {

        setAllBlogCategory(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetAllPortfolioCategories = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyportfoliocategory/getDummyPortfolioCategorys`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setPortfolioData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddCustomData = (e) => {
    e.preventDefault();
    if (customData?.section_name === '' || customData?.section_link === '') {
      setCustomDataErr(true)
    } else {
      setCustomDataErr(false)
      setStoreList([...storeList, { section_name: customData?.section_name, section_link: customData?.section_link, section_type: 'custom', redirect_type: customData?.redirect_type }])
      setStoreCustomData([...storeCustomData, { section_name: customData?.section_name, section_link: customData?.section_link, section_type: 'custom', redirect_type: customData?.redirect_type }]); setCustomData({ section_name: '', section_link: '', redirect_type: "new" })
    }
  };
  useEffect(() => {
    getPageData();
    getCategoryData();
    GetAllBlogCategories();
    GetAllPortfolioCategories();
  }, []);
  const [storeList, setStoreList] = useState([]);
  const handleSelectProduct = (name, slugname, type, category_id) => {
    if (slugname) {
      const existList = storeList?.find((x) => x?.section_link === slugname);
      if (!existList) {
        setStoreList([...storeList, { section_name: name, section_link: slugname, section_type: type, category_id }]);
      } else {
        const removeFilter = storeList?.filter((x) => x?.section_link !== slugname);
        setStoreList(removeFilter);
      }
    }
  };
  const handleAddSection = async (e) => {
    e.preventDefault();
    if (!groupTitle) {
      setDataErr(true);
    } else {
      setDataErr(false);
      try {
        await API({ url: `/super/admin/dummydata/dynamicdummyheader/addHeaderGroup`, method: 'post', data: {
          header_section_title: groupTitle,
          header_section_redirect_link: groupLink,
          column: linkColumn,
          header: storeList,
        }});
        navigate(`/dummy-data/module-setting/header-menu`);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='w-full'>
          <Breadcrumbs />
          <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>
            Add New Header Menu
          </h3>
        </div>
        <form className='w-[90%] lg:w-[70%] xl:w-[60%] space-y-4 mx-auto'>
          <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
            <div className='mb-4'>
              <label htmlFor='image'>Header menu Title</label>
              <input
                type='text'
                className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${dataErr ? 'border-red-500' : ''
                  }`}
                placeholder='e.g. Categories'
                value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
              />
              {dataErr && !groupTitle && (
                <p className='text-xs text-red-500'>Please enter menu name</p>
              )}
            </div>
            <div className='mb-4'>
              <label htmlFor='image'>
                section redirect URL
              </label>
              <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm`} placeholder='e.g. /product' value={groupLink} onChange={(e) => setGroupLink(e.target.value)} />
            </div>
            <div className='mb-4'>
              <label htmlFor='image'>menu Link column</label>
              <input
                type='number'
                className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm`}
                placeholder='e.g. Categories'
                value={linkColumn}
                onChange={(e) => setLinkColumn(e.target.value)}
              />
            </div>
            <div className='mb-4'>
              <details
                open
                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
              >
                <summary className='text-base flex items-center justify-between text-black'>
                  Content pages
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className='text-black w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                  {pageData?.records?.map((elem) => {
                    return (
                      <label className='flex items-center space-x-4'>
                        <input
                          type='checkbox'
                          value={elem?._id}
                          onClick={(e) =>
                            handleSelectProduct(elem?.pages_name, `/page/${elem?.page_slug_name}`, "pages", elem?._id)
                          }
                          className='block w-4 h-4'
                        />
                        <p className='text-sm text-black font-normal break-all'>
                          {elem?.pages_name}
                        </p>
                      </label>
                    );
                  })}
                </div>
              </details>
            </div>
            <div className='mb-4'>
              <details
                open
                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
              >
                <summary className='text-base flex items-center justify-between text-black'>
                  Portfolio Category
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className='text-black w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                  {portfolioData?.data?.records?.map((elem) => {
                    return (
                      <label className='flex items-center space-x-4'>
                        <input
                          type='checkbox'
                          value={elem?._id}
                          onClick={(e) =>
                            handleSelectProduct(
                              elem?.portfoliocategory_name,
                              `/portfolio/portfolio-category/${elem?.portfolio_category_slug_name}`,
                              "portfolio category",
                              elem?._id
                            )
                          }
                          className='block w-4 h-4'
                        />
                        <p className='text-sm text-black font-normal break-all'>
                          {elem?.portfoliocategory_name}
                        </p>
                      </label>
                    );
                  })}
                </div>
              </details>
            </div>

            <div className='mb-4'>
              <details
                open
                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
              >
                <summary className='text-base flex items-center justify-between text-black'>
                  Categories
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className='text-black w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                  {categoryData?.records?.map((elem) => {
                    return (
                      <label className='flex items-center space-x-4'>
                        <input
                          type='checkbox'
                          value={elem?._id}
                          onClick={(e) =>
                            handleSelectProduct(
                              elem?.category_name,
                              `/category/${elem?.category_slug_name}`,
                              "product category",
                              elem?._id
                            )
                          }
                          className='block w-4 h-4'
                        />
                        <p className='text-sm text-black font-normal break-all'>
                          {elem?.category_name}
                        </p>
                      </label>
                    );
                  })}
                </div>
              </details>
            </div>
            <div className='mb-4'>
              <details
                open
                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
              >
                <summary className='text-base flex items-center justify-between text-black'>
                  Blog Category
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className='text-black w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                  {allBlogCategory?.data?.records?.map((elem) => {
                    return (
                      <label className='flex items-center space-x-4'>
                        <input
                          type='checkbox'
                          value={elem?._id}
                          onClick={(e) =>
                            handleSelectProduct(
                              elem?.blogcategory_name,
                              `/blog/blog-category/${elem?.blog_category_slug_name}`,
                              "blog category",
                              elem?._id
                            )
                          }
                          className='block w-4 h-4'
                        />
                        <p className='text-sm text-black font-normal break-all'>
                          {elem?.blogcategory_name}
                        </p>
                      </label>
                    );
                  })}
                </div>
              </details>
            </div>
            <details
              open
              className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
            >
              <summary className='text-base flex items-center justify-between text-black'>
                Custom content
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className='text-black w-4 h-5' />
                </span>
              </summary>
              <div className='w-full pt-4  border-t'>
                <div className='mb-4'>
                  <label htmlFor='image'>Title</label>
                  <input
                    type='text'
                    className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${customDataErr && customData?.section_name === "" ? 'border-red-500' : ''}`}
                    placeholder='e.g. Google'
                    value={customData?.section_name}
                    onChange={(e) => setCustomData({ ...customData, section_name: e.target.value })}
                  />
                  {
                    customDataErr && customData?.section_name === "" && <p className='text-xs text-red-500'>Please enter title</p>
                  }
                </div>
                <div className='mb-4'>
                  <label htmlFor='image'>URL</label>
                  <input
                    type='text'
                    className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${customDataErr && customData?.section_link === "" ? 'border-red-500' : ''}`}
                    placeholder='e.g. https://google.com'
                    value={customData?.section_link}
                    onChange={(e) => setCustomData({ ...customData, section_link: e.target.value })}
                  />
                  {
                    customDataErr && customData?.section_link === "" && <p className='text-xs text-red-500'>Please enter url</p>
                  }
                </div>
                <div className='mb-4'>
                  <label htmlFor='image'>Redirect Type</label>
                  <div className="relative">
                    <select
                      name='gender'
                      id='id_gender'
                      className='w-full '
                      value={customData.redirect_type}
                      onChange={(e) =>
                        setCustomData({ ...customData, redirect_type: e.target.value })
                      }
                    >
                      <option value='new'>New</option>
                      <option value='current'>Current</option>
                    </select>
                    <div className="select-arrow"></div>
                  </div>
                </div>
                <button
                  onClick={(e) => handleAddCustomData(e)}
                  className='secondary-btn text-sm inline-block'
                >
                  Add custom data
                </button>
                {
                  storeCustomData?.map((elem) => {
                    return (
                      <div className='border border-gray-300 p-3 rounded mt-4'>
                        <p className='text-sm'><span className='font-medium'>Title : </span><span>{elem?.section_name}</span></p>
                        <p className='text-sm'><span className='font-medium'>URL : </span><span>{elem?.section_link}</span></p>
                      </div>
                    )
                  })
                }
              </div>
            </details>
          </div>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[60%] mx-auto'>
            <Link
              to={`/dummy-data/module-setting/header-menu`}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              Go to header menu
            </Link>
            <button onClick={(e) => handleAddSection(e)} className='btn text-sm text-white'>
              Add Header Menu
            </button>
          </div>
        </form>
      </div>

    </>
  );
};

export default CreateDummyHeaderGroup;
