import { ADD_TENANTS, GET_TENANTS, GET_PERTICULAR_TENANT, GET_DELETE_REQUEST } from "../action.type";

const initialState = {
  addTenants: null,
  allTenants: null,
  getPerticularTenantData: null,
  updateTenants: null,
  deleteStore:null
}

export const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TENANTS:
      return {
        ...state,
        addTenants: action.payload?.data?.data,
      };
    case GET_DELETE_REQUEST:
      return {
        ...state,
        deleteStore: action.payload?.data?.data,
      };
    case GET_TENANTS:
      return {
        ...state,
        allTenants: action.payload?.data?.data,
      };
    case GET_PERTICULAR_TENANT:
      return {
        ...state,
        getPerticularTenantData: action.payload?.data,
      }
    // case UPDATE_TENANTS:
    //   return{
    //     updateTenants: action.payload?.data?.data,
    //   }    

    default:
      return state;
  }
}
