import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import API from '../../../API';
import { ArrowLeft, Check, ChevronDown, ChevronLeft, ChevronRight, Code, Edit } from 'feather-icons-react/build/IconComponents';
import Compressor from 'compressorjs';
import {
  Backdrop,
  CircularProgress,
  Tooltip,
  Autocomplete,
  TextField,
  Skeleton,
  Popover,
  Pagination,
  Box,
} from '@mui/material';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { X } from 'feather-icons-react/build/IconComponents';
import ReactQuill from 'react-quill';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import GenerateUniqueFilename from '../../../controllers/GenerateUniqueFilename';
import { GenerateSlugName } from '../../../controllers/GenerateSlugName';
import Notification from '../../Notification';
import NoDataFound from '../../Main/NoDataFound';
import FormatAmount from '../../../controllers/FormatAmount';
import SourceCode from '../../../controllers/SourceCode';
import ImageUploader from '../../../controllers/ImageUploader';

const UpdateDummyCategory = () => {
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const [nodata, setNodata] = useState(false)
  const [warn, setwarn] = useState(false);
  const [categoryData, setCategoryData] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [enableSlug, setEnableSlug] = useState(false);
  const [slugEdited, setSlugEdited] = useState(false);
  const [severity, setSeverity] = useState();
  const [CatData, setCatData] = useState({
    category_name: '',
    description: '',
    store_category:'',
    meta_tag_title: '',
    meta_tag_description: '',
    keyword: '',
    sort_order:''
  });
  const [selected, setSelected] = useState("");
  const [image, setimage] = useState();
  const [description, setDescription] = useState();
  const [slugName, setSlugName] = useState();
  const [formSpinner, setFormSpinner] = useState(true)
  const [isSelect, setIsSelect] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState([])
  const getCategoryBySlugName = async () => {
    setFormSpinner(true)
    try {
      const apiCall = await API({ url: `/super/admin/dummydata/dummycategory/getDummyCategory/${params?.category_slug_name}`, method: 'get' })
      setCategoryData(apiCall?.data?.data);
      setCatData({
        category_name: `${apiCall?.data?.data?.category_name}`,
        description: `${apiCall?.data?.data?.description}`,
        image: `${apiCall?.data?.data?.image}`,
        meta_tag_title: `${apiCall?.data?.data?.meta_tag_title}`,
        meta_tag_description: `${apiCall?.data?.data?.meta_tag_description}`,
        keyword: `${apiCall?.data?.data?.keyword}`,
        sort_order:`${apiCall?.data?.data?.sort_order||0}`
      })
      setSelected(apiCall?.data?.data?.parent_category?._id);
      setDescription(apiCall?.data?.data?.description);
      setimage(apiCall?.data?.data?.image);
      setSlugName(apiCall?.data?.data?.category_slug_name ? apiCall?.data?.data?.category_slug_name : apiCall?.data?.data?.category_slug_name,)
      setFormSpinner(false);
      handleGetSelectedProduct(apiCall?.data?.data?._id);
    } catch (error) {
      setNodata(true)
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  useEffect(() => {
    getCategoryBySlugName();
  }, [])

  const {
    category_name,
    meta_tag_title,
    meta_tag_description,
    keyword,
    sort_order
  } = CatData;

  const HandleInput = (e) => {
    setCatData({ ...CatData, [e.target.name]: e.target.value });
  };


  // get Parents Category
  const [categoryWithParent, setAllCategoryWithParent] = useState([])

  const GetCategoriesWithAllParent = async () => {
    try {
      if (categoryData) {
        const GetCategories = await API({ url: `/super/admin/dummydata/dummycategory/GetCategoriesWithAllParent`, method: 'get' });
        if (GetCategories.status === 200 || GetCategories.status === 304) {
          const filterCategory = await GetCategories?.data?.data?.records?.filter(x => x._id !== categoryData?._id)
          setAllCategoryWithParent(filterCategory)
        }
      }
    } catch (error) {
      setNodata(true)
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };

  useEffect(() => {
    GetCategoriesWithAllParent()
  }, [categoryData])
  const handleSetImage = (value) => {
    setimage(value)
}
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const handleGetSelectedProduct = async (id) => {
    try {
      let categoryProduct = await API({ url: `/super/admin/dummydata/dummyproduct/GetProductsForCategory`, method: 'get', params: { categories: id, categories_exist: 1 }});
      setSelectedProduct(categoryProduct?.data?.data?.records?.length > 0 && categoryProduct?.data?.data?.records?.map((x) => { return { product_name: x?.product_name, _id: x?._id } }));
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  const [getProduct, setGetProduct] = useState()
  const handleGetCategoryProduct = async () => {
    if (search?.length === 0 || search?.length >= 3) {
      try {
        let categoryProduct = await API({ url: `/super/admin/dummydata/dummyproduct/GetProductsForCategory`, method: 'get' });
        setGetProduct(categoryProduct?.data?.data);
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  }
  const handleSelectProduct = (id, name) => {
    if (id) {
      const pro = selectedProduct?.length > 0 && selectedProduct?.find((x) => x?._id === id);
      if (!pro) {
        if (selectedProduct?.length > 0) {
          setSelectedProduct([...selectedProduct, { _id: id, product_name: name }]);
        } else {
          setSelectedProduct([{ _id: id, product_name: name }])
        }
      }
    }
  }
  const handleRemoveProduct = (id) => {
    const removeProduct = selectedProduct.filter((x) => x?._id !== id);
    setSelectedProduct(removeProduct);
  };
  const handleAddProduct = async (id) => {
    try {
      await API({ url: `/super/admin/dummydata/dummyproduct/addCategoryInProduct`, method: 'post', data: {
        id: selectedProduct?.length && selectedProduct?.map((x) => x?._id),
        categories: categoryData?._id
      }})
    } catch (error) {
      console.log(error);
    }
  }


  const [err, setErr] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const HandleSubmit = async (e) => {
    e.preventDefault();
    handleAddProduct();
    setBtnLoading(true)
    try {
      let updatebody = {
        ...CatData,
        parent_category: selected,
        image: image,
        description: description,
        meta_tag_title,
        meta_tag_description
      }

      if (slugEdited && slugName) {
        updatebody.category_slug_name = slugName
      }
      let SubmitData = await API({ url: `/super/admin/dummydata/dummycategory/updateDummyCategory/${categoryData?._id}`, method: 'put', data: updatebody});
      if (SubmitData.status === 200 || SubmitData.status === 304) {
        setErr(false)
        navigate('/dummy-data/dummy-category');
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
    setBtnLoading(false)
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  // MUI Filter Option for category
  const filterOptionsForCategory = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.category_name?.toLowerCase()} ${option?.category_slug_name?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  useEffect(() => {
    handleGetCategoryProduct();
  }, [page, limit, search])

  //MUI autocomplete filter for product
  const filterOptionsForProduct = (options, { inputValue }) => {
    const normalizedInputValue = inputValue?.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.product_name?.toLowerCase()} ${option?.product_slug_name?.toLowerCase()} ${option?.sku_id?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
      setDescription(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
      e.preventDefault();
      const quill = quillRef.current.getEditor();
      const htmlContent = quill.root.innerHTML
      const formattedHtml = formatCode(htmlContent);
      setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
      setHtml({ ...html, htmlPP: false })
      setDescription(value)
  }
  const closeCode = () => {
      setHtml({ ...html, htmlPP: false })
  }

  return (
    nodata ? <NoDataFound /> :
      <>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <div className='h-auto overflow-y-auto pb-[64px]'>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Categories', path: '/categories' },
            { name: 'Update category', path: '/categories/edit-category' }
          ]} />
          <h1 className='text-xl md:text-2xl font-medium pb-4 text-primary'>Update category - {categoryData?.category_name}</h1>
          <div className='flex justify-evenly p-4'>
            <form
              method='POST'
              className='space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
            >
              {
                formSpinner ? <div className='space-y-4'>
                  <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                    <div className='flex items-center space-x-4 w-full'>
                      <div className='w-full'>
                        <Skeleton className='w-[200px] mb-1' variant='rounded' />
                        <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                      </div>
                      <div className='w-full'>
                        <Skeleton className='w-[200px] mb-1' variant='rounded' />
                        <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                      </div>
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[200px]' variant='rounded' />
                    </div>
                  </div>
                  <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                    <div>
                      Media
                      <p className='text-xs text-gray-500 font-normal'>Upload captivating image to make your product stand out.</p>
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[130px]' variant='rounded' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[130px]' variant='rounded' />
                    </div>
                  </div>
                </div> :
                  <>
                    <div className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'>
                      <div className='w-full mb-4'>
                        <label htmlFor='category_name' className='flex items-center justify-between'>
                          Name
                        </label>
                        <input
                          className={category_name === '' && err ? 'w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none' : 'w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'}
                          type='text'
                          id='category_name'
                          name='category_name'
                          maxLength={60}
                          value={category_name}
                          onChange={HandleInput}
                          placeholder='e.g. Tech'
                          onBlur={() => setCatData({ ...CatData, meta_tag_title: category_name })}
                        />
                        <p className='w-full text-xs mt-0 text-gray-400'>only 60 characters allowed</p>
                        {
                          category_name === '' && err ? <p className='text-red-500 text-xs'>Please enter category name</p> : <></>
                        }
                        {warn && (
                          <h1 className='text-sm text-red-500'>
                            * You Must Entered manufacture Name To Generate AI Responses
                          </h1>
                        )}
                      </div>
                      <div className='w-full mb-4'>
                        <label
                          htmlFor='category_slug_name'
                        >
                          Category Slug Name
                        </label>
                        <div className={`flex items-center justify-between w-full border ${err ? 'border-red-500' : 'border-gray-300'} rounded-[4px]`}>
                          <input
                            className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                            type='text'
                            name='category_slug_name'
                            placeholder='Enter slug name'
                            value={slugName}
                            disabled={!enableSlug}
                            onChange={(e) => setSlugName(e.target.value)}
                          />
                          {
                            enableSlug ?
                              <Check onClick={async () => { setSlugName(await GenerateSlugName(slugName)); setEnableSlug(!enableSlug); setErr(false); setSlugEdited(true) }} className='text-green-500 p-1 h-8 w-8 cursor-pointer' /> :
                              <Edit onClick={() => setEnableSlug(!enableSlug)} className='text-blue-500 p-1 h-7 w-7 cursor-pointer' />
                          }
                        </div>
                        {err && <p className='text-xsm text-red-500'>Please add slug name </p>}
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='description'>
                          Parent category
                        </label>
                        <Autocomplete
                          className='autocomplete'
                          filterOptions={filterOptionsForCategory}
                          freeSolo
                          options={categoryWithParent ? categoryWithParent : []}
                          getOptionLabel={(category) => category?.parent_category}
                          onChange={(e, v) => v ? setSelected(v._id) : setSelected('')}
                          value={categoryWithParent?.find((category) => category._id === categoryData?.parent_category?._id) || null}
                          renderInput={(category) => (
                            <TextField
                              placeholder="Select parent category"
                              className='cursor-pointer text-black'
                              {...category}
                            />
                          )}
                        />
                      </div>

                      <div className='mb-4'>
                        <div className='flex space-x-3 justify-between items-center mb-1'>
                          <label
                            htmlFor='Description'
                          >
                            Description
                          </label>
                          <div className='flex items-center'>
                            
                            <span
                              className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                              onClick={handleViewHtml}
                            >
                              <Code className="w-[15px] h-[15px] me-1" /> Source code
                            </span>
                          </div>
                        </div>
                        <ReactQuill
                          modules={module}
                          ref={quillRef}
                          className={err && description?.length === 0 ? 'border rounded-[10px] border-red-500 w-full h-auto' : 'w-full h-auto'}
                          theme='snow'
                          name='description'
                          value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                          onChange={handleQuillChange}
                          onBlur={() => setCatData({ ...CatData, meta_tag_description: description })}
                          placeholder='enter description'
                        />
                        <div className='flex items-center justify-between'>
                          {
                            err && description?.length === 0 && <p className='text-red-500 text-xs'>Please enter description for generate meta data</p>
                          }
                          <p className='w-full text-xs mt-0 text-gray-400'>
                            of 280 words allowed
                          </p>
                        </div>
                      </div>
                    </div>
                    <details open className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                      <summary
                        className='text-base flex items-center justify-between font-medium text-black'
                      >
                        <div>
                          <span>Media</span>
                          <p className='text-xs text-gray-500 font-normal'>Upload captivating image to make your category stand out.</p>
                        </div>
                        <span className="transition group-open:rotate-180">
                          <ChevronDown className="text-black w-4 h-5" />
                        </span>
                      </summary>
                      <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} folder="category" format="image" />
                    </details>
                    <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                      <summary
                        className='text-base flex items-center justify-between font-medium text-black'
                      >
                        <div>
                          Products
                          <p className='text-xs text-gray-500 font-normal'>Select product for add in this category</p>
                        </div>
                        <span className="transition group-open:rotate-180">
                          <ChevronDown className="text-black w-4 h-5" />
                        </span>
                      </summary>
                      <div className='mb-4'>
                        {/* <ul className='divide-y max-h-[450px] overflow-auto'>
                        {selectedProduct?.records?.map((elem, index) => {
                          return (
                            <li className='py-2 flex items-center justify-between'>
                              <div className='flex items-center space-x-3'>
                                <div className='w-[50px] rounded h-[50px] border flex items-center justify-center'>
                                  <img src={elem?.image} className='max-w-full max-h-full inline-block' />
                                </div>
                                <div>
                                  <p className='mt-1 line-clamp-2 text-sm block'>{elem?.product_name}</p>
                                </div>
                              </div>
                              <button className='inline-block me-2 w-4 h-4 mt-1' onClick={(e) => { e.preventDefault(); handleRemoveProduct(elem?.id) }}><X className="w-4 h-4" /></button>
                            </li>
                          );
                        })}
                      </ul>
                      <button onClick={(e) => { e.preventDefault(); handleGetCategoryProduct(); setIsSelect(true) }} className='mt-4 px-[20px] py-[6px] border border-gray-300 hover:bg-[#f5f5f5] text-sm rounded'>Select product</button> */}
                        <Autocomplete
                          disableClearable
                          className='autocomplete'
                          options={getProduct?.records ? getProduct.records : []}
                          filterOptions={filterOptionsForProduct}
                          getOptionLabel={(product) => product.product_name}
                          renderOption={(props, option) =>
                            <Box component="li" {...props} className='flex space-x-2 border-b p-2 shadow bg-[#fafafa] items-center'>
                              <img src={option?.image} className='object-contain border p-0.5' style={{ width: '50px', height: '50px' }} />
                              <div>
                                <p>{option?.product_name}</p>
                                <p className='text-xs'>({option?.product_slug_name})</p>
                              </div>
                            </Box>
                          }
                          onChange={(e, v) => handleSelectProduct(v._id, v?.product_name)}
                          renderInput={(Products) => (
                            <TextField
                              placeholder='Select products'
                              className='cursor-pointer text-black placeholder:text-black'
                              {...Products}
                            />
                          )}
                          onBlur={() => handleAddProduct()}
                        />
                        <div className='flex flex-wrap mt-2'>
                          {selectedProduct?.length > 0 && selectedProduct?.map((elem, index) => {
                            return (
                              <div
                                key={index}
                                className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                              >
                                <span>{elem?.product_name}</span>
                                <X
                                  className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                  onClick={() => handleRemoveProduct(elem._id)}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </details>
                    <details className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'>
                      <summary
                        htmlFor='Meta Tag Title'
                        className='text-base w-full flex flex-col items-center font-medium text-black'
                      >
                        <div className='flex w-full items-center justify-between'>
                          <div>
                            <span>Search engine listing</span>
                            <p className='text-xs text-gray-500 font-normal'>Add a title and description to see how this product might appear in a search engine listing</p>
                          </div>
                          <span className="transition group-open:rotate-180">
                            <ChevronDown className="text-black w-4 h-5" />
                          </span>
                        </div>
                        {
                          CatData?.meta_tag_title?.length > 0 &&
                          <div className='text-left font-normal mt-2 w-full'>
                            <h3 className='text-blue-700 text-base'>{meta_tag_title}</h3>
                            <p className='text-gray-800 text-sm' dangerouslySetInnerHTML={{ __html: meta_tag_description }}></p>
                          </div>
                        }
                      </summary>
                      <div className='mb-4'>
                        <div className='flex items-center justify-between'>
                          <label
                            className='flex items-center justify-between'
                            htmlFor='Meta Tag Title'
                          >
                            <div className='flex items-center'>
                              Meta title
                              <Tooltip title="Meta title is important for SEO, as they can help to improve the click-through rate (CTR) of your web pages." arrow placement="right" className='ml-2 -tracking-tighter'>
                                <QuestionCircleFill className='info-link cursor-pointer' />
                              </Tooltip>
                            </div>
                          </label>
                         
                        </div>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='text'
                          id='Meta Tag Title'
                          name='meta_tag_title'
                          value={meta_tag_title === "undefined" ? "" : meta_tag_title}
                          onChange={HandleInput}
                          placeholder='Enter meta title'
                        />
                      </div>
                      <div className='mb-4'>
                        <div className='flex items-center justify-between'>
                          <label
                            className='flex items-center justify-between'
                            htmlFor='Meta Tag Description'
                          >
                            <div className='flex items-center'>
                              Meta Description
                              <Tooltip
                                title='Meta description is important for SEO, as they can help to improve the click-through rate (CTR) of your web pages.'
                                arrow
                                placement='right'
                                className='ml-2 -tracking-tighter'
                              >
                                <QuestionCircleFill className='info-link cursor-pointer' />
                              </Tooltip>
                            </div>
                          </label>
                        </div>
                        <textarea
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='text'
                          id='Meta Tag Description'
                          name='meta_tag_description'
                          value={meta_tag_description === "undefined" ? "" : meta_tag_description.replace(/<[^>]+>/g, '')}
                          onChange={HandleInput}
                          placeholder='e.g. We have best fashionable products'
                        />
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='keyword'>
                          Keyword
                        </label>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='text'
                          id='keyword'
                          name='keyword'
                          value={keyword === "undefined" ? "" : keyword}
                          onChange={HandleInput}
                          placeholder='e.g. Cloth fashion'
                        />
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='sort_order'>
                          sort order
                        </label>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='number'
                          id='sort_order'
                          name='sort_order'
                          value={sort_order}
                          onChange={(e)=>setCatData({ ...CatData, sort_order: e.target.value<=0 ? 0 : e.target.value })}
                          placeholder='Enter meta sort order'
                        />
                      </div>
                    </details>
                    <div className='flex items-center justify-between w-full'>
                      <Link to={`/dummy-data/dummy-category`} className='w-fit flex text-link font-medium hover:underline text-sm items-center '><ArrowLeft className='w-3 mr-1' />Go to categories</Link>

                      <div>
                        {category_name === '' || slugName === '' ? (
                          <button className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'>
                            <span>Update Category</span>
                          </button>
                        ) : btnLoading ?
                          <button type='button'
                            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[161px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'>
                            <div className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full">
                            </div>
                            <span>Loading...</span>
                          </button> :
                          <button
                            onClick={(e) => HandleSubmit(e)}
                            className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                          >
                            <span>Update Category</span>
                          </button>
                        }
                      </div>
                    </div>
                  </>
              }
            </form>
          </div>
          {
            isSelect &&
            <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
              <Popover
                open={isSelect}
                className='h-screen popup-content'
                onClose={() => { setIsSelect(false) }}
              >
                <div className='w-[600px] p-4 max-h-full'>
                  <input type='text' placeholder='Search products...' className='w-full px-5 mb-4 block border border-gray-300 focus:outline-none focus:border-gray-500' value={search} onChange={(e) => setSearch(e.target.value)} />
                  <ul className='divide-y h-[450px] overflow-auto'>
                    {!getProduct?.records ?
                      <li className='text-center text-black'>No product found</li> :
                      getProduct?.records?.map((elem) => {
                        return (
                          <li className='p-2 flex justify-between'>
                            <div className='flex space-x-3'>
                              <div className='w-[60px] rounded h-[60px] border flex items-center justify-center'>
                                <img src={elem?.image} className='max-w-full max-h-full inline-block' />
                              </div>
                              <div>
                                <p className='font-[500] line-clamp-2 text-sm block'>{elem?.product_name}</p>
                                <div className='text-sm mt-0.5 text-gray-500'>
                                  {
                                    elem?.mrp !== elem?.selling_price ?
                                      <span className='text-black'>{FormatAmount(elem.selling_price?.toFixed(2))}<del className='ms-2 text-gray-500'>{FormatAmount(elem.mrp?.toFixed(2))}</del></span> : <span>{FormatAmount(elem.selling_price?.toFixed(2))}</span>
                                  }
                                </div>
                              </div>
                            </div>
                            <input type='checkbox' className='w-[15px] me-2 h-[15px]' checked={selectedProduct?.records?.find((x) => x?._id === elem?._id)} onClick={() => handleSelectProduct(elem?._id)} />
                          </li>
                        )
                      })}
                  </ul>
                  <div className='flex items-center border-t justify-between pt-4 pagination'>
                    <button className='btn text-xs text-white' onClick={(e) => { e.preventDefault(); setIsSelect(false) }}>Select</button>
                    {getProduct?.totalPages > 1 ? (
                      <Pagination
                        count={getProduct?.totalPages}
                        page={page}
                        onChange={(e, v) => setPage(v)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Popover>
            </div>
          }
        </div>
        {html?.htmlPP && (
          <SourceCode open={html?.htmlPP} htmlCode={html?.code} closeCode={closeCode} saveHTML={saveHTML} />
        )}
      </>
  );
};

export default UpdateDummyCategory;
