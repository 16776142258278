import { LOGIN } from "../action.type";
import API from "../../API";
import { toast } from "react-toastify";



export const login = (email, password) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/user/super-login`, method: 'post', data: {
                    email,
                    password,
                }
            });
            if (ApiCall?.data) {
                localStorage.setItem("token", ApiCall?.data?.data?.token)
                window.location.href = '/dashboard'
                dispatch({
                    type: LOGIN,
                    payload: ApiCall?.data
                });
                toast.success(ApiCall?.data?.message)
            }
        } catch (error) {
            toast.error(error)
        }
    };
};