import React, { useEffect, useRef, useState } from "react";
import API from "../../API";
import ReactQuill from "react-quill";
import { ToastContainer} from "react-toastify";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GenerateSlugName } from "../../controllers/GenerateSlugName"
import { getGuidePerticularCategories, UpdateGuideCategories } from "../../Redux/Action/guide.action";
import GenerateUniqueFilename from "../../controllers/GenerateUniqueFilename"
import Compressor from 'compressorjs';
import {
  Popover,
} from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import Notification from "../../Popup/Notification";
import module, { formatCode } from "../../controllers/QuillToolbar";
import Breadcrumbs from "../../controllers/breadCrumbs";
const UpdateGuideCategory = () => {
  const params = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { getPerticularCategoriesData } = useSelector((state) => state.guidesReducer)
  const [value, setValue] = useState(getPerticularCategoriesData?.description);
  const [formData, setFormData] = useState({
    category: '',
    sort_order: ''
  });
  // Category upload image
  const [image, setimage] = useState();
  const [imgErr, setImgErr] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(getGuidePerticularCategories(params.guide_cat_id))
  }, [dispatch]);
  useEffect(() => {
    if (getPerticularCategoriesData) {
      setFormData({ category: getPerticularCategoriesData?.category_name, sort_order: getPerticularCategoriesData?.sort_order })
      setValue(getPerticularCategoriesData?.description)
      setimage(getPerticularCategoriesData?.image)
    }
  }, [getPerticularCategoriesData])

  const { category, sort_order } = formData;
  const [err, setErr] = useState(false)
  // collect Data
  const HandleInput = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [slugAlready, setSlugAlready] = useState(false);
  const [slugName, setSlugName] = useState();
  const handleSlug = async (slug) => {
    if (category?.length > 0) {
      setSlugAlready(true);
    }
    setSlugName(await GenerateSlugName(slug));
  };


  const HandleImage = async (file) => {
    let parts = file?.name?.split('.');
    if (file) {
      let fileName = file?.name;
      if (!fileName) {
        fileName = await GenerateUniqueFilename();
      }
      let Ext = parts[parts?.length - 1];
      setLoader(true);
      if (
        Ext === 'jpg' ||
        Ext === 'svg' ||
        Ext === 'png' ||
        Ext === 'jpeg' ||
        Ext === 'webp' ||
        (Ext === 'gif' && file.size < 10000000)
      ) {
        new Compressor(file, {
          quality: 50,
          async success(result) {
            setLoader(true);
            const formData = new FormData();
            formData.append('file', result, fileName);
            formData.append('folder', 'product');

            const res = await API({ url: '/super/admin/tenant/fileupload ', method: 'post', data: formData});
            setimage(res?.data?.data[0]);
            setImgErr(false);
            setLoader(false);
          },
        });
      } else {
        setImgErr(true);
      }
      setLoader(false);
    }
  };

  //   popUpsetting
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState();
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg("");
    setSeverity("");
  };

  // Quill Emmpty check
  const strippedContent = value?.replace(/<[^>]*>/g, '');
  const trimmedContent = strippedContent?.trim();



  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (category === "" || sort_order === "" || !image || trimmedContent === "") {
      setErr(true)
    } else {
      dispatch(UpdateGuideCategories(value, slugName, category, sort_order, image, params.guide_cat_id));
      navigate('/guide-categories')
    }
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };

  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const { htmlPP, code } = html
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };

  const saveHTML = () => {
    setHtml({ ...html, htmlPP: false })
    setValue(code)
  }

  return (
    <>
      <div className="w-full pb-[64px]">
        {isNotification && notificationMsg ? (
          <Notification
            message={notificationMsg}
            close={handleClose}
            severity={severity}
          />
        ) : (
          <></>
        )}
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Guide Categories', path: `/guide-categories` },
            { name: 'Update Guide Category', path: `/guide-categories` },
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold mb-4 text-primary'>Update Guide Category</h1>
        </div>
        <div className="pb-4 overflow-x-hidden h-full w-full">
          <form
            method="POST"
            className="w-[90%] lg:w-[70%] xl:w-[60%] bg-white border border-gray-300 p-4 mx-auto rounded"
          >
            <div className="mb-4 flex w-full justify-between space-x-3">
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="category"
                >
                  Category
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  id="category"
                  name="category"
                  value={category}
                  placeholder="category"
                  onChange={HandleInput}
                  onBlur={() => handleSlug(category)}
                />
                {err && category?.length === 0 ? (
                  <p className='text-red-500 text-xs'>Please enter Category name</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {slugAlready &&
              <div className="w-full mb-4">
                <label
                  className="flex text-gray-700 text-sm font-medium mb-1"
                  htmlFor="slugname"
                >
                  slugname
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  id="slugname"
                  name="slugname"
                  value={slugName}
                  placeholder="slugname"
                />
              </div>
            }
            <div className="mb-4 flex w-full justify-between space-x-3">
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="sort_order"
                >
                  sort category
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="number"
                  id="sort_order"
                  name="sort_order"
                  value={sort_order}
                  placeholder="sort category"
                  onChange={(e) => setFormData({ ...formData, sort_order: e.target.value <= 0 ? 0 : e.target.value })}
                />
                {err && sort_order?.length === 0 ? (
                  <p className='text-red-500 text-xs'>Please enter sort number</p>
                ) : (
                  <></>
                )}
              </div>

            </div>

            <div className='w-full'>
              <div className='w-full mb-4'>
                <label
                  className='flex text-gray-700 text-sm font-medium mb-1'
                  htmlFor='category'
                >image
                </label>
                <label
                  htmlFor='dropzone-file'
                  className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded cursor-pointer hover:bg-gray-50 hover:border-gray-400 border-dashed'
                >
                  <label className='text-center cursor-pointer'>
                    <input
                      id='dropzone-file'
                      type='file'
                      name='image'
                      onChange={(e) => {
                        HandleImage(e.target.files[0]);
                      }}
                      className='hidden'
                    />
                    <div className=''>
                      {!image && !loader ? (
                        <>
                          <svg
                            className='w-7 h-7 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                            aria-hidden='true'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 20 16'
                          >
                            <path
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth={1}
                              d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                            />
                          </svg>
                          <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                            <span className='font-medium'>Click to upload</span>
                          </p>
                          <p className='text-xs mb-1 text-gray-500 dark:text-gray-400'>
                            Recommended size 800 x 800px
                          </p>
                          {!image && err ? (
                            <p className='text-red-500 text-xs font-medium'>
                              Please enter image
                            </p>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : loader === true ? (
                        <div className='w-[80px] mx-auto h-[80px] flex items-center justify-center mt-2 p-1 border border-gray-300'>
                          <div className='nb-spinner'></div>
                        </div>
                      ) : (
                        <>
                          <div className='w-[80px] mx-auto h-[80px] flex items-center justify-center mt-2 p-1 border border-gray-300 relative'>
                            <img src={image} alt={image} className='max-w-full max-h-full' />
                            <X
                              className='text-black cursor-pointer h-4 w-4 ms-2 absolute z-50 top-0 right-0'
                              onClick={() => setimage('')}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </label>
                  {imgErr && (
                    <span className='text-sm bg-yellow-100 p-1 rounded-[10px]'>
                      <b>NOTE: </b> File Should be in jpg, jpeg, png format Max Size 10 MB
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div className="mb-4">
              <div className='flex space-x-3 justify-between items-center mb-1'>
                <label
                  className="block text-gray-700 text-sm font-medium mb-1"
                  htmlFor="description"
                >
                  Description
                </label>
                <section
                  className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                  onClick={handleViewHtml}
                >
                  View HTML
                </section>
              </div>
              <div>
                {
                  err && (trimmedContent === '' || value === '') &&
                  <p className='text-xs text-red-500'>Page enter description</p>
                }
                <ReactQuill
                  className="w-full bg-white"
                  placeholder="Description"
                  ref={quillRef}
                  modules={module}
                  value={value}
                  onChange={handleQuillChange}
                />
              </div>
            </div>
            <button
              className="block w-full btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
              type="submit"
              onClick={HandleSubmit}
            >
              Update Guide Categories
            </button>
          </form>
        </div>
        <ToastContainer />
      </div >
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
          // onClose={() => {
          //   setHtmlPP(false);
          // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X className="cursor-pointer" onClick={() => {
                    if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                      setHtml({ ...html, htmlPP: false })
                    }
                  }} />
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({ ...html, code: e.target.value })}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
                <button
                  className='btn w-full mt-3 text-white px-3 py-2 rounded-[10px] mx-auto'
                  onClick={saveHTML}
                >
                  save
                </button>
              </div>
            </div>
          </Popover>
        </div>
      )
      }
    </>
  );
};

export default UpdateGuideCategory;
