import React, { useEffect, useRef, useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import API from '../../../API';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Loader from '../../Loader/Loader';
import { Move, Trash } from 'feather-icons-react/build/IconComponents';
import Notification from '../../Notification';
import { TrashFill } from 'react-bootstrap-icons';
import NoDataFound from '../../Main/NoDataFound';

const CreateDummyLayout = () => {
    const [sectionMenu, setSectionMenu] = useState([
        'slider',
        'category',
        'product',
        'manufacture',
        'banner',
        'blog',
        'cmsoffer',
        'reassurance'
    ]);
    let sliderExpandableRowsColumn = [];
    let bannerExpandableRowsColumn = [];
    let brandExpandableRowsColumn = [];
    let blogExpandableRowsColumn = [];
    let categoryExpandableRowsColumn = [];
    let productExpandableRowsColumn = [];
    let cmsOfferExpandableRowsColumn = [];
    let reassuranceExpandableRowsColumn = [];
    const [nodata, setNodata] = useState(false)
    const [selectedRowsId, setSelectedRowsId] = useState()
    const params = useParams();
    const [selectSection, setSelectSection] = useState('slider');
    const [selectItems, setSelectItems] = useState('');
    const [sliderData, setSliderData] = useState();
    const [bannerData, setBannerData] = useState();
    const [brandData, setBrandData] = useState();
    const [blogData, setBlogData] = useState();
    const [categoryData, setCategoryData] = useState();
    const [productData, setProductData] = useState();
    const [cmsOfferData, setCmsOfferData] = useState();
    const [reassuranceData, setReassuranceData] = useState();
    const [filterProductData, setFilterProductData] = useState();
    const [getLayout, setGetLayout] = useState()
    const [isNotification, setIsNotification] = useState(false)
    const [notificationMsg, setNotificationMsg] = useState('')
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false)
        setNotificationMsg('')
        setSeverity('')
    }
    const handleSelectSection = () => {
        switch (selectSection) {
            case 'slider':
                API({ url: `/super/admin/dummydata/dynamicdummyslider/getSliderGroups`, method: 'get' }).then((res) => {
                    setSliderData(res?.data?.data);
                }).catch(error => console.error(error));
                break;
            case 'banner':
                API({ url: `/super/admin/dummydata/dynamicdummybanner/getBannerGroups`, method: 'get' }).then((res) => {
                    setBannerData(res?.data?.data);
                }).catch(error => console.error(error));
            case 'manufacture':
                API({ url: `/super/admin/dummydata/dynamicdummybrand/getBrandGroups`, method: 'get' }).then((res) => {
                    setBrandData(res?.data?.data);
                }).catch(error => console.error(error));
            case 'blog':
                API({ url: `/super/admin/dummydata/dynamicdummyblog/getBlogGroups`, method: 'get' }).then((res) => {
                    setBlogData(res?.data?.data);
                }).catch(error => console.error(error));
            case 'category':
                API({ url: `/super/admin/dummydata/dynamicdummycategory/getCategoryGroups`, method: 'get' }).then((res) => {
                    setCategoryData(res?.data?.data);
                }).catch(error => console.error(error));
            case 'product':
                API({ url: `/super/admin/dummydata/dynamicdummyproduct/getProductGroups`, method: 'get' }).then((res) => {
                    setProductData(res?.data?.data);
                }).catch(error => console.error(error));
            case 'cmsoffer':
                API({ url: `/super/admin/dummydata/dynamicdummycmsoffer/getCmsOffers`, method: 'get' }).then((res) => {
                    setCmsOfferData(res?.data?.data);
                }).catch(error => console.error(error));
            case 'reassurance':
                API({ url: `/super/admin/dummydata/dynamicreassurance/getReassuranceGroups`, method: 'get' }).then((res) => {
                    setReassuranceData(res?.data?.data);
                }).catch(error => console.error(error));
            default:
                break;
        }
    }
    useEffect(() => {
        handleSelectSection();
    }, [selectSection])
    const [err, setErr] = useState(false)
    const handleAddLayout = async (e) => {
        e.preventDefault();
        if (selectItems === '') {
            setErr(true)
        } else {
            try {
                await API({ url: `/super/admin/dummydata/dummylayout/addSection`, method: 'post', data: {
                    layout: params?.layoutName,
                    section: {
                        section_type: selectSection,
                        section_id: selectItems
                    }
                }})
                setSelectSection('slider');
                setSelectItems('')
                handleGetLayout();
            } catch (error) {
                console.log(error);
            }
        }
    }
    const [pending, setPending] = useState(false)
    const handleGetLayout = async () => {
        setPending(true)
        try {
            const Apicall = await API({ url: `/super/admin/dummydata/dummylayout/getLayout/${params?.layoutName}`, method: 'get' })
            setGetLayout(Apicall?.data?.data?.sections);
            setPending(false)
        } catch (error) {
            setNodata(true)
        }
    }
    useEffect(() => {
        handleGetLayout();
    }, [])
    const UpdateSectionStatus = async (id, value) => {
        try {
            const data = await API({ url: `/super/admin/dummydata/dummylayout/updateSection/${id}`, method: 'put', data: {
                status: value,
            }});
            if (data.status === 200 || data.status === 304) {
                handleGetLayout()
            }
        } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity("error")
            console.log(error)
        }
    }
    const [isClear, setIsClear] = useState(false)
    const handleDelete = async (e, id) => {
        e.preventDefault();
        if (window.confirm('Are You Sure To Remove This section?')) {
            try {
                const data = await API({ url: `/super/admin/dummydata/dummylayout/deleteSection`, method: 'post', data: {
                    id: selectedRowsId?.length > 0 ? selectedRowsId : [id]
                }});
                if (data.status === 200 || data.status === 304) {
                    setIsNotification(true)
                    setNotificationMsg('Section Removed SuccesFully!')
                    setSeverity('success')
                    handleGetLayout();
                    setIsClear(true)
                }
            } catch (error) {
                setIsNotification(true)
                setNotificationMsg(error)
                setSeverity("error")
            }
        }
    };
    const sortSection = async (index, id) => {
        try {
            await API({ url: `/super/admin/dummydata/dummylayout/updateSection/${id}`, method: 'put', data: {
                newIndex: index === 0 ? 0 : Number(index),
            }});
            handleGetLayout();
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    };
    const DragItem = useRef();
    const DragOverItem = useRef();
    const HandleSort = (e) => {
        e.preventDefault();
        let _sort = [...getLayout];
        const dragItemContent = _sort.splice(DragItem.current, 1)[0];
        _sort.splice(DragOverItem.current, 0, dragItemContent);
        sortSection(DragOverItem.current, dragItemContent._id);
        DragItem.current = null;
        DragOverItem.current = null;
        let FinalArray = { getLayout: _sort };
        setGetLayout(FinalArray);
    };
    const columns = [
        {
            name: '',
            width: '50px',
            padding: '0px',
            cell: (row, index) => (
                <div
                    className='cursor-move text-center block w-full'
                    draggable
                    onDragStart={(e) => (DragItem.current = index)}
                    onDragEnter={(e) => (DragOverItem.current = index)}
                    onDragEnd={HandleSort}
                >
                    <Move className='cursor-move w-4' />
                </div>
            ),
        },
        {
            name: 'section type',
            cell: (row) => <span className='line-clamp-2'>{row?.section_type}</span>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'section',
            cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{
                __html:
                    row?.section_type === "slider" ? row?.section?.slider_title :
                        row?.section_type === "banner" ? row?.section?.banner_title :
                            row?.section_type === "manufacture" ? row?.section?.brand_section_title :
                                row?.section_type === "blog" ? row?.section?.blog_section_title :
                                    row?.section_type === "category" ? row?.section?.category_section_title :
                                        row?.section_type === "product" ? row?.section?.product_section_title :
                                            row?.section_type === "cmsoffer" ? row?.section?.cms_offer_title :
                                            row?.section_type === "reassurance" ? row?.section?.reassurance_title :
                                                ""
            }}>
            </span>,
            sortable: true,
            minWidth: '300px'
        },
        {
            name: 'Status',
            width: '100px',
            cell: row => <input
                className="mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={row.status}
                name='slider'
                onChange={() => {
                    UpdateSectionStatus(row?._id, !row.status)
                }}
            />,
        },
        {
            name: <span className='w-full text-right'>Action</span>,
            width: '80px',
            cell: (row) => <div className='space-x-2 w-full text-right'>
                <button onClick={(e) => {
                    {handleDelete(e, row?._id)};
                }}><Trash className='w-[17px] h-[17px] text-red-500' /></button>
            </div>
        }
    ]
    const UpdateExpandableRowsStatus = async (id, value, key) => {
        if (key === "slider") {
            await API({ url: `/super/admin/dummydata/dynamicdummyslider/updateDummySlider/${id}`, method: 'put', data: {
                slider_status: value,
            }}).then(() => {
                handleGetLayout()
            }).catch((error) => {
                setIsNotification(true)
                setNotificationMsg(error)
                setSeverity("error")
            });
        } else if (key === "banner") {
            await API({ url: `/super/admin/dummydata/dynamicdummybanner/updateBanner/${id}`, method: 'put', data: {
                banner_status: value,
            }}).then(() => {
                handleGetLayout()
            }).catch((error) => {
                setIsNotification(true)
                setNotificationMsg(error)
                setSeverity("error")
            });
        } else {
            handleGetLayout();
        }
    }
    sliderExpandableRowsColumn = [
        {
            name: 'Image',
            width: '150px',
            cell: (row) => <img width={80} height={50} src={row.slider_img} className='p-1 object-contain max-w-full max-h-full' />,
        },
        {
            name: 'slider Text',
            cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.slider_text }}></span>,
            sortable: true,
            width: '250px'
        },
        {
            name: 'button Text',
            cell: (row) => <span className='line-clamp-2'>{row.slider_button_text}</span>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Link',
            cell: (row) => <span className='line-clamp-1' dangerouslySetInnerHTML={{ __html: row.slider_button_link || '-' }}></span>,
            width: '250px'
        },
        {
            name: 'Status',
            width: '100px',
            cell: row => <input
                className="mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={row.slider_status}
                name='slider'
                onChange={() => {
                    UpdateExpandableRowsStatus(row?._id, !row.slider_status, "slider")
                }}
            />,
        },
    ]
    bannerExpandableRowsColumn = [
        {
            name: 'Image',
            width: '150px',
            cell: (row) => <img width={80} height={50} src={row.banner_img} className='p-1 object-contain max-w-full max-h-full' />,
        },
        {
            name: 'banner Text',
            cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.banner_text }}></span>,
            sortable: true,
            width: '250px'
        },
        {
            name: 'button Text',
            cell: (row) => <span className='line-clamp-2'>{row.banner_button_text}</span>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Link',
            cell: (row) => <span className='line-clamp-1' dangerouslySetInnerHTML={{ __html: row.banner_button_link || '-' }}></span>,
            width: '250px'
        },
        {
            name: 'Status',
            width: '100px',
            cell: row => <input
                className="mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={row.banner_status}
                name='slider'
                onChange={() => {
                    UpdateExpandableRowsStatus(row?._id, !row.banner_status, "banner")
                }}
            />,
        },
    ]
    brandExpandableRowsColumn = [
        {
            name: 'Image',
            width: '150px',
            cell: (row) => <img width={80} height={50} src={row?.brandData?.brand_logo} className='p-1 object-contain max-w-full max-h-full' />,
        },
        {
            name: 'manufacture name',
            cell: (row) => <span className='line-clamp-2'>{row?.brandData?.brand_name}</span>,
            sortable: true,
            width: '250px'
        },
    ]
    blogExpandableRowsColumn = [
        {
            name: 'Image',
            width: '150px',
            cell: (row) => <img width={80} height={50} src={row?.blogData?.image} className='p-1  object-contain max-w-full max-h-full' />,
        },
        {
            name: 'blog title',
            cell: (row) => <span className='line-clamp-2'>{row?.blogData?.title}</span>,
            sortable: true,
            width: '250px'
        },
    ]
    categoryExpandableRowsColumn = [
        {
            name: 'Image',
            width: '150px',
            cell: (row) => <img width={80} height={50} src={row?.categoryData?.image} className='p-1 object-contain max-w-full max-h-full' />,
        },
        {
            name: 'category name',
            cell: (row) => <span className='line-clamp-2'>{row?.categoryData?.category_name}</span>,
            sortable: true,
            width: '250px'
        },
    ]
    productExpandableRowsColumn = [
        {
            name: 'Image',
            width: '150px',
            cell: (row) => <img width={80} height={50} src={row?.productData?.image} className='p-1 object-contain max-w-full max-h-full' />,
        },
        {
            name: 'product name',
            cell: (row) => <span className='line-clamp-2'>{row?.productData?.product_name}</span>,
            sortable: true,
            width: '250px'
        },
    ]
    cmsOfferExpandableRowsColumn = [
        {
            name: 'Cms offer title',
            cell: (row) => <span className='line-clamp-2'>{row?.cms_offer_title}</span>,
            sortable: true,
            width: '250px'
        },
        {
            name: 'Cms offer Text',
            cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row?.cms_offer?.cms_offer_text }}></span>,
            sortable: true,
            width: '250px'
        },
    ]
    reassuranceExpandableRowsColumn = [
        {
            name: 'reassurances image',
            cell: (row) => <img width={80} height={50} src={row.reassurance_img} className='p-1 object-contain max-w-full max-h-full' />,
            width: '250px'
        },
        {
            name: 'reassurances title',
            cell: (row) => <span className='line-clamp-2'>{row?.reassurance_title}</span>,
        },
        {
            name: 'redirect link',
            cell: (row) => <span className='line-clamp-2'>{row?.reassurance_redirect_link}</span>,
        },
    ]
    const ExpandedComponent = ({ data }) =>
        <div className='w-full bg-[#f5f5f5] block overflow-x-auto overflow-y-hidden p-2'>
            <DataTable
                className='border border-gray-200 rounded'
                columns={data?.section_type === "slider" ? sliderExpandableRowsColumn :
                    data?.section_type === "banner" ? bannerExpandableRowsColumn :
                        data?.section_type === "manufacture" ? brandExpandableRowsColumn :
                            data?.section_type === "blog" ? blogExpandableRowsColumn :
                                data?.section_type === "category" ? categoryExpandableRowsColumn :
                                    data?.section_type === "product" ? productExpandableRowsColumn :
                                            data?.section_type === "cmsoffer" ? cmsOfferExpandableRowsColumn :
                                                data?.section_type === "reassurance" ? reassuranceExpandableRowsColumn :
                                                    ""}
                data={data?.section_type === "slider" ? data?.section?.sliders :
                    data?.section_type === "banner" ? data?.section?.banners :
                        data?.section_type === "manufacture" ? data?.section?.brands :
                            data?.section_type === "blog" ? data?.section?.blogs :
                                data?.section_type === "category" ? data?.section?.categories :
                                    data?.section_type === "product" ? data?.section?.products :
                                            data?.section_type === "cmsoffer" ? data?.section && [data?.section] :
                                            data?.section_type === "reassurance" ? data?.section?.reassurances :
                                                ""}
                selectableRowsHighlight
                highlightOnHover
            />
        </div>
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            {
                nodata ? <NoDataFound /> :
                    <div className='h-auto overflow-y-auto pb-[64px]'>
                        <div className='flex items-end justify-between w-full pb-4'>
                            <div>
                                <Breadcrumbs />
                                <h1 className='text-xl md:text-2xl font-medium text-primary'>Add Section</h1>
                            </div>
                        </div>
                        <form
                            method='POST'
                            className='w-[90%] sm:w-[80%] xl:w-[70%] mx-auto'
                        >
                            <div className='bg-white p-[25px] shadow rounded-xl'>
                                <div className='mb-4'>
                                    <label
                                        htmlFor='product_name'
                                    >
                                        section type
                                    </label>
                                    <div className='relative w-full'>
                                        <select className='w-full capitalize' value={selectSection} onChange={(e) => setSelectSection(e.target.value)}>
                                            {
                                                sectionMenu?.map((elem) => {
                                                    return (
                                                        <option value={elem} className='capitalize'>{elem}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <div className='select-arrow'></div>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <label
                                        htmlFor='product_name'
                                    >
                                        section
                                    </label>
                                    <div className={`relative w-full ${err ? 'border border-red-500 rounded' : ''}`}>
                                        <select className='w-full capitalize' value={selectItems} onClick={() => setErr(false)} onChange={(e) => { setSelectItems(e.target.value) }}>
                                            <option value="" className='capitalize'>Select section</option>
                                            {
                                                selectSection === 'slider' ?
                                                    sliderData?.map((elem) => {
                                                        return (
                                                            elem?.status && <option value={elem?._id} className='capitalize'>{elem?.slider_title}</option>
                                                        )
                                                    }) :    
                                                    selectSection === 'banner' ?
                                                        bannerData?.map((elem) => {
                                                            return (
                                                                elem?.status && <option value={elem?._id} className='capitalize'>{elem?.banner_title}</option>
                                                            )
                                                        }) : selectSection === 'manufacture' ?
                                                            brandData?.map((elem) => {
                                                                return (
                                                                    elem?.status && <option value={elem?._id} className='capitalize' dangerouslySetInnerHTML={{ __html: elem?.brand_section_title }}></option>
                                                                )
                                                            }) : selectSection === 'blog' ?
                                                                blogData?.map((elem) => {
                                                                    return (
                                                                        elem?.status && <option value={elem?._id} className='capitalize' dangerouslySetInnerHTML={{ __html: elem?.blog_section_title }}></option>
                                                                    )
                                                                }) : selectSection === 'category' ?
                                                                    categoryData?.map((elem) => {
                                                                        return (
                                                                            elem?.status && <option value={elem?._id} className='capitalize' dangerouslySetInnerHTML={{ __html: elem?.category_section_title }}></option>
                                                                        )
                                                                    }) : selectSection === 'product' ?
                                                                        productData?.map((elem) => {
                                                                            return (
                                                                                elem?.status && <option value={elem?._id} className='capitalize' dangerouslySetInnerHTML={{ __html: elem?.product_section_title }}></option>
                                                                            )
                                                                        }) : selectSection === 'cmsoffer' ?
                                                                            cmsOfferData?.map((elem) => {
                                                                                return (
                                                                                    <option value={elem?._id} className='capitalize' dangerouslySetInnerHTML={{ __html: elem?.cms_offer_title }}></option>
                                                                                )
                                                                            }) : selectSection === 'reassurance' ?
                                                                                reassuranceData?.map((elem) => {
                                                                                    return (
                                                                                        elem?.status && <option value={elem?._id} className='capitalize' dangerouslySetInnerHTML={{ __html: elem?.reassurance_title }}></option>
                                                                                    )
                                                                                }) : <></>
                                            }
                                        </select>
                                        <div className='select-arrow'></div>
                                    </div>
                                    {
                                        err ? <p className='text-red-500 text-xs'>Please select section</p> : <></>
                                    }
                                </div>
                                <button className='btn text-sm text-white' onClick={(e) => handleAddLayout(e)}>Add Section</button>
                                <hr className='my-4 border-t border-gray-300'></hr>
                                <details
                                    className={
                                        selectedRowsId?.length === 0
                                            ? `opacity-60 cursor-not-allowed mb-2 group w-[200px] relative`
                                            : `cursor-pointer group w-[200px] mb-2 relative`
                                    }
                                >
                                    <summary className='flex bg-white  border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
                                        <span> Bulk Selection</span>
                                        <span
                                            className={
                                                selectedRowsId?.length === 0
                                                    ? 'transition group-open:rotate-0'
                                                    : 'transition group-open:rotate-180'
                                            }
                                        >
                                            <svg
                                                fill='none'
                                                height={14}
                                                shapeRendering='geometricPrecision'
                                                stroke='currentColor'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1.5'
                                                viewBox='0 0 24 24'
                                                width={14}
                                            >
                                                <path d='M6 9l6 6 6-6' />
                                            </svg>
                                        </span>
                                    </summary>
                                    <div
                                        className={
                                            selectedRowsId?.length === 0
                                                ? `hidden`
                                                : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
                                        }
                                    >
                                        <button
                                            className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center'
                                            onClick={(e) => { handleDelete(e) }}
                                        >
                                            <TrashFill className='w-3.5 h-3.5' />
                                            <span>Delete selection</span>
                                        </button>
                                    </div>
                                </details>
                                <div className='w-full inline-block border border-gray-300 rounded-[4px] overflow-x-auto overflow-y-hidden'>
                                    <DataTable
                                        columns={columns}
                                        data={getLayout}
                                        expandableRows
                                        expandableRowsComponent={ExpandedComponent}
                                        selectableRows
                                        onSelectedRowsChange={({ selectedRows }) => { setSelectedRowsId((selectedRows?.map((x) => x._id))) }}
                                        clearSelectedRows={isClear}
                                        selectableRowsHighlight
                                        highlightOnHover
                                        progressComponent={<Loader />}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
            }
        </>
    )
}

export default CreateDummyLayout