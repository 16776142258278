import API from "../../API";
import { ADD_TENANTS, GET_TENANTS, GET_PERTICULAR_TENANT, GET_DELETE_REQUEST } from "../action.type"

export const AddTenants = (email, business_category, storeName, business_name) => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({
        url: "/super/admin/tenant/addTenant", method: 'post', data: {
          email,
          business_category,
          store_id: storeName,
          business_name,
        }
      });
      if (ApiCall?.data) {
        dispatch({
          type: ADD_TENANTS,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const GetTenants = (page = 1, limit = 10, v_status = '', store_status = '', search = '') => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      if (token) {
        let ApiCall = await API({
          url: `/super/admin/tenant/getTenants`, method: 'get', params: {
            search, page, limit, verification: v_status, status: store_status
          }
        });
        if (ApiCall?.data) {
          dispatch({
            type: GET_TENANTS,
            payload: ApiCall
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export const viewTenants = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    if (id)
      try {
        if (token) {
          let ApiCall = await API({ url: `/super/admin/tenant/getPerticularTenant/${id}`, method: 'get' });
          if (ApiCall?.data) {
            dispatch({
              type: GET_PERTICULAR_TENANT,
              payload: ApiCall
            })
          }
        }
      } catch (error) {
        console.log(error);
      }
  }
}

export const handleGetDeleteRequest = () => {
  return async (dispatch) => {
    try {
      let apicall = await API({ url: `/super/admin/tenant/deleteStoreRequest`, method: 'get' })
      if (apicall?.data) {
        dispatch({
          type: GET_DELETE_REQUEST,
          payload: apicall
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
}