import { ADD_ANNAUNCEMENTS, GET_ANNAUNCEMENTS, GET_PERTICULAR_DATA } from "../action.type";

const initialState={
    addAnnauncements:null,
    allAnnauncements:null,
    getPerticularAnnauncementData:null 
    // updateAnnauncements:null,
    // getextensionname:null,
}                                 

export const annauncementsReducer=(state=initialState,action)=> {
    switch (action.type) {
      case ADD_ANNAUNCEMENTS:
        return {
           addAnnauncements: action.payload?.data?.data,
        };
      case GET_ANNAUNCEMENTS:
        return {
            allAnnauncements: action.payload?.data?.data,
        };
      case GET_PERTICULAR_DATA:
        return {
          getPerticularAnnauncementData: action.payload?.data?.data
        }
      
      default:
        return state;
    }
  }
