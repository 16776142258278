export const GET_STTLED_PAYMENTS="GET_STTLED_PAYMENTS"
export const PAYMENT_TOBE_SETTLE='PAYMENT_TOBE_SETTLE'

// Status
export const GET_SYSTEM_STATUS='GET_SYSTEM_STATUS'

// Guide
export const GET_GUIDES="GET_GUIDES"
export const ADD_GUIDE_GUAIDENCE='ADD_GUIDE_GUAIDENCE'
export const DELETE_GUAIDE = 'DELETE_GUAIDE'
export const UPDATE_GUIDE = 'UPDATE_GUIDE'
export const GET_EXTENSION_NAME = 'GET_EXTENSION_NAME'
export const GET_PERTICULAR_DATA = 'GET_PERTICULAR_DATA'

// Guide Category
export const GET_GUAIDE_CATERORIES="GET_GUAIDE_CATERORIES"
export const ADD_GUAIDE_CATERORIES_GUAIDENCE = 'ADD_GUIDE_CATERORIES_GUAIDENCE'
export const UPDATE_GUAIDE_CATERORIES = 'UPDATE_GUIDE_CATERORIES'
export const DELETE_GUAIDE_CATERORIES = 'DELETE_GUAIDE_CATERORIES'
export const GET_PERTICULAR_CATERORIES_DATA = 'GET_PERTICULAR__CATERORIES_DATA'



// Annauncements
export const ADD_ANNAUNCEMENTS='ADD_ANNAUNCEMENTS'
export const GET_ANNAUNCEMENTS='GET_ANNAUNCEMENTS'

// Guide Dummy Category
export const ADD_DUMMY_CATEGORY = 'ADD_DUMMY_CATEGORY'

// Tenants
export const ADD_TENANTS='ADD_TENANTS'
export const GET_TENANTS='GET_TENANTS'
export const GET_PERTICULAR_TENANT='GET_PERTICULAR_TENANT'
export const GET_DELETE_REQUEST = "GET_DELETE_REQUEST"

//Changelog
export const GET_ALL_CHANGELOG_CATEGORIES='GET_ALL_CHANGELOG_CATEGORIES'
export const GET_CHANGELOG="GET_CHANGELOG"
export const GET_PERTICULAR_LOG='GET_PERTICULAR_LOG'

// Store Actions
export const GET_ALL_STORES = 'GET_ALL_STORES'
export const GET_STORE_CUSTOMERS = 'GET_STORE_CUSTOMERS'
export const GET_STORE_PRODUCTS = 'GET_STORE_PRODUCTS'
export const GET_STORE_ORDERS = 'GET_STORE_ORDERS'
export const GET_STORE_PLANS = 'GET_STORE_PLANS'

//company
export const GET_COMPANY_DATA = 'GET_COMPANY_DATA'
export const GET_PERTICULER_COMPANY_DATA = 'GET_PERTICULER_COMPANY_DATA'

// Auth
export const LOGIN = 'LOGIN'

//Dashboard
export const GET_LATEST_STORE = 'GET_LATEST_STORE'
export const GET_ACTIVE_PLAN = 'GET_ACTIVE_PLAN'
export const GET_PLAN_EXPIRED = 'GET_PLAN_EXPIRED'
export const GET_TOTAL_EXTENSION = 'GET_TOTAL_EXTENSION'