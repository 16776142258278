import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import Notification from '../Notification';
import { NOUSER_IMAGE } from '../../Assets/StaticData/StaticImage';
export default function Navbar() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token")
  useEffect(() => {
    if (!token) {
      // navigate("/")
      window.location.href = '/'
    }
  }, [token])

  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    window.location.reload(true)
    window.location.href = '/'
    navigate("/")
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='px-4 bg-white border-b border-gray-300 w-full max-w-full rounded-t-lg'>
        <div className='relative flex h-16 items-center justify-between'>
          <div className='flex items-center justify-end space-x-2 w-full'>
            <Menu as='div' className='relative'>
              <div className='z-50'>
                <Menu.Button className='flex rounded  text-sm focus:outline-none border border-gray-200 focus:border-gray-400 px-1 '>
                  <div className='flex items-center space-x-2'>
                    <img
                      className='h-10 w-10 object-cover object-top p-0.5 rounded-full'
                      onError={(e) => { e.target.src = NOUSER_IMAGE }}
                      src={NOUSER_IMAGE}
                      alt=''
                    />
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='absolute space-y-0.5 px-3 z-50 right-0 w-56 origin-top-right rounded-[10px] bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  <Menu.Item>
                    <button
                      className='py-2 w-full block text-left'
                      onClick={(e) => handleLogout(e)}
                    >Sign out
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}
