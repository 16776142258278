import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { decryptData } from './encryptionUtils';
const TextEditor = ({ label, handleEditorData, defaultValue }) => {
  const editorRef = useRef(null);
  const [content, setContent] = useState(defaultValue);

  useEffect(() => {
    setContent(defaultValue);
  }, [defaultValue]);

  const sanitizeContent = (content) => content?.replace(/\\n/g, '');

  const handleEditorChange = (newContent, editor) => {
    setContent(sanitizeContent(newContent));
    // const sanitizedContent = sanitizeContent(newContent);
    // if (sanitizedContent !== content) {
    //   setContent(sanitizedContent);
    // }
  };

  useEffect(() => {
    handleEditorData(content);
  }, [content]);

  const initEditor = useCallback((evt, editor) => {
    editorRef.current = editor;
    editor.on('change keyup', () => {
      const editorContent = editor.getContent();
      if (editorContent !== content) {
        setContent(editorContent);
      }
    });
  }, [content]);

  const uploadImage = async (file, callback) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', "SEBlog");
    const token = localStorage.getItem('token')
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/super/admin/fileupload/upload`,
        {
          method: "POST",
          body: formData,
          headers: { token },
        }
      );
      const data = await response.json();
      console.log(data);
      if (data) {
        if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
          callback(data?.data[0], { title: file.name });
        } else {
          const result = decryptData(data?.encrypted)
          callback(result?.data[0], { title: file.name });
        }
      } else {
        console.error('Invalid response from server:', data);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };
  // const generateToC = () => {
  //   const editor = editorRef.current;
  //   const htmlContent = editor.getContent();
  //   console.log(htmlContent);

  //   const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
  //   const headings = Array.from(doc.querySelectorAll('h1, h2, h3, h4, h5, h6'));

  //   // Generate ToC HTML
  //   const tocHtml = headings.map((heading, index) => {
  //     const id = `heading-${index}`;
  //     heading.setAttribute('id', id);
  //     const level = parseInt(heading.tagName.substring(1));
  //     return `<li style="margin-left:${(level - 1) * 20}px;"><a href="#${id}">${heading.textContent}</a></li>`;
  //   }).join('');
  //   const toc = `<div class="toc"><h2>Table of Contents</h2><ul>${tocHtml}</ul></div>`;

  //   // Insert ToC into the content
  //   editor.setContent(toc + htmlContent);
  // };
  return (
    <>
      <div className="flex space-x-3 justify-between items-center mb-1">
        <label htmlFor="Description">{label}</label>
        {/* <button type="button" onClick={generateToC}>Generate Table of Contents</button> */}
      </div>
      <Editor
        apiKey='52tps9sm4xpctloujo9oocuyhzhlqeizezxjx00nv6bg5urq'
        onInit={initEditor}
        value={content}
        onEditorChange={handleEditorChange}
        init={{
          height: 350,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          content_css: 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css',
          content_style: `
          body {
            font-family: 'Inter', sans-serif; /* Optional: Tailwind's default font */
          }
          h1 { font-size: 40px} \
          h2 { font-size: 38px } \
          h3 { font-size: 34px } \
          h4 { font-size: 30px } \
          h5 { font-size: 26px } \
          h6 { font-size: 20px } \ `,
          toolbar: 'undo redo blocks bold italic forecolor alignleft aligncenter alignright alignjustify link bullist numlist outdent indent removeformat code image table link help',
          toolbar_mode: 'wrap',
          image_title: true,
          automatic_uploads: true,
          file_picker_types: 'image',
          image_uploadtab: false,
          file_picker_callback: (cb, value, meta) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = function () {
              const file = this.files[0];
              uploadImage(file, cb);
            };
            input.click();
          },
          images_upload_handler: (blobInfo, success, failure) => {
            const file = blobInfo.blob();
            uploadImage(file, success);
          }
        }}
      />
    </>
  );
};

export default TextEditor;
