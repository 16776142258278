import React from 'react'
import Notification from '../../Notification'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import { useState } from 'react'
import { PlusCircle, X } from 'feather-icons-react/build/IconComponents'
import Loader from '../../Loader/Loader'
import DataTable from 'react-data-table-component'
import { Popover } from '@mui/material';
import API from '../../../API'
import { useNavigate } from 'react-router-dom'
import { IconTab1, IconTab2, IconTab3 } from '../../../Assets/StaticData/ReassuranceIcon'
import ImageUploader from '../../../controllers/ImageUploader'

const CreateDummyReassuranceGroup = () => {
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  const navigate = useNavigate();
  const [dataErr, setDataErr] = useState(false);
  const [groupTitle, setGroupTitle] = useState('');
  const [storeReassurance, setStoreReassurance] = useState([])
  const [addReassurance, setAddReassurance] = useState(false);
  const [image, setimage] = useState();
  const [groupStatus, setGroupStatus] = useState(true)
  const [reassuranceStatus, setReassuranceStatus] = useState(true)
  const [reassuranceData, setReassuranceData] = useState({
    reassurance_title: '',
    reassurance_description: '',
    reassurance_redirect_link: '',
  })
  const handleSetImage = (value) => {
    setimage(value)
}
  const handleAddReassurance = (e) => {
    e.preventDefault();
    if (image?.length > 0 || icon?.length > 0) {
      setDataErr(false)
      setStoreReassurance([...storeReassurance, { ...reassuranceData, reassurance_status: reassuranceStatus, reassurance_img: icon?.length > 0 ? icon : image }]);
      setAddReassurance(false)
      setReassuranceData({
        reassurance_title: '',
        reassurance_description: '',
        reassurance_redirect_link: '',
      })
      setimage();
      seticon('');
      setReassuranceStatus(true)
    } else {
      setDataErr(true)
    }
  }
  const [err, setErr] = useState(false);
  const handleAddReassuranceGroup = async (e) => {
    e.preventDefault();
    if (!groupTitle) {
      setErr(true)
    } else {
      setErr(false)
      try {
        await API({ url: `/super/admin/dummydata/dynamicreassurance/addReassuranceGroup`, method: 'post', data: {
          reassurance_title: groupTitle,
          status: groupStatus,
          reassurances: storeReassurance
        }})
        navigate('/dummy-data/module-setting/reassurance-group')
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  }
  const columns = [
    // {
    //   name: 'Image',
    //   width: '150px',
    //   cell: (row) => row?.reassurance_img?.includes("<svg") ? <div dangerouslySetInnerHTML={{ __html: row.reassurance_img }} className='inline-block mx-auto'></div> : <img width={80} height={50} src={row.reassurance_img} className='p-1 max-w-full object-contain max-h-full' />
    // ,
    // },
    {
      name: 'Reassurance title',
      cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.reassurance_title }}></span>,
      minWidth: '250px'
    },
    {
      name: 'redirect url',
      cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.reassurance_redirect_link }}></span>,
      minWidth: '250px'
    },
  ]
  const [iconDD, setIconDD] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [openTab, setOpenTab] = useState(1)
  const [icon, seticon] = useState('')
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='w-full'>
          <Breadcrumbs />
          <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Add New Reassurance Group</h3>
        </div>
        <form className='w-[90%] space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'>
          <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
            <div className='mb-4'>
              <label htmlFor='image'>
                Reassurance Group Title
              </label>
              <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${err ? 'border-red-500' : ''}`} placeholder='Reassurance group title' value={groupTitle} onChange={(e) => {setGroupTitle(e.target.value);setErr(false)}} />
              {
                err && !groupTitle && <p className='text-xs text-red-500'>Please enter group name</p>
              }
            </div>
            <div className='border w-full border-gray-300 flex items-center space-x-2 rounded px-4 py-2 text-sm'>
              <p className='text-[13px] font-[500] text-[#484848] capitalize'>
                Reassurance group status -
              </p>
              <input
                className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={groupStatus}
                name='sliderbutton'
                onChange={() => setGroupStatus(!groupStatus)}
              />
            </div>
            <hr className='border-t border-gray-200 my-4'></hr>
            <button className='text-link underline w-full text-right flex items-center justify-end font-medium text-sm text-white' onClick={(e) => { e.preventDefault(); setAddReassurance(true) }}><PlusCircle className="w-4 me-2 h-4" />Add Reassurance</button>
            <div className='w-full inline-block border border-gray-300 rounded-[4px] overflow-x-auto overflow-y-hidden'>
              <DataTable
                columns={columns}
                data={storeReassurance}
                selectableRowsHighlight
                highlightOnHover
                progressComponent={<Loader />}
              />
            </div>
            <hr className='border-t border-gray-200 my-4'></hr>
            <button className='btn text-sm text-white' onClick={(e) => handleAddReassuranceGroup(e)}>Add Reassurance Group</button>
          </div>
        </form>
        {
          addReassurance &&
          <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
            <Popover
              open={addReassurance}
              className="h-screen popup-content"
              onClose={() => {
                setAddReassurance(false);
                setReassuranceData({
                  reassurance_title: '',
                  reassurance_description: '',
                  reassurance_redirect_link: '',
                })
                setimage();
                seticon('');
                setReassuranceStatus(true)
              }}
            >
              <form className='bg-white w-[700px] max-w-[600px] lg:max-w-full border border-gray-300  space-y-4 rounded p-5'>
                <div className='flex border-b border-gray-300 pb-3 items-center justify-between'>
                  <h3 className='font-medium text-xl'>Add Reassurance</h3>
                  <button onClick={(e) => {
                    e.preventDefault(); setAddReassurance(false); setReassuranceData({
                      reassurance_title: '',
                      reassurance_description: '',
                      reassurance_redirect_link: '',
                    })
                    setimage();
                    seticon('')
                    setReassuranceStatus(true)
                  }}><X className="w-5 h-4" /></button>
                </div>
                <div className='mb-4'>
                  <div className='flex items-center justify-between'>
                    <label htmlFor='image'>
                      Reassurance image
                    </label>
                    <button
                      aria-describedby='iconDD'
                      variant="contained"
                      className='text-link text-sm hover:underline'
                      onClick={(e) => {
                        { e.preventDefault(); setAnchorEl(e.currentTarget); setIconDD(true) }
                      }}
                    >
                      Select icon
                    </button>
                  </div>
                  <form>
                    <label className={`block border border-gray-300 p-2 hover:bg-gray-50 border-dashed w-full rounded hover:border-gray-400 cursor-pointer text-gray-900 outline-none text-sm ${dataErr && !image ? 'border-red-500' : ''}`}>
                      {
                        icon?.length ?
                          <div className='text-center'>
                            <div className='mx-auto inline-block' dangerouslySetInnerHTML={{ __html: icon }}></div>
                            <br></br>
                            <button onClick={(e) => { e.preventDefault(); seticon('') }} className='text-link text-sm font-normal hover:underline'>Remove icon</button>
                          </div> :
                        <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} folder="reassurance" format="image" />
                      }
                    </label>
                    {
                      dataErr ? <p className='text-red-500 text-xs'>Please enter image</p> : <></>
                    }
                  </form>
                </div>
                <div className='w-full'>
                  <label htmlFor='image'>
                    Reassurance redirect link
                  </label>
                  <input name='slider_button_link' value={reassuranceData?.reassurance_redirect_link} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='Add link' onChange={(e) => setReassuranceData({ ...reassuranceData, reassurance_redirect_link: e.target.value })} />
                </div>
                <div className='w-full'>
                  <label htmlFor='image'>
                    Reassurance title
                  </label>
                  <input name='slider_button_link' value={reassuranceData?.reassurance_title} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='e.g. Free Shipping World Wide' onChange={(e) => setReassuranceData({ ...reassuranceData, reassurance_title: e.target.value })} />
                </div>
                <div className='w-full'>
                  <label htmlFor='image'>
                    Reassurance description
                  </label>
                  <input name='slider_button_link' value={reassuranceData?.reassurance_description} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='e.g. Free Shipping World Wide' onChange={(e) => setReassuranceData({ ...reassuranceData, reassurance_description: e.target.value })} />
                </div>
                {/* <div className='border w-full border-gray-300 flex items-center space-x-2 rounded px-4 py-2 text-sm'>
                  <p className='text-[13px] font-[500] text-[#484848] capitalize'>
                    Reassurance status -
                  </p>
                  <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={reassuranceStatus === 'enabled'}
                    name='sliderbutton'
                    onChange={() => setReassuranceStatus(reassuranceStatus === 'enabled' ? 'disabled' : 'enabled')}
                  />
                </div> */}
                <button className='btn inline-block text-sm text-white' onClick={(e) => handleAddReassurance(e)}>Add Reassurance</button>
              </form>
              {
                iconDD &&
                <Popover
                  id={'iconDD'}
                  open={iconDD}
                  anchorEl={anchorEl}
                  onClose={() => setIconDD(!iconDD)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div className="h-auto w-96 max-w-screen bg-white  border-gray-600  z-50 rounded-[4px]">
                    <ul
                      className='flex items-center mb-0 list-none overflow-x-auto flex-row'
                      role='tablist'
                    >
                      <li className='text-center lg:mb-0'>
                        <a
                          className={
                            'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                            (openTab === 1
                              ? 'text-black border-b-2 border-b-[#6a63fb]'
                              : 'text-gray-600 bg-white border-b-2 border-white')
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(1);
                          }}
                          data-toggle='tab'
                          href='#link1'
                          role='tablist'
                        >
                          <div className='max-h-[50px] m-3'>{IconTab1?.title}</div>
                        </a>
                      </li>
                      <li className='text-center lg:mb-0'>
                        <a
                          className={
                            'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                            (openTab === 2
                              ? 'text-black border-b-2 border-b-[#6a63fb]'
                              : 'text-gray-600 bg-white border-b-2 border-white')
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle='tab'
                          href='#link2'
                          role='tablist'
                        >
                          <div className='max-h-[50px] m-3'>{IconTab2?.title}</div>
                        </a>
                      </li>
                      <li className='text-center lg:mb-0'>
                        <a
                          className={
                            'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                            (openTab === 3
                              ? 'text-black border-b-2 border-b-[#6a63fb]'
                              : 'text-gray-600 bg-white border-b-2 border-white')
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(3);
                          }}
                          data-toggle='tab'
                          href='#link3'
                          role='tablist'
                        >
                          <div className='max-h-[50px] m-3'>{IconTab3?.title}</div>
                        </a>
                      </li>
                    </ul>
                    {
                      openTab === 1 &&
                      <div className='grid grid-cols-3 mt-4 gap-4'>
                        {
                          IconTab1?.icons?.map((elem) => {
                            return (
                              <div className={`text-center cursor-pointer ${elem === icon ? 'bg-gray-100 rounded' : ''}`}>
                                <div dangerouslySetInnerHTML={{ __html: elem }} onClick={(e) => { seticon(elem); setDataErr(false); setIconDD(false) }} className='inline-block mx-auto'></div>
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                    {
                      openTab === 2 &&
                      <div className='grid grid-cols-3 mt-4 gap-4'>
                        {
                          IconTab2?.icons?.map((elem) => {
                            return (
                              <div className='text-center cursor-pointer'>
                                <div dangerouslySetInnerHTML={{ __html: elem }} onClick={(e) => { seticon(elem); setDataErr(false); setIconDD(false) }} className='inline-block mx-auto'></div>
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                    {
                      openTab === 3 &&
                      <div className='grid grid-cols-3 mt-4 gap-4'>
                        {
                          IconTab3?.icons?.map((elem) => {
                            return (
                              <div className='text-center cursor-pointer'>
                                <div dangerouslySetInnerHTML={{ __html: elem }} onClick={(e) => { seticon(elem); setDataErr(false); setIconDD(false) }} className='inline-block mx-auto'></div>
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                    <div className='border-t p-4 text-center'>
                      <button className='text-link' onClick={(e) => { seticon(''); setDataErr(false); setIconDD(false) }}>Select none</button>
                    </div>
                  </div>
                </Popover>
              }
            </Popover>
          </div>
        }
      </div>
    </>
  )
}

export default CreateDummyReassuranceGroup