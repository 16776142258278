import { ChevronLeft, ChevronRight, Edit, Filter, ChevronDown, PlusCircle, Trash, Trash2 } from 'feather-icons-react/build/IconComponents'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { handleDeleteCompany, handleGetCompanyData } from '../../Redux/Action/company.action';
import DataTable from 'react-data-table-component';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { Menu, MenuItem, Pagination } from '@mui/material'
const AllCompany = () => {
    const [filterDD, setFilterDD] = useState(true)
    const [selectedRowsId, setSelectedRowsId] = useState([])
    const [search, setSearch] = useState('')
    const dispatch = useDispatch();
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    useEffect(() => {
        dispatch(handleGetCompanyData(page, limit, search))
    }, [page, limit, search])
    const { allCompany } = useSelector((state) => state?.companyReducer)
    const navigate = useNavigate()
    const HandleMultipleDelete = (id) => {
        dispatch(handleDeleteCompany(id ? [id] : selectedRowsId, page, limit))
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const columns = [
        {
            name: "bussiness name",
            minWidth: "150px",
            cell: (row) => (
                <span className="line-clamp-1 capitalize">{row.business_name}</span>
            ),
        },
        {
            name: "store url",
            minWidth: "150px",
            cell: (row) => (
                <span className="line-clamp-1 capitalize">{row.store_url}</span>
            ),
        },
        {
            name: "mobile no.",
            minWidth: "150px",
            cell: (row) => (
                <span className="line-clamp-1 capitalize">{row.cust_mobile}</span>
            ),
        },
        {
            name: "customer name",
            minWidth: "150px",
            cell: (row) => (
                <span className="line-clamp-1 capitalize">{row.cust_name}</span>
            ),
        },
        {
            name: "group id",
            minWidth: "150px",
            cell: (row) => (
                <span className="line-clamp-1 capitalize">{row.group_id}</span>
            ),
        },
        {
            name: "Subscribe",
            minWidth: "150px",
            cell: (row) => (
                <span className="line-clamp-1 capitalize">{row.Subscribe}</span>
            ),
        },
        {
            name: <p className="w-full text-right block">ACTION</p>,
            cell: (row) => (
                <div className="space-x-3 w-full flex items-center justify-end">
                    <button onClick={(e) => navigate(`/company/${row?._id}`)}>
                        <button className="">
                            <Edit className="w-[17px] h-[17px] text-blue-500" />
                        </button>
                    </button>

                    <button onClick={() => { HandleMultipleDelete(row?._id) }}>
                        <button className="">
                            <Trash2 className="w-[17px] h-[17px] text-red-500" />
                        </button>
                    </button>
                </div>
            ),
        },
    ]
    return (
        <div className=" w-full pb-[64px]">
            <div className="flex space-y-2 lg:space-y-0 items-center justify-between mb-4">
                <div>
                    <Breadcrumbs crumbs={[
                        { name: 'Dashboard', path: `/dashboard` },
                        { name: 'Company', path: `/company` }
                    ]} />
                    <h1 className='text-xl md:text-2xl font-semibold text-primary'>Company</h1>
                </div>
                <div className='flex items-center space-x-3'>
                    <div className='flex items-center bg-[#6a63FB] rounded text-sm font-medium'>
                        <button
                            className='flex btn rounded-s px-5 py-2.5 text-sm items-center font-medium  text-white transition duration-300'
                            onClick={() => navigate('/company/add-company')}
                        >
                            <PlusCircle className='w-[18px] h-[18px] me-2' />
                            Add Company
                        </button>
                        <button
                            className='flex btn px-3 border-s border-gray-600 rounded-e py-[9px] items-center text-white transition duration-300'
                            onClick={handleClick}
                        >
                            <ChevronDown className='w-[18px] h-[18px]' />
                        </button>
                        <div>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                className='text-sm'
                            >
                                <MenuItem className='text-sm block' onClick={() => navigate("/company/import-company-info")}>
                                    Import Company Info
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <button
                        className="filter-btn text-center rounded w-10 h-10"
                        onClick={() => setFilterDD(!filterDD)}
                    >
                        <Filter className="inline-flex mx-auto rounded-md w-5 h-5" />
                    </button>
                </div>
            </div>
            <details
                className={
                    selectedRowsId?.length === 0
                        ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative`
                        : `cursor-pointer group w-[200px] mb-1.5 relative`
                }
            >
                <summary className="flex bg-white border border-gray-300 px-2 py-2 text-sm rounded justify-between items-center list-none">
                    <span> Bulk Selection</span>
                    <span
                        className={
                            selectedRowsId?.length === 0
                                ? "transition group-open:rotate-0"
                                : "transition group-open:rotate-180"
                        }
                    >
                        <svg
                            fill="none"
                            height={14}
                            shapeRendering="geometricPrecision"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            width={14}
                        >
                            <path d="M6 9l6 6 6-6" />
                        </svg>
                    </span>
                </summary>
                <div
                    className={
                        selectedRowsId?.length === 0
                            ? `hidden`
                            : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
                    }
                >
                    <button
                        className="mt-1 text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center"
                        onClick={() => HandleMultipleDelete()}
                    >
                        <Trash className="w-3.5 h-3.5" />
                        <span>Delete selection</span>
                    </button>
                </div>
            </details>
            <div className="w-full flex space-x-4">
                <div className="w-full space-y-3">
                    <div className="w-full overflow-x-auto">
                        <div className="w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]">
                            <DataTable
                                columns={columns}
                                data={allCompany?.records}
                                selectableRows
                                onSelectedRowsChange={({ selectedRows }) => {
                                    setSelectedRowsId(selectedRows?.map((x) => x._id));
                                }}
                                selectableRowsHighlight
                                highlightOnHover
                            />
                        </div>
                    </div>
                    {allCompany?.records && (
                        <div className="bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]">
                            <div className="hidden sm:block">
                                <p className="text-sm flex text-gray-700 space-x-4">
                                    <div>
                                        Current Page :
                                        <span className="font-semibold ms-1">
                                            {allCompany ? allCompany?.currentPage : ""}
                                        </span>
                                    </div>
                                    <div>
                                        Total Records :
                                        <span className="font-semibold ms-1">
                                            {allCompany ? allCompany?.totalRecords : ""}
                                        </span>
                                    </div>
                                </p>
                            </div>
                            <div>
                                {allCompany?.totalPages !== 1 ? (
                                    <Pagination
                                        count={allCompany?.totalPages}
                                        page={page}
                                        onChange={(e, v) => setPage(v)}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
                    <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
                        <Filter className="me-1 w-4 h-4" />
                        Filter
                    </p>
                    <hr className="border-[#a0d0eb] my-3"></hr>
                    <div className="grid gap-y-3 max-w-full overflow-hidden">
                        <div>
                            <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                                Search
                            </span>
                            <label className="relative block w-full">
                                <input
                                    className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                    placeholder="Search for anything..."
                                    type="text"
                                    name="search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </label>
                        </div>
                        <div>
                            <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                                Limit
                            </span>
                            <div className='relative'>
                                <select
                                    onChange={(e) => {
                                        setLimit(e.target.value)
                                    }}
                                    name="verification_status"
                                    className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                                    value={limit}
                                >
                                    <option value="">All</option>
                                    <option >10</option>
                                    <option >20</option>
                                    <option >50</option>
                                </select>
                                <div className='select-arrow'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllCompany