export const WebsiteData = [
    {
        name: "Blog",
        discripion: "Organize your product assortment for easy navigation and browsing.",
        redirect: "/site-data/blog"
    },
    {
        name: "Blog Categories",
        discripion: "Configure product display and reviews.",
        redirect: "/site-data/blog-category"
    },
    // {
    //     name: "Blog Comment",
    //     discripion: "Blog comments are comments, questions, and opinions that are left by readers at the end of a blog.",
    //     redirect: "/site-data/blog-comments"
    // },
]