import { GET_ACTIVE_PLAN, GET_LATEST_STORE, GET_PLAN_EXPIRED, GET_TOTAL_EXTENSION } from "../action.type";
import API from "../../API";
import { toast } from "react-toastify";



export const getLatestStores = () => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/analytics/latestStores`, method: 'get'
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_LATEST_STORE,
                    payload: ApiCall?.data
                });
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getActivePlan = () => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/analytics/totalActivePlans`, method: 'get'
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_ACTIVE_PLAN,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getPlanExpiring = () => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/analytics/planExpiring`, method: 'get'
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_PLAN_EXPIRED,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getTotalExtension = (startDate, endDate) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `super/admin/analytics/totalExtensions`, method: 'get', params: {startDate, endDate}
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_TOTAL_EXTENSION,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};