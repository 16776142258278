import { GET_ALL_STORES, GET_STORE_CUSTOMERS, GET_STORE_ORDERS, GET_STORE_PLANS, GET_STORE_PRODUCTS } from "../action.type";

const initialState = {
  allStores: null,
  allStoreProducts: null,
  allStoreOrders: null,
  allStorePlans: null,
  allStoreCustomers: null,
};

export const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STORES:
      return {
        ...state,
        allStores: action.payload.data?.data,
      };
    case GET_STORE_CUSTOMERS:
      return {
        ...state,
        allStoreCustomers: action.payload.data?.data,
      };
    case GET_STORE_PRODUCTS:
      return {
        ...state,
        allStoreProducts: action.payload.data?.data,
      };
    case GET_STORE_ORDERS:
      return {
        ...state,
        allStoreOrders: action.payload.data?.data,
      };
    case GET_STORE_PLANS:
      return {
        ...state,
        allStorePlans: action.payload.data,
      };
    default:
      return state;
  }
};
