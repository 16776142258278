import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../../API';
import ReactQuill from 'react-quill';
import { X, PlusCircle, Repeat, ChevronDown, Edit, ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { Tooltip, Autocomplete, TextField, Popover, Skeleton } from '@mui/material';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import Compressor from 'compressorjs';
import { GenerateSlugName } from '../../../controllers/GenerateSlugName';
import { CountryCodes } from '../../../Assets/StaticData/countrycode';
import _ from 'lodash';
import { Plus, QuestionCircleFill, Trash3Fill, TrashFill } from 'react-bootstrap-icons';
import Notification from '../../Notification';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import moment from 'moment-timezone';
import GenerateUniqueFilename from '../../../controllers/GenerateUniqueFilename';
import { CalendarFill } from 'react-bootstrap-icons';
import DatePicker from "react-datepicker";
import NoDataFound from '../../Main/NoDataFound';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
const UpdateDummyProduct = () => {
  const params = useParams()
  const navigate = useNavigate();
  let isPublish = /^publish=/.test(params?.slugname);
  const [productSpecs, setProductSpecs] = useState({
    productDiscount: '',
    Brands: [],
    tags: [],
    TagsArray: '',
    selectTabs: [],
  });
  const [ProductStats, setProductStats] = useState({
    selatedBundle: [],
    selatedRelated: [],
    selatedCrossell: [],
    selatedUpsell: [],
    selatedCategory: [],
  });
  const [selectBran, setSelectedBrands] = useState({ brandName: '', brandId: '' })
  const [meta, setMeta] = useState({ meta_description: '', meta_title: '' });
  const { meta_description, meta_title } = meta
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [deletePopup, setDeletePopup] = useState({ index: '', visibility: false });
  const [specificationclone, setSpecificationClone] = useState([]);
  const [description, setDescription] = useState(``);
  const [array, setarray] = useState([]);
  const [groupVariant, setGroupVarient] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [groupVariantData, setGroupVariantData] = useState([]);
  const [image, setimage] = useState(``);
  const [Documentationimage, setDocumentationimage] = useState('');
  const [Category, setCategory] = useState([]);
  const [allProduct, setAllProduct] = useState();
  const [varientGRP, setVarientGRP] = useState([]);
  // const [timeDate, setTimeDate] = useState();
  const [discountDD, setDiscountDD] = useState(false);
  const [varient, setVarient] = useState({
    group_name: '',
    group_value: '',
    price: '',
    image: '',
    quantity: '',
    sku_id: '',
  });
  const [err, setErr] = useState(false);
  let displayGroup = [];
  let groupNamesfilteration = groupVariantData?.map((x) => x.group?.map((y) => y.group_name));
  let usedGroupNames = [...new Set(groupNamesfilteration?.flat())];
  for (const groupName of usedGroupNames) {
    let groupValues = groupVariantData?.map((x) =>
      x.group?.map((y) => (y.group_name === groupName ? y.group_value : '')),
    );
    displayGroup.push({ groupName, groupValues: [...new Set(groupValues?.flat())] });
  }

  const { selatedBundle, selatedRelated, selatedCrossell, selatedUpsell, selatedCategory } =
    ProductStats;
  const { productDiscount, tags, TagsArray, selectTabs } = productSpecs;
  const [additionalProductInfo, setAdditionalProductInfo] = useState({ product_id: '', product_type: '' })
  const [UpdateData, setUpdateData] = useState({
    product_name: ``,
    category_name: ``,
    image: ``,
    product_user_manual: ``,
    additional_images: ``,
    min_order_quantity: ``,
    product_return_policy: ``,
    product_replacement_days: ``,
    sku_id: '',
    mrp: ``,
    model: '',
    video_url: '',
    selling_price: ``,
    stock_status: ``,
    quantity: ``,
    product_unit: ``,
    product_badge: '',
    country_of_origin: '',
    length: ``,
    height: ``,
    breadth: ``,
    weight: ``,
  });
  const [affiliateData, setAffiliateData] = useState({
    button_text: "",
    affiliate_url: ""
  })
  const [shortDescription, setShortDescription] = useState();
  //MUI autocomplete filter for Category
  const filterOptionsForCategory = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.category_name?.toLowerCase()} ${option?.category_slug_name?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [Discount, setDiscount] = useState({
    quantity: 0,
    discount_value: 0,
    product_discount_type: `Select discount type`,
  });
  const [spec, setSpec] = useState({
    specification_field: '',
    specification_value: '',
  });

  // Category upload image
  const [catimage, setcatimage] = useState();
  const [catloader, setCatLoader] = useState(false);

  const HandleCatImage = async (file) => {
    setCatLoader(true);
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    new Compressor(file, {
      quality: 0.6,
      async success(result) {
        const formdata = new FormData();
        formdata.append('file', result, fileName);
        formdata.append('folder', 'category');
        const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formdata});
        setCatLoader(false);
        setcatimage(res.data.data[0]);
      },
    });
  };
  const [Catdata, setCatData] = useState('');

  const HandleCatSubmit = async (e) => {
    e.preventDefault();
    let body = {
      category_name: Catdata,
      image: catimage,
    };
    if (Catdata === '' || !catimage) {
      setErr(true)
      setIsNotification(true);
      setNotificationMsg('Fill category data properly!');
      setSeverity('error');
      setErr(false)
    } else {
      try {
        let SubmitData = await API({ url: `/super/admin/dummydata/dummycategory/addDummyCategory`, method: 'post', data: body});
        if (SubmitData.status === 200 || SubmitData.status === 304) {
          GetCategory();
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }

  };

  const [addCategory, setAddCategory] = useState(false);

  // Brand upload image
  const [brandimage, setBrandimage] = useState();
  const [brandloader, setBrandLoader] = useState(false);
  const HandleManufacturerImage = async (file) => {
    setBrandLoader(true);
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    new Compressor(file, {
      quality: 0.6,
      async success(result) {
        const formdata = new FormData();
        formdata.append('file', result, fileName);
        formdata.append('folder', 'category');
        const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formdata});
        setBrandLoader(false);
        setBrandimage(res.data.data[0]);
      },
    });
  };

  const [manufacturerdata, setManufacturerData] = useState('');

  const HandleManufacturerSubmit = async (e) => {
    e.preventDefault();
    let body = {
      brand_name: manufacturerdata,
      brand_logo: brandimage,
      brand_slug_name: await GenerateSlugName(manufacturerdata),
    };
    if (manufacturerdata === '' || !brandimage) {
      setErr(true);
      setIsNotification(true);
      setNotificationMsg('Fill category data properly!');
      setSeverity('error');
    } else {
      try {
        let SubmitData = await API({ url: `/super/admin/dummydata/dummybrand/addDummyManufacturer`, method: 'post', data: body });
        if (SubmitData.status === 200 || SubmitData.status === 304) {
          // setSelectedBrands(SubmitData?.data?.data?._id);
          GetManufactures();
          setManufacturerData('');
          setBrandimage('');
          setIsNotification(true);
          setNotificationMsg("Manufacturer added successfully");
          setSeverity('success');
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
      setAddBrand(false)
    }
  };

  // MUI Autocomplete Filter options
  const filterOptionsForProduct = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.product_name?.toLowerCase()} ${option?.sku_id?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };

  const [addBrand, setAddBrand] = useState(false);

  // drag And Drop
  const DragItem = useRef();
  const DragOverItem = useRef();
  // sorting DnD
  const HandleSort = (e) => {
    e.preventDefault();
    let images_array = [...array];
    const dragItemContent = images_array.splice(DragItem.current, 1)[0];
    images_array.splice(DragOverItem.current, 0, dragItemContent);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = images_array;
    setarray(FinalArray);
  };

  // Save Tags In Array
  const HandleArray = () => {
    if (tags.includes(',')) {
      let TagSeperator = tags.split(',');
      let finalArray = [];
      let productTags = [...TagsArray, ...TagSeperator];
      productTags.filter((tag) => {
        const searchRegex = new RegExp(`^${tag.trim()}$`, 'i');
        let exists = finalArray.some((finalTag) => searchRegex.test(finalTag));
        if (!exists) {
          finalArray.push(tag.trim());
        }
      });
      setProductSpecs({ ...productSpecs, TagsArray: finalArray, tags: '' });
    } else {
      const searchRegex = new RegExp(`^${tags}$`, 'i');
      const exists = TagsArray.some((tag) => searchRegex.test(tag));
      if (!exists) {
        setProductSpecs({ ...productSpecs, TagsArray: [...TagsArray, tags], tags: '' });
      }
    }
  };

  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      // only splice array when item is found
      TagsArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setProductSpecs({ ...productSpecs, TagsArray: [...TagsArray] });
  };
  useEffect(() => {
    if (additionalProductInfo?.product_id) {
      GetProductDiscount();
    }
  }, [additionalProductInfo?.product_id])

  // get Product Discount
  const GetProductDiscount = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyproduct/getProductDiscount/${additionalProductInfo?.product_id}`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setProductSpecs({ ...productSpecs, productDiscount: data?.data?.data?.product_discount });
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (additionalProductInfo?.product_id !== '') {

      GetProductDiscount()
    }
  }, [additionalProductInfo])
  const [alltab, setAllTab] = useState();
  //get tabs from client
  const GetAllTabs = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyextratabs/GetExtraTabsForProduct`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setAllTab(data?.data?.data);
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  useEffect(() => {
    GetAllTabs();
    getPerticularProductInfo();
    GetManufactures();
    GetCategory();
    GetGroupVarients();
    GetAllProducts();
  }, []);

  // Add New Discount
  const [disErr, setDisErr] = useState(false);
  const NewProductDiscount = async (e) => {
    e.preventDefault();
    const { product_discount_type, quantity, discount_value } = Discount;
    if (
      product_discount_type === '' ||
      product_discount_type === 'Select discount type' ||
      quantity <= 0 ||
      discount_value <= 0
    ) {
      setDisErr(true);
    } else {
      try {
        const data = await API({ url: `/super/admin/dummydata/dummyproduct/addProductDiscount/${additionalProductInfo?.product_id}`, method: 'post', data: {
          ...Discount,
          start_date: startDate,
          end_date: endDate
        }});
        if (data.status === 200 || data.status === 304) {
          // GetProductDiscount();
          // setDiscountDD(false);
          setDiscount({
            quantity: 0,
            discount_value: 0,
            product_discount_type: `Select discount type`,
          })
          setStartDate(new Date())
          setEndDate(new Date())
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }

    }
  };

  // remove Product DiscountGetProductVariants
  const RemoveProductDiscount = async (e, id) => {
    e.preventDefault();
    if (window.confirm('Are You Sure To Remove This Discount?')) {
      try {
        const data = await API({ url: `/super/admin/dummydata/dummyproduct/deleteproductdiscount/${id}`, method: 'delete' });
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Discount Removed SuccessFully!');
          setSeverity('success');
          // GetProductDiscount();
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };

  const HandleBundleProduct = (id, name) => {
    if (id) {
      const pro = selatedBundle?.find((x) => x.id === id);
      if (!pro) {
        setProductStats({ ...ProductStats, selatedBundle: [...selatedBundle, { id, name }] });
      }
    }
  };

  // handleRelated Products
  const HandleRelatedProduct = (id, name) => {
    if (id) {
      const pro = selatedRelated.find((x) => x.id === id);
      if (!pro) {
        setProductStats({ ...ProductStats, selatedRelated: [...selatedRelated, { id, name }] });
      }
    }
  };
  const handleRemoverelated = (id) => {
    const removeRelated = selatedRelated.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selatedRelated: removeRelated });
  };

  const HandleUpsellProduct = (id, name) => {
    if (id) {
      const pro = selatedUpsell.find((x) => x.id === id);
      if (!pro) {
        setProductStats({ ...ProductStats, selatedUpsell: [...selatedUpsell, { id, name }] });
      }
    }
  };
  const handleRemoveUpsell = (id) => {
    GetProductVariants()
    const FilteredData = selatedUpsell.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selatedUpsell: FilteredData });
  };

  const HandleCrossellProduct = (id, name) => {
    if (id) {
      const pro = selatedCrossell.find((x) => x.id === id);
      if (!pro) {
        setProductStats({ ...ProductStats, selatedCrossell: [...selatedCrossell, { id, name }] });
      }
    }
  };
  const HandleRemoveCrossSell = (id) => {
    const FilteredData = selatedCrossell.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selatedCrossell: FilteredData });
  };

  const HandleTabs = (id, name) => {
    if (id) {
      const tabs = selectTabs.find((x) => x.id === id);
      if (!tabs) {
        setProductSpecs({ ...productSpecs, selectTabs: [...selectTabs, { id, name }] });
      }
    }
  };
  const HandleRemoveTabs = (id) => {
    const filtered = selectTabs.filter((x) => x.id !== id);
    setProductSpecs({ ...productSpecs, selectTabs: filtered });
  };
  // get Variants of the product
  const GetProductVariants = async () => {
    if(isPublish) {
      try {
        const data = await API({ url: `/super/admin/dummydata/dummyproduct/getProductVariant/${additionalProductInfo?.product_id}`, method: 'get' });
        if (data.status === 200 || data.status === 304) {
          setGroupVariantData(data.data.data);
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    } else {
      try {
        const data = await API({ url: `/super/admin/dummydata/dummyproduct/getDraftProductVariantsGroup/${additionalProductInfo?.product_id}`, method: 'get' });
        if (data.status === 200 || data.status === 304) {
          setGroupVariantData(data.data.data.variants);
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };

  const GroupValueSet = () => {
    let updatedAttribute = [];
    if (groupVariant.group_value) {
      if (attributes.length === 0) {
        setAttributes([{ ...groupVariant, group_value: [groupVariant.group_value] }]);
      } else {
        let isGroupAlreadyExists = attributes.find((x) => x.group_name === groupVariant.group_name);
        if (isGroupAlreadyExists) {
          let isGroupValueExists = isGroupAlreadyExists.group_value.find(
            (x) => x === groupVariant.group_value,
          );
          if (!isGroupValueExists) {
            updatedAttribute = attributes.filter((x) => x.group_name !== groupVariant.group_name);
            isGroupAlreadyExists.group_value.push(groupVariant.group_value);
            setAttributes([...updatedAttribute, isGroupAlreadyExists]);
          }
        } else {
          setAttributes([
            ...attributes,
            { ...groupVariant, group_value: [groupVariant.group_value] },
          ]);
        }
      }

      setGroupVarient({ ...groupVariant, group_value: '' });
    }
  };

  const groupAttrDivide = async (attributes) => {
    let attrData = [];
    for (let attributeObj of attributes) {
      attrData.push(attributeObj.group_value.map((v) => ({ [attributeObj.group_name]: v })));
    }

    if (attrData.length > 0) {
      attrData = attrData.reduce((a, b) => a.flatMap((d) => b.map((e) => ({ ...d, ...e }))));
    }

    return attrData;
  };

  const SetInsertedGroupsToAttributes = async (groupData) => {
    // Extract inserted groups from groupVariantData
    const insertedGroups = (groupVariantData && groupVariantData.filter((x) => x._id)) || [];

    // Format groupData for update
    const updatedGroups = groupData.map(({ group }) => ({ group }));

    // Generate attribute information
    const attributeInfo = DisplayVarient([...insertedGroups, ...updatedGroups]);

    // Divide attribute information into groups
    const updatedVariants = await groupAttrDivide(attributeInfo);

    // Format updatedVariants into the desired data structure
    const data = updatedVariants.map((attr) => ({
      group: Object.entries(attr).map(([group_name, group_value]) => ({ group_name, group_value })),
      new: true,
    }));

    // Step 1: Remove _id from insertedGroups
    const cleanedInsertedGroups = insertedGroups.map(({ group }) => ({ group: group.map(({ _id, ...group }) => group) }));

    // Step 2: Compare and remove inserted groups from newGroups
    const result = data.filter((newGroup) =>
      !cleanedInsertedGroups.some((insertedGroup) =>
        JSON.stringify(newGroup.group) === JSON.stringify(insertedGroup.group)
      )
    );

    // Remove duplicates from the result
    let newGroups = _.uniqWith(result, _.isEqual);


    let variantsForUniq = [];

    // Handle cases where groupVariantData has length
    if (groupVariantData?.length) {
      // Extract and filter unique variants with _id
      const removeNewlyCreatedVariantsForUniq = [...new Set(groupVariantData.map((variant) => variant._id && variant).filter((x) => x))];

      // Append non-new variants to newGroups
      for (const variant of removeNewlyCreatedVariantsForUniq) {
        newGroups.push({ ...variant, new: false });
      }

      // Find the maximum group length
      let groupMaxLength = Math.max(...newGroups.map(({ group }) => group.length));

      // Check if any group in newGroups matches any group in oldGroups; if match, update oldGroup and remove from newGroups
      for (let i = 0; i < newGroups?.length; i++) {
        const isMatchOldGroupWithNewGroup = removeNewlyCreatedVariantsForUniq?.find((oldGroup) => hasMatchingGroup(newGroups[i], oldGroup));

        if (isMatchOldGroupWithNewGroup) {
          removeNewlyCreatedVariantsForUniq[i] = isMatchOldGroupWithNewGroup;
          let copyMatch = { ...isMatchOldGroupWithNewGroup };
          delete copyMatch.group;
          delete newGroups[i].new;
          let copyNewGrp = { ...newGroups[i] };
          newGroups[i] = { ...newGroups[i], ...copyMatch };

          // Update the existing group if it's not a duplicate
          const existingIds = newGroups.filter((newgrp) => newgrp._id === copyNewGrp._id);

          if (existingIds && existingIds?.length > 1) {
            newGroups[i] = copyNewGrp;
          }
        }

        // Remove groups with a different length than the maximum
        if (newGroups[i]?.group?.length !== groupMaxLength) {
          newGroups.splice(i, 1);
        }
      }

      // Sort newGroups by _id
      newGroups = _.sortBy(newGroups, [(o) => o?._id]);

      variantsForUniq = [...newGroups];

    } else {
      variantsForUniq = [...newGroups];
    }

    // Remove duplicates from the result
    let uniqGroups = _.uniqWith(variantsForUniq, _.isEqual);
    // Update the state with the unique groups
    setGroupVariantData(uniqGroups);
  };

  // Function to check if a group in oldGroups matches the criteria in newGroup
  function hasMatchingGroup(newGroup, oldGroup) {
    // Check if all groups in oldGroup have matching counterparts in newGroup
    const allGroupsMatched = oldGroup.group.every((oldGroupItem) => {
      return newGroup.group.some(
        (newGroupItem) =>
          newGroupItem.group_name === oldGroupItem.group_name &&
          newGroupItem.group_value === oldGroupItem.group_value
      );
    });

    return allGroupsMatched;
  }




  const DisplayVarient = (variants) => {
    let grpvalue = [];
    if (variants.length > 0) {
      for (let e of variants) {
        for (const y of e.group) {
          const grp_exist = grpvalue.find((arr) => arr.group_name === y.group_name);
          if (grp_exist) {
            grpvalue.forEach((a) => {
              if (a.group_name === y.group_name) {
                const valueIndex = a.group_value.indexOf(y.group_value);
                if (valueIndex < 0) {
                  a.group_value.push(y.group_value);
                }
              }
            });
          } else {
            grpvalue.push({
              group_name: y.group_name,
              group_value: [y.group_value],
            });
          }
        }
      }
    }
    return grpvalue;
  };
  const HandleMultipleVariants = async () => {
    let attrs = await groupAttrDivide(attributes);

    const groupData = [];
    for (let attr of attrs) {
      let object = Object.keys(attr);
      let values = Object.values(attr);
      let newArr = [];
      object.map((elem, index) => {
        let obj = {
          group_name: elem,
          group_value: values[index],
        };
        newArr.push(obj);
      });
      groupData.push({
        group: newArr,
      });
    }
    await SetInsertedGroupsToAttributes(groupData);
  };

  useEffect(() => {
    GroupValueSet();
  }, [groupVariant]);
  useEffect(() => {
    if (additionalProductInfo.product_id) {
      GetProductVariants();
    }
  }, [additionalProductInfo]);
  useEffect(() => {
    HandleMultipleVariants();
  }, [attributes]);

  

  // remove item from Varient Data
  const HandleRemove = (index, e) => {
    e.preventDefault();
    const UpdateTable = groupVariantData.filter((elem, ind) => ind !== index);
    setGroupVariantData(UpdateTable);
  };

  // Remove Variant
  const HandleRemoveVarient = async (id, e) => {
    e.preventDefault();
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyproduct/deleteProductVariant/${id}`, method: 'delete' });
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Varient successfully removed!');
        setSeverity('success');
        const UpdateVariants = groupVariantData.filter(variant => String(variant._id) !== String(id));
        setGroupVariantData(UpdateVariants);
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };

  // image upload logic
  const [loader, setLoader] = useState(false);
  const [imgErr, setImgErr] = useState(false);
  const HandleImage = async (file) => {
    if (file) {
      let fileName = file?.name;
      if (!fileName) {
        fileName = await GenerateUniqueFilename();
      }
      let parts = file?.name?.split('.');
      let Ext = parts[parts.length - 1];
      if (
        Ext === 'jpg' ||
        Ext === 'png' ||
        Ext === 'jpeg' ||
        Ext === 'webp' ||
        (Ext === 'gif' && file.size < 10000000)
      ) {
        new Compressor(file, {
          quality: 50,
          async success(result) {
            setLoader(true);
            const formData = new FormData();
            formData.append('file', result, fileName);
            formData.append('folder', 'product');

            const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formData});
            setimage(res.data.data[0]);
            setImgErr(false);
            setLoader(false);
          },
        });
      } else {
        setImgErr(true);
      }
      setLoader(false);
    }
  };
  const [Documentationloader, setDocumentationLoader] = useState(false);

  const [documentationErr, setdocumentationErr] = useState(false);
  // Product Documentation
  const HandleDocumentation = async (file) => {
    setDocumentationLoader(true);
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    let parts = file?.name?.split('.');
    let Ext = parts[parts.length - 1];
    if (
      Ext === 'jpg' ||
      Ext === 'png' ||
      Ext === 'jpeg' ||
      Ext === 'webp' ||
      Ext === 'gif' ||
      (Ext === 'pdf' && file.size < 200000000)
    ) {
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'product');
      const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formData});
      setDocumentationimage(res.data.data[0]);
      setdocumentationErr(false);
    } else {
      setdocumentationErr(true);
    }
    setDocumentationLoader(false);
  };

  // for multiple Images
  const [Extraimgloader, setextraImgLoader] = useState(false);
  const HandleImages = async (imagess) => {
    if (array?.length + imagess?.length > 10) {
      setIsNotification(true);
      setNotificationMsg('you Cannot Add Greater Than 10 Images');
      setSeverity('warn');
    } else {
      setextraImgLoader(true);
      const formData = new FormData();
      for (let i of imagess) {
        let fileName = i?.name;
        if (!fileName) {
          fileName = await GenerateUniqueFilename();
        }
        formData.append('file', i, fileName);
      }
      formData.append('folder', 'product');
      const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formData});
      setarray([...array, ...res.data.data]);
      setextraImgLoader(false);
    }
  };

  // remove perticular image from selected imagesss
  const removeImage = (index) => {
    if (index > -1) {
      // only splice array when item is found
      array.splice(index, 1); // 2nd parameter means remove one item only
    }
    setarray([...array]);
  };

  const [isValidVideoLink, setisValidVideoLink] = useState(true);
  const HandleInput = (e) => {
    setErr(false);
    if (e.target.name === 'video_url') {
      setisValidVideoLink(true)
    }
    setUpdateData({ ...UpdateData, [e.target.name]: e.target.value });
  };

  // Check Video link is valid or not
  function isYouTubeLink(url) {
    const youtubeRegex = /<iframe.*?src="(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*?<\/iframe>/;
    if (!youtubeRegex.test(url)) {
      setisValidVideoLink(false)
    };
  }
  // const [brand, setBrand] = useState(row.brand ? row.brand._id : '');
  const {
    product_name,
    min_order_quantity,
    product_return_policy,
    product_replacement_days,
    sku_id,
    mrp,
    model,
    stock_status,
    status_message,
    selling_price,
    product_unit,
    country_of_origin,
    product_badge,
    video_url,
    length,
    quantity,
    height,
    breadth,
    weight,
  } = UpdateData;

  //get category
  const GetCategory = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummycategory/getDummyCategorys`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setCategory(data?.data?.data);
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  // save Category Stats
  const HandleCategory = (id, name) => {
    if (id) {
      const cat = selatedCategory.find((x) => x.id === id);
      if (!cat) {
        setProductStats({ ...ProductStats, selatedCategory: [...selatedCategory, { id, name }] });
      }
    }
  };
  const handleRemovecategory = (id) => {
    const removecat = selatedCategory.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selatedCategory: removecat });
  };
  const handleRemovebundle = (id) => {
    const removecat = selatedBundle.filter((x) => x.id !== id);
    setProductStats({ ...ProductStats, selatedBundle: removecat });
  };

  const [brandTest, setBrandTest] = useState([])
  const GetManufactures = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummybrand/getSelectedManufacturer`, method: 'get' });
      setBrandTest(data?.data)
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };

  //Edit Discount
  const DiscountChange = (diselem) => {
    navigate(`/products/${slugName}/update-discount`, { state: { diselem } });
  };

  // Update Variant
  const [Upvariant, setUpvariant] = useState({
    quantity: '',
    price: '',
    sku_id: '',
    image: '',
    selectedIndex: '',
    selectedValue: '',
    group: [],
  });
  // image upload logic
  const HandlevariantImage = async (file, index) => {
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'variant');
    const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formData });
    const img = await res.data.data[0];
    groupVariantData[index].image = img;
    setGroupVariantData([...groupVariantData]);
  };

  const [slugName, setSlugName] = useState();

  // GetProduct Info
  const [formSpinner, setFormSpinner] = useState(true)
  const [productName, setProductName] = useState('')
  const [nodata, setNodata] = useState(false)
  const getPerticularProductInfo = async () => {
    setFormSpinner(true)
    if (isPublish) {
      try {
        let ApiCall = await API({ url: `/super/admin/dummydata/dummyproduct/getDummyProduct/${params?.slugname.replace('publish=', '')}`, method: 'get' })
        setProductName(ApiCall?.data?.data?.product_name)
        setUpdateData({
          product_name: `${ApiCall?.data?.data?.product_name}`,
          category_name: `${ApiCall?.data?.data?.category}`,
          image: `${ApiCall?.data?.data?.image}`,
          video_url: ApiCall?.data?.data?.youtube_url || '',
          product_user_manual: `${ApiCall?.data?.data?.product_user_manual}`,
          additional_images: `${ApiCall?.data?.data?.additional_images}`,
          min_order_quantity: `${ApiCall?.data?.data?.min_order_quantity}`,
          product_return_policy: `${ApiCall?.data?.data?.product_return_policy}`,
          product_replacement_days:
            ApiCall?.data?.data?.product_replacement_days !== undefined ? `${ApiCall?.data?.data?.product_replacement_days}` : `7`,
          sku_id: ApiCall?.data?.data?.sku_id ? `${ApiCall?.data?.data?.sku_id}` : '',
          mrp: ApiCall?.data?.data?.mrp ? `${ApiCall?.data?.data?.mrp}` : '',
          model: ApiCall?.data?.data?.model ? `${ApiCall?.data?.data?.model}` : '',
          selling_price: ApiCall?.data?.data?.selling_price ? `${ApiCall?.data?.data?.selling_price}` : 0,
          stock_status: `${ApiCall?.data?.data?.stock_status}`,
          status_message: ApiCall?.data?.data?.status_message ? `${ApiCall?.data?.data?.status_message}` : '',
          quantity: ApiCall?.data?.data?.quantity ? `${ApiCall?.data?.data?.quantity}` : 0,
          product_unit: ApiCall?.data?.data?.product_unit && `${ApiCall?.data?.data?.product_unit}`,
          product_badge: ApiCall?.data?.data?.product_badge ? `${ApiCall?.data?.data?.product_badge}` : '',
          country_of_origin: ApiCall?.data?.data?.country_of_origin ? `${ApiCall?.data?.data?.country_of_origin}` : '',
          length: ApiCall?.data?.data?.dimension ? `${ApiCall?.data?.data?.dimension.length}` : 0.6,
          height: ApiCall?.data?.data?.dimension ? `${ApiCall?.data?.data?.dimension.height}` : 0.6,
          breadth: ApiCall?.data?.data?.dimension ? `${ApiCall?.data?.data?.dimension.breadth}` : 0.6,
          weight: ApiCall?.data?.data?.weight ? `${ApiCall?.data?.data?.weight}` : 0.6,
        })
        setAffiliateData({
          button_text: ApiCall?.data?.data?.button_text ? `${ApiCall?.data?.data?.button_text}` : '',
          affiliate_url: ApiCall?.data?.data?.affiliate_url ? `${ApiCall?.data?.data?.affiliate_url}` : ''
        })
        setShortDescription(ApiCall?.data?.data?.short_description ? `${ApiCall?.data?.data?.short_description}` : '')
        setDescription(ApiCall?.data?.data?.description ? `${ApiCall?.data?.data?.description}` : ``)
        setDocumentationimage(ApiCall?.data?.data?.product_user_manual ? `${ApiCall?.data?.data?.product_user_manual}` : '')
        setMeta({ meta_description: ApiCall?.data?.data?.meta_description, meta_title: ApiCall?.data?.data?.meta_title })
        setSelectedBrands({ brandName: ApiCall?.data?.data?.brand?.brand_name, brandId: ApiCall?.data?.data?.brand?._id })
        setSpecificationClone([...ApiCall?.data?.data?.specification])
        setarray([...ApiCall?.data?.data?.additional_images])
        setimage(ApiCall?.data?.data?.image ? `${ApiCall?.data?.data?.image}` : '')
        // setTimeDate(ApiCall?.data?.data?.product_launch_date ? new Date(ApiCall?.data?.data?.product_launch_date) : '')
        setSlugName(ApiCall?.data?.data?.product_slug_name ? ApiCall?.data?.data?.product_slug_name : ApiCall?.data?.data?.product_name)
        setAdditionalProductInfo({ product_id: ApiCall?.data?.data?._id, product_type: ApiCall?.data?.data?.product_type })
        setProductSpecs({
          productDiscount: [],
          tags: [],
          TagsArray: ApiCall?.data?.data?.product_tags,
          selectTabs: ApiCall?.data?.data?.extratabs
        })
        setProductStats({
          selatedBundle: ApiCall?.data?.data?.grouped_product_ids.map((x) => {
            return { id: x._id, name: x.product_name };
          }),
          selatedRelated: ApiCall?.data?.data?.related_products.map((x) => {
            return { id: x._id, name: x.product_name };
          }),
          selatedCrossell: ApiCall?.data?.data?.cross_sell.map((x) => {
            return { id: x._id, name: x.product_name };
          }),
          selatedUpsell: ApiCall?.data?.data?.up_sell.map((x) => {
            return { id: x._id, name: x.product_name };
          }),
          selatedCategory: ApiCall?.data?.data?.categories.map((x) => {
            return { id: x._id, name: x.category_name };
          }),
        })
        setFormSpinner(false)
        setNodata(false)
      } catch (error) {
        setNodata(true)
      }
    } else {
      try {
        let ApiCall = await API({ url: `/super/admin/dummydata/dummyproduct/getPerticularDraftProduct/${params?.slugname.replace('draft=', '')}`, method: 'get' })
        setProductName(ApiCall?.data?.draftProduct?.product_name)
        setUpdateData({
          product_name: `${ApiCall?.data?.draftProduct?.product_name}`,
          category_name: `${ApiCall?.data?.draftProduct?.dummy_category}`,
          image: `${ApiCall?.data?.draftProduct?.image}`,
          video_url: ApiCall?.data?.draftProduct?.youtube_url || '',
          product_user_manual: `${ApiCall?.data?.draftProduct?.product_user_manual}`,
          additional_images: `${ApiCall?.data?.draftProduct?.additional_images}`,
          min_order_quantity: `${ApiCall?.data?.draftProduct?.min_order_quantity}`,
          product_return_policy: `${ApiCall?.data?.draftProduct?.product_return_policy}`,
          product_replacement_days:
            ApiCall?.data?.draftProduct?.product_replacement_days !== undefined ? `${ApiCall?.data?.draftProduct?.product_replacement_days}` : `7`,
          sku_id: ApiCall?.data?.draftProduct?.sku_id ? `${ApiCall?.data?.draftProduct?.sku_id}` : '',
          mrp: `${ApiCall?.data?.draftProduct?.mrp}`,
          model: ApiCall?.data?.draftProduct?.model ? `${ApiCall?.data?.draftProduct?.model}` : '',
          selling_price: ApiCall?.data?.draftProduct?.selling_price ? ApiCall.data.draftProduct.selling_price : 0,
          stock_status: `${ApiCall?.data?.draftProduct?.stock_status}`,
          status_message: ApiCall?.data?.draftProduct?.status_message ? `${ApiCall?.data?.draftProduct?.status_message}` : '',
          quantity: ApiCall?.data?.draftProduct?.quantity ? `${ApiCall?.data?.draftProduct?.quantity}` : 0,
          product_unit: ApiCall?.data?.draftProduct?.product_unit && `${ApiCall?.data?.draftProduct?.product_unit}`,
          product_badge: ApiCall?.data?.draftProduct?.product_badge ? `${ApiCall?.data?.draftProduct?.product_badge}` : '',
          country_of_origin: ApiCall?.data?.draftProduct?.country_of_origin ? `${ApiCall?.data?.draftProduct?.country_of_origin}` : '',
          length: ApiCall?.data?.draftProduct?.dimension ? `${ApiCall?.data?.draftProduct?.dimension.length}` : 0.6,
          height: ApiCall?.data?.draftProduct?.dimension ? `${ApiCall?.data?.draftProduct?.dimension.height}` : 0.6,
          breadth: ApiCall?.data?.draftProduct?.dimension ? `${ApiCall?.data?.draftProduct?.dimension.breadth}` : 0.6,
          weight: ApiCall?.data?.draftProduct?.weight ? `${ApiCall?.data?.draftProduct?.weight}` : 0.6,
        })
        setAffiliateData({
          button_text: ApiCall?.data?.draftProduct?.button_text ? `${ApiCall?.data?.draftProduct?.button_text}` : '',
          affiliate_url: ApiCall?.data?.draftProduct?.affiliate_url ? `${ApiCall?.data?.draftProduct?.affiliate_url}` : ''
        })
        setShortDescription(ApiCall?.data?.draftProduct?.short_description ? `${ApiCall?.data?.draftProduct?.short_description}` : '')
        setDescription(ApiCall?.data?.draftProduct?.description ? `${ApiCall?.data?.draftProduct?.description}` : ``)
        setDocumentationimage(ApiCall?.data?.draftProduct?.product_user_manual ? `${ApiCall?.data?.draftProduct?.product_user_manual}` : '')
        setMeta({ meta_description: ApiCall?.data?.draftProduct?.meta_description, meta_title: ApiCall?.data?.draftProduct?.meta_title })
        setSelectedBrands({ brandName: ApiCall?.data?.draftProduct?.brand?.brand_name, brandId: ApiCall?.data?.draftProduct?.brand?._id })
        setSpecificationClone([...ApiCall?.data?.draftProduct?.specification])
        setarray([...ApiCall?.data?.draftProduct?.additional_images])
        setimage(ApiCall?.data?.draftProduct?.image ? `${ApiCall?.data?.draftProduct?.image}` : '')
        // setTimeDate(ApiCall?.data?.draftProduct?.product_launch_date ? new Date(ApiCall?.data?.draftProduct?.product_launch_date) : '')
        setSlugName(ApiCall?.data?.draftProduct?.draft_product_slug_name ? ApiCall?.data?.draftProduct?.draft_product_slug_name : ApiCall?.data?.draftProduct?.product_name)
        setAdditionalProductInfo({ product_id: ApiCall?.data?.draftProduct?._id, product_type: ApiCall?.data?.draftProduct?.product_type })
        setProductSpecs({
          productDiscount: [],
          tags: [],
          TagsArray: ApiCall?.data?.draftProduct?.product_tags,
          selectTabs: ApiCall?.data?.draftProduct?.extratabs
        })
        setProductStats({
          selatedBundle: ApiCall?.data?.draftProduct?.dummy_grouped_product_ids.map((x) => {
            return { id: x._id, name: x.product_name };
          }),
          selatedRelated: ApiCall?.data?.draftProduct?.related_products.map((x) => {
            return { id: x._id, name: x.product_name };
          }),
          selatedCrossell: ApiCall?.data?.draftProduct?.cross_sell.map((x) => {
            return { id: x._id, name: x.product_name };
          }),
          selatedUpsell: ApiCall?.data?.draftProduct?.up_sell.map((x) => {
            return { id: x._id, name: x.product_name };
          }),
          selatedCategory: ApiCall?.data?.draftProduct?.dummy_categories.map((x) => {
            return { id: x._id, name: x.category_name };
          }),
        })
        setFormSpinner(false)
        setNodata(false)
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity('error')
      }
    }
    setFormSpinner(false)
    setNodata(false)
  }


  const HandleUpdate = async (e, key) => {
    e.preventDefault();
    let body = {
      product_name: product_name,
      description:description,
      short_description: shortDescription,
      categories: selatedCategory?.map((x) => x.id),
      specification: specificationclone,
      ...(key === "publish" && { product_slug_name: slugName }),
      sku_id:sku_id,
      mrp:mrp,
      youtube_url: video_url,
      // product_launch_date: additionalProductInfo?.product_type !== "affiliate" && timeDate ? timeDate : null,
      stock_status: additionalProductInfo?.product_type !== "group" ? stock_status : 'in_stock',
      min_order_quantity:min_order_quantity,
      product_return_policy,
      product_replacement_days,
      related_products: selatedRelated?.map((x) => x.id),
      up_sell: selatedUpsell?.map((x) => x.id),
      cross_sell: selatedCrossell?.map((x) => x.id),
      extratabs: selectTabs?.map((x) => x.id),
      product_unit:product_unit,
      country_of_origin:country_of_origin,
      product_tags: TagsArray?.length > 0 ? TagsArray : [],
      selling_price: selling_price ? selling_price : 0,
      status_message:status_message,
      product_badge:product_badge,
      product_discount: productDiscount,
      meta_description:meta_description,
      product_type: additionalProductInfo?.product_type,
      meta_title:meta_title,
      dimension: { length, breadth, height },
      weight:weight,
      variants: additionalProductInfo?.product_type === "variant" ? groupVariantData : [],
      model:model,
      quantity: additionalProductInfo?.product_type !== "group" ? quantity : '',
      image: image,
      additional_images: [...array],
      ...(selectBran?.brandId ? { brand: selectBran?.brandId } : ''),
      ...(groupVariantData && groupVariantData.length === 0 ? { quantity: quantity } : ''),
    };
    if (additionalProductInfo?.product_type === "group") {
      body = {
        ...body,
        grouped_product_ids: selatedBundle?.map((x) => x.id),
      }
    } else if (additionalProductInfo?.product_type === "affiliate") {
      body = {
        ...body,
        button_text: affiliateData?.button_text,
        affiliate_url: affiliateData?.affiliate_url
      }
    } else if (Documentationimage) {
      body = {
        ...body,
        product_user_manual: Documentationimage
      }
    } else {
      body = {
        ...body
      }
    }
    if (key === "publish") {
      if (
        product_name === '' ||
        mrp === '' ||
        image === '' ||
        weight < 0.5 ||
        height < 10 ||
        breadth < 10 ||
        length < 10 ||
        (additionalProductInfo.product_type==="affiliate" ?affiliateData?.button_text==='' ||affiliateData.affiliate_url==='':false) ||
        !image || !isValidVideoLink
      ) {
        setErr(true);
        if (weight < 0.5 || height < 10 || breadth < 10 || length < 10) {
          setIsNotification(true)
          setNotificationMsg("Please enter valid value of dimention!")
          setSeverity('error')
        }
      } else {
        setErr(false)
        if (window.confirm("Are you want to publish this product?")) {
          try {
            const data = await API({ url: `/super/admin/dummydata/dummyproduct/addDummyProduct`, method: 'post', data: body});
            const deleteDraft = await API({ url: `/super/admin/dummydata/dummyproduct/deleteDraftProducts`, method: 'post', data: {
              id: [additionalProductInfo?.product_id]
            }});
            if ((data.status === 200 || data.status === 304) && (deleteDraft?.status === 200 || deleteDraft?.status === 304)) {
              navigate('/dummy-data/dummy-product');
            }
          } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity('error')
          }
        }
      }
    } else {
      
      if (isPublish) {
        if (
          product_name === '' ||
          mrp === '' ||
          image === '' ||
          weight < 0.5 ||
          height < 10 ||
          breadth < 10 ||
          length < 10 ||
          (additionalProductInfo.product_type==="affiliate" ?affiliateData?.button_text==='' ||affiliateData.affiliate_url==='':false) ||
          !image || !isValidVideoLink
        ) {
          setErr(true);
          if (weight < 0.5 || height < 10 || breadth < 10 || length < 10) {
            setIsNotification(true)
            setNotificationMsg("Please enter valid value of dimention!")
            setSeverity('error')
          }
        } else {
          try {
            setErr(false)

            const data = await API({ url: `/super/admin/dummydata/dummyproduct/updateDummyProduct/${additionalProductInfo?.product_id}`, method: 'put', data: body});
            if (data.status === 200 || data.status === 304) {
              navigate('/dummy-data/dummy-product');
            }
          } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity('error')
          }
        }
      } else {
        if (product_name === "" || mrp === '') {
          setErr(true)
        } else {
          try {
            setErr(false)
            const data = await API({ url: `/super/admin/dummydata/dummyproduct/updateDraftProducts/${additionalProductInfo?.product_id}`, method: 'put', data: body});
            if (data.status === 200 || data.status === 304) {
              navigate('/dummy-data/dummy-product');
            }
          } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity('error')
          }
        }
      }
    }
  };
  const GetAllProducts = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyproduct/GetProductsListForCrossSell`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setAllProduct(data.data.data);
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };

  const HandleDiscountChange = (e) => {
    setDiscount({ ...Discount, [e.target.name]: e.target.value });
  };

  const GetGroupVarients = async (e) => {
    try {
      const data = await API({ url: '/super/admin/dummydata/dummygroupvariant/getDummyGroupVariants', method: 'get' }); 
      if (data.status === 200 || data.status === 304) {
        setVarientGRP(data.data);
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  // add Varient Sections
  const HandleVarientChange = (e) => {
    if (e.target.value !== 'Select Value') {
      setGroupVarient({ ...groupVariant, [e.target.name]: e.target.value });
      setVarient({ ...varient, [e.target.name]: e.target.value });
    }
  };

  const addSpecification = (e) => {
    e.preventDefault();
    if (!spec.specification_field || !spec.specification_value) return;
    setSpecificationClone((prev) => [...prev, spec]);
    setSpec({ specification_field: '', specification_value: '' });
  };
  const [isSpecification, setIsSpecification] = useState();
  const [isDimention, setIsDimention] = useState();


  const inputs = document.querySelectorAll('input[type="number"]');
  for (const input of inputs) {
    input.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const [openYoutubeInfo, setOpenYoutubeInfo] = useState(false);

    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const ShortquillRef=useRef(null)
    const handleShortDescQuillChange = (value) => {
      setShortDescription(value);
    };
    const handleLongDescQuillChange=(value)=>{
      setDescription(value)
    };
  
  const [html, setHtml] = useState({htmlPP:false,code:''});
  const [descType,setDescType]=useState()
  const {htmlPP,code}=html
    const handleViewHtml = (e,type) => {
      e.preventDefault();  
      setDescType(type)
      let quill;
      if(type===1){
      quill = quillRef.current.getEditor();
      }
      else{
        quill=ShortquillRef.current.getEditor()
      }
      const htmlContent = quill.root.innerHTML
      const formattedHtml = formatCode(htmlContent);
      setHtml({htmlPP:true,code:formattedHtml}); // Log or use the HTML content as needed

    };
  
    const saveHTML=()=>{
      setHtml({...html,htmlPP:false})
      if(descType===1){
        setDescription(code)
      }
      else{
        setShortDescription(code)
      }
      setDescType()
    }
  return (
    nodata === true && !formSpinner ? <NoDataFound /> :
      <>
        <div className='h-auto overflow-y-auto relative pb-[130px]'>
          {isNotification && notificationMsg && (
            <Notification message={notificationMsg} close={handleClose} severity={severity} />
          )}
          <div>
            <Breadcrumbs />
            <h1 className='text-xl md:text-2xl font-medium text-primary'>
              Update product - {productName}
            </h1>
          </div>
          <div className='flex flex-wrap p-4'>
            <form className='w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'>
              {/* <div className='flex w-full items-center justify-end mb-2'>

              </div> */}
              {
                formSpinner ?
                  <div className='space-y-4'>
                    <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                      <div className='flex items-center space-x-4 w-full'>
                        <div className='w-full'>
                          <Skeleton className='w-[200px] mb-1' variant='rounded' />
                          <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                        </div>
                        <div className='w-full'>
                          <Skeleton className='w-[200px] mb-1' variant='rounded' />
                          <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                        </div>
                      </div>
                      <div className='w-full'>
                        <Skeleton className='w-[200px] mb-1' variant='rounded' />
                        <Skeleton className='w-full min-h-[200px]' variant='rounded' />
                      </div>
                      <div className='w-full'>
                        <Skeleton className='w-[200px] mb-1' variant='rounded' />
                        <Skeleton className='w-full min-h-[200px]' variant='rounded' />
                      </div>
                    </div>
                    <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                      <div>
                        Media
                        <p className='text-xs text-gray-500 font-normal'>Upload captivating image to make your product stand out.</p>
                      </div>

                    </div>
                    {additionalProductInfo?.product_type === 'group' ? (
                      <>
                        <div className='mb-4'>
                          <label
                            htmlFor='Description'
                          >
                            Group product
                          </label>
                          <Autocomplete
                            className="autocomplete"
                            options={allProduct?.records ? allProduct?.records : []}
                            getOptionLabel={(products) => products.product_name}
                            onChange={(e, v) => HandleBundleProduct(v?._id, v?.product_name)}
                            renderInput={(products) => (
                              <TextField placeholder='Select group Product' {...products} />
                            )}
                          />
                          <div className='flex flex-wrap mt-2'>
                            {ProductStats?.selatedBundle.map((elem) => {
                              return (
                                <div className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'>
                                  <span>{elem.name}</span>
                                  <X
                                    className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                    onClick={() => handleRemovebundle(elem.id)}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className='mb-4'>
                      <div className='flex space-x-3 mb-1 justify-between'>
                        <label
                          className='flex items-center justify-between text-gray-700 text-sm font-medium'
                          htmlFor='Description'
                        >
                          Product Short description
                        </label>
                        

                      </div>
                      <div className='w-full'>
                        <Skeleton className='w-[200px] mb-1' variant='rounded' />
                        <Skeleton className='w-full min-h-[130px]' variant='rounded' />
                      </div>
                    </div>
                  </div>
                  :
                  <div className='w-full space-y-4'>
                    <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                      <div className='w-full mb-4'>
                        <label
                          className='flex items-center justify-between text-gray-700 text-sm font-medium mb-1'
                          htmlFor='product_name'
                        >
                          Product Name
                          <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p>
                        </label>
                        <input
                          className={
                            err && product_name?.length === 0
                              ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                              : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                          }
                          type='text'
                          id='product_name'
                          name='product_name'
                          maxLength={60}
                          value={product_name}
                          onChange={HandleInput}
                          placeholder='e.g. IPhone 17'
                          onBlur={async () => setSlugName(await GenerateSlugName(product_name))}
                        />
                        {err && product_name?.length === 0 && (
                          <p className='text-red-500 text-xs font-medium'>
                            Please enter product name
                          </p>
                        )}
                      </div>
                      <div className='w-full mb-4'>
                        <label
                          className='flex text-gray-700 text-sm font-medium mb-1'
                          htmlFor='product_slug_name'
                        >
                          Product Slug Name
                        </label>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='text'
                          id='category_slug_name'
                          name='category_slug_name'
                          value={slugName}
                          disabled
                        // onChange={(e) => setSlugName(e.target.value)}
                        // onBlur={async (e) => setSlugName(await GenerateSlugName(e.target.value))}
                        />
                      </div>
                      <div className='w-full mb-4'>
                        <label>Product Type </label>
                        <div className='relative'>
                          <select
                            className='border w-full border-gray-300 px-2  text-sm cursor-pointer outline-none rounded-[10px]'
                            onChange={(e) => {
                              if (e.target.value === "single") {
                                if (window.confirm("Are You Sure To Switch? Your all Variant Data Will be lost after update.")) {
                                  let defaulVariant = groupVariantData?.find(x => x.default)
                                  setUpdateData({ ...UpdateData, selling_price: defaulVariant?.price || 0, mrp: defaulVariant?.price || 0, quantity: defaulVariant?.quantity || 0 })
                                  setAdditionalProductInfo({ ...additionalProductInfo, product_type: e.target.value })
                                }
                              } else {
                                setAdditionalProductInfo({ ...additionalProductInfo, product_type: e.target.value })
                              }
                            }}
                            key='order_status'
                            name='order_status'
                            value={additionalProductInfo.product_type}
                          >
                            <option value="single">Single product</option>
                            <option value="variant">Variant product</option>
                            <option value="group">Group product</option>
                            <option value="affiliate">Affiliate product</option>
                          </select>
                          <div className='select-arrow'></div>
                        </div>
                      </div>
                      {additionalProductInfo?.product_type === 'group' ? (
                        <>
                          <div className='mb-4'>
                            <label
                              htmlFor='Description'
                            >
                              Group product
                            </label>
                            <Autocomplete
                              className="autocomplete"
                              options={allProduct?.records ? allProduct.records : []}
                              getOptionLabel={(products) => products.product_name}
                              onChange={(e, v) => HandleBundleProduct(v?._id, v?.product_name)}
                              renderInput={(products) => (
                                <TextField placeholder='Select group Product' {...products} />
                              )}
                            />
                            <div className='flex flex-wrap mt-2'>
                              {ProductStats?.selatedBundle.map((elem) => {
                                return (
                                  <div className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'>
                                    <span>{elem.name}</span>
                                    <X
                                      className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                      onClick={() => handleRemovebundle(elem.id)}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className='mb-4'>
                      <div className='flex space-x-3 mb-1 justify-between'>
                            <label htmlFor='Description'>Product Short Description</label>
                            <div className='flex justify-between items-center'>
                            <section
                    className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                    onClick={(e)=>handleViewHtml(e,2)}
                  >
                    View HTML
                  </section>
               </div>
                          </div>
                          <ReactQuill
                            modules={module}
                            ref={ShortquillRef}
                            className='w-full h-auto rounded-[10px]'
                            theme='snow'
                            name='description'
                            value={
                              shortDescription?.length &&
                              shortDescription?.split(' ').slice(0, 280).join(' ')
                            }
                            onChange={handleShortDescQuillChange}
                            placeholder='Enter description'
                          />
                        <p className='text-right w-full text-xs mt-0 text-gray-400'>
                          of 280 words allowed
                        </p>
                      </div>
                      <div className='mb-4  '>
                      <div className='flex space-x-3 justify-between items-center mb-1'>
                            <label htmlFor='Description'>Product Long Description</label>
                            <div className='flex justify-between items-center'>
                            <section
                    className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                    onClick={(e)=>handleViewHtml(e,1)}
                  >
                    View HTML
                  </section>
               </div>
                          </div>
                          <ReactQuill
                            modules={module}
                            className='w-full h-auto'
                            ref={quillRef}
                            theme='snow'
                            name='description'
                            value={
                              description?.length &&
                              description?.split(' ').slice(0, 5000).join(' ')
                            }
                            onChange={handleLongDescQuillChange}
                            placeholder='Enter description'
                          />
                      </div>
                    </div>
                    <details open className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                      <summary
                        className='text-base flex items-center justify-between font-medium text-black'
                      >
                        <div>
                          Media
                          <p className='text-xs text-gray-500 font-normal'>Upload captivating image to make your product stand out.</p>
                        </div>
                        <span className="transition group-open:rotate-180">
                          <ChevronDown className="text-black w-4 h-5" />
                        </span>
                      </summary>
                      <div className='w-full'>
                        <div className='w-full mb-4'>
                          <label
                            className='flex text-gray-700 text-sm font-medium mb-1'
                            htmlFor='product_name'
                          >
                            Featured image
                          </label>
                          <label
                            htmlFor='dropzone-file'
                            className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded cursor-pointer hover:bg-gray-50 hover:border-gray-400 border-dashed'
                          >
                            <label className='text-center cursor-pointer'>
                              <input
                                id='dropzone-file'
                                type='file'
                                name='image'
                                onChange={(e) => {
                                  HandleImage(e.target.files[0]);
                                }}
                                className='hidden'
                              />
                              <div className=''>
                                {!image && !loader ? (
                                  <>
                                    <svg
                                      className='w-7 h-7 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                                      aria-hidden='true'
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='none'
                                      viewBox='0 0 20 16'
                                    >
                                      <path
                                        stroke='currentColor'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth={1}
                                        d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                                      />
                                    </svg>
                                    <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                                      <span className='font-medium'>Click to upload</span>
                                    </p>
                                    <p className='text-xs mb-1 text-gray-500 dark:text-gray-400'>
                                      Recommended size 800 x 800px
                                    </p>
                                    {err && !image ? (
                                      <p className='text-red-500 text-xs font-medium'>
                                        Please enter image
                                      </p>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : loader === true ? (
                                  <div className='w-[80px] mx-auto h-[80px] flex items-center justify-center mt-2 p-1 border border-gray-300'>
                                    <div className='nb-spinner'></div>
                                  </div>
                                ) : (
                                  <>
                                    <div className='w-[80px] mx-auto h-[80px] flex items-center justify-center mt-2 p-1 border border-gray-300 relative'>
                                      <img src={image} alt={image} className='max-w-full max-h-full' />
                                      <X
                                        className='text-black cursor-pointer h-4 w-4 ms-2 absolute z-50 top-0 right-0'
                                        onClick={() => setimage('')}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </label>
                            {imgErr && (
                              <span className='text-sm bg-yellow-100 p-1 rounded-[10px]'>
                                <b>NOTE: </b> File Should be in jpg, jpeg, png format Max Size 10 MB
                              </span>
                            )}
                          </label>
                        </div>
                        <div className='w-full mb-4'>
                          <label
                            className='flex text-gray-700 text-sm font-medium mb-1'
                            htmlFor='product_name'
                          >
                            Additional images
                          </label>
                          {array.length === 0 ? (
                            <>
                              <label
                                htmlFor='additional-file'
                                className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded cursor-pointer hover:bg-gray-50 hover:border-gray-400 border-dashed'
                              >
                                <div className='text-center cursor-pointer'>

                                  <input
                                    id='additional-file'
                                    type='file'
                                    name='image'
                                    multiple
                                    onChange={(e) => HandleImages(e.target.files)}
                                    className='hidden'
                                  />
                                  <div className=''>
                                    {array?.length === 0 && !Extraimgloader ? (
                                      <>
                                        <svg
                                          className='w-7 h-7 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                                          aria-hidden='true'
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 20 16'
                                        >
                                          <path
                                            stroke='currentColor'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth={1}
                                            d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                                          />
                                        </svg>
                                        <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                                          <span className='font-medium'>Click to upload</span>
                                        </p>
                                        <p className='text-xs mb-1 text-gray-500 dark:text-gray-400'>
                                          Recommended size 800 x 800px
                                        </p>
                                      </>
                                    ) : Extraimgloader === true ? (
                                      <div className='w-[80px] mx-auto h-[80px] flex items-center justify-center mt-2 p-1 border border-gray-300'>
                                        <div className='nb-spinner'></div>
                                      </div>
                                    ) : (
                                      <div className='w-[80px] overflow-hidden mx-auto h-[80px] flex items-center justify-center mt-2 p-1 border border-gray-300'>
                                        <img
                                          className='max-w-full max-h-full object-contain'
                                          src={image}
                                          alt={image}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </label>
                            </>
                          ) : (
                            <div className='flex flex-col justify-start items-center p-3 border border-gray-300 rounded cursor-pointer hover:bg-gray-50 hover:border-gray-400 border-dashed '>
                              {Extraimgloader === true ? (
                                <div className='w-full h-[100px] flex items-center justify-center'>
                                  <div className='nb-spinner'></div>
                                </div>
                              ) : (
                                array.length !== 0 && (
                                  <div className='flex w-full justify-start flex-wrap'>
                                    {array.map((x, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className='relative flex items-center justify-center mb-2 me-2 border w-[80px] h-[80px] hover:border-2 p-1 hover:border-black box-border'
                                          >
                                            <img
                                              src={x}
                                              alt={x}
                                              draggable
                                              onDragStart={(e) => (DragItem.current = index)}
                                              onDragEnter={(e) => (DragOverItem.current = index)}
                                              onDragEnd={HandleSort}
                                              onClick={() => {
                                                setDeletePopup({
                                                  index: index,
                                                  visibility: true,
                                                });
                                              }}
                                              className={
                                                deletePopup.visibility &&
                                                  deletePopup.index === index
                                                  ? 'max-w-full max-h-full object-contain cursor-move hover:border-0'
                                                  : 'max-w-full max-h-full object-contain cursor-move hover:border-0'
                                              }
                                            ></img>
                                            {deletePopup.visibility &&
                                              deletePopup.index === index && (
                                                <div className='text-white hover:border-0 border-3 top-0 right-0 absolute bg-black h-4 w-4 flex items-center justify-center'>
                                                  <X
                                                    className='text-white h-3 w-3 '
                                                    onClick={() => {
                                                      removeImage(index);
                                                      setDeletePopup({
                                                        index,
                                                        visibility: false,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        </>
                                      );
                                    })}
                                    <label className=' w-[80px] cursor-pointer h-[80px] border border-gray-300 hover:bg-gray-100 hover:border-gray-500 text-gray-500 hover:text-gray-800 rounded-[10px] flex items-center justify-center'>
                                      <div className='text-center text-sm'>
                                        <PlusCircle className='w-5 h-5 mx-auto' />
                                        <p className='text-sm'>Add more</p>
                                      </div>
                                      <input
                                        id='additional-file'
                                        type='file'
                                        name='image'
                                        multiple
                                        onChange={(e) => HandleImages(e.target.files)}
                                        className='hidden'
                                      />
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                        <div className='w-full'>
                          <label htmlFor='video_url' className='flex items-center'>
                            Youtube Video I-frame
                            <button className='ms-2' aria-describedby="youtubeinfo" variant="contained" onMouseEnter={(e) => { e.preventDefault(); setAnchorEl(e.currentTarget); setOpenYoutubeInfo(true) }}>
                              <QuestionCircleFill className='info-link cursor-pointer' />
                            </button>
                          </label>
                          <textarea
                            className={`w-full px-3 py-2 border ${!isValidVideoLink && !video_url === '' ? 'border-red-500' : 'border-gray-300'
                              } rounded-[10px] focus:outline-none`}
                            type='text'
                            id='video_url'
                            name='video_url'
                            value={video_url}
                            onChange={HandleInput}
                            onBlur={(e) => isYouTubeLink(e.target.value)}
                            placeholder='Please enter a YouTube I-frame link or Embed link.'
                          />
                          {!isValidVideoLink && !video_url === '' &&
                            <span className='text-xs text-red-500 font-medium'>Please enter valid video link</span>}
                        </div>
                      </div>
                    </details>
                    <details open className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                      <summary
                        className='text-base flex items-center justify-between font-medium text-black'
                      >
                        <div>
                          Pricing & Quantity
                          <p className='text-xs text-gray-500 font-normal'>Define pricing strategies and track stock availability.</p>
                        </div>
                      </summary>
                      <div className='flex items-center justify-between mb-4 space-x-4'>
                        <div className='w-full contryCode'>
                          <label
                            className='flex text-gray-700 text-sm font-medium mb-1'
                            htmlFor='mrp'
                          >
                            MRP
                          </label>
                          <div className='flex items-center'>
                            <p disabled className='px-4 py-2 border-y border-l border-gray-300 text-sm w-fit rounded-s-[4px]'>₹</p>
                            <input
                              className={
                                err && mrp === ''
                                  ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                  : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                              }
                              type='number'
                              min={1}
                              id='mrp'
                              name='mrp'
                              value={mrp}
                              onChange={HandleInput}
                              placeholder='₹ Enter your price'
                            />
                          </div>
                          {err && mrp === '' ? (
                            <p className='text-red-500 text-xs font-medium'>Please enter MRP</p>
                          ) : <></>}
                        </div>
                        {
                          additionalProductInfo?.product_type !== 'variant' &&
                          <div className='flex w-full items-center justify-between flex-col'>
                            <div className='w-full contryCode'>
                              <label
                                className='flex items-center text-gray-700 text-sm font-medium mb-1'
                                htmlFor='selling_price'
                              >
                                Selling price
                                <Tooltip
                                  title='This is the net sales price for your customers.'
                                  arrow
                                  placement='right'
                                  className='ml-2 -tracking-tighter'
                                >
                                  <QuestionCircleFill className='info-link cursor-pointer' />
                                </Tooltip>
                              </label>
                              <div className='flex items-center'>
                                <p disabled className='px-4 py-2 border-y border-l border-gray-300 text-sm w-fit rounded-s-[4px]'>₹</p>
                                <input
                                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                  type='number'
                                  min={1}
                                  id='selling_price'
                                  name='selling_price'
                                  value={selling_price}
                                  onChange={HandleInput}
                                  placeholder='₹ Enter your selling price'
                                />
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      <div className='flex items-center justify-between mb-4 space-x-4'>
                        {
                          additionalProductInfo?.product_type !== 'variant' && additionalProductInfo?.product_type !== 'group' &&
                          <div className='w-full'>
                            <label
                              className='flex items-center text-gray-700 text-sm font-medium mb-1'
                              htmlFor='quantity'
                            >
                              Quantity
                              <Tooltip
                                title='How many products should be available for sale?'
                                arrow
                                placement='right'
                                className='ml-2 -tracking-tighter'
                              >
                                <QuestionCircleFill className='info-link cursor-pointer' />
                              </Tooltip>
                            </label>
                            <input
                              className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                              type='number'
                              min={1}
                              id='quantity'
                              name='quantity'
                              value={quantity}
                              onChange={HandleInput}
                              placeholder='e.g. 10,200,500'
                            />
                          </div>
                        }
                        <div className='w-full'>
                          <label
                            className='block text-gray-700 text-sm font-medium mb-1'
                            htmlFor='Product Unit'
                          >
                            Product Unit
                          </label>
                          <input
                            className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                            type='text'
                            id='Product Unit'
                            name='product_unit'
                            value={product_unit}
                            onChange={HandleInput}
                            placeholder='e.g. Pieces'
                          />
                        </div>
                      </div>
                    </details>
                    {
                      additionalProductInfo?.product_type === "affiliate" &&
                      <details
                        open
                        className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                      >
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                          Affiliate Details
                          <span className='transition group-open:rotate-180'>
                            <ChevronDown className='text-black w-4 h-5' />
                          </span>
                        </summary>
                        <div className='flex items-center mb-4 space-x-4 w-full'>
                          <div className='w-full'>
                            <label className='flex items-center' htmlFor='quantity'>
                              affiliate Button text
                            </label>
                            <input
                              className={`w-full px-3 py-2 border ${err && affiliateData?.button_text===''? 'border-red-400':'border-gray-300'} rounded-[10px] focus:outline-none`}
                              type='text'
                              id='button_text'
                              name='button_text'
                              value={affiliateData?.button_text}
                              onChange={(e) => setAffiliateData({ ...affiliateData, button_text: e.target.value })}
                              placeholder='e.g. Get it Here'
                            />
                            {
                                err && affiliateData?.button_text==='' && <span className='text-xs text-red-500'>Button text is required</span>
                              }
                          </div>
                        </div>
                        <div className='flex items-center mb-4 space-x-4 w-full'>
                          <div className='w-full'>
                            <label className='flex items-center' htmlFor='quantity'>
                              affiliate url
                            </label>
                            <input
                              className={`w-full px-3 py-2 border ${err && affiliateData?.affiliate_url===''? 'border-red-400':'border-gray-300'} rounded-[10px] focus:outline-none`}
                              type='text'
                              id='affiliate_url'
                              name='affiliate_url'
                              value={affiliateData?.affiliate_url}
                              onChange={(e) => setAffiliateData({ ...affiliateData, affiliate_url: e.target.value })}
                              placeholder='e.g. Affiliate link'
                            />
                              {
                                err && affiliateData?.affiliate_url==='' && <span className='text-xs text-red-500'>Affiliate URL is required</span>
                              }
                          </div>
                        </div>
                      </details>
                    }
                    {
                      additionalProductInfo?.product_type !== 'group' &&
                      <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                        <summary
                          className='text-base flex items-center justify-between font-medium text-black'
                        >
                          <div>
                            Category & Manufacture
                            <p className='text-xs text-gray-500 font-normal'>Manage the categories and manufacture of your products</p>
                          </div>
                          <span className="transition group-open:rotate-180">
                            <ChevronDown className="text-black w-4 h-5" />
                          </span>
                        </summary>
                        <div className='max-w-full mb-4'>
                          <label
                            className='flex items-center justify-between text-gray-700 text-sm font-medium mb-1'
                            htmlFor='product_name'
                          >
                            <div className='flex items-center'>
                              Categories
                              <Tooltip
                                title='Where should the product be available on your site?'
                                arrow
                                placement='right'
                                className='ml-2 -tracking-tighter'
                              >
                                <QuestionCircleFill className='info-link cursor-pointer' />
                              </Tooltip>
                            </div>
                            <button
                              className='text-link hover:underline font-medium flex items-center rounded-[10px] space-x-2 transition-all duration-200 text-sm '
                              type='button'
                              onClick={() => setAddCategory(true)}
                            >
                              <Plus />
                              Add new category
                            </button>
                          </label>
                          <Autocomplete
                            className='autocomplete'
                            filterOptions={filterOptionsForCategory}
                            options={Category?.records ? Category.records : []}
                            getOptionLabel={(category) => category.category_name}
                            onChange={(e, v) => (v ? HandleCategory(v?._id, v?.category_name) : '')}
                            onKeyDown={e=>{if(e.key==='Enter')e.preventDefault() }}
                            renderInput={(categories) => (
                              <TextField placeholder='Select categories' {...categories} />
                            )}
                          />
                          <div className='flex flex-wrap mt-1'>
                            {selatedCategory?.map((elem) => {
                              return (
                                <div className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'>
                                  <span>{elem.name}</span>
                                  <X
                                    className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                    onClick={() => handleRemovecategory(elem.id)}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* Add new category start */}

                        {/* Add new category end */}
                        <div className='mb-4 overflow-y-auto'>
                          <label
                            className='flex items-center justify-between w-full text-gray-700 text-sm font-medium mb-1'
                            htmlFor='Description'
                          >
                            Manufacture
                            <button
                              className='text-link hover:underline font-medium flex items-center rounded-[10px] space-x-2 transition-all duration-200 text-sm '
                              type='button'
                              onClick={() => setAddBrand(true)}
                            >
                              <Plus />
                              Add new manufacture
                            </button>
                          </label>
                          {
                            <Autocomplete
                              className='autocomplete'
                              options={brandTest?.data}
                              getOptionLabel={(brand) => brand?.brand_name}
                              onChange={(e, v) =>
                                v ? setSelectedBrands({ brandName: v?.brand_name, brandId: v?._id }) : setSelectedBrands({ brandName: '', brandId: '' })
                              }
                              renderInput={(brands) => (
                                <TextField
                                  placeholder='Select manufacture'
                                  className='cursor-pointer'
                                  {...brands}
                                />
                              )}
                            />
                          }
                        </div>
                        {/* Add new Brand start */}

                        {/* Add new Brand end */}
                        {allProduct &&
                          allProduct.records.length >= 0 &&
                          additionalProductInfo?.product_type !== 'group' ? (
                          <>
                            <div className='mb-4'>
                              <label
                                className='flex text-gray-700 text-sm font-medium mb-1'
                                htmlFor='Description'
                              >
                                Related products
                              </label>

                              <Autocomplete
                                className='autocomplete'
                                filterOptions={filterOptionsForProduct}
                                options={allProduct?.records ? allProduct.records : []}
                                getOptionLabel={(products) => products.product_name}
                                onChange={(e, v) => HandleRelatedProduct(v?._id, v?.product_name)}
                                renderInput={(products) => (
                                  <TextField placeholder='Select related products' {...products} />
                                )}
                              />
                              <div className='flex flex-wrap mt-1'>
                                {ProductStats?.selatedRelated?.map((elem) => {
                                  return (
                                    <div className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'>
                                      <span>{elem.name}</span>
                                      <X
                                        className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                        onClick={() => handleRemoverelated(elem.id)}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </details>
                    }
                    <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                      <summary
                        className='text-base flex items-center justify-between font-medium text-black'
                      >
                        <div>
                          Details
                          <p className='text-xs text-gray-500 font-normal'>Add additional information of product</p>
                        </div>
                        <span className="transition group-open:rotate-180">
                          <ChevronDown className="text-black w-4 h-5" />
                        </span>
                      </summary>
                      <div className='w-full'>
                        <>
                          <div className='flex items-center justify-between grid-cols-3 space-x-4 mb-4'>
                            <div className='w-full'>
                              <label
                                className='flex text-gray-700 text-sm font-medium mb-1'
                                htmlFor='SKU ID'
                              >
                                Sku Id
                              </label>
                              <input
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='text'
                                id='sku_id'
                                name='sku_id'
                                value={sku_id}
                                onChange={HandleInput}
                                placeholder='e.g. 10123'
                              />
                            </div>
                            <div className='w-full'>
                              <label
                                className='flex items-center text-gray-700 text-sm font-medium mb-1'
                                htmlFor='min_order_quantity'
                              >
                                Minimum Order Quantity
                                <Tooltip
                                  title='The minimum quantity required to buy this product (set to 1 to disable this feature). E.g. if set to 3, customers will be able to purchase the product only if they take at least 3 in quantity.'
                                  arrow
                                  placement='right'
                                  className='ml-2 -tracking-tighter'
                                >
                                  <QuestionCircleFill className='info-link cursor-pointer' />
                                </Tooltip>
                              </label>
                              <input
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='number'
                                min={1}
                                id='min_order_quantity'
                                name='min_order_quantity'
                                value={min_order_quantity}
                                onChange={HandleInput}
                                placeholder='e.g. 1'
                              />
                            </div>
                          </div>
                          <div className='flex items-center justify-between grid-cols-3 space-x-4 mb-4'>
                            <div className='w-full'>
                              <label
                                className='block text-gray-700 text-sm font-medium mb-1'
                                htmlFor='model'
                              >
                                Model
                              </label>
                              <input
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='text'
                                id='model'
                                name='model'
                                value={model}
                                onChange={HandleInput}
                                placeholder='e.g. Model name'
                              />
                            </div>
                            <div className='w-full'>
                              <label
                                className='block text-gray-700 text-sm font-medium mb-1'
                                htmlFor='product_badge'
                              >
                                Product Badge
                              </label>
                              <input
                                type='text'
                                maxLength={20}
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                name='product_badge'
                                value={product_badge}
                                onChange={HandleInput}
                                placeholder='e.g. Product Of The Month'
                              />
                            </div>
                            <div className='w-full'>
                              <label
                                className='block text-gray-700 text-sm font-medium mb-1'
                                htmlFor='CountryOfOrigin'
                              >
                                Country Of Origin
                              </label>
                              <div>
                                <Autocomplete
                                  className='autocomplete cursor-pointer'
                                  options={CountryCodes ? CountryCodes : []}
                                  getOptionLabel={(x) => x.name}
                                  onChange={(e, v) =>
                                    setUpdateData({
                                      ...UpdateData,
                                      country_of_origin: v?.name ? v.name : '',
                                    })
                                  }
                                  // value={CountryCodes?.filter((x) => x.name === UpdateData?.country_of_origin)?.name}
                                  renderInput={(name) => (
                                    <TextField
                                      placeholder='Country of origin'
                                      defaultValue='IN'
                                      className='select-none cursor-pointer border-black'
                                      {...name}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='flex justify-between space-x-4 mb-4 w-full'>
                            {
                              additionalProductInfo?.product_type !== 'group' &&
                              <div className='w-full'>
                                <label
                                  className='block text-gray-700 text-sm font-medium mb-1'
                                  htmlFor='Stock'
                                >
                                  Stock Status
                                </label>
                                <div className='relative'>
                                  <select
                                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] outline-none'
                                    name='stock_status'
                                    value={stock_status}
                                    onChange={HandleInput}
                                  >
                                    <option selected>in_stock</option>
                                    <option>out_of_stock</option>
                                    <option>available</option>
                                  </select>
                                  <div className='select-arrow'></div>
                                </div>
                              </div>
                            }
                            <div className='w-full'>
                              <label
                                className='block text-gray-700 text-sm font-medium mb-1'
                                htmlFor='status_message'
                              >
                                Status Message
                              </label>
                              <input
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='text'
                                name='status_message'
                                value={status_message}
                                onChange={HandleInput}
                                placeholder='e.g. Product Stock will be Available In 7 Days'
                              />
                            </div>
                            <div className='w-full'>
                              <label
                                className='block text-gray-700 text-sm font-medium mb-1'
                                htmlFor='product_return_policy'
                              >
                                Return Status
                              </label>
                              <div className='relative'>
                                <select
                                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] outline-none'
                                  name='product_return_policy'
                                  value={product_return_policy}
                                  onChange={HandleInput}
                                >
                                  <option>non_returnable</option>
                                  <option>returnable</option>
                                </select>
                                <div className='select-arrow'></div>
                              </div>
                            </div>
                            {product_return_policy === 'non_returnable' ? (
                              <></>
                            ) : (
                              <div className='w-full'>
                                <label
                                  className='block text-gray-700 text-sm font-medium mb-1'
                                  htmlFor='product_replacement_days'
                                >
                                  Replacement validity
                                </label>

                                <input
                                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                  type='number'
                                  id='product_replacement_days'
                                  name='product_replacement_days'
                                  value={product_replacement_days}
                                  onChange={HandleInput}
                                  placeholder='e.g. 2'
                                />
                              </div>
                            )}

                             
                            </div>
                          </>
                          <div className='flex justify-between space-x-4 mb-4 w-full'>
                                    <div className='w-full'>
                                  <label htmlFor='product_name'>Upsell Products</label>
                                  <Autocomplete
                                    className='autocomplete'
                                    filterOptions={filterOptionsForProduct}
                                    options={allProduct?.records ? allProduct.records : []}
                                    getOptionLabel={(product) => product.product_name}
                                    onChange={(e, v) => HandleUpsellProduct(v?._id, v?.product_name)}
                                    renderInput={(products) => (
                                      <TextField placeholder='Select upsell products' {...products} />
                                    )}
                                  />
                                  <div className='flex flex-wrap mt-1'>
                                    {ProductStats?.selatedUpsell?.map((elem, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                                        >
                                          <span>{elem.name}</span>
                                          <X
                                            className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                            onClick={() => handleRemoveUpsell(elem.id)}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className='w-full'>
                                  <label htmlFor='product_name'>Cross Products</label>
                                  <Autocomplete
                                    className='autocomplete'
                                    filterOptions={filterOptionsForProduct}
                                    options={allProduct?.records ? allProduct.records : []}
                                    getOptionLabel={(product) => product.product_name}
                                    onChange={(e, v) =>
                                      HandleCrossellProduct(v?._id, v?.product_name)
                                    }
                                    renderInput={(products) => (
                                      <TextField
                                        placeholder='Select cross sell products'
                                        {...products}
                                      />
                                    )}
                                  />
                                  <div className='flex flex-wrap mt-1'>
                                    {ProductStats?.selatedCrossell?.map((elem, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                                        >
                                          <span>{elem.name}</span>
                                          <X
                                            className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                            onClick={() => HandleRemoveCrossSell(elem.id)}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                          {alltab?.records ? (
                                <div className='w-full flex items-center justify-between grid-cols-2 space-x-4 '>
                                  <div className='w-full '>
                                    <label htmlFor='product_name'>Custom Tabs</label>
                                    <Autocomplete
                                      className='autocomplete'
                                      options={alltab?.records ? alltab.records : []}
                                      getOptionLabel={(tab) => tab?.tab_title}
                                      onChange={(e, v) => HandleTabs(v?._id, v?.tab_title)}
                                      renderInput={(tabs) => (
                                        <TextField placeholder='Select custom tabs' {...tabs} />
                                      )}
                                    />
                                    <div className='flex flex-wrap mt-1'>
                                      {selectTabs?.map((elem, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                                          >
                                            <span className='line-clamp-1'>{elem?.tab_title}</span>
                                            <X
                                              className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                              onClick={() => HandleRemoveTabs(elem.id)}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                          </div>
                                            
                          {/* documentation */}
                          <div className='w-full xl:w-full mb-4'>
                            <label
                              className='flex text-gray-700 text-sm font-medium mb-2'
                              htmlFor='product_name'
                            >
                              Product Documentation
                            </label>
                            <label
                              htmlFor='dropzone-file'
                              className='flex flex-col items-center p-3 justify-center border-2 border-gray-200 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
                            >
                              <label className='text-center cursor-pointer'>
                                <svg
                                  className='w-8 h-8 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 20 16'
                                >
                                  <path
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth={2}
                                    d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                                  />
                                </svg>
                                <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                                  <span className='font-medium'>Click to upload</span> or drag and
                                  drop
                                </p>
                                <p className='text-xs mb-1 text-gray-500 dark:text-gray-400'>
                                  PDF, SVG, PNG, JPG or GIF
                                </p>
                                <input
                                  type='file'
                                  name='image'
                                  onChange={(e) => HandleDocumentation(e.target.files[0])}
                                  className='hidden'
                                />
                                <div className=''>
                                  {!Documentationimage && !Documentationloader ? (
                                    <img
                                      src={NOPHOTO_IMAGE}
                                      alt={NOPHOTO_IMAGE}
                                      className='w-24 border mx-auto border-gray-300 p-1 mt-2'
                                    />
                                  ) : Documentationloader === true ? (
                                    <div className='nb-spinner'></div>
                                  ) : (
                                    <img
                                      className='w-24 border mx-auto p-1 mt-2 border-gray-300'
                                      src={Documentationimage}
                                      alt={Documentationimage}
                                    />
                                  )}
                                </div>
                              </label>
                              {documentationErr && (
                                <span className='text-sm bg-yellow-100 p-1 rounded-[10px]'>
                                  <b>NOTE: </b> File Should be in jpg, jpeg, png, pdf format Max Size 10
                                  MB
                                </span>
                              )}
                            </label>
                          </div>
                          {/* coutdown */}
                          {/* {
                            additionalProductInfo?.product_type !== "affiliate" &&
                            <div className='w-full mb-4'>
                              <label
                                className='block text-gray-700 text-sm font-medium'
                                htmlFor='CountryOfOrigin'
                              >
                                Product Launch Date
                              </label>
                              <label className='relative w-full block cursor-pointer'>
                                <DatePicker
                                  className='border border-gray-300 focus:outline-none focus:border-gray-400 w-full'
                                  showTimeSelect
                                  placeholderText='Select product launch date'
                                  minTime={new Date(0, 0, 0, 12, 30)}
                                  maxTime={new Date(0, 0, 0, 19, 0)}
                                  selected={timeDate}
                                  onChange={(date) => setTimeDate(date)}
                                  dateFormat='MMMM d, yyyy h:mm aa'
                                />
                                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                                  <CalendarFill className='text-gray-600 w-3 h-3' />
                                </span>
                              </label>
                            </div>
                          } */}
                          <div className='mb-4'>
                            <div className='flex items-center justify-between'>
                              <label htmlFor='Description'>
                                product Tags
                              </label>
                              <p className='text-gray-500 font-normal text-xs'>
                                Use <b className='text-black'>Enter</b> key to separate the tags
                              </p>
                            </div>
                            <input
                              className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                              type='text'
                              id='product_tags'
                              name='product_tags'
                              value={tags}
                              onChange={(e) =>
                                setProductSpecs({ ...productSpecs, tags: e.target.value })
                              }
                              onKeyDown={(e) => (e.key === 'Enter' ? (e.preventDefault(), HandleArray()) : '')}
                              placeholder='e.g. ProductTag1,Tag2'
                            />
                            <div className='flex flex-wrap space-x-2 mt-1 items-center'>
                              {TagsArray &&
                                TagsArray.map((elem, index) => {
                                  return (
                                    <div key={index} className='flex items-center'>
                                      {elem !== '' ? (
                                        <span className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'>
                                          {elem}
                                          <X
                                            className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                            onClick={() => RemoveTag(index)}
                                          />
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <div className='mb-4'>
                            <div className='w-full px-3 py-2 border border-gray-300 rounded-[10px]'>
                              <label
                                className='block text-gray-700 text-sm font-medium'
                                htmlFor='specification'
                              >
                                Product Specification
                              </label>
                              <hr className='border-gray-200 my-3'></hr>
                              {!isSpecification && (
                                <p
                                  className='flex items-center space-x-2 ml-4 text-link font-medium hover:underline cursor-pointer'
                                  onClick={() => setIsSpecification(true)}
                                >
                                  <Plus />
                                  Add specification option
                                </p>
                              )}
                              {isSpecification && (
                                <>
                                  <div className='mb-4'>
                                    <>
                                      <label
                                        className='block text-gray-700 text-sm font-medium mb-2'
                                        htmlFor='specification'
                                      >
                                        Specification
                                      </label>
                                      <form>
                                        <div className='flex mb-1'>
                                          <textarea
                                            className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none mr-2'
                                            type='text'
                                            id='specification_field'
                                            name='specification_field'
                                            value={spec.specification_field}
                                            onChange={(e) =>
                                              setSpec({
                                                ...spec,
                                                specification_field: e.target.value,
                                              })
                                            }
                                            placeholder='e.g. battery'
                                            required
                                          />
                                          <textarea
                                            className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none mr-2'
                                            type='text'
                                            id='specification_value'
                                            name='specification_value'
                                            value={spec.specification_value}
                                            onChange={(e) =>
                                              setSpec({
                                                ...spec,
                                                specification_value: e.target.value,
                                              })
                                            }
                                            placeholder='e.g. 5000mh'
                                            required
                                          />
                                          <Tooltip title='Remove' arrow>
                                            <button onClick={() => setIsSpecification(false)}>
                                              <X className='w-4 h-4 cursor-pointer' />
                                            </button>
                                          </Tooltip>
                                        </div>
                                        <button
                                          type='button'
                                          onClick={(e) => addSpecification(e)}
                                          className='inline-block btn text-white text-sm font-medium py-2 mt-2 px-8 rounded-[10px] transition duration-300'
                                        >
                                          Add specification
                                        </button>
                                      </form>
                                    </>
                                  </div>
                                </>
                              )}
                              <div className='mt-3 space-y-1'>
                                <div>
                                  {specificationclone.map((elem, index) => {
                                    return (
                                      <>
                                        <div className='flex'>
                                          <input
                                            key={index}
                                            type='text'
                                            name='specification_field'
                                            className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none mr-2 mt-1'
                                            defaultValue={elem.specification_field}
                                            onChange={(e) =>
                                            (specificationclone[index].specification_field =
                                              e.target.value)
                                            }
                                          />
                                          <input
                                            key={index}
                                            type='text'
                                            name='specification_value'
                                            className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none mr-2 mt-1'
                                            defaultValue={elem.specification_value}
                                            onChange={(e) =>
                                            (specificationclone[index].specification_value =
                                              e.target.value)
                                            }
                                          />
                                          <div>
                                            <TrashFill
                                              className='cursor-pointer w-4 h-4 text-gray-500 mt-3'
                                              onClick={() => {
                                                const selectedSpec = specificationclone.filter(
                                                  (val, key) => key !== index,
                                                );
                                                setSpecificationClone(selectedSpec);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mb-4'>
                            <div className='w-full px-3 py-2 border border-gray-300 rounded-[10px]'>
                              <div className='flex items-center justify-between'>
                                <label htmlFor='specification'>Product Dimensions</label>
                                {isDimention && (
                                  <Tooltip title='Remove' arrow>
                                    <button onClick={() => setIsDimention(false)}>
                                      <X className='w-4 h-4 cursor-pointer' />
                                    </button>
                                  </Tooltip>
                                )}
                              </div>
                              <hr className='border-gray-200 my-3'></hr>
                              {!isDimention && (
                                <p
                                  className='flex items-center space-x-2 ml-4 text-link font-medium hover:underline cursor-pointer'
                                  onClick={() => setIsDimention(true)}
                                >
                                  <Plus />
                                  Add dimension option
                                </p>
                              )}
                              {isDimention && (
                                <>
                                  <div className='flex items-center justify-between grid-cols-2 space-x-4 mb-4'>
                                    <div className='w-full'>
                                      <label htmlFor='length'>Length (in cm)</label>
                                      <input
                                        className={
                                          err && length < 10
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                        }
                                        type='number'
                                        min={1}
                                        id='length'
                                        name='length'
                                        value={length}
                                        onChange={HandleInput}
                                        placeholder='e.g. 100'
                                      />
                                      {err && length < 10 ? (
                                        <p className='text-red-500 text-xs font-medium'>
                                          length must be greater than or equal or equal to 10
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className='w-full'>
                                      <label htmlFor='height'>Height (in cm)</label>
                                      <input
                                        className={
                                          err && height < 10
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                        }
                                        type='number'
                                        min={1}
                                        id='height'
                                        name='height'
                                        value={height}
                                        onChange={HandleInput}
                                        placeholder='e.g. 100'
                                      />
                                      {err && height < 10 ? (
                                        <p className='text-red-500 text-xs font-medium'>
                                          Height must be greater than or equal 10
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  <div className='flex items-center justify-between grid-cols-2 space-x-4 mb-4'>
                                    <div className='w-full'>
                                      <label htmlFor='breadth'>Breadth (in cm)</label>
                                      <input
                                        className={
                                          err && breadth < 10
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                        }
                                        type='number'
                                        min={1}
                                        id='breadth'
                                        name='breadth'
                                        value={breadth}
                                        onChange={HandleInput}
                                        placeholder='e.g. 100'
                                      />
                                      {err && breadth < 10 ? (
                                        <p className='text-red-500 text-xs font-medium'>
                                          Breadth must be greater than or equal 10
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className='w-full'>
                                      <label htmlFor='weight'>Weight (in kg)</label>
                                      <input
                                        className={
                                          err && weight < 0.5
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                        }
                                        type='number'
                                        min={1}
                                        id='weight'
                                        name='weight'
                                        value={weight}
                                        onChange={HandleInput}
                                        placeholder='e.g. 1'
                                      />
                                      {err && weight < 0.5 ? (
                                        <p className='text-red-500 text-xs font-medium'>
                                          Weight must be greater than or equal 0.5
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                       
                          </div>
                      
                    </details>
                    {additionalProductInfo?.product_type === 'variant' &&
                      <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                        <summary
                          className='text-base flex items-center justify-between font-medium text-black'
                        >
                          <div>
                            Variants
                            <p className='text-xs text-gray-500 font-normal'>Enhance your product catalog with variants</p>
                          </div>
                          <span className="transition group-open:rotate-180">
                            <ChevronDown className="text-black w-4 h-5" />
                          </span>
                        </summary>
                        <>
                          <div className='mb-4'>
                            <label htmlFor='group_name'>Group name</label>
                            <div className='relative'>
                              <select
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                id='group_name'
                                name='group_name'
                                value={groupVariant.group_name}
                                onChange={HandleVarientChange}
                                placeholder='e.g. blue,black,red'
                              >
                                <option>Please select</option>

                                {varientGRP.data &&
                                  varientGRP.data.records.map((elem, index) => {
                                    return (
                                      <>
                                        <option key={index}>{elem.group_name}</option>
                                      </>
                                    );
                                  })}
                              </select>
                              <div className='select-arrow'></div>
                            </div>
                          </div>
                          <div className='mb-4'>
                            <label htmlFor='group_value'>Group value</label>
                            <div className='relative'>
                              <select
                                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                id='group_value'
                                name='group_value'
                                value={groupVariant.group_value}
                                onChange={HandleVarientChange}
                                placeholder='e.g. blue,black,red'
                              >
                                <option>Select Group Value</option>
                                {varientGRP.data &&
                                  varientGRP.data.records.map((x) => {
                                    if (x.group_name === groupVariant.group_name) {
                                      return (
                                        x.group_value &&
                                        x.group_value.map((y, index) => {
                                          return (
                                            <>
                                              <option key={index}>{y}</option>
                                            </>
                                          );
                                        })
                                      );
                                    }
                                  })}
                              </select>
                              <div className='select-arrow'></div>
                            </div>
                          </div>
                          {groupVariantData && groupVariantData.length > 0 ? (
                            <div className='varient-table'>
                              <div className='flex flex-col'>
                                <div className='overflow-x-auto'>
                                  <div className='inline-block min-w-full py-2'>
                                    <div className='overflow-hidden'>
                                      <table className='w-full border border-gray-300 text-center text-sm font-light dark:border-neutral-500  bg-white'>
                                        <thead className='border-b border-gray-300 font-medium dark:border-neutral-500 bg-[#f1f1f1]'>
                                          <tr>
                                            <th
                                              scope='col'
                                              className='border-r border-gray-300 px-6 py-4 dark:border-neutral-500'
                                            >
                                              Variant
                                            </th>
                                            <th
                                              scope='col'
                                              className='border-r border-gray-300 px-6 py-4 dark:border-neutral-500'
                                            >
                                              Image
                                            </th>
                                            <th
                                              scope='col'
                                              className='border-r border-gray-300 px-6 py-4 dark:border-neutral-500'
                                            >
                                              Price
                                            </th>
                                            <th
                                              scope='col'
                                              className='border-r border-gray-300 px-6 py-4 dark:border-neutral-500'
                                            >
                                              Quantity
                                            </th>

                                            <th
                                              scope='col'
                                              className='border-r border-gray-300 px-6 py-4 dark:border-neutral-500'
                                            >
                                              Sku id
                                            </th>
                                            <th
                                              scope='col'
                                              className='border-r border-gray-300 px-6 py-4 dark:border-neutral-500'
                                            >
                                              default
                                            </th>
                                            <th
                                              scope='col'
                                              className='border-r border-gray-300 px-6 py-4 dark:border-neutral-500'
                                            >
                                              Remove
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {groupVariantData.map((data, index) => {
                                            return (
                                              <tr
                                                key={index}
                                                className='border-b dark:border-neutral-500'
                                              >
                                                <td className='whitespace-nowrap flex items-center border-r border-gray-300 px-6 py-4 dark:border-neutral-500 font-medium capitalize'>
                                                  {data.group.map((x, i) => {
                                                    return i === 0
                                                      ? `${x.group_value}`
                                                      : ` / ${x.group_value}`;
                                                  })}
                                                  {data.new && <p className='bg-blue-100 inline-block rounded-lg text-sm px-2 py-1 ms-2'>New</p>}
                                                </td>
                                                <td className='whitespace-nowrap border-r border-gray-300 p-1 dark:border-neutral-500 font-medium'>
                                                  <div className='relative w-full h-full'>
                                                    <label
                                                      htmlFor={`file-input-${index}`} // Use a unique id for each file input
                                                      className='relative border border-gray-300 w-full h-full cursor-pointer '
                                                    >
                                                      <input
                                                        type='file'
                                                        id={`file-input-${index}`} // Use a unique id for each file input
                                                        className='hidden'
                                                        onChange={async (e) => {await HandlevariantImage(e.target.files[0], index)}}
                                                      />
                                                      <div className='text-white hover:border-0 border-3 top-0 right-0 absolute bg-black h-4 w-4 flex items-center justify-center'>
                                                        <Repeat className='text-white stroke-white h-3 w-3' />
                                                      </div>
                                                    </label>
                                                    <img className='w-36 h-full' src={data?.image || NOPHOTO_IMAGE} alt={data?.image || NOPHOTO_IMAGE}/>
                                                  </div>
                                                </td>
                                                <td className='whitespace-nowrap border-r border-gray-300 px-6 py-4 dark:border-neutral-500 font-medium capitalize'>
                                                  <input
                                                    type='number'
                                                    min={1}
                                                    placeholder='e.g. 500'
                                                    defaultValue={data.price}
                                                    onChange={(e) =>
                                                      (groupVariantData[index].price = e.target.value)
                                                    }
                                                    className=' p-2 border border-gray-300 bg-[#f1f1f1]'
                                                  />
                                                </td>
                                                <td className='whitespace-nowrap border-r border-gray-300 px-6 py-4 dark:border-neutral-500 font-medium'>
                                                  <input
                                                    type='number'
                                                    min={1}
                                                    placeholder='Quantity'
                                                    defaultValue={data.quantity}
                                                    onChange={(e) =>
                                                      (groupVariantData[index].quantity = e.target.value)
                                                    }
                                                    className=' p-2 border border-gray-300 bg-[#f1f1f1]'
                                                  />
                                                </td>
                                                <td className='whitespace-nowrap border-r border-gray-300 px-6 py-4 dark:border-neutral-500 font-medium'>
                                                  <input
                                                    type='text'
                                                    placeholder='Sku Id'
                                                    defaultValue={data.sku_id}
                                                    onChange={(e) =>
                                                      (groupVariantData[index].sku_id = e.target.value)
                                                    }
                                                    className=' p-2 border border-gray-300 bg-[#f1f1f1]'
                                                  />
                                                </td>
                                                <td className='whitespace-nowrap border-r border-gray-300 px-6 py-4 dark:border-neutral-500 font-medium'>
                                                  <input
                                                    type='radio'
                                                    name='defaultVar'
                                                    placeholder='Quantity'
                                                    defaultChecked={data.default}
                                                    onClick={(e) => {
                                                      setGroupVariantData((prevState) =>
                                                        prevState.map((obj) => ({
                                                          ...obj,
                                                          default: obj._id === groupVariantData[index]._id ? true : false
                                                        }))
                                                      );

                                                    }}
                                                    className=' p-2 border border-gray-300 bg-[#f1f1f1]'
                                                  />
                                                </td>

                                                <td className='whitespace-nowrap border-r border-gray-300 px-6 py-4 dark:border-neutral-500 font-medium'>

                                                  {groupVariantData?.length > 1 &&
                                                    data?._id ?

                                                    <button
                                                      href='#'
                                                      className='bg-red-500 p-2 text-white hover:shadow-lg text-xs font-medium'
                                                      onClick={(e) => HandleRemoveVarient(data?._id, e)}
                                                    >
                                                      Remove
                                                    </button>

                                                    : groupVariantData?.length > 1 &&

                                                    <button
                                                      href='#'
                                                      className='bg-red-500 p-2 text-white hover:shadow-lg text-xs font-medium'
                                                      onClick={(e) => HandleRemove(index, e)}
                                                    >
                                                      Remove
                                                    </button>
                                                  }
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      </details>}
                    {
                      additionalProductInfo?.product_type === 'single' &&
                      <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                        <summary
                          className='text-base flex items-center justify-between font-medium text-black'
                        >
                          <div>
                            Discounts
                            <p className='text-xs text-gray-500 font-normal'>Unlock customer's savings with exclusive discounts</p>
                          </div>
                          <span className="transition group-open:rotate-180">
                            <ChevronDown className="text-black w-4 h-5" />
                          </span>
                        </summary>
                        <>
                          <div className='flex justify-end w-full my-3'>
                            <button
                              className='inline-block secondary-btn text-white text-sm font-medium py-2 px-8 rounded-[10px] transition duration-300'
                              onClick={(e) => { e.preventDefault(); setDiscountDD(!discountDD) }}
                            >
                              + Add Discounts
                            </button>
                          </div>
                          <div>
                            <div>
                              {discountDD && (
                                <div className='border-b border-gray-300 pb-1 mb-4'>
                                  <div className='mb-4'>
                                    <label htmlFor='quantity'>Product Discount Quantity</label>
                                    <input
                                      className={`w-full px-3 py-2 ${disErr && Discount.quantity <= 0
                                        ? 'border-2 border-red-500 '
                                        : 'border border-gray-300 '
                                        } rounded-[10px] focus:outline-none `}
                                      type='number'
                                      min={1}
                                      id='quantity'
                                      name='quantity'
                                      value={Discount.quantity}
                                      onChange={HandleDiscountChange}
                                      placeholder='e.g. 1,5,10'
                                    />
                                    {disErr && Discount.quantity <= 0 && (
                                      <p className='text-red-500 text-xs font-medium'>
                                        Please enter valid discount quantity
                                      </p>
                                    )}
                                  </div>



                                  <div className='flex items-center justify-between mb-4 space-x-4'>
                                    <div className='w-full'>
                                      <label htmlFor='product_discount_type'>Product Discount type</label>
                                      <div className='relative'>
                                        <select
                                          className={`w-full px-3 py-2 ${disErr &&
                                            Discount.product_discount_type === 'Select discount type'
                                            ? 'border-2 border-red-500 '
                                            : 'border border-gray-300 '
                                            } rounded-[10px] focus:outline-none `}
                                          name='product_discount_type'
                                          value={Discount?.product_discount_type}
                                          onChange={HandleDiscountChange}
                                        >
                                          <option>Select discount type</option>
                                          <option>amount</option>
                                          <option>percentage</option>
                                        </select>
                                        <div className='select-arrow'></div>
                                      </div>
                                      {disErr &&
                                        Discount.product_discount_type === 'Select discount type' && (
                                          <p className='text-red-500 text-xs font-medium'>
                                            Please Select valid discount type
                                          </p>
                                        )}
                                    </div>
                                    <div className='w-full contryCode'>
                                      <label htmlFor='discount_value'>
                                        Product Discount Price/Percentage
                                      </label>
                                      <div className='flex items-center'>
                                        <p disabled className='px-4 py-2 border-y border-l border-gray-300 text-sm w-fit rounded-s-[4px]'>{Discount?.product_discount_type === 'percentage' ? '%' : '₹'}</p>
                                        <input
                                          className={`w-full px-3 py-2 ${disErr && (Discount.discount_value <= 0 || selling_price < Discount.discount_value)
                                            ? 'border-2 border-red-500 '
                                            : 'border border-gray-300 '
                                            } rounded-[10px] focus:outline-none `}
                                          type='number'
                                          min={1}
                                          id='discount_value'
                                          name='discount_value'
                                          value={Discount.discount_value}
                                          onBlur={(e) => {
                                            if (selling_price < e.target.value) {
                                              setDisErr(true)
                                            }
                                          }}
                                          onChange={HandleDiscountChange}
                                          placeholder='Enter your discount price/percentage'
                                        />
                                      </div>
                                      {disErr && (Discount.discount_value <= 0 || selling_price < Discount.discount_value) && (
                                        <p className='text-red-500 text-xs font-medium'>
                                          Discount value should not be greater than selling price
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className='flex w-full mb-4 space-x-4'>
                                    <div className='w-full'>
                                      <label>start date</label>
                                      <label className='relative w-full block cursor-pointer'>
                                        <DatePicker
                                          className='border cursor-pointer w-full focus:border-gray-400 border-gray-300 outline-none'
                                          selected={startDate}
                                          onChange={(date) => setStartDate(date)}
                                          selectsEnd
                                          startDate={startDate}
                                          endDate={endDate}
                                          dateFormat="dd-MM-yyyy"
                                        />
                                        <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'><CalendarFill className='text-gray-600 w-3 h-3' /></span>
                                      </label>
                                    </div>
                                    <div className='w-full'>
                                      <label>end date</label>
                                      <label className='relative block w-full cursor-pointer'>
                                        <DatePicker
                                          className='border cursor-pointer w-full focus:border-gray-400 border-gray-300 outline-none'
                                          selected={endDate}
                                          onChange={(date) => setEndDate(date)}
                                          selectsEnd
                                          startDate={startDate}
                                          endDate={endDate}
                                          dateFormat="dd-MM-yyyy"
                                        />
                                        <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'><CalendarFill className='text-gray-600 w-3 h-3' /></span>
                                      </label>
                                    </div>
                                  </div>
                                  <button
                                    onClick={NewProductDiscount}
                                    className='inline-block btn text-white text-sm mb-4 font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
                                  >
                                    Add discount coupon
                                  </button>
                                </div>
                              )}
                              <table className='w-full border borer-gray-300'>
                                <thead>
                                  <tr className='bg-[#f5f5f5] border-b border-gray-300'>
                                    <th className='p-2 border-r border-gray-300 cursor-pointer text-sm font-medium text-gray-500'>
                                      Discount Type
                                    </th>
                                    <th className='p-2 border-r border-gray-300 cursor-pointer text-sm font-medium text-gray-500'>
                                      Discount Value
                                    </th>

                                    <th className='p-2 border-r border-gray-300 cursor-pointer text-sm font-medium text-gray-500'>
                                      Quantity
                                    </th>
                                    <th className='p-2 border-r border-gray-300 cursor-pointer text-sm font-medium text-gray-500'>
                                      StartDate
                                    </th>
                                    <th className='p-2 border-r border-gray-300 cursor-pointer text-sm font-medium text-gray-500'>
                                      End Date
                                    </th>
                                    <th className='p-2 border-r border-gray-300 cursor-pointer text-sm font-medium text-gray-500'>
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productDiscount?.length > 0 ? (
                                    productDiscount.map((elem, index) => {
                                      
                                      return (
                                        <>
                                          <tr
                                            key={index}
                                            className='text-center border-b border-gray-300 text-sm text-gray-600'
                                          >
                                            <td className='border  py-3 border-gray-300'>
                                              {elem.product_discount_type}{' '}
                                            </td>
                                            <td className='border border-gray-300'>
                                              {elem.discount_value}{' '}
                                            </td>
                                            <td className='border border-gray-300'>{elem.quantity} </td>
                                            <td className='border border-gray-300'>{moment(elem.start_date).tz('Asia/Kolkata').format('DD-MM-YYYY')} </td>
                                            <td className='border border-gray-300'>{moment(elem.end_date).tz('Asia/Kolkata').format('DD-MM-YYYY')} </td>
                                            <td className='border border-gray-300 p-2'>
                                              <div className=' justify-center flex space-x-3'>
                                                {' '}
                                                <button
                                                  href='#'
                                                  className='text-blue-500 hover:text-blue-600'
                                                  onClick={() => DiscountChange(elem)}
                                                >
                                                  <Edit className="w-4 h-4" />
                                                </button>
                                                <>
                                                  {' '}
                                                  <button
                                                    href='#'
                                                    className='text-red-500 hover:text-red-600'
                                                    onClick={(e) => RemoveProductDiscount(e, elem._id)}
                                                  >
                                                    <Trash3Fill className="w-4 h-4" />
                                                  </button>
                                                </>
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <p className='py-2 ps-4'>No data found</p>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      </details>
                    }
                    <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                      <summary
                        className='text-base flex items-center justify-between font-medium text-black'
                      >
                        <div>
                          Search engine listing
                          <p className='text-xs text-gray-500 font-normal'>Add a title and description to see how this product might appear in a search engine listing</p>
                        </div>
                        <span className="transition group-open:rotate-180">
                          <ChevronDown className="text-black w-4 h-5" />
                        </span>
                      </summary>
                      <div className='mb-4'>
                        <div className='flex items-center justify-between'>
                          <label
                            htmlFor='Meta Tag Title'
                            className='flex items-center justify-between'
                          >
                            Meta Title
                          </label>  
                        </div>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='text'
                          id='Meta Tag Title'
                          name='meta_title'
                          value={meta_title}
                          onChange={(e) => {
                            setMeta({ ...meta, [e.target.name]: e.target.value })
                          }}
                          placeholder='Enter meta title'
                        />
                      </div>
                      <div className='mb-4'>
                        <div className='flex items-center justify-between'>
                          <label
                            htmlFor='Meta Tag description'
                          >
                            Meta Description
                          </label>
                        </div>
                        <textarea
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='text'
                          id='Meta Tag description'
                          name='meta_description'
                          value={meta_description}
                          onChange={(e) => {
                            setMeta({ ...meta, [e.target.name]: e.target.value })
                          }}
                          placeholder='Enter meta description'
                        />
                      </div>
                    </details>
                    <div className='flex items-center justify-between'>
              <Link
                to={`/dummy-data/dummy-product`}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                Go to Products
              </Link>
              {/* <button
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                type='submit'
                // onClick={HandleSubmit}
              >
                Add New Dummy Category
              </button> */}
              <div className='flex space-x-3 items-center'>
              {
                !isPublish &&  
              <button
                className='flex items-center secondary-btn text-white text-sm font-medium py-2.5 px-16 rounded-[10px] transition duration-300'
                type='button'
                onClick={(e) => HandleUpdate(e, "publish")}
              >
                Publish product
              </button>
              }
              <button
                className='flex items-center btn text-white text-sm font-medium py-2.5 px-16 rounded-[10px] transition duration-300'
                type='button'
                onClick={HandleUpdate}
              >
                {isPublish ? "Update product" : "Update Draft"}
              </button>
            </div>
            </div>

                  </div>
              }
            </form>
          </div>
        </div>
        {/* <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-40 bg-white border-t border-gray-300 p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[60%] mx-auto'>
            <Link to={`/products`} className='w-fit flex text-link font-medium hover:underline text-sm items-center '><ArrowLeft className='w-3 mr-1' />Go to Products</Link>
            <div className='flex space-x-3 items-center'>
              {
                !isPublish &&  
              <button
                className='flex items-center secondary-btn text-white text-sm font-medium py-2.5 px-16 rounded-[10px] transition duration-300'
                type='submit'
                onClick={(e) => HandleUpdate(e, "publish")}
              >
                Publish product
              </button>
              }
              <button
                className='flex items-center btn text-white text-sm font-medium py-2.5 px-16 rounded-[10px] transition duration-300'
                type='submit'
                onClick={HandleUpdate}
              >
                {isPublish ? "Update product" : "Update Draft"}
              </button>
            </div>
          </div>
        </div> */}
        {
          addBrand &&
          <div className="h-full fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
            <Popover
              open={addBrand}
              className="h-screen"
              onClose={() => setAddBrand(false)}
            >
              <div className='p-5 w-[500px]'>
                <div className='flex items-center justify-between'>
                  <h3 className='text-xl text-black font-medium'>Add Manufacture</h3>
                  <button onClick={() => setAddBrand(false)} className='transition-all duration-300 text-link'><X className="w-4 h-4" /></button>
                </div>
                <hr className='my-4 border-gray-300'></hr>
                <form
                  method='POST'
                  className='w-full'
                  onSubmit={(e) => HandleCatSubmit(e)}
                >
                  <div className='mb-4'>
                    <label
                      className='flex text-gray-700 text-sm font-medium mb-1'
                      htmlFor='brand_name'
                    >
                      Manufacture Name
                    </label>
                    <input
                      className={`w-full px-3 py-2 border ${err && !manufacturerdata ? 'border-red-500' : 'border-gray-300 '} rounded-[10px]  focus:outline-none`}
                      type='text'
                      id='brand_name'
                      name='brand_name'
                      value={manufacturerdata}
                      onChange={(e) => setManufacturerData(e.target.value)}
                      placeholder='ex.Tech'
                      required
                    />
                  </div>

                  <div className='mb-4'>
                    <label
                      className='flex text-gray-700 text-sm font-medium mb-1'
                      htmlFor='image'
                    >
                      Manufacture Image
                    </label>
                    <label className='text-sm cursor-pointer leading-6 text-gray-600'>
                      <input
                        type='file'
                        name='image'
                        onChange={(e) => HandleManufacturerImage(e.target.files[0])}
                      />
                      <div className='mt-2'>
                        {err && !brandimage && (
                          <h1 className='text-red-500'>Please enter image</h1>
                        )}
                        {brandloader === true && !brandimage ? (
                          <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                            <div className='nb-spinner'></div>
                          </div>
                        ) : (
                          brandimage ?
                            <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                              <img className='max-w-full max-h-full' src={brandimage} alt={brandimage} />
                            </div> :
                            <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                              <img className='max-w-full max-h-full' src={NOPHOTO_IMAGE} alt={NOPHOTO_IMAGE} />
                            </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <button
                    className='w-full block btn text-white text-sm font-medium py-2 px-3 rounded-[10px] transition duration-300'
                    type='button'
                    onClick={HandleManufacturerSubmit}
                  >
                    Add manufacture
                  </button>
                </form>
              </div>
            </Popover>
          </div>
        }
        {
          addCategory &&
          <div className="h-full fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
            <Popover
              open={addCategory}
              className="h-screen"
              onClose={() => setAddCategory(false)}
            >
              <div className='p-5 w-[500px]'>
                <div className='flex items-center justify-between'>
                  <h3 className='text-xl text-black font-medium'>Add category</h3>
                  <button onClick={() => setAddCategory(false)} className='transition-all duration-300 text-link'><X className="w-4 h-4" /></button>
                </div>
                <hr className='my-4 border-gray-300'></hr>
                <form method='get'
                  className='w-full'
                >
                  <div className='mb-4'>
                    <label
                      htmlFor='category_name'
                    >
                      category Name
                    </label>
                    <input
                      className={`w-full px-3 py-2 border ${err && !Catdata ? 'border-red-500' : 'border-gray-300 '} rounded-[10px]  focus:outline-none`}
                      type='text'
                      id='category_name'
                      name='category_name'
                      value={Catdata}
                      onChange={(e) => setCatData(e.target.value)}
                      placeholder='e.g. Tech'
                      required
                    />
                    {
                      err && !Catdata && (
                        <span className='text-red-500 text-sm'>*Category name is required</span>
                      )
                    }
                  </div>
                  <label className='mb-4'>
                    <label
                      htmlFor='image'
                    >
                      category Image
                    </label>
                    <input
                      type='file'
                      name='image'
                      onChange={(e) => { e.preventDefault(); HandleCatImage(e.target.files[0]) }}
                      required
                    />
                    <div className='mt-2 cursor-pointer'>
                      {
                        err && !catimage &&
                        <h1 className='text-red-500'>Please enter image</h1>
                      }
                      {catloader === true ? (
                        <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                          <div className='nb-spinner'></div>
                        </div>
                      ) : (
                        catimage ?
                          <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                            <img className='max-w-full max-h-full' src={catimage && catimage} alt={catimage && catimage} />
                          </div> :
                          <div className='w-[80px] h-[80px] border p-1 border-gray-300 flex items-center justify-center'>
                            <img className='max-w-full max-h-full' src={NOPHOTO_IMAGE} alt={NOPHOTO_IMAGE} />
                          </div>
                      )}
                    </div>
                  </label>
                  <button
                    className='w-full block btn text-white text-sm font-medium py-2 px-3 rounded-[10px] transition duration-300'
                    type='button'
                    onClick={(e) => HandleCatSubmit(e)}
                  >
                    Add category
                  </button>
                </form>
              </div>
            </Popover>
          </div>
        }
        {
          openYoutubeInfo &&
          <Popover
            id={"youtubeinfo"}
            className='youtubeInfo'
            open={openYoutubeInfo}
            anchorEl={anchorEl}
            onClose={() => setOpenYoutubeInfo(false)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <div className='p-4 text-sm'>
              <p><b>1.</b> Open the YouTube video you want to share.</p>
              <br></br>
              <p><b>2.</b> Look for the "Share" button below the video. It's usually located just below the video player.</p>
              <br></br>
              <p><b>3.</b> Click on the "Share" button. A menu will pop up with different sharing options.</p>
              <br></br>
              <p><b>4.</b> In the menu, you'll see an option called "Embed." Click on that.</p>
              <br></br>
              <p><b>5.</b> Once you click on "Embed," you'll see a box with some code inside it.</p>
              <br></br>
              <p><b>6.</b> Right next to the code, there's a button that says "Copy." Click on that button.</p>
            </div>
          </Popover>
        }
        {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] h-96  mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X onClick={()=>{
                    if(window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")){
                      setHtml({...html,htmlPP:false})
                      setDescType()
                    }
                  }}/>
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea 
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({...html,code:e.target.value})}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              </div>
              <button
                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
            </div>
          </Popover>
        </div>
      )}
      </>
  );
};
export default UpdateDummyProduct;
