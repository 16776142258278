import React, {useEffect, useRef, useState} from "react";
import API from "../../../API";
import ReactQuill from "react-quill";
import {Link, useNavigate, useParams} from "react-router-dom";
import module, {formatCode} from "../../../controllers/QuillToolbar";
import {TextField, Autocomplete, Popover} from "@mui/material";
import {GenerateSlugName} from "../../../controllers/GenerateSlugName";
import Compressor from "compressorjs";
import {
    X,
    ArrowLeft,
    ChevronDown,
    Check,
    Edit,
} from "feather-icons-react/build/IconComponents";
import Breadcrumbs from "../../../controllers/breadCrumbs";
import GenerateUniqueFilename from "../../../controllers/GenerateUniqueFilename";
import Notification from "../../Notification";
import ImageUploader from "../../../controllers/ImageUploader";

function AddWebsiteBlogCategories() {
    const params = useParams();
    const navigate = useNavigate();
    const [warn, setwarn] = useState(false);
    const [SelectCategory, setSelectCategory] = useState("");
    const [enableSlug, setEnableSlug] = useState(false);
    const [description, setDescription] = useState("");
    const [secondDescription, setSecondDescription] = useState("");
    const [title, setTitle] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [keyword, setKeyword] = useState("");
    const [meta, setMeta] = useState({
        meta_tag_title: "",
        meta_tag_description: "",
    });
    const {meta_tag_description, meta_tag_title} = meta;
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();

    // image upload logic
    const [image, setimage] = useState();
    const handleSetImage = (value) => {
        setimage(value);
    };

    // get Parents Category
    const [Category, setCategory] = useState([]);
    const GetCategory = async () => {
        try {
            const data = await API({ url: `/super/admin/se_site/blogcategory/getSEBlogCategorys`, method: 'get' });
            if (data.status === 200 || data.status === 304) {
                setCategory(data?.data);
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
    };
    const [slugField, setSlugField] = useState(false);
    const [slugName, setSlugName] = useState();
    const handleSlug = async (slug) => {
        if (slug?.length) {
            setSlugField(true);
            setSlugName(await GenerateSlugName(slug));
        }
    };
    useEffect(() => {
        GetCategory();
    }, []);
    const [err, setErr] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBlogCategory, setSelectedBlogCategory] = useState();
    const handleBlogCategory = (id, name) => {
        setSelectedBlogCategory(id);
        if (id) {
            const cat = selectedCategory.find((x) => x.id === id);
            if (!cat) {
                setSelectedCategory([...selectedCategory, {id, name}]);
            }
        }
    };

    // add Blog
    const HandleSubmit = async (e) => {
        e.preventDefault();
        if (title === "" || enableSlug) {
            setErr(true);
        } else {
            try {
                const data = await API({ url: "/super/admin/se_site/blogcategory/addSEBlogCategory", method: 'post',
                    data: {
                        blogcategory_name: title,
                        description: secondDescription,
                        // image: image,
                        sort_order: sortOrder,
                        keyword: keyword,
                        ...meta,
                        parent_blogcategory: selectedBlogCategory,
                    }
                });
                if (data.status === 200 || data.status === 304) {
                    navigate("/site-data/blog-category");
                }
            } catch (error) {
                setIsNotification(true);
                setNotificationMsg(error);
                setSeverity("error");
            }
        }
    };
    const [desErr, setDesErr] = useState(false);
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    const removeCategory = (id) => {
        const removeRelated = selectedCategory.filter((x) => x.id !== id);
        setSelectedCategory(removeRelated);
    };

    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const handleQuillChange = (value) => {
        setSecondDescription(value);
    };

    const [html, setHtml] = useState({htmlPP: false, code: ""});
    const {htmlPP, code} = html;
    const handleViewHtml = (e) => {
        e.preventDefault();
        const quill = quillRef.current.getEditor();
        const htmlContent = quill.root.innerHTML;
        const formattedHtml = formatCode(htmlContent);
        setHtml({htmlPP: true, code: formattedHtml}); // Log or use the HTML content as needed
    };

    const saveHTML = () => {
        setHtml({...html, htmlPP: false});
        setSecondDescription(code);
    };
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}
            <div className="h-auto overflow-y-auto pb-[64px]">
                <Breadcrumbs />
                <h1 className="text-xl md:text-2xl font-medium pb-4 text-primary">
                    Add new blog category
                </h1>
                <div className="flex justify-evenly p-4">
                    <form
                        method="POST"
                        className="space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto"
                    >
                        <div className="bg-white group shadow  space-y-4 rounded-xl p-[25px]">
                            <div className="w-full">
                                <label
                                    htmlFor="title"
                                    className="flex items-center justify-between"
                                >
                                    <p className="text-[13px]">
                                        Blog Title
                                        <span className="text-red-500 ms-1">
                                            *
                                        </span>
                                    </p>
                                    {/* <p className="text-xs text-gray-500 font-normal">
                                        only 60 characters allowed
                                    </p> */}
                                </label>
                                <input
                                    className={
                                        (err && title?.length === 0) || warn
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                    }
                                    type="text"
                                    name="title"
                                    // maxLength={60}
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        setwarn(false);
                                    }}
                                    placeholder="e.g. Blog about your latest products or deals"
                                    onBlur={() => handleSlug(title)}
                                />
                                {(err && title?.length === 0) || warn ? (
                                    <p className="text-red-500 text-xs">
                                        Please enter blog title
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </div>

                            <div className="mb-4">
                                <label htmlFor="description">
                                    Blog Category
                                </label>
                                <Autocomplete
                                    className="autocomplete"
                                    options={
                                        Category?.data
                                            ? Category?.data?.records
                                            : []
                                    }
                                    getOptionLabel={(category) =>
                                        category.blogcategory_name
                                    }
                                    onChange={(e, v) =>
                                        v
                                            ? handleBlogCategory(
                                                  v?._id,
                                                  v?.blogcategory_name
                                              )
                                            : ""
                                    }
                                    renderInput={(Categories) => (
                                        <TextField
                                            placeholder="Select blog category"
                                            className="cursor-pointer"
                                            {...Categories}
                                        />
                                    )}
                                />
                                <div className="flex flex-wrap mt-1">
                                    {selectedCategory?.map((elem) => {
                                        return (
                                            <div className="bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded">
                                                <span>{elem.name}</span>
                                                <X
                                                    className="h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer"
                                                    onClick={() =>
                                                        removeCategory(elem?.id)
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex space-x-3 justify-between items-center mb-1">
                                    <label htmlFor="Description">
                                        Blog description
                                    </label>
                                    <div>
                                        <span
                                            className="inline-block cursor-pointer text-link underline align-baseline text-sm rounded-[10px] transition duration-300"
                                            onClick={() => {
                                                title === ""
                                                    ? setwarn(true)
                                                    : setwarn(false);
                                            }}
                                        >
                                            Get description
                                        </span>
                                        <section
                                            className="inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300"
                                            onClick={handleViewHtml}
                                        >
                                            View HTML
                                        </section>
                                    </div>
                                </div>
                                <ReactQuill
                                    modules={module}
                                    className={
                                        desErr
                                            ? "border-red-500 border rounded"
                                            : "w-full h-auto"
                                    }
                                    theme="snow"
                                    ref={quillRef}
                                    placeholder="Enter blog content"
                                    value={secondDescription}
                                    onChange={handleQuillChange}
                                />
                                <div className="flex items-center justify-between">
                                    {desErr ? (
                                        <p className="text-red-500 text-xs ">
                                            Please enter blog description
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                    <p className="text-xs mt-0 text-gray-400">
                                        of 5000 words allowed
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <details open className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
              <summary
                className='text-base flex items-center justify-between font-medium text-black'
              >
                <div>
                  <span>Media</span>
                  <p className='text-xs text-gray-500 font-normal'>Upload captivating image to make your blog stand out.</p>
                </div>
                <span className="transition group-open:rotate-180">
                  <ChevronDown className="text-black w-4 h-5" />
                </span>
              </summary>
              <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} folder="blog-category" format="image" />
            </details> */}

                        <details className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]">
                            <summary
                                htmlFor="Meta Tag Title"
                                className="text-base flex items-center justify-between font-medium text-black"
                            >
                                <div>
                                    <span>Search engine listing</span>
                                    <p className="text-xs text-gray-500 font-normal">
                                        Add a title and description to see how
                                        this product might appear in a search
                                        engine listing
                                    </p>
                                </div>
                                <span className="transition group-open:rotate-180">
                                    <ChevronDown className="text-black w-4 h-5" />
                                </span>
                            </summary>
                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="Meta Tag Title"
                                        className="flex items-center justify-between"
                                    >
                                        Meta Title
                                    </label>
                                </div>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="text"
                                    id="Meta Tag Title"
                                    name="meta_tag_title"
                                    value={meta_tag_title}
                                    onChange={(e) => {
                                        setMeta({
                                            ...meta,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    placeholder="Enter meta title"
                                />
                            </div>

                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="Meta Tag description"
                                        className="flex items-center justify-between"
                                    >
                                        Meta Description
                                    </label>
                                </div>
                                <textarea
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="text"
                                    id="Meta Tag description"
                                    name="meta_tag_description"
                                    value={meta_tag_description}
                                    onChange={(e) => {
                                        setMeta({
                                            ...meta,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    placeholder="Enter meta description"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="keyword">Meta Keywords</label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="text"
                                    id="keyword"
                                    name="keyword"
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    placeholder="Enter meta keywords"
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="sort_order">sort order</label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="number"
                                    id="sort_order"
                                    name="sort_order"
                                    value={sortOrder}
                                    onChange={(e) => {
                                        setSortOrder(
                                            e.target.value <= 0
                                                ? 0
                                                : e.target.value
                                        );
                                    }}
                                    placeholder="Enter sort order"
                                />
                            </div>
                        </details>
                        <div className="flex items-center justify-between w-full">
                            <Link
                                to={`/site-data/blog-category`}
                                className="w-fit flex text-link font-medium hover:underline text-sm items-center "
                            >
                                <ArrowLeft className="w-3 mr-1" />
                                Go to blogs Categories
                            </Link>
                            <button
                                className="inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                type="button"
                                onClick={(e) => HandleSubmit(e)}
                            >
                                Add New Blog Category
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {htmlPP && (
                <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
                    <Popover
                        open={htmlPP}
                        className="h-screen popup-content"
                        // onClose={() => {
                        //   setHtmlPP(false);
                        // }}
                    >
                        <div className="max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700">
                            <div className="w-[35rem] h-96  mb-4">
                                <label
                                    className="flex justify-between text-gray-700 text-md font-bold mb-2"
                                    htmlFor="product_name"
                                >
                                    Edit HTML:
                                    <X
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    "Are You Sure to Leave?. Your recent changes will not be included."
                                                )
                                            ) {
                                                setHtml({
                                                    ...html,
                                                    htmlPP: false,
                                                });
                                            }
                                        }}
                                    />
                                </label>

                                <label className="flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <textarea
                                        className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                        type="text"
                                        rows={16}
                                        value={code}
                                        onChange={(e) =>
                                            setHtml({
                                                ...html,
                                                code: e.target.value,
                                            })
                                        }
                                        placeholder="<p>Code here<p/>"
                                    />
                                </label>
                            </div>
                            <button
                                className="btn w-full text-white px-3 py-2 rounded-[10px] mx-auto"
                                onClick={saveHTML}
                            >
                                save
                            </button>
                        </div>
                    </Popover>
                </div>
            )}
        </>
    );
}

export default AddWebsiteBlogCategories;
