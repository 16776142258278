import React, { useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import API from '../../../API';
import { Popover } from '@mui/material';
import { Move, PlusCircle, Trash, X } from 'feather-icons-react/build/IconComponents';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import DataTable from 'react-data-table-component';
import { useEffect } from 'react';
import { TrashFill } from 'react-bootstrap-icons';
import Notification from '../../Notification';
import { useRef } from 'react';

const UpdateDummyCategoryGroup = () => {
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  const params = useParams()
  const navigate = useNavigate();
  const [storeCategory, setStoreCategory] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState([])
  const [categoryGroupTitle, setCategoryGroupTitle] = useState('')
  const [categoryData, setCategoryData] = useState({
    category_limit: '5',
    carousel_status: true,
    status: true,
    category_name_display: true,
    category_image_width: '',
    category_image_height: '',
    carousel_speed_ms: '',
    loop_forever: true,
    auto_play: false,
    pause_on_hover: false,
    button_text: '',
    button_link: '',
  })
  const handleGetPerticularCategoryeGroup = async () => {
    try {
      const apiCall = await API({ url: `/super/admin/dummydata/dynamicdummycategory/getCategoryGroupById/${params?.groupId}`, method: 'get' })
      setCategoryData({
        button_text: apiCall?.data?.data[0]?.button_text,
        button_link: apiCall?.data?.data[0]?.button_link,
        category_limit: apiCall?.data?.data[0]?.category_limit,
        carousel_status: apiCall?.data?.data[0]?.carousel_status,
        status: apiCall?.data?.data[0]?.status,
        category_name_display: apiCall?.data?.data[0]?.category_name_display,
        category_image_width: apiCall?.data?.data[0]?.category_image_width,
        category_image_height: apiCall?.data?.data[0]?.category_image_height,
        carousel_speed_ms: apiCall?.data?.data[0]?.carousel_speed_ms,
        loop_forever: apiCall?.data?.data[0]?.loop_forever,
        auto_play: apiCall?.data?.data[0]?.auto_play,
        pause_on_hover: apiCall?.data?.data[0]?.pause_on_hover,
      })
      setCategoryGroupTitle(apiCall?.data?.data[0]?.category_section_title)
      setStoreCategory(apiCall?.data?.data[0]?.categories)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    handleGetPerticularCategoryeGroup()
  }, [])
  const [addCategory, setAddCategory] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddCategory = async () => {
    try {
      await API({ url: '/super/admin/dummydata/dynamicdummycategory/addCategory', method: 'post', data: {
        category_group_id: params?.groupId,
        categories: selectedCategory
      }})
      setSelectedCategory([]);
      setSearch('');
      setAddCategory(false);
      handleGetPerticularCategoryeGroup();
    } catch (error) {
      console.log(error);
    }
  }
  const [selectedCategory, setSelectedCategory] = useState([])
  const handleSelectCategory = (id) => {
    if (id) {
      const category = selectedCategory.find((x) => x?.category === id);
      if (!category) {
        setSelectedCategory([...selectedCategory, { category: id }]);
        // setStoreCategory([...storeCategory, { id, name, image }])
      } else {
        const removeSelected = selectedCategory.filter((x) => String(x?.category) !== String(id));
        // const removeCategory = storeCategory.filter((x) => x?.id !== id)
        setSelectedCategory(removeSelected);
        // setStoreCategory(removeCategory)
      }
    }
  }
  const [search, setSearch] = useState('')
  const [allCategory, setAllCategory] = useState([])
  const GetCategories = async () => {
    try {
      const apiCall = await API({ url: `/super/admin/dummydata/dummycategory/GetCategoriesWithProductCount`, method: 'get', params: { searchkey: search, group: params?.groupId }});
      if (apiCall.status === 200 || apiCall.status === 304) {
        setAllCategory(apiCall?.data?.data?.records)
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addCategory === true && (search?.length === 0 || search?.length >= 3)) {
      GetCategories();
    }
    if(!addCategory) {
      setSearch('');
      setSelectedCategory([])
    }
  }, [addCategory, search])
  const handleAddCategoryGroup = async (e) => {
    e.preventDefault();
    if (categoryGroupTitle?.length === 0) {
      setDataErr(true)
    } else {
      setDataErr(false)
      try {
        await API({ url: `/super/admin/dummydata/dynamicdummycategory/updateCategoryGroup/${params?.groupId}`, method: 'put', data: {
          category_section_title: categoryGroupTitle,
          button_text: categoryData?.button_text,
          button_link: categoryData?.button_link,
          category_limit: categoryData?.category_limit,
          carousel_status: categoryData?.carousel_status,
          status: categoryData?.status,
          category_image_width: categoryData?.category_image_width,
          category_image_height: categoryData?.category_image_height,
          carousel_speed_ms: categoryData?.carousel_speed_ms,
          loop_forever: categoryData?.loop_forever,
          auto_play: categoryData?.auto_play,
          pause_on_hover: !categoryData?.auto_play ? false : categoryData?.pause_on_hover,
        }})
        navigate('/dummy-data/module-setting/category-group')
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity('error')
      }
    }
  }
  const handleDelete = async (e, id) => {
    e.preventDefault();
    if (window.confirm('Are You Sure To Remove This Category?')) {
      try {
        await API({ url: `/super/admin/dummydata/dynamicdummycategory/deleteCategory`, method: 'post', data: {
          id: selectedRowsId?.length > 0 ? selectedRowsId : [id]
        }});
        setIsNotification(true)
        setNotificationMsg('Removed SuccesFully!')
        setSeverity('success')
        handleGetPerticularCategoryeGroup();
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };
  const sortCategory = async (index, id) => {
    try {
      await API({ url: `/super/admin/dummydata/dynamicdummycategory/updateCategory/${id}`, method: 'put', data: {
        newIndex: index === 0 ? 0 : Number(index),
      }});
      handleGetPerticularCategoryeGroup();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...storeCategory];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    sortCategory(DragOverItem.current, dragItemContent._id);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = { storeCategory: _sort };
    setStoreCategory(FinalArray);
  };
  const columns = [
    {
      name: '',
      width: '40px',
      padding: '0px',
      cell: (row, index) => (
        <div
          className='cursor-move text-center block w-full'
          draggable
          onDragStart={(e) => (DragItem.current = index)}
          onDragEnter={(e) => (DragOverItem.current = index)}
          onDragEnd={HandleSort}
        >
          <Move className='cursor-move w-4' />
        </div>
      ),
    },
    {
      name: 'Image',
      width: '150px',
      cell: (row) => <img width={80} height={50} src={row?.categoryData?.image} className='p-1 max-w-full max-h-full' />,
    },
    {
      name: 'category name',
      cell: (row) => <span className='line-clamp-2'>{row?.categoryData?.category_name}</span>,
      minWidth: '250px'
    },
    {
      name: <span className='w-full text-right'>Action</span>,
      width: '100px',
      cell: (row) => <div className='space-x-2 w-full text-right'>
        <button onClick={(e) => {
          handleDelete(e, row._id);
        }}><Trash className='w-[17px] h-[17px] text-red-500' /></button>
      </div>
    }
  ]
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDataErr(false)
    setCategoryGroupTitle(value);
  };

  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const { htmlPP, code } = html
  // const handleViewHtml = (e) => {
  //   e.preventDefault();
  //   const quill = quillRef.current.getEditor();
  //   const htmlContent = quill.root.innerHTML
  //   const formattedHtml = formatCode(htmlContent);
  //   setHtml({ htmlPP: true, code: formattedHtml }); 
  // };

  const saveHTML = () => {
    setHtml({ ...html, htmlPP: false })
    setCategoryGroupTitle(code)
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='w-full'>
          <Breadcrumbs />
          <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Edit Category Group</h3>
        </div>
        <form className='w-[90%] space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'>
          <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
          <div className='w-full mb-4'>
                <label htmlFor='group title'>
                  Category group title
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='text'
                  id='group title'
                  name='group title'
                  value={categoryGroupTitle}
                  onChange={(e)=>setCategoryGroupTitle(e.target.value)}
                  placeholder='Enter title....'
                />
              {
                dataErr && categoryGroupTitle?.length === 0 && <p className='text-xs text-red-500'>Please enter group title</p>
              }
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full'>
              <div className='mb-4 flex items-center'>
                <label>Category Group Status</label>
                <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={categoryData?.status}
                    name='status'
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, status: !categoryData?.status })
                    }}
                />
              </div>
              <div className='w-full'>
                <label>Category Limit</label>
                <div className='relative'>
                  <select className='w-full' value={categoryData?.category_limit} onChange={(e) => setCategoryData({ ...categoryData, category_limit: e.target.value })}>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4 flex items-center'>
                <label>Carousel Status</label>
                <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={categoryData?.carousel_status}
                    name='carousel_status'
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, carousel_status: !categoryData?.carousel_status })
                    }}
                />
              </div>
              <div className='mb-4 flex items-center'>
                <label>Carousel Loop</label>
                <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={categoryData?.loop_forever}
                    name='loop_forever'
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, loop_forever: !categoryData?.loop_forever })
                    }}
                />
              </div>
              <div className='mb-4 flex items-center'>
                <label>Autoplay</label>
                <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={categoryData?.auto_play}
                    name='auto_play'
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, auto_play: !categoryData?.auto_play })
                    }}
                />
              </div>
              {
                categoryData?.auto_play && 
                <div className='mb-4 flex items-center'>
                  <label>Pause On Hover</label>
                  <input
                      className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                      type='checkbox'
                      role='switch'
                      id='flexSwitchCheckDefault'
                      checked={categoryData?.pause_on_hover}
                      name='pause_on_hover'
                      onChange={(e) => {
                        setCategoryData({ ...categoryData, pause_on_hover: !categoryData?.pause_on_hover })
                      }}
                  />
                </div>
              }
              <div className='w-full'>
                <label>carousel speed ms</label>
                <div className='relative'>
                  <input value={categoryData?.carousel_speed_ms} disabled={categoryData?.carousel_status === 'disabled'} onChange={(e) => setCategoryData({ ...categoryData, carousel_speed_ms: e.target.value })} className='w-full border border-gray-300 px-4 py-2 rounded text-sm outline-none focus:border-gray-500' placeholder='e.g. 5000' />
                </div>
              </div>
              
              <div className='w-full'>
                <label htmlFor='image'>
                  button text
                </label>
                <input value={categoryData?.button_text} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' onChange={(e) => setCategoryData({ ...categoryData, button_text: e.target.value })} placeholder='e.g. Explore All' />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>
                  button link
                </label>
                <input value={categoryData?.button_link} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='Add link' onChange={(e) => setCategoryData({ ...categoryData, button_link: e.target.value })} />
              </div>
              <div className='w-full'>
                <label>Category image width</label>
                <div className='relative'>
                  <input value={categoryData?.category_image_width} onChange={(e) => setCategoryData({ ...categoryData, category_image_width: e.target.value })} className='w-full border border-gray-300 px-4 py-2 rounded text-sm outline-none focus:border-gray-500' placeholder='e.g. 100' />
                </div>
              </div>
              <div className='w-full'>
                <label>Category image height</label>
                <div className='relative'>
                  <input value={categoryData?.category_image_height} onChange={(e) => setCategoryData({ ...categoryData, category_image_height: e.target.value })} className='w-full border border-gray-300 px-4 py-2 rounded text-sm outline-none focus:border-gray-500' placeholder='e.g. 150' />
                </div>
              </div>
            </div>
            <hr className='border-t border-gray-200 my-4'></hr>
            <div className='flex items-end w-full justify-between'>
              <details className={(selectedRowsId?.length === 0) ? `opacity-60 cursor-not-allowed group w-[200px] relative` : `cursor-pointer group w-[200px] relative`}>
                <summary className='flex bg-white border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
                  <span> Bulk Selection</span>
                  <span className={(selectedRowsId?.length === 0) ? 'transition group-open:rotate-0' : 'transition group-open:rotate-180'}>
                    <svg
                      fill='none'
                      height={14}
                      shapeRendering='geometricPrecision'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      width={14}
                    >
                      <path d='M6 9l6 6 6-6' />
                    </svg>
                  </span>
                </summary>
                <div className={(selectedRowsId?.length === 0) ? `hidden` : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`}>
                  <button className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center' onClick={(e) => handleDelete(e)}>
                    <TrashFill className='w-3.5 h-3.5' />
                    <span>Delete selection</span>
                  </button>
                </div>
              </details>
              <button className='text-link underline flex items-center justify-end font-medium text-sm text-white' onClick={(e) => { e.preventDefault(); setAddCategory(true) }}><PlusCircle className="w-4 me-2 h-4" />Add Category</button>
            </div>
            <div className='w-full inline-block border border-gray-300 rounded-[4px] overflow-x-auto overflow-y-hidden'>
              <DataTable
                columns={columns}
                data={!addCategory && storeCategory}
                selectableRows
                onSelectedRowsChange={({ selectedRows }) => { setSelectedRowsId((selectedRows?.map((x) => x._id))) }}
                selectableRowsHighlight
                highlightOnHover
                progressComponent={<Loader />}
              />
            </div>
            <hr className='border-t border-gray-200 my-4'></hr>
            <button className='btn text-sm text-white' onClick={(e) => handleAddCategoryGroup(e)}>Edit Category group</button>
          </div>
        </form>
        {
          addCategory ?
            <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
              <Popover
                open={addCategory}
                className="h-screen popup-content"
                onClose={() => {
                  setAddCategory(false);
                }}
              >
                <div className='bg-white w-full lg:w-[600px] border border-gray-300  space-y-4 rounded p-5'>
                  <div className='flex border-b border-gray-300 pb-3 items-center justify-between'>
                    <h3 className='font-medium text-xl'>Select Categories</h3>
                    <button onClick={(e) => { e.preventDefault(); setAddCategory(false); setSearch(''); setSelectedCategory([]); handleGetPerticularCategoryeGroup(); }}><X className="w-5 h-4" /></button>
                  </div>
                  <input value={search} onChange={(e) => setSearch(e.target.value)} className='w-full border border-gray-300 px-4 py-2 rounded outline-none focus:border-gray-500 text-sm' placeholder='Search categories..' />
                  <div className='h-[450px] overflow-y-auto divide-y'>
                    {
                      allCategory?.map((elem) => {
                        return (
                          <label className='flex py-2 items-start justify-between'>
                            <div className='flex'>
                              <input type='checkbox' checked={selectedCategory?.find((x) => x?.category === elem?._id)} value={elem?._id} onChange={(e) => handleSelectCategory(elem?._id)} className='block mt-1 w-4 h-4 me-4' />
                              <div className='w-[60px] h-[60px] p-1 border me-2 border-gray-200 flex items-center justify-center'>
                                <img src={elem?.image} className='max-w-full max-h-full' />
                              </div>
                              <p className='text-base break-all'>{elem?.category_name}</p>
                            </div>
                            <p className='me-2 text-base text-black'>({elem?.product_count})</p>
                          </label>
                        )
                      })
                    }
                  </div>
                  <button className='btn text-sm text-white' onClick={() => handleAddCategory()}>Add Category</button>
                </div>
              </Popover>
            </div> : <></>
        }
        {htmlPP && (
          <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
            <Popover
              open={htmlPP}
              className='h-screen popup-content'
              onClose={() => {
                setHtml(false);
              }}
            >
              <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
                <div className='w-[35rem] h-96  mb-4'>
                  <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                    Edit HTML:
                    <X onClick={() => {
                      if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                        setHtml({ ...html, htmlPP: false })
                      }
                    }} />
                  </label>
                  <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                    <textarea
                      className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                      type='text'
                      rows={16}
                      value={code}
                      onChange={(e) => setHtml({ ...html, code: e.target.value })}
                      placeholder='<p>Code here<p/>'
                    />
                  </label>
                </div>
                <button
                  className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                  onClick={saveHTML}
                >
                  save
                </button>
              </div>
            </Popover>
          </div>
        )}
      </div>
    </>
  )
}

export default UpdateDummyCategoryGroup