import {
  Edit,
  Eye,
  Filter,
  PlusCircle,
  Trash,
} from "feather-icons-react/build/IconComponents";
import API from "../../API";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { GetGuides } from "../../Redux/Action/guide.action";
import { GetDeleteGuides } from "../../Redux/Action/guide.action"
import { useDispatch, useSelector } from "react-redux";
import Notification from '../../Popup/Notification'
import Breadcrumbs from "../../controllers/breadCrumbs";
import { Pagination } from "@mui/material";
const MyGuide = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { allGuides } = useSelector((state) => state.guidesReducer)
  const [filterDD, setFilterDD] = useState(false);
  const [filter, setFilter] = useState({ search: "", a_type: '' });
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();

  useEffect(() => {
    if (filter.search) {
      if (filter.search?.length === 0 || filter.search?.length >= 3) {
        dispatch(GetGuides(page, limit, filter.search));
      }
    } else {
      dispatch(GetGuides(page, limit, filter.search));
    }
  }, [page, limit, dispatch, filter.search]);

  const SelectAllReadArticles = async (id, action) => {
    try {
      const data = await API({ url: `/super/admin/guide/updateGuide/${id}`, method: 'put', data: {
        FrequentlyReadArticlesAll: action === "true" ? true : false,
      }});
      if (data.status === 200 || data.status === 304) {
        dispatch(GetGuides(page, limit, filter.search));
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  const SelectReadArticlesCategory = async (id, action) => {
    try {
      const data = await API({ url: `/super/admin/guide/updateGuide/${id}`, method: 'put', data: {
        FrequentlyReadArticlesCateogry: action === "true" ? true : false,
      }});
      if (data.status === 200 || data.status === 304) {
        dispatch(GetGuides(page, limit, filter.search));
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  const setPublished = async (id, action) => {
    try {
      const data = await API({ url: `/super/admin/guide/updateGuide/${id}`, method: 'put', data: {
        Published: action === "true" ? true : false,
      }});
      if (data.status === 200 || data.status === 304) {
        dispatch(GetGuides(page, limit, filter.search));
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };


  // //   Get Guides
  // const GetGuides = async () => {
  //   await fetch(
  //     `${process.env.REACT_APP_BACKEND_URL}/super/admin/guide/getguides?search=${filter.search}&page=${Page}&limit=${limit}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}&announcement_type=${filter.a_type}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "content-type": "application/json",
  //         token,
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((data) => setGuides(data));
  // };

  // Api call For delete  Announcement
  const HandleMultipleDelete = async (id) => {
    dispatch(GetDeleteGuides(selectedRowsId, id))
  };

  // table Data
  const columns = [
    {
      name: <div className='flex w-full pe-2'>Title</div>,
      cell: (row) => (
        <span className="line-clamp-1">
          {row.title}
        </span>
      ),
      minWidth: '300px'
    },
    {
      name: 'Category',
      cell: (row) => (
        <div>
          {row?.category?.category_name}
        </div>
      ),
      minWidth: '150px'
    },
    {
      name: 'All Visibility',
      minWidth: '120px',
      right: true,
      cell: (row) =>
        <input
          className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
          type='checkbox'
          role='switch'
          checked={row?.FrequentlyReadArticlesAll}
          id='flexSwitchCheckDefault'
          name='FrequentlyReadArticlesAll'
          onChange={() => SelectAllReadArticles(row._id, row?.FrequentlyReadArticlesAll ? 'false' : 'true')}
        />
    },
    {
      name: 'Category Visibility',
      right: true,
      minWidth: '100px',
      cell: (row) =>
        <input
          className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
          type='checkbox'
          role='switch'
          checked={row?.FrequentlyReadArticlesCateogry}
          id='flexSwitchCheckDefault'
          name='FrequentlyReadArticlesCateogry'
          onChange={() => SelectReadArticlesCategory(row._id, row?.FrequentlyReadArticlesCateogry ? 'false' : 'true')}
        />
    },
    {
      name: <div className='flex justify-end w-full pe-1'>Published</div>,
      minWidth: '100px',
      right: true,
      cell: (row) =>
        <input
          className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
          type='checkbox'
          role='switch'
          checked={row?.Published}
          id='Published'
          name='Published'
          onChange={() => setPublished(row._id, row?.Published ? 'false' : 'true')}
        />
    },
    {
      name: <p className="w-full text-right block">ACTION</p>,
      width: '150px',
      right: true,
      cell: (row) => (
        <div className="space-x-3 w-full flex items-center justify-end">
          <button
            onClick={(e) => {
              navigate(`/guide/updateguide/${row.guide_slug_name}`);
            }}
          >
            <button className="">
              <Edit className="w-[17px] h-[17px] text-blue-500" />
            </button>
          </button>
          <button onClick={() => HandleMultipleDelete(row._id)}>
            <button className="">
              <Trash className="w-[17px] h-[17px] text-red-500" />
            </button>
          </button>
          <button
            onClick={() =>
              navigate(`/guide/${row.guide_slug_name}`)
            }
          >
            <button className="">
              <Eye className="w-[17px] h-[17px]" />
            </button>
          </button>
        </div>
      ),
    },
  ];


  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  var startRecord = (allGuides?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(allGuides?.currentPage * limit, allGuides?.totalRecords);
  return (
    <div className=" w-full pb-[64px]">
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Guide', path: `/guide` },
            { name: 'Add Guide', path: `/guide/add-guide` },
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>Guide</h1>
        </div>
        <div className="flex space-x-3">
          <Link
            to={"/guide/addguide"}
            className="flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
            type="submit"
          //   onClick={HandleSubmit}
          >
            <PlusCircle className="w-4 h-4 me-2" />
            Add Guide
          </Link>
          <button
            className="filter-btn rounded w-10 h-10"
            onClick={() => setFilterDD(!filterDD)}
          >
            <Filter className="inline-flex rounded-md w-5 h-5" />
          </button>
        </div>
      </div>
      <details
        className={
          selectedRowsId?.length === 0
            ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative`
            : `cursor-pointer group w-[200px] mb-1.5 relative`
        }
      >
        <summary className="flex bg-white border border-gray-300 px-2 py-2 text-sm rounded justify-between items-center list-none">
          <span> Bulk Selection</span>
          <span
            className={
              selectedRowsId?.length === 0
                ? "transition group-open:rotate-0"
                : "transition group-open:rotate-180"
            }
          >
            <svg
              fill="none"
              height={14}
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width={14}
            >
              <path d="M6 9l6 6 6-6" />
            </svg>
          </span>
        </summary>
        <div
          className={
            selectedRowsId?.length === 0
              ? `hidden`
              : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
          }
        >
          <button
            className="mt-1 text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center"
            onClick={() => HandleMultipleDelete()}
          >
            <Trash className="w-3.5 h-3.5" />
            <span>Delete selection</span>
          </button>
        </div>
      </details>
      <div className="w-full flex space-x-4">
        <div className="w-full space-y-3">
          <div className="w-full overflow-x-auto">
            <div className='w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
              {allGuides?.records?.length ?
                <DataTable
                  columns={columns}
                  data={allGuides?.records}
                  selectableRows
                  fixedHeaderScrollHeight="700px"
                  onSelectedRowsChange={({ selectedRows }) => {
                    setSelectedRowsId(selectedRows?.map((x) => x._id));
                  }}
                  selectableRowsHighlight
                  highlightOnHover
                />
                : <></>}
            </div>
          </div>
          {allGuides?.records?.length > 0 ? (
            <div className='bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
              <span className='text-sm font-medium'>
                {
                  limit === "all" ? `${allGuides?.totalRecords} Records` :
                    `${startRecord} - ${endRecord} of ${allGuides?.totalRecords}`
                }
              </span>
              <div>
                {allGuides?.totalPages !== 1 ? (
                  <Pagination
                    count={allGuides?.totalPages}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
          <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
            <Filter className="me-1 w-4 h-4" />
            Filter
          </p>
          <hr className="border-[#a0d0eb] my-3"></hr>
          <div className="grid gap-y-3 max-w-full overflow-hidden">
            <div>
              <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                Search
              </span>
              <label className="relative block w-full">
                <input
                  className="w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search for anything..."
                  type="text"
                  name="search"
                  value={filter.search}
                  onChange={(e) => setFilter({ search: e.target.value })}
                />
              </label>
            </div>
            <div>
              <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                Limit
              </span>
              <div className="relative">
                <select
                  onChange={(e) => {
                    setLimit(e.target.value)
                  }}
                  name="verification_status"
                  className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                  value={limit}
                >
                  <option value="">All</option>
                  <option >10</option>
                  <option >20</option>
                  <option >50</option>
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default MyGuide;
