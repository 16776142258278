import React, { useState, useEffect } from 'react';
import {  useLocation, useNavigate } from 'react-router-dom';
import {
  Filter,
  Trash,
  Copy,
  Move,
  PlusCircle,
  Edit,
  ChevronDown,
} from 'feather-icons-react/build/IconComponents';
import {
  CaretDownFill,
  CaretUpFill,
  Star,
  StarFill,
  TrashFill,
} from 'react-bootstrap-icons';
import API from '../../../API';
import {  Autocomplete, Menu, MenuItem, Pagination,  TextField, Tooltip } from '@mui/material';
import DataTable from 'react-data-table-component';
import Loader from '../../Loader/Loader';
import { useRef } from 'react';
import Notification from '../../Notification';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import { getInitialPage, replacePageInUrl } from '../../../controllers/PageHandleFromQuery';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';

const DummyProductTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [Product, setAllProduct] = useState([]);
  const [Page, setPage] = useState(getInitialPage());
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(Number(initialLimit) || 10);
  const [search, setSearch] = useState(location?.state?.searchkey ? location?.state?.searchkey : '');
  const [filtercategory, setfiltercategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pending, setPending] = useState(true);
  const [sortBy, setSortBy] = useState(location?.state?.accending ? location?.state?.field : '');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [openTab, setOpenTab] = useState(1)
  useEffect(() => {
    replacePageInUrl(Page)
  }, [Page])
  // Sorting Data
  const SortData = () => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetAllProducts();
  };

  var startRecord = (Product?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(Product?.data?.currentPage * limit, Product?.data?.totalRecords);
  useEffect(() => {
    if (search === '' || search?.length > 2) {
      GetAllProducts()
    }
  }, [search])
  const GetAllProducts = async () => {
    setPending(true)
    if (openTab === 1) {
      try {
        const data = await API({ url: `/super/admin/dummydata/dummyproduct/getDummyProducts`, method: 'get', params: {
            searchkey: search?.length > 2 ? search : '',
            page: Page,
            limit: limit,
            categories: filtercategory,
            sort_by: sortBy,
            order_by: orderBy.orderByValue,
          },
        });
        if (data.status === 200 || data.status === 304) {
          setAllProduct(data.data);
          if (Number(startRecord) > Number(data.data?.data?.totalRecords)) {
            setPage(Page - 1);
          }
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        setPending(false)
      }
    } else {
      try {
        const data = await API({ url: `/super/admin/dummydata/dummyproduct/getDraftProducts`, method: 'post', 
          params: { page: Page, limit, key: 'sort_order' }, 
          data: {
            searchkey: search?.length > 2 ? search : '',
            categories: filtercategory,
            sort_by: sortBy,
            order_by: orderBy.orderByValue,
          },
        });
        if (data.status === 200 || data.status === 304) {
          setAllProduct(data.data);
          if (Number(startRecord) > Number(data.data?.data?.totalRecords)) {
            setPage(Page - 1);
          }
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        setPending(false)
      }
    }
  };

  const GetAllCategories = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummycategory/GetCategoriesName`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setCategories(data?.data?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetAllCategories();
  }, []);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const HandleRemove = async (id) => {
    if (openTab === 1) {
      if (window.confirm('Delete Product?')) {
        try {
          const data = await API({ url: `/super/admin/dummydata/dummyproduct/deleteDummyProduct`, method: 'post', data: {
            id: id ? [id] : selectedRowsId?.length > 0 && selectedRowsId
          }});
          if (data.status === 200 || data.status === 304) {
            setIsNotification(true);
            setNotificationMsg('Product Removed Successfully!');
            setSeverity('success');
            GetAllProducts();
          }
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
        }
      }
    } else {
      if (window.confirm('Delete Product?')) {
        try {
          const data = await API({ url: `/super/admin/dummydata/dummyproduct/deleteDraftProducts`, method: 'post', data: {
            id: id ? [id] : selectedRowsId?.length > 0 && selectedRowsId
          }});
          if (data.status === 200 || data.status === 304) {
            setIsNotification(true);
            setNotificationMsg('Product Removed Successfully!');
            setSeverity('success');
            GetAllProducts();
          }
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
        }
      }
    }
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    GetAllProducts();
  }, [limit, filtercategory, Page, openTab]);

  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(true);
  const handleFeatured = async (id, value) => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyproduct/updateDummyProduct/${id}`, method: 'put', data: {
        featured_status: value,
      }});
      if (data.status === 200 || data.status === 304) {
        GetAllProducts();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };


  const sortProduct = async (index, id) => {
    try {
      const apiCall = await API({ url: `/super/admin/dummydata/dummyproduct/updateDummyProduct/${id}`, method: 'put', data: {
        newIndex:
          index === 0 && Page === 1
            ? 0
            : Page != 1
              ? Number(index) + Number(limit) * Number(Page - 1)
              : Number(index),
      }});
      if (apiCall.status === 200) {
        GetAllProducts();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleDublicate = async (slug) => {
    let data;
    try {
      let ApiCall = await API({ url: `/super/admin/dummydata/dummyproduct/getDummyProduct/${slug}`, method: 'get' }) 
      data = ApiCall?.data?.data
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity('error')
    }
    if (data) {
      delete data.sku_id;
      delete data.client;
      delete data.createdAt;
      delete data.tenant;
      delete data.updatedAt;
      delete data.user;
      delete data._id;
      delete data.notify;
      let variantArray = [];
      for (const variant of data.variants) {
        delete variant._id;
        delete variant.createdAt;
        delete variant.client;
        delete variant.tenant;
        delete variant.updatedAt;
        delete variant.user;
        delete variant.sku_id;
        variantArray.push(variant);
      }
      let discountArray = [];
      for (const discount of data.product_discount) {
        delete discount._id;
        discountArray.push(discount);
      }
      data.variants = variantArray;
      data.product_discount = discountArray;
      if (window.confirm('Duplicate Product?')) {
        const addPro = await API({ url: '/super/admin/dummydata/dummyproduct/addDummyProduct', method: 'post', data: data });
        if (addPro.status === 200 || addPro.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Duplicate product added successfully');
          setSeverity('success');
          GetAllProducts();
        }
      }
    }
  };
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...Product?.data?.records];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    sortProduct(DragOverItem.current, dragItemContent._id);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = { data: { records: _sort } };
    setAllProduct(FinalArray);
    //  GetAllProducts()
  };
  const tableData = Product?.data?.records;
  // Drag And Drop Functionality
  const columns = [
    {
      name: '',
      width: '30px',
      padding: '0px',
      cell: (row, index) => (
        <div
          className='cursor-move drag-btn block w-full'
          draggable
          onDragStart={(e) => (DragItem.current = index)}
          onDragEnter={(e) => (DragOverItem.current = index)}
          onDragEnd={HandleSort}
        >
          <Move className='cursor-move w-4' />
        </div>
      ),
    },
    {
      name: 'Image',
      cell: (row) => (
        <div className='line-clamp-1 block'>
          <img width={40} height={50}
            onError={(e) => { e.target.src = NOPHOTO_IMAGE }}
            src={row.image || NOPHOTO_IMAGE}
            alt={row.image || NOPHOTO_IMAGE}
            className='p-1' />
        </div>
      ),
      width: '80px',
    },
    {
      name: (
        <div className='flex items-center'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'product_name' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('product_name') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('product_name')}>
            Name
            {iconVisible && sortBy === 'product_name' ? (
              !orderBy.isDecending ? (
                <CaretUpFill className='ms-2' />
              ) : (
                <CaretDownFill className='ms-2' />
              )
            ) : (
              <></>
            )}
          </button>
        </div>
      ),
      cell: (row) => <p className='line-clamp-2'>{row.product_name}</p>,
      minWidth: '350px'
    },
    {
      name: 'type',
      cell: (row) => <p className='line-clamp-2'>{row.product_type || "-"}</p>,
    },
    {
      name: (
        <div className='flex items-center justify-end w-full'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'selling_price' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('selling_price') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('selling_price')}>
            {iconVisible && sortBy === 'selling_price' ? (
              !orderBy.isDecending ? (
                <CaretUpFill className='me-2' />
              ) : (
                <CaretDownFill className='me-2' />
              )
            ) : (
              <></>
            )}
            Price
          </button>
        </div>
      ),
      minWidth: '200px',
      className: 'text-right',
      cell: (row) => <span className='text-right w-full'>₹{row?.selling_price?.toFixed(2)}</span>,
    },
    {
      name: (
        <div className='flex items-center w-full justify-end'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'quantity' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('quantity') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('quantity')}>
            {iconVisible && sortBy === 'quantity' ? (
              !orderBy.isDecending ? (
                <CaretUpFill className='me-2' />
              ) : (
                <CaretDownFill className='me-2' />
              )
            ) : (
              <></>
            )}
            Quantity
          </button>
        </div>
      ),
      minWidth: '200px',
      cell: (row) => (
        <span className='block w-full text-right'>
          {row.quantity >= 5 && row.quantity <= 10 ? (
            <span className='text-yellow-600 bg-yellow-200 font-medium rounded-full w-6 h-6 flex items-center justify-center ml-auto'>
              {row.quantity}
            </span>
          ) : row.quantity >= 0 && row.quantity <= 5 ? (
            <span className='text-red-600 bg-red-200 font-medium rounded-full w-6 h-6 flex items-center justify-center ml-auto'>
              {row.quantity}
            </span>
          ) : (
            <span>{row.quantity}</span>
          )}
        </span>
      ),
    },
    {
      name: <span className='w-full block text-right'>Action</span>,
      minWidth: '150px',
      cell: (row) => (
        <div className='space-x-3 w-full flex justify-end items-center'>
          {
            openTab === 1 ?
              <>
                {row.featured_status === true ? (
                  <Tooltip title='Featured' arrow>
                    <button>
                      <StarFill
                        className='w-5 h-5 text-yellow-500'
                        onClick={() => handleFeatured(row._id, false)}
                      />
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip title='Featured' arrow>
                    <button>
                      <Star
                        className='w-5 h-5 text-yellow-500'
                        onClick={() => handleFeatured(row._id, true)}
                      />
                    </button>
                  </Tooltip>
                )}
                <button href='#' className='text-xs font-thin text-gray-700 hover:text-gray-900'>
                  <div>
                    <Tooltip title='Clone' arrow>
                      <button onClick={() => handleDublicate(row?.product_slug_name)}>
                        <Copy className='w-[16px] h-[16px] text-gray-700' />
                      </button>
                    </Tooltip>
                  </div>
                </button>
              </> : <></>
          }
          <button
            href='#'
            className='text-xs font-thin text-blue-500 hover:text-blue-600'
            onClick={() =>
              openTab === 1 ? navigate(`/dummy-data/dummy-product/publish=${row?.product_slug_name}`, { state: { row } }) :
                navigate(`/dummy-data/dummy-product/draft=${row?.draft_product_slug_name}`, { state: { row } })
            }
          >
            <div>
              <Tooltip title='Edit' arrow>
                <button className=''>
                  <Edit className='w-[17px] h-[17px]' />
                </button>
              </Tooltip>
            </div>
          </button>
          <button
            href='#'
            className='text-xs font-thin text-red-500 hover:text-red-600'
            onClick={() => HandleRemove(row._id)}
          >
            <div>
              <Tooltip title='Delete' arrow>
                <button className=''>
                  <Trash className='w-[17px] h-[17px]' />
                </button>
              </Tooltip>
            </div>
          </button>
        </div>
      ),
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-4'>
          <div>
            <Breadcrumbs />
            <h1 className='text-xl md:text-2xl font-medium text-primary'>Products</h1>
          </div>
          <div className='flex items-center bg-[#6a63FB] rounded text-sm font-medium'>
            <button
              className='flex bg-btn rounded-s px-5 py-2.5 text-sm items-center font-medium  text-white transition duration-300'
              onClick={() => navigate('/dummy-data/dummy-product/add-dummy-product')}
            >
              <PlusCircle className='w-[18px] h-[18px] me-2' />
              Add New Product
            </button>
            <button
              className='flex bg-btn px-3 border-s border-gray-600 rounded-e py-[9px] items-center text-white transition duration-300'
              onClick={handleClick}
            >
              <ChevronDown className='w-[18px] h-[18px]' />
            </button>
            <div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                className='text-sm'
              >
                <MenuItem className='text-sm block' onClick={() => navigate("/products/bulk-upload")}>Bulk upload products</MenuItem>
                <MenuItem className='text-sm block' onClick={() => navigate("/products/shopify-product-import")}>Shopify import products</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <div className='flex justify-between w-full space-x-4'>
          <div className='w-full space-y-1.5'>
            <div className='flex items-center justify-between'>
              <details
                className={
                  selectedRowsId?.length === 0
                    ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative`
                    : `cursor-pointer group w-[200px] mb-1.5 relative`
                }
              >
                <summary className='flex bg-white  border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
                  <span> Bulk Selection</span>
                  <span
                    className={
                      selectedRowsId?.length === 0
                        ? 'transition group-open:rotate-0'
                        : 'transition group-open:rotate-180'
                    }
                  >
                    <svg
                      fill='none'
                      height={14}
                      shapeRendering='geometricPrecision'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      width={14}
                    >
                      <path d='M6 9l6 6 6-6' />
                    </svg>
                  </span>
                </summary>
                <div
                  className={
                    selectedRowsId?.length === 0
                      ? `hidden`
                      : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
                  }
                >
                  <button
                    className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center'
                    onClick={() => HandleRemove()}
                  >
                    <TrashFill className='w-3.5 h-3.5' />
                    <span>Delete selection</span>
                  </button>
                  {/* {
                    openTab === 1 &&
                    <button
                    className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center'
                    onClick={HandleExport}
                  >
                    <BoxArrowRight className='w-3.5 h-3.5' />
                    <span>Export selection</span>
                  </button>
                  } */}

                </div>
              </details>
              <button
                className={'filter-btn'}
                onClick={() => setFilter(!filter)}
              >
                <Tooltip title='Filter' arrow>
                  <button className=''>
                    <Filter className='h-5 w-5' />
                  </button>
                </Tooltip>
              </button>
            </div>
            <div className={filter ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative' : 'flex w-full h-full overflow-hidden relative'}>
              <div className='w-full'>
                <ul
                  className='flex items-center mt-2 mb-0 ms-2 list-none overflow-x-auto flex-row'
                  role='tablist'
                >
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs font-bold break-keep uppercase px-5 xl:px-10 pb-4 block leading-normal transition-all duration-150 ' +
                        (openTab === 1
                          ? 'text-link border-b-2 border-b-[#6a63fb]'
                          : 'text-black border-b-2 border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                      }}
                      data-toggle='tab'
                      href='#link1'
                      role='tablist'
                    >
                      Published
                    </a>
                  </li>
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs font-bold break-keep uppercase px-5 xl:px-10 pb-4 block leading-normal transition-all duration-150 ' +
                        (openTab === 2
                          ? 'text-link border-b-2 border-b-[#6a63fb]'
                          : 'text-black border-b-2 border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      data-toggle='tab'
                      href='#link1'
                      role='tablist'
                    >
                      draft
                    </a>
                  </li>
                </ul>
                <div className='w-full  transition-all duration-100 inline-block mb-2.5 overflow-hidden border border-gray-300 rounded-[10px]'>
                  <DataTable
                    columns={columns}
                    data={tableData}
                    selectableRows
                    // onRowClicked={handleRowClick}
                    onSelectedRowsChange={({ selectedRows }) => {
                      setSelectedRowsId(selectedRows?.map((x) => x._id));
                    }}
                    selectableRowsHighlight
                    highlightOnHover
                    progressPending={pending}
                    progressComponent={<Loader />}
                  />
                </div>
                {/* Paginator */}
                {Product?.data ? (
                  <div className='bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                    <div className='hidden sm:block'>
                      <p className='flex w-full text-black space-x-4'>
                        <span className='text-sm font-medium'>
                          {limit === 'all'
                            ? `${Product?.data?.totalRecords} Records`
                            : `${startRecord} - ${endRecord} of ${Product?.data?.totalRecords}`}
                        </span>
                      </p>
                    </div>
                    <div>
                      {Product?.data?.totalPages !== 1 ? (
                        <Pagination
                          count={Product?.data?.totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className={`z-10 transition-all h-fit duration-300 fixed 2xl:relative bg-white rounded-[10px] overflow-hidden ${filter ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0 -right-full'}`}>
                <p className='flex text-gray-600 font-semibold uppercase text-sm m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  Filter
                </p>
                <hr className='border-gray-300 my-3'></hr>
                <div className='grid gap-y-5'>
                  <div>
                    <span className='text-sm text-gray-600 font-medium mb-1 inline-block'>
                      Search
                    </span>
                    <label className='relative block w-full'>
                      <input
                        className='w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-[10px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                        placeholder='Search Products'
                        type='text'
                        name='search'
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setPage(1);
                        }}
                      />
                    </label>
                  </div>
                  <div>
                    <span className='text-sm text-gray-600 font-medium mb-1 inline-block'>
                      Filter by category
                    </span>
                    {
                      <Autocomplete
                        className='autocomplete'
                        options={categories?.records ? categories.records : []}
                        getOptionLabel={(category) => category.category_name}
                        onChange={(e, v) => {
                          v ? setfiltercategory([v._id]) : setfiltercategory();
                          setPage(1);
                        }}
                        renderInput={(Categories) => (
                          <TextField
                            placeholder='Select category'
                            className='cursor-pointer'
                            {...Categories}
                          />
                        )}
                      />
                    }
                  </div>
                  <div>
                    <span className='text-sm text-gray-600 font-medium mb-1 inline-block'>
                      Set items limit
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border border-gray-300 p-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => {
                          e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                          setPage(1);
                        }}
                      >
                        {Product?.data?.totalRecords > 10 ? (
                          <>
                            <option value='10'>10</option>
                          </>
                        ) : (
                          <></>
                        )}
                        {Product?.data?.totalRecords > 20 ? (
                          <option value='20'>20</option>
                        ) : (
                          <></>
                        )}
                        {Product?.data?.totalRecords > 50 ? (
                          <option value='50'>50</option>
                        ) : (
                          <></>
                        )}
                        <option value='all'>All</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DummyProductTable;
