import React, { useState } from 'react'
import Breadcrumbs from '../../controllers/breadCrumbs'
import API from '../../API'

const Domains = () => {
    const [domain, setDomain] = useState()
    const [data, setData] = useState()
    const [status, setStatus] = useState()
    const handleGetDomainDetail = async (e) => {
        e.preventDefault();
        try {
            await API({ url: `/super/admin/tenant/getCustomDomainDetail`, method: 'post', data: {
                custom_domain: domain
            }})
            setData();
        } catch (error) {
            setStatus(error?.response?.status)
            setData(error?.response?.data);
        }
    }
    const handleDomainCleanUp = async (e) => {
        e.preventDefault();
        try {
            await API({ url: `/super/admin/tenant/domain_cleanup`, method: 'post', data: {
                custom_domain: domain
            }})
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className="w-full pb-[64px]">
            <div>
                <Breadcrumbs crumbs={[
                    { name: 'Dashboard', path: `/dashboard` },
                    { name: 'Domains', path: `/domains` }
                ]} />
                <h1 className='text-xl md:text-2xl font-semibold text-primary'>Domains</h1>
            </div>
            <div className=" pt-4 overflow-x-hidden pl-3  h-full w-full">
                <form
                    method="POST"
                    className="w-[90%] lg:w-[70%] xl:w-[60%] text-sm bg-white border border-gray-300 p-5 mx-auto rounded-lg"
                >
                    <div className="mb-4 flex w-full justify-between space-x-3">
                        <div className="w-full">
                            <label
                                className="flex text-gray-700 text-sm font-medium mb-1"
                                htmlFor="receiver"
                            >
                                Enter domain
                            </label>
                            <input
                                className={`w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`}
                                type="text"
                                id="name"
                                name="name"
                                value={domain}
                                onChange={(e) => setDomain(e.target.value)}
                                placeholder='Ex. Whispo.shop'
                            />
                        </div>
                    </div>
                    <button className='btn text-sm text-white' onClick={(e) => { handleGetDomainDetail(e) }}>Get Domian Details</button>
                    {
                        data &&
                        <>
                            <p className='text-red-500 my-2 font-medium text-sm'>{data?.message}</p>
                            <div className='flex items-start'>
                                <p className='font-medium capitalize'>Request From : </p>
                                <div className='flex flex-col ms-2 gap-2'>
                                    <span className='text-sm'><span className='font-medium capitalize'>store name : </span>{data?.data?.business_name}</span>
                                    <span className='text-sm'><span className='font-medium capitalize'>store email : </span>{data?.data?.business_email}</span>
                                    <span className='text-sm'><span className='font-medium capitalize'>store id : </span>{data?.data?.store_id}</span>
                                    <span className='text-sm'><span className='font-medium capitalize'>store category : </span>{data?.data?.business_category}</span>
                                </div>
                            </div>
                        </>
                    }
                    {
                        status === 409 &&
                        <div className='btn text-sm inline-block mt-4 text-white' onClick={(e) => handleDomainCleanUp(e)}>domain cleanup</div>
                    }
                </form>
            </div>
        </div>
    )
}

export default Domains