import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import API from '../../../API';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import { GenerateSlugName } from '../../../controllers/GenerateSlugName';
import { ArrowLeft, Check, Edit, X } from 'feather-icons-react/build/IconComponents';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import { Popover } from '@mui/material';
const CreateDummyPages = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [pageTitle, setPageTitle] = useState('')
  const [value, setValue] = useState('');

  // function generateSlug() {
  //   let slug = pageTitle?.toLowerCase(RefundPolicy, Termspolicy, PrivacyPolicy);
  //   slug = slug?.replace(/[^a-z0-9-]+/g, '-');
  //   slug = slug?.replace(/^-+|-+$/g, '');
  //   setSlugName(slug);
  //   return slug;
  // }
  const [slugField, setSlugField] = useState(false)
  const [slugName, setSlugName] = useState();


  const handleSlug = async (slug) => {
    setSlugField(true)
    setSlugName(await GenerateSlugName(slug));
  }

  const [err, setErr] = useState(false)
    // Quill Emmpty check
    const strippedContent = value?.replace(/<[^>]*>/g, '');
    const trimmedContent = strippedContent?.trim();
  const [enableSlug, setEnableSlug] = useState(false)
  const HandleSubmit = async (e) => {
    e.preventDefault()
    if (pageTitle === "" ||trimmedContent === '' || enableSlug) {
      setErr(true)
    }
    else {
      try {
        const HSubmit = await API({ url: '/super/admin/dummydata/dummypages/addDummyPage', method: 'post', data: {
          pages_name: pageTitle,
          page_value: value,
          page_slug_name: slugName
        }});
        if (HSubmit.status === 200 || HSubmit.status === 304) {
          navigate('/dummy-data/dummy-pages');
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }


  };

  // useEffect(() => {
  //   generateSlug();
  // }, [open]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };

const [html, setHtml] = useState({htmlPP:false,code:''});
const {htmlPP,code}=html
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML
    const formattedHtml = formatCode(htmlContent);
    setHtml({htmlPP:true,code:formattedHtml}); // Log or use the HTML content as needed
  };

  const saveHTML=()=>{
    setHtml({...html,htmlPP:false})
    setValue(code)
  }
  

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <Breadcrumbs />
        <h1 className='text-xl md:text-2xl font-medium pb-4 text-primary'>Add new page</h1>
        <form className='w-[90%] lg:w-[70%] xl:w-[60%] space-y-4 mx-auto'>

          <>
            <div className='bg-white p-[25px] shadow rounded-xl'>
              <div className='mb-4'>
                <label htmlFor='title' className='flex items-center justify-between'>
                <p className='text-[13px]'>page Title<span className='text-red-500 ms-1'>*</span></p>
                  <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p>
                </label>
                <input
                  className={err && pageTitle?.length === 0 ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none` : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`}
                  type='text'
                  name='pagename'
                  id='pagename'
                  maxLength={60}
                  disabled={location?.state?.pageName}
                  value={pageTitle}
                  placeholder='Enter page name'
                  onChange={(e) => setPageTitle(e.target.value)}
                  onBlur={() => handleSlug(pageTitle)}
                />
                {err && pageTitle?.length === 0 ? <p className='text-red-500 text-xs '>Please enter page title</p> : ''}
              </div>
              {
                slugField &&
                <div className='mb-4'>
                  <label htmlFor='category_name'>
                    Page Slug Name
                  </label>
                  <div className={`flex items-center justify-between w-full border ${err && enableSlug ? 'border-red-500' : 'border-gray-300'} rounded-[4px]`}>
                    <input
                      className="w-full  focus:outline-none"
                      type='text'
                      name='page_slug_name'
                      value={slugName}
                      disabled={!enableSlug}
                      onChange={(e) => setSlugName(e.target.value)}
                    // }
                    // onBlur={async (e) => setSlugName(await GenerateSlugName(e.target.value))}
                    />
                    {
                      enableSlug ?
                        <Check onClick={async () => { setSlugName(await GenerateSlugName(slugName)); setEnableSlug(!enableSlug); setErr(false) }} className='text-green-500 p-1 h-8 w-8' /> :
                        <Edit onClick={() => setEnableSlug(!enableSlug)} className='text-blue-500 p-1 h-7 w-7' />
                    }
                  </div>
                  {err && enableSlug && <p className='text-xsm text-red-500'>Please save this changes</p>}
                </div>
              }
              <div className='relative mb-4'>
              <div className='flex justify-between items-center'>
                <label htmlFor='title'>
                <p className='text-[13px]'>page content<span className='text-red-500 ms-1'>*</span></p>
                </label>
                <section
                    className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                    onClick={handleViewHtml}
                  >
                    View HTML
                  </section>
               </div>
                <div className={err &&trimmedContent === '' ? 'border rounded border-red-500' : 'w-full h-auto'}>
                 <ReactQuill
                  ref={quillRef}
                  modules={module}
                  theme='snow'
                  value={value}
                  onChange={handleQuillChange}
                />
                </div>
                {
                  err && trimmedContent==='' && value?.length === 0 ? <p className='text-xs text-red-500'>Please enter page value</p> : <></>
                }
              </div>
            </div>
            <div className='flex items-center justify-between w-full'>
              <Link to={`/pages`} className='w-fit flex text-link font-medium hover:underline text-sm items-center '><ArrowLeft className='w-3 mr-1' />Go to pages</Link>
              <button
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                onClick={HandleSubmit}
              >
                Add New Page
              </button>
            </div>
          </>
        </form>
      </div>
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
            // onClose={() => {
            //   setHtmlPP(false);
            // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] h-96  mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X onClick={()=>{
                    if(window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")){
                      setHtml({...html,htmlPP:false})
                    }
                  }}/>
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea 
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({...html,code:e.target.value})}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              </div>
              <button
                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

export default CreateDummyPages;
