import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../../API';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import ReactQuill from 'react-quill';
import { ArrowLeft, X } from 'feather-icons-react/build/IconComponents';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import { Popover } from '@mui/material';

function CreateDummyCustomeTab() {
  const navigate = useNavigate();
  const [tabStatus, setTabStatus] = useState(false);
  const [description, setDescription] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [tabData, setTabData] = useState({
    title: '',
  });
  const [sortOrder, setSortOrder] = useState('');
  const handleChange = (e) => {
    setTabData({ ...tabData, [e.target.name]: e.target.value });
  };
  const [err, setErr] = useState(false);
    // Quill Emmpty check
    const strippedContent = description?.replace(/<[^>]*>/g, '');
    const trimmedContent = strippedContent?.trim();
  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (tabData?.title === '' || trimmedContent === '') {
      setErr(true);
    } else {
      try {
        await API({ url: '/super/admin/dummydata/dummyextratabs/addExtratab', method: 'post', data: {
          tab_title: tabData ? tabData.title : '',
          tab_description: description,
          sort_order:sortOrder,
          tab_status: tabStatus,
        }});
        navigate('/dummy-data/dummy-custometab');
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
        //Quil To HTML code and Functions
        const quillRef = useRef(null);
        const handleQuillChange = (value) => {
          setDescription(value);
        };
      
      const [html, setHtml] = useState({htmlPP:false,code:''});
      const {htmlPP,code}=html
        const handleViewHtml = (e) => {
          e.preventDefault();
          const quill = quillRef.current.getEditor();
          const htmlContent = quill.root.innerHTML
          const formattedHtml = formatCode(htmlContent);
          setHtml({htmlPP:true,code:formattedHtml}); // Log or use the HTML content as needed
        };
      
        const saveHTML=()=>{
          setHtml({...html,htmlPP:false})
          setDescription(code)
        }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <Breadcrumbs />
        <h1 className='text-xl md:text-2xl pb-4 font-medium text-primary'>
          Add new custom tab
        </h1>
        <div className='flex justify-evenly'>
          <form
            method='POST'
            className='space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='bg-white shadow  rounded-xl p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='title'>
                  Title
                </label>
                <input
                  className={
                    err && tabData?.title?.length === 0
                      ? `w-full px-3 py-2 border border-red-500 rounded-md focus:outline-none`
                      : `w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none`
                  }
                  type='text'
                  name='title'
                  placeholder='Enter tab title'
                  value={tabData.title}
                  onChange={(e) => handleChange(e)}
                />
                {err && tabData?.title?.length === 0 ? (
                  <p className='text-red-500 text-sm font-medium'>Please enter tab title</p>
                ) : (
                  <></>
                )}
              </div>
              <div className='mb-4'>
              <div className='flex justify-between items-center'>
                <label htmlFor='description'>
                 Description
                </label>
                <section
                    className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                    onClick={handleViewHtml}
                  >
                    View HTML
                  </section>
               </div>
                <ReactQuill
                  ref={quillRef}
                  modules={module}
                  theme='snow'
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={handleQuillChange}
                />
                <p className='text-right w-full text-xs mt-0 text-gray-400'>
                  of 280 words allowed
                </p>
              </div>
              <div className='mb-4'>
                <label htmlFor='sort_order'>
                  sort order
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='number'
                  id='sort_order'
                  name='sort_order'
                  value={sortOrder}
                  onChange={(e) => { setSortOrder(e.target.value<=0 ? 0 : e.target.value)}}
                  placeholder='Enter sort order'
                />
              </div>
              <div className='flex items-center mb-3'>
                <h3 className='font-medium text-md me-2'>Staus : </h3>
                <input
                  className="mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault'
                  checked={tabStatus === true}
                  name='slider'
                  value={tabStatus}
                  onClick={() => {
                    setTabStatus((prev) => !prev);
                  }}
                />
                {tabStatus ? (
                  <p className='text-sm font-medium'>Enable</p>
                ) : (
                  <p className='text-sm font-medium'>Disable</p>
                )}
              </div>
            </div>
            <div className='flex items-center justify-between w-full'>
              <Link
                to={`/tabs`}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                Go to custom tabs
              </Link>
              <button
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-md transition duration-300'
                type='submit'
                onClick={HandleSubmit}
              >
                Add Custom Tab
              </button>
            </div>
          </form>
        </div>
      </div>
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
            // onClose={() => {
            //   setHtmlPP(false);
            // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] h-96  mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X onClick={()=>{
                    if(window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")){
                      setHtml({...html,htmlPP:false})
                    }
                  }}/>
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea 
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({...html,code:e.target.value})}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              </div>
              <button
                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
}

export default CreateDummyCustomeTab;
