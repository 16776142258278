import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettledPayments } from "../../Redux/Action/payment.action";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";
import FormatAmount from "../../controllers/FormatAmount";

const SettleAmount = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettledPayments())
  }, [])

  const { settlePayments } = (useSelector((state) => state.paymentReducer));
  // table Data
  const columns = [
    {
      name: 'Tenant Name',
      cell: (row) => (
        <p className='line-clamp-2'>{row?.tenant_name}</p>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          >
            Paid Amout
          </button>
        </div>
      ),
      width: "150px",
      right: true,
      cell: (row) => (
        <span className="line-clamp-1">
          {FormatAmount(row.pay_out_data?.payable_amount ? row.pay_out_data?.payable_amount : 0)}
        </span>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          >
            shopeasy service fee
          </button>
        </div>
      ),
      right: true,
      width: "190px",
      cell: (row) => (
        <span className="line-clamp-1">
          {FormatAmount(row.pay_out_data?.shopeasy_service_fee)}
        </span>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          >
            total orders amount
          </button>
        </div>
      ),
      width: "190px",
      right: true,
      cell: (row) => (
        <span className="line-clamp-1">
          {FormatAmount(row.pay_out_data?.total_orders_amount)}
        </span>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          >
            shipping charges
          </button>
        </div>
      ),
      width: "190px",
      right: true,
      cell: (row) => (
        <span className="line-clamp-1 ">
          {FormatAmount(row.pay_out_data?.total_shipping_charges ? row.pay_out_data?.total_shipping_charges : 0)}
        </span>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          >
            transaction charge
          </button>
        </div>
      ),
      width: "190px",
      right: true,
      cell: (row) => (
        <span className="line-clamp-1">
          {FormatAmount(row.pay_out_data?.transaction_charge ? row.pay_out_data?.transaction_charge : 0)}
        </span>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          >
            Date
          </button>
        </div>
      ),
      width: "190px",
      right: true,
      cell: (row) => (
        <span className="line-clamp-1">
          {row?.createdAt ? moment(row.createdAt).format('lll') : ''}
        </span>
      ),
    },

    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <div className="space-x-2">
    //       <button
    //         onClick={(e) => {
    //           navigate("/announcement/updateannouncement", {
    //             state: { ...row },
    //           });
    //         }}
    //       >
    //         <button className="">
    //           <Edit className="w-[18px] h-[18px] text-blue-500" />
    //         </button>
    //       </button>

    //       <button
    //       //  onClick={() => HandleMultipleDelete(row._id)}
    //       >
    //         <button className="">
    //           <Trash className="w-[18px] h-[18px] text-red-500" />
    //         </button>
    //       </button>
    //       <button
    //         onClick={() =>
    //           navigate("/announcement/viewannouncement", { state: row._id })
    //         }
    //       >
    //         <button className="">
    //           <Eye className="w-[18px] h-[18px]" />
    //         </button>
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className="p-4 w-full">
      <div className="w-full flex border border-gray-300 category-table  overflow-x-auto overflow-y-hidden rounded-[4px]">
        <DataTable
          columns={columns}
          data={settlePayments?.records}
          fixedHeader
          highlightOnHover
        />
      </div>
    </div>
  );
};

export default SettleAmount;
