import React, { useEffect, useRef, useState } from 'react';
import API from '../../../API';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import {
  TextField,
  Autocomplete,
  Popover,
} from '@mui/material';
import { GenerateSlugName } from '../../../controllers/GenerateSlugName';
import { X, ArrowLeft, ChevronDown, Check, PlusCircle, Edit } from 'feather-icons-react/build/IconComponents';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import GenerateUniqueFilename from '../../../controllers/GenerateUniqueFilename';
import Notification from '../../Notification';
import ImageUploader from '../../../controllers/ImageUploader';

// import MyDummyBlogs from './MyDummyBlog';

function CreateDummyPortfolio() {
  const navigate = useNavigate();
  const [warn, setwarn] = useState(false);
  const [enableSlug, setEnableSlug] = useState(false)
  const [secondDescription, setSecondDescription] = useState('')
  const [title, setTitle] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [meta, setMeta] = useState({ meta_title: "", meta_description: "" })
  const { meta_description, meta_title } = meta
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [blogTagsArray, setBlogTagsArray] = useState([]);
  const [blogTagsValue, setBlogTagsValue] = useState([]);
  // save Blog Values In Array
  const SaveblogTagsValue = () => {
    if (blogTagsValue.length > 0) {
      if (blogTagsValue.includes(',')) {
        let TagSeperator = blogTagsValue.split(',');
        let finalArray = []
        let blogTags = [...blogTagsArray, ...TagSeperator]
        blogTags.filter(tag => {
          const searchRegex = new RegExp(`^${tag.trim()}$`, "i");
          let exists = finalArray.some((finalTag) => searchRegex.test(finalTag))
          if (!exists) {
            finalArray.push(tag.trim())
          }
        })
        setBlogTagsArray(finalArray);
      } else {
        const searchRegex = new RegExp(`^${blogTagsValue}$`, "i");
        const exists = blogTagsArray.some((tag) => searchRegex.test(tag));
        if (!exists) {
          setBlogTagsArray((prev) => [...prev, blogTagsValue]);
        }
      }
      setBlogTagsValue('');
    }
  };

  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      // only splice array when item is found
      blogTagsArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setBlogTagsArray([...blogTagsArray]);
  };

  // image upload logic
  const [image, setimage] = useState();
  const handleSetImage = (value) => {
    setimage(value)
  }
  const [deletePopup, setDeletePopup] = useState({ index: '', visibility: false });
  const [Extraimgloader, setextraImgLoader] = useState(false);
  const [array, setarray] = useState([]);

  const HandleImages = async (imagess) => {
    if (array?.length + imagess?.length > 10) {
      setIsNotification(true);
      setNotificationMsg('you Cannot Add Greater Than 10 Images');
      setSeverity('success');
    } else {
      setextraImgLoader(true);
      const formData = new FormData();
      for (let i of imagess) {
        let fileName = i?.name;
        if (!fileName) {
          fileName = await GenerateUniqueFilename();
        }
        formData.append('file', i, fileName);
      }
      formData.append('folder', 'additionaldummyportfolio');
      const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formData});
      setextraImgLoader(false);
      setarray([...array, ...res.data.data]);
    }
  };


  // drag And Drop
  const DragItem = useRef();
  const DragOverItem = useRef();
  // sorting DnD
  const HandleSort = (e) => {
    e.preventDefault();
    let images_array = [...array];
    const dragItemContent = images_array.splice(DragItem.current, 1)[0];
    images_array.splice(DragOverItem.current, 0, dragItemContent);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = images_array;
    setarray(FinalArray);
  };

  // remove perticular image from selected imagesss
  const removeImages = (index) => {
    if (window.confirm('Are You Sure To Remove This Image?')) {
      if (index > -1) {
        (
          // only splice array when item is found
          array.splice(index, 1)
        ); // 2nd parameter means remove one item only
      }
      setarray([...array]);
    }
  };

  // get Portfolio Parents Category
  const [portfolioCategory, setPortfolioCategory] = useState([]);
  const GetPortfolioCategory = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyportfoliocategory/getDummyPortfolioCategorys`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setPortfolioCategory(data?.data);
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  const [slugField, setSlugField] = useState(false)
  const [slugName, setSlugName] = useState();
  const handleSlug = async (slug) => {
    if (slug?.length) {
      setSlugField(true)
      setSlugName(await GenerateSlugName(slug));
    }
  }
  useEffect(() => {
    GetPortfolioCategory();
  }, []);
  const [err, setErr] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const handlePortfolioCategory = (id, name) => {
    if (id) {
      const cat = selectedCategory.find((x) => x.id === id);
      if (!cat) {
        setSelectedCategory([...selectedCategory, { id, name }]);
      }
    }
  }
  // add Blog
  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (title === "" || !image?.length || enableSlug) {
      setErr(true)
    } else {
      try {
        const data = await API({ url: '/super/admin/dummydata/dummyportfolio/addDummyPortfolio', method: 'post', data: {
          portfoliocategory: selectedCategory?.map((x) => x.id),
          title: title,
          image: image,
          additional_images: array,
          description: secondDescription,
          portfolio_slug_name: slugName,
          sort_order: sortOrder,
          // blog_tags: blogTagsArray?.filter(x => x !== ""),
          ...meta
        }});
        if (data.status === 200 || data.status === 304) {
          navigate(`/dummy-data/dummy-portfolio`);
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setSecondDescription(value);
  };

  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const { htmlPP, code } = html
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed

  };

  const saveHTML = () => {
    setHtml({ ...html, htmlPP: false })
    setSecondDescription(code)
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <Breadcrumbs />
        <h1 className='text-xl md:text-2xl font-medium pb-4 text-primary'>Add new portfolio post</h1>
        <div className='flex justify-evenly p-4'>
          <form
            method='POST'
            className='space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'>
              <div className='w-full'>
                <label htmlFor='title' className='flex items-center justify-between'>
                  <p className='text-[13px]'>Portfolio Title<span className='text-red-500 ms-1'>*</span></p>
                  <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p>
                </label>
                <input
                  className={(err && title?.length === 0) || warn ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none` : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`}
                  type='text'
                  name='title'
                  maxLength={60}
                  value={title}
                  onChange={(e) => { setTitle(e.target.value); setwarn(false) }}
                  placeholder='e.g. Blog about your latest products or deals'
                  onBlur={() => handleSlug(title)}
                />
                {
                  (err && title?.length === 0) || warn ? <p className='text-red-500 text-xs'>Please enter portfolio title</p> : <></>
                }
              </div>
              {
                slugField &&
                <div className='w-full'>
                  <label htmlFor='category_name'>
                    portfolio Slug name
                  </label>
                  <div className={`flex items-center justify-between w-full border ${err && enableSlug ? 'border-red-500' : 'border-gray-300'} rounded-[4px]`}>

                    <input
                      className="w-full  focus:outline-none"
                      type='text'
                      name='blog_slug_name'
                      value={slugName}
                      disabled={!enableSlug}
                      onChange={(e) => setSlugName(e.target.value)}
                    />
                    {
                      enableSlug ?
                        <Check onClick={async () => { setSlugName(await GenerateSlugName(slugName)); setEnableSlug(!enableSlug); setErr(false) }} className='text-green-500 p-1 h-8 w-8' /> :
                        <Edit onClick={() => setEnableSlug(!enableSlug)} className='text-blue-500 p-1 h-7 w-7' />
                    }
                  </div>
                  {err && enableSlug && <p className='text-xsm text-red-500'>Please save this changes</p>}
                </div>
              }
              <div className='mb-4'>
                <label htmlFor='description'>
                  Portfolio Category
                </label>
                <Autocomplete
                  className='autocomplete'
                  options={portfolioCategory?.data ? portfolioCategory?.data?.records : []}
                  getOptionLabel={(category) => category.portfoliocategory_name}
                  onChange={(e, v) => v ? handlePortfolioCategory(v?._id, v?.portfoliocategory_name) : ''}
                  renderInput={(Categories) => (
                    <TextField
                      placeholder='Select portfolio category'
                      className='cursor-pointer'
                      {...Categories}
                    />
                  )}
                />
              </div>
              <div className='mb-4'>
                <div className='flex space-x-3 justify-between items-center mb-1'>
                  <label
                    htmlFor='Description'
                  >
                    Portfolio description
                  </label>
                  <div>
                    <span
                      className='inline-block cursor-pointer text-link underline align-baseline text-sm rounded-[10px] transition duration-300'
                      onClick={() => {
                        title === '' ? setwarn(true) : setwarn(false);
                      }}
                    >
                      Get description
                    </span>
                    <section
                      className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                      onClick={handleViewHtml}
                    >
                      View HTML
                    </section>
                  </div>

                </div>
                <ReactQuill
                  modules={module}
                  className={'w-full h-auto'}
                  theme='snow'
                  ref={quillRef}
                  placeholder='Enter blog content'
                  value={secondDescription}
                  onChange={handleQuillChange}
                />
                <div className='flex items-center justify-between'>
                  <p className='text-xs mt-0 text-gray-400'>
                    of 5000 words allowed
                  </p>
                </div>
              </div>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='Description'>
                    portfolio Tags
                  </label>
                  <p className='text-gray-500 font-normal text-xs'>
                    Use <b className='text-black'>Enter</b> key to separate the tags
                  </p>
                </div>
                <input
                  className={`w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`}
                  type='text'
                  id='blog_tags'
                  name='blog_tags'
                  value={blogTagsValue}
                  onChange={(e) => setBlogTagsValue(e.target.value)}
                  onKeyDown={(e) => (e.key === 'Enter' ? SaveblogTagsValue() : '')}
                  placeholder='e.g. Black'
                />
                <div className='flex mt-1 flex-wrap items-center'>
                  {blogTagsArray &&
                    blogTagsArray.map((elem, index) => {
                      if (elem !== "")
                        return (
                          <span
                            key={index}
                            className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                          >
                            {elem}
                            <X
                              className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                              onClick={() => RemoveTag(index)}
                            />
                          </span>
                        );
                    })}
                </div>
              </div>
            </div>
            <details open className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
              <summary
                className='text-base flex items-center justify-between font-medium text-black'
              >
                <div>
                  <span>Media</span>
                  <p className='text-xs text-gray-500 font-normal'>Upload captivating image to make your portfolio stand out.</p>
                </div>
                <span className="transition group-open:rotate-180">
                  <ChevronDown className="text-black w-4 h-5" />
                </span>
              </summary>
              <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} folder="portfolio" format="image" />
              <div className='w-full'>
                <label htmlFor='product_name'>Additional images</label>
                {array?.length === 0 ? (
                  <>
                    <label
                      htmlFor='additional-file'
                      className='flex items-center p-3 min-h-[100px] justify-center border border-gray-300 rounded cursor-pointer hover:bg-gray-50 border-dashed dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
                    >
                      <div className='text-center cursor-pointer'>
                        <input
                          id='additional-file'
                          type='file'
                          name='image'
                          multiple
                          onChange={(e) => HandleImages(e.target.files)}
                          className='hidden'
                        />
                        <div className=''>
                          {array?.length === 0 && !Extraimgloader ? (
                            <>
                              <svg
                                className='w-7 h-7 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 20 16'
                              >
                                <path
                                  stroke='currentColor'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth={1}
                                  d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                                />
                              </svg>
                              <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                                <span className='font-medium'>Click to upload</span>
                              </p>
                              <p className='text-xs font-normal mb-1 text-gray-500 dark:text-gray-400'>
                                Recommended size 800 x 800px
                              </p>
                            </>
                          ) : Extraimgloader === true ? (
                            <div className='w-[100px] mx-auto h-[100px] flex items-center justify-center mt-2 p-1 border border-gray-300'>
                              <div className='nb-spinner'></div>
                            </div>
                          ) : (
                            <div className='w-[100px] mx-auto h-[100px] flex items-center justify-center mt-2 p-1 border border-gray-300'>
                              <img
                                className='w-24 mx-auto border p-1 mt-1 border-gray-300'
                                src={image}
                                alt={image}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </>
                ) : (
                  <div className='flex items-start min-h-[210px] space-x-4 p-3  border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 '>
                    {Extraimgloader === true ? (
                      <div className='w-full h-[180px] flex items-center justify-center'>
                        <div className='nb-spinner'></div>
                      </div>
                    ) : (
                      <div>
                        {array?.length !== 0 && (
                          <div className='flex flex-wrap'>
                            {array?.map((x, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className='relative flex items-center justify-center rounded-[10px] mb-2 me-2 border w-[90px] h-[90px] hover:border-2 hover:border-black box-border'
                                  >
                                    <img
                                      src={x}
                                      alt={x}
                                      draggable
                                      onDragStart={(e) => (DragItem.current = index)}
                                      onDragEnter={(e) => (DragOverItem.current = index)}
                                      onDragEnd={HandleSort}
                                      onClick={() => {
                                        setDeletePopup({
                                          index: index,
                                          visibility: true,
                                        });
                                      }}
                                      className={
                                        deletePopup?.visibility &&
                                          deletePopup?.index === index
                                          ? 'rounded-[10px] max-w-full max-h-full object-contain cursor-move hover:border-0'
                                          : 'w-full max-h-full object-contain cursor-move'
                                      }
                                    ></img>
                                    {deletePopup.visibility &&
                                      deletePopup.index === index && (
                                        <div className='text-white hover:border-0 border-3 top-0 right-0 absolute bg-black h-4 w-4 flex items-center justify-center'>
                                          <X
                                            className='text-white h-3 w-3 '
                                            onClick={() => {
                                              removeImages(index);
                                              setDeletePopup({
                                                index,
                                                visibility: false,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                  </div>
                                </>
                              );
                            })}
                            <label className=' w-[90px] cursor-pointer h-[90px] border border-gray-300 hover:bg-gray-100 hover:border-gray-500 text-gray-500 hover:text-gray-800 rounded-[10px] flex items-center justify-center'>
                              <div className='text-center text-sm'>
                                <PlusCircle className='w-5 h-5 mx-auto' />
                                <p className='text-sm'>Add more</p>
                              </div>
                              <input
                                id='additional-file'
                                type='file'
                                name='image'
                                multiple
                                onChange={(e) => HandleImages(e.target.files)}
                                className='hidden'
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </details>


            <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
              <summary
                htmlFor='Meta Tag Title'
                className='text-base flex items-center justify-between font-medium text-black'
              >
                <div>
                  <span>Search engine listing</span>
                  <p className='text-xs text-gray-500 font-normal'>Add a title and description to see how this product might appear in a search engine listing</p>
                </div>
                <span className="transition group-open:rotate-180">
                  <ChevronDown className="text-black w-4 h-5" />
                </span>
              </summary>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label
                    htmlFor='Meta Tag Title'
                    className='flex items-center justify-between'
                  >
                    Meta Title
                  </label>
                </div>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='text'
                  id='Meta Tag Title'
                  name='meta_title'
                  value={meta_title}
                  onChange={(e) => {
                    setMeta({ ...meta, [e.target.name]: e.target.value })
                  }}
                  placeholder='Enter meta title'
                />
              </div>

              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label
                    htmlFor='Meta Tag description'
                    className='flex items-center justify-between'
                  >
                    Meta Description
                  </label>
                </div>
                <textarea
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='text'
                  id='Meta Tag description'
                  name='meta_description'
                  value={meta_description}
                  onChange={(e) => {
                    setMeta({ ...meta, [e.target.name]: e.target.value })
                  }}
                  placeholder='Enter meta description'
                />
              </div>

              <div className='mb-4'>
                <label htmlFor='sort_order'>
                  sort order
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='number'
                  id='sort_order'
                  name='sort_order'
                  value={sortOrder}
                  onChange={(e) => { setSortOrder(e.target.value <= 0 ? 0 : e.target.value) }}
                  placeholder='Enter sort order'
                />
              </div>
            </details>
            <div className='flex items-center justify-between w-full'>
              <Link to={`/blog-post`} className='w-fit flex text-link font-medium hover:underline text-sm items-center '><ArrowLeft className='w-3 mr-1' />Go to blogs</Link>
              <button
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                type='button'
                onClick={(e) => HandleSubmit(e)}
              >
                Add New Portfolio Post
              </button>
            </div>
          </form>
        </div>
      </div>
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
          // onClose={() => {
          //   setHtmlPP(false);
          // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] h-96  mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X onClick={() => {
                    if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                      setHtml({ ...html, htmlPP: false })
                    }
                  }} />
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({ ...html, code: e.target.value })}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              </div>
              <button
                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
            </div>
          </Popover>
        </div>
      )}

    </>
  );
}

export default CreateDummyPortfolio;
