import React from "react"
import { useEffect, useState } from "react";
import {
    ChevronLeft,
    ChevronRight,
    Eye,
} from "feather-icons-react/build/IconComponents";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../controllers/breadCrumbs";
import API from "../../API";



const Subscriptions = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [SubscriptionInfo, setSubscriptionInfo] = useState();
    const [filter, setFilter] = useState({ limit: 10, page: 1 })
    const { page, limit } = filter


    const GetSubscription = async () => {
        try {
            await API(
                {
                    url: `/super/admin/plan/GetPruchasePlan`,
                    method: "get",
                    params: { page, limit }
                }
            )
                .then((res) => res.data)
                .then((data) => setSubscriptionInfo(data));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        GetSubscription();
    }, [page, limit]);

    // pagination
    const HandlePageClick = (data) => {
        setFilter({ ...filter, page: data.selected + 1 })
    };
    return (
        <>
            <div className=" w-full pb-[64px]">
                <div>
                    <Breadcrumbs crumbs={[
                        { name: 'Dashboard', path: `/dashboard` },
                        { name: 'Subscriptions', path: `/subscriptions` },
                    ]} />
                    <h1 className='text-xl md:text-2xl mb-4 font-semibold text-primary'>Subscriptions</h1>
                </div>
                <div className='w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
                    <table className="w-full text-left">
                        <>
                            <thead className="h-[45px]">
                                <tr>
                                    <th className="cursor-pointer px-3">Name</th>
                                    <th className="cursor-pointer px-3">Email</th>
                                    <th className="cursor-pointer px-3 text-right">Purchase Plan Count</th>
                                    <th className="cursor-pointer text-right px-3">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!SubscriptionInfo ? (
                                    <h1 className="text-gray-600 text-base p-3">No subscription found</h1>
                                ) : (
                                    SubscriptionInfo.data &&
                                    SubscriptionInfo?.data?.records.map((elem, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                className="bg-white text-left border-b border-slate-200 text-sm text-gray-600"
                                            >

                                                <td className="p-3.5 w-28">{elem.tenant_name}</td>
                                                <td
                                                    className="p-2"
                                                //   onClick={() => HandleView(product_name, mrp, image)}
                                                >
                                                    {elem.tenant_email}
                                                </td>
                                                <td className="p-2 text-right">{elem.plan_count}</td>
                                                <td className="space-x-3 pe-3 text-right">
                                                    <button className="inline-block">
                                                        <Eye
                                                            className="w-4 h-4 text-black"
                                                            onClick={() =>
                                                                navigate("/subscriptions/viewsubscription", { state: elem })
                                                            }
                                                        />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </>

                    </table>
                </div>
                {SubscriptionInfo?.data ? (
                    <div className='bg-white border mt-2 border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[4px]'>
                        <div className='hidden sm:block'>
                            <p className='text-sm flex text-gray-700 space-x-4'>
                                <div>
                                    Current Page :
                                    <span className='font-semibold ms-1'>
                                        {SubscriptionInfo?.data ? SubscriptionInfo?.data.currentPage : ''}
                                    </span>
                                </div>
                                <div>
                                    Total Records :
                                    <span className='font-semibold ms-1'>
                                        {SubscriptionInfo?.data ? SubscriptionInfo?.data?.totalRecords : ''}
                                    </span>
                                </div>
                            </p>
                        </div>
                        <div>
                            {SubscriptionInfo?.data?.totalPages !== 1 ? (
                                <ReactPaginate
                                    previousLabel={<ChevronLeft className='w-4 h-4' />}
                                    nextLabel={<ChevronRight className='w-4 h-4' />}
                                    breakLabel={'...'}
                                    pageCount={SubscriptionInfo?.data?.totalPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={HandlePageClick}
                                    containerClassName='isolate inline-flex space-x-2'
                                    pageLinkClassName='relative z-10 w-7 h-7 hover:bg-gray-200 hover:text-black rounded-full flex items-center justify-center text-sm font-medium'
                                    previousClassName='relative z-10 w-7 h-7 rounded-full text-black flex items-center justify-center font-semibold hover:border-2 hover:text-gray-500 transition-all duration-150 text-sm'
                                    nextClassName='relative z-10 w-7 h-7 rounded-full text-black flex items-center justify-center hover:border font-semibold hover:text-gray-500 transition-all duration-300 text-sm'
                                    breakClassName='relative z-10 w-8 h-8 rounded-full hover:bg-gray-200 flex items-center justify-center text-black font-semibold border-2'
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

            </div>
        </>
    )
}
export default Subscriptions;