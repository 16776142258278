import {
  ChevronDown,
  ChevronUp,
  Edit,
  Filter,
  PlusCircle,
  Trash,
} from "feather-icons-react/build/IconComponents";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { GetGuideCategories } from "../../Redux/Action/guide.action";
import { GetDeleteGuideCategories } from "../../Redux/Action/guide.action"
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../controllers/breadCrumbs";
const MyGuideCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState("");
  const { allGuidesCategories } = useSelector((state) => state.guidesReducer)
  const [iconVisible, setIconVisible] = useState(false);
  const [filterDD, setFilterDD] = useState(false);
  const [filter, setFilter] = useState({ search: "", a_type: '' });
  const [orderBy, setOrderBy] = useState({
    orderByValue: "",
    isDecending: false,
  });
  const [selectedRowsId, setSelectedRowsId] = useState([]);

  useEffect(() => {
    if (filter.search?.length === 0 || filter.search?.length >= 3) {
      dispatch(GetGuideCategories(filter.search));
    }
  }, [filter.search]);

  // Sorting Data
  const SortData = (sort_by) => {
    setSortBy(sort_by);
    setIconVisible(true);
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: "DESC", isDecending: true });
    } else {
      setOrderBy({ orderByValue: "ASC", isDecending: false });
    }
  };


  // Api call For delete  Announcement
  const HandleMultipleDelete = async (id) => {

    dispatch(GetDeleteGuideCategories(selectedRowsId, id))
  };

  // table Data
  const columns = [
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
            onClick={() => SortData("category_name")}
          >
            Category
          </button>
          {iconVisible && sortBy === "category_name" ? (
            !orderBy.isDecending ? (
              <ChevronUp className="w-3 h-3" />
            ) : (
              <ChevronDown className="w-3 h-3" />
            )
          ) : (
            <></>
          )}
        </div>
      ),
      width: "200px",
      cell: (row) => (
        <span className="line-clamp-1">

          {row.category_name}
        </span>
      ),

    },
    {
      name: <p className="w-full block">Description</p>,
      cell: (row) => (
        <span className="line-clamp-1" dangerouslySetInnerHTML={{ __html: row.description }}>
          {/* {row.description} */}
        </span>
      )
    },
    {
      name: <p className="w-full text-right block">ACTION</p>,
      cell: (row) => (
        <div className="space-x-2 w-full flex items-center justify-end">
          <button
            onClick={(e) => {
              navigate(`/guide-categories/update-categories/${row._id}`);
            }}
          >
            <button className="">
              <Edit className="w-[18px] h-[18px] text-blue-500" />
            </button>
          </button>

          <button onClick={() => HandleMultipleDelete(row._id)}>
            <button className="">
              <Trash className="w-[18px] h-[18px] text-red-500" />
            </button>
          </button>

        </div>
      ),
    },
  ];



  return (
    <div className=" w-full pb-[64px]">
      <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Guide categories', path: `/guide-categories` },
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>Guide Categories</h1>
        </div>
        <div className="flex space-x-3">
          <Link
            to={"/guide-categories/add-guide-categories"}
            className="flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
            type="submit"
          //   onClick={HandleSubmit}
          >
            <PlusCircle className="w-4 h-4 me-2" />
            Add Guide Category
          </Link>
          <button
            className="filter-btn rounded w-10 h-10"
            onClick={() => setFilterDD(!filterDD)}
          >
            <Filter className="inline-flex rounded-md w-5 h-5" />
          </button>
        </div>
      </div>
      <details
        className={
          selectedRowsId?.length === 0
            ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative`
            : `cursor-pointer group w-[200px] mb-1.5 relative`
        }
      >
        <summary className="flex bg-white border border-gray-300 px-2 py-2 text-sm rounded justify-between items-center list-none">
          <span> Bulk Selection</span>
          <span
            className={
              selectedRowsId?.length === 0
                ? "transition group-open:rotate-0"
                : "transition group-open:rotate-180"
            }
          >
            <svg
              fill="none"
              height={14}
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width={14}
            >
              <path d="M6 9l6 6 6-6" />
            </svg>
          </span>
        </summary>
        <div
          className={
            selectedRowsId?.length === 0
              ? `hidden`
              : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
          }
        >
          <button
            className="mt-1 text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center"
            onClick={() => HandleMultipleDelete()}
          >
            <Trash className="w-3.5 h-3.5" />
            <span>Delete selection</span>
          </button>
        </div>
      </details>
      <div className="w-full flex space-x-4">
        <div className="w-full space-y-4">
          <div className="w-full overflow-x-auto">
            <div className="w-full h-full border border-gray-300 category-table overflow-x-auto overflow-y-hidden rounded-[4px]">
              {allGuidesCategories ?
                <DataTable
                  columns={columns}
                  data={allGuidesCategories}
                  selectableRows
                  fixedHeaderScrollHeight="700px"
                  onSelectedRowsChange={({ selectedRows }) => {
                    setSelectedRowsId(selectedRows?.map((x) => x._id));
                  }}
                  selectableRowsHighlight
                  highlightOnHover
                />
                : <></>}
            </div>
          </div>

        </div>
        {filterDD ? (
          <div className="z-10 absolute right-5 h-auto xl:relative xl:right-0 w-auto xl:w-1/3 xl:h-full p-3 border border-gray-300 bg-white rounded-[4px] overflow-hidden">
            <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
              <Filter className="me-1 w-4 h-4" />
              Filter
            </p>
            <hr className="border-[#a0d0eb] my-3"></hr>
            <div className="grid gap-y-3 max-w-full overflow-hidden">
              <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Search
                </span>
                <label className="relative block w-full">
                  <input
                    className="w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    placeholder="Search for anything..."
                    type="text"
                    name="search"
                    value={filter.search}
                    onChange={(e) => setFilter({ search: e.target.value })}
                  />
                </label>
              </div>

              {/* <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    Sort By Store Status
                  </span>
                  <div>
                    <>
                      <select
                        onChange={(e) => {
                          if (e.target.value === "All") {
                            setFilter({ ...filter, store_status: "" });
                          } else {
                            setFilter({
                              ...filter,
                              store_status: e.target.value,
                            });
                          }
                        }}
                        name="verification_status"
                        className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                        value={store_status}
                      >
                        <option>All</option>
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                      </select>
                    </>
                  </div>
                </div> */}
            </div>
          </div>
        ) : (
          <></>
        )}

      </div>

      <ToastContainer />
    </div>
  );
};

export default MyGuideCategory;
