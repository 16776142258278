import { GET_SYSTEM_STATUS } from "../action.type";

const initialState={
    allSystemStatus:null
}

export const systemStatusReducer=(state=initialState,action)=> {
    switch (action.type) {
      case GET_SYSTEM_STATUS:
        return {
           allSystemStatus: action.payload?.data?.data,
        };
      default:
        return state;
    }
  }