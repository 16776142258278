import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import Notification from '../../Notification'
import { Link, useNavigate } from 'react-router-dom'
import { ChevronDown } from 'react-bootstrap-icons'
import API from '../../../API';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents'
const CreateDummyFooterGroup = () => {
    const [isNotification, setIsNotification] = useState(false)
    const [notificationMsg, setNotificationMsg] = useState('')
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false)
        setNotificationMsg('')
        setSeverity('')
    }
    const navigate = useNavigate();
    const [groupTitle, setGroupTitle] = useState()
    const [dataErr, setDataErr] = useState(false);
    const [customDataErr, setCustomDataErr] = useState(false);
    const [pageData, setPageData] = useState()
    const [categoryData, setCategoryData] = useState()
    const [filterData, setFilterData] = useState();
    const [socialData, setSocialData] = useState();
    const [customData, setCustomData] = useState({
        section_name: '',
        section_link: '',
        redirect_type: 'new'
    });
    const [storeCustomData, setStoreCustomData] = useState([])
    const getPageData = async () => {
        try {
            const getPages = await API({ url: `/super/admin/dummydata/dummypages/getDummyPages`, method: 'get' })
            setPageData(getPages?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }
    const getCategoryData = async () => {
        try {
            const getCategories = await API({ url: `/super/admin/dummydata/dummycategory/getDummyCategorys`, method: 'get' })
            setCategoryData(getCategories?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }
    const getFilterData = async () => {
        try {
            const getProductFilter = await API({ url: '/admin/client/GetProductFilter', method: 'get' });
            setFilterData(Object.entries(getProductFilter?.data).map(([name, value]) => ({
                filter_name: name?.toLowerCase(),
                status: value,
            })))
        } catch (error) {
            console.log(error);
        }
    }
    const getSocialData = async () => {
        try {
            const getClient = await API({ url: '/admin/client/getClient', method: 'get' });
            setSocialData(Object.entries(getClient?.data?.data?.social_media).map(([name, value]) => ({
                social_name: name?.toLowerCase(),
                redirect_link: value,
                status: value?.length > 8 ? true : false
            })))
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getPageData();
        getCategoryData();
        // getFilterData();
        // getSocialData();
    }, [])
    const [storeList, setStoreList] = useState([])
    const handleSelectProduct = (name, slugname, type, category_id) => {
        if (slugname) {
            const existList = storeList?.find((x) => x?.section_link === slugname);
            if (!existList) {
                setStoreList([...storeList, { section_name: name, section_link: slugname, section_type: type, category_id }])
            } else {
                const removeFilter = storeList?.filter((x) => x?.section_link !== slugname)
                setStoreList(removeFilter);
            }
        }
    }
    const handleAddCustomData = (e) => {
        e.preventDefault();
        if (customData?.section_name === '' || customData?.section_link === '') {
            setCustomDataErr(true)
        } else {
            setCustomDataErr(false)
            setStoreList([...storeList, { section_name: customData?.section_name, section_link: customData?.section_link, section_type: 'Custom', redirect_type: customData?.redirect_type }])
            setStoreCustomData([...storeCustomData, { section_name: customData?.section_name, section_link: customData?.section_link, section_type: 'Custom', redirect_type: customData?.redirect_type }]); setCustomData({ section_name: '', section_link: '', redirect_type: "new" })
        }
    }
    const handleAddSection = async (e) => {
        e.preventDefault();
        if (!groupTitle) {
            setDataErr(true)
        } else {
            setDataErr(false)
            try {
                await API({ url: `/super/admin/dummydata/dynamicdummyfooter/addFooterGroup`, method: 'post', data: {
                    footer_section_title: groupTitle,
                    footer: storeList
                }})
                navigate(`/dummy-data/module-setting/footer`)
            } catch (error) {
                setIsNotification(true)
                setNotificationMsg(error)
                setSeverity('error')
            }
        }
    }
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className='h-auto overflow-y-auto pb-[130px]'>
                <div className='w-full'>
                    <Breadcrumbs />
                    <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Add New Footer Section</h3>
                </div>
                <form className='w-[90%] lg:w-[70%] xl:w-[60%] space-y-4 mx-auto'>
                    <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
                        <div className='mb-4'>
                            <label htmlFor='image'>
                                footer section Title
                            </label>
                            <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${dataErr ? 'border-red-500' : ''}`} placeholder='e.g. Categories' value={groupTitle} onChange={(e) => setGroupTitle(e.target.value)} />
                            {
                                dataErr && !groupTitle && <p className='text-xs text-red-500'>Please enter section name</p>
                            }
                        </div>
                        <div className='mb-4'>
                            <details
                                open
                                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                            >
                                <summary className='text-base flex items-center justify-between text-black'>
                                    Content pages
                                    <span className='transition group-open:rotate-180'>
                                        <ChevronDown className='text-black w-4 h-5' />
                                    </span>
                                </summary>
                                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                                    {
                                        pageData?.records?.map((elem) => {
                                            return (
                                                <label className='flex items-center space-x-4'>
                                                    <input type='checkbox' value={elem?._id} onClick={(e) => handleSelectProduct(elem?.pages_name, `/page/${elem?.page_slug_name}`, 'Pages', elem?._id)} className='block w-4 h-4' />
                                                    <p className='text-sm text-black font-normal break-all'>{elem?.pages_name}</p>
                                                </label>
                                            )
                                        })
                                    }
                                </div>
                            </details>
                        </div>
                        {/* <div className='mb-4'>
                            <details
                                open
                                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                            >
                                <summary className='text-base flex items-center justify-between text-black'>
                                    Product Filter
                                    <span className='transition group-open:rotate-180'>
                                        <ChevronDown className='text-black w-4 h-5' />
                                    </span>
                                </summary>
                                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                                    {
                                        filterData?.map((elem) => {
                                            return (
                                                elem?.status === true &&
                                                <label className='flex items-center space-x-4'>
                                                    <input type='checkbox' value={elem?.filter_name} onClick={(e) => handleSelectProduct(elem?.filter_name, `/filter=${elem?.filter_name}`, 'Product Filter', elem?._id)} className='block w-4 h-4' />
                                                    <p className='text-sm text-black font-normal break-all'>{elem?.filter_name}</p>
                                                </label>
                                            )
                                        })
                                    }
                                </div>
                            </details>
                        </div> */}
                        <div className='mb-4'>
                            <details
                                open
                                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                            >
                                <summary className='text-base flex items-center justify-between text-black'>
                                    Categories
                                    <span className='transition group-open:rotate-180'>
                                        <ChevronDown className='text-black w-4 h-5' />
                                    </span>
                                </summary>
                                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                                    {
                                        categoryData?.records?.map((elem) => {
                                            return (
                                                <label className='flex items-center space-x-4'>
                                                    <input type='checkbox' value={elem?._id} onClick={(e) => handleSelectProduct(elem?.category_name, `/category/${elem?.category_slug_name}`, 'Product Category', elem?._id)} className='block w-4 h-4' />
                                                    <p className='text-sm text-black font-normal break-all'>{elem?.category_name}</p>
                                                </label>
                                            )
                                        })
                                    }
                                </div>
                            </details>
                        </div>
                        {/* <div className='mb-4'>
                            <details
                                open
                                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                            >
                                <summary className='text-base flex items-center justify-between text-black'>
                                    Social Media
                                    <span className='transition group-open:rotate-180'>
                                        <ChevronDown className='text-black w-4 h-5' />
                                    </span>
                                </summary>
                                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                                    {
                                        socialData?.map((elem) => {
                                            return (
                                                elem?.status &&
                                                <label className='flex items-center space-x-4'>
                                                    <input type='checkbox' value={elem?.social_name} onClick={(e) => handleSelectProduct(elem?.social_name, elem?.redirect_link, 'Social Media')} className='block w-4 h-4' />
                                                    <p className='text-sm text-black font-normal break-all'>{elem?.social_name}</p>
                                                </label>
                                            )
                                        })
                                    }
                                </div>
                            </details>
                        </div> */}
                        <div className='mb-4'>
                            <details
                                open
                                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                            >
                                <summary className='text-base flex items-center justify-between text-black'>
                                    Accounts
                                    <span className='transition group-open:rotate-180'>
                                        <ChevronDown className='text-black w-4 h-5' />
                                    </span>
                                </summary>
                                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                                    <label className='flex items-center space-x-4'>
                                        <input type='checkbox' onClick={(e) => handleSelectProduct("contact us", `/contact-us`, 'Your Accounts')} className='block w-4 h-4' />
                                        <p className='text-sm text-black font-normal break-all'>Contact us</p>
                                    </label>
                                    <label className='flex items-center space-x-4'>
                                        <input type='checkbox' onClick={(e) => handleSelectProduct("login", `/login`, 'Your Accounts')} className='block w-4 h-4' />
                                        <p className='text-sm text-black font-normal break-all'>Login</p>
                                    </label>
                                    <label className='flex items-center space-x-4'>
                                        <input type='checkbox' onClick={(e) => handleSelectProduct("my account", `/account`, 'Your Accounts')} className='block w-4 h-4' />
                                        <p className='text-sm text-black font-normal break-all'>My account</p>
                                    </label>
                                </div>
                            </details>
                        </div>
                        <div className='mb-4'>
                            <details
                                open
                                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                            >
                                <summary className='text-base flex items-center justify-between text-black'>
                                    Help
                                    <span className='transition group-open:rotate-180'>
                                        <ChevronDown className='text-black w-4 h-5' />
                                    </span>
                                </summary>
                                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                                    <label className='flex items-center space-x-4'>
                                        <input type='checkbox' onClick={(e) => handleSelectProduct("announcement", `/announcements`, 'Help')} className='block w-4 h-4' />
                                        <p className='text-sm text-black font-normal break-all'>Announcement</p>
                                    </label>
                                    <label className='flex items-center space-x-4'>
                                        <input type='checkbox' onClick={(e) => handleSelectProduct("blog", `/blog`, 'Help')} className='block w-4 h-4' />
                                        <p className='text-sm text-black font-normal break-all'>Blog</p>
                                    </label>
                                    <label className='flex items-center space-x-4'>
                                        <input type='checkbox' onClick={(e) => handleSelectProduct("portfolio", `/portfolio?page=1`, 'Help')} className='block w-4 h-4' />
                                        <p className='text-sm text-black font-normal break-all'>Portfolio</p>
                                    </label>
                                    <label className='flex items-center space-x-4'>
                                        <input type='checkbox' onClick={(e) => handleSelectProduct("FAQ", `/faqs`, 'Help')} className='block w-4 h-4' />
                                        <p className='text-sm text-black font-normal break-all'>FAQ</p>
                                    </label>
                                </div>
                            </details>
                        </div>
                        <details
                            open
                            className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                        >
                            <summary className='text-base flex items-center justify-between text-black'>
                                Custom content
                                <span className='transition group-open:rotate-180'>
                                    <ChevronDown className='text-black w-4 h-5' />
                                </span>
                            </summary>
                            <div className='w-full pt-4  border-t'>
                                <div className='mb-4'>
                                    <label htmlFor='image'>
                                        Title
                                    </label>
                                    <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${customDataErr && customData?.section_name === "" ? 'border-red-500' : ''}`} placeholder='e.g. Google' value={customData?.section_name} onChange={(e) => setCustomData({ ...customData, section_name: e.target.value })} />
                                    {
                                        customDataErr && customData?.section_name === "" && <p className='text-xs text-red-500'>Please enter title</p>
                                    }
                                </div>
                                <div className='mb-4'>
                                    <label htmlFor='image'>
                                        URL
                                    </label>
                                    <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${customDataErr && customData?.section_link === "" ? 'border-red-500' : ''}`} placeholder='e.g. https://google.com' value={customData?.section_link} onChange={(e) => setCustomData({ ...customData, section_link: e.target.value })} />
                                    {
                                        customDataErr && customData?.section_link === "" && <p className='text-xs text-red-500'>Please enter url</p>
                                    }
                                </div>
                                <div className='mb-4'>
                                    <label htmlFor='image'>
                                        redirect type
                                    </label>
                                    <div className='relative'>
                                        <select value={customData?.redirect_type} onChange={(e) => setCustomData({ ...customData, redirect_type: e.target.value })} className='w-full border border-gray-300'>
                                            <option value="new">New tab</option>
                                            <option value="current">Current tab</option>
                                        </select>
                                        <div className='select-arrow'></div>
                                    </div>
                                </div>
                                <button onClick={(e) => handleAddCustomData(e)} className='secondary-btn text-sm inline-block'>Add custom data</button>
                                {
                                    storeCustomData?.map((elem) => {
                                        return (
                                            <div className='border border-gray-300 p-3 rounded mt-4'>
                                                <p className='text-sm'><span className='font-medium'>Title : </span><span>{elem?.section_name}</span></p>
                                                <p className='text-sm'><span className='font-medium'>URL : </span><span>{elem?.section_link}</span></p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </details>
                    </div>
                    <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[60%] mx-auto'>
                        <Link
                            to={`/dummy-data/module-setting`}
                            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
                        >
                            <ArrowLeft className='w-3 mr-1' />
                            Go to module setting
                        </Link>
                        <button onClick={(e) => handleAddSection(e)} className='btn text-sm text-white'>Add Footer Section</button>
                    </div>
                </form>
            </div>
          
        
        </>
    )
}

export default CreateDummyFooterGroup