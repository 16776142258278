export const ModuleData = [
    {
        name: "Header menu",
        discripion: "Customize website top with links, cart, and wishlist.",
        redirect: "/dummy-data/module-setting/header-menu"
    },
    {
        name: "Manufacturer list",
        discripion: "Manage information and listings for different product brands.",
        redirect: "/dummy-data/module-setting/manufacture-group"
    },
    {
        name: "Category list",
        discripion: "Organize your product assortment for easy navigation and browsing.",
        redirect: "/dummy-data/module-setting/category-group"
    },
    {
        name: "Product list",
        discripion: "Configure product display and reviews.",
        redirect: "/dummy-data/module-setting/product-group"
    },
    {
        name: "Image slider",
        discripion: "Showcase featured products, promotions, or brand messages.",
        redirect: "/dummy-data/module-setting/slider-group"
    },
    {
        name: "Blog list",
        discripion: "Manage website content creation with articles, categories, and SEO.",
        redirect: "/dummy-data/module-setting/blog-group"
    },
    {
        name: "Banners",
        discripion: "Display eye-catching promotional banners.",
        redirect: "/dummy-data/module-setting/banner-group"
    },
    {
        name: "Reassurance",
        discripion: "Build trust with testimonials, guarantees, and security seals.",
        redirect: "/dummy-data/module-setting/reassurance-group"
    },
    {
        name: "CMS discount",
        discripion: "Manage promotions and targeted messages.",
        redirect: "/dummy-data/module-setting/cms-offer"
    },
    {
        name: "Footer",
        discripion: "Customize website bottom with links, social media, and copyright info.",
        redirect: "/dummy-data/module-setting/footer"
    },
    {
        name: "Common setting",
        discripion: "Global site configuration like buttons, text colors, background colors etc.",
        redirect: "/dummy-data/module-setting/common-setting"
    }
]