import { LOGIN } from "../action.type";

const initialState={
    token: null,
}                                 

export const authReducer=(state=initialState, action)=> {
    switch (action.type) {
      case LOGIN:
        return {
            ...state,
            token: action.payload?.data?.token,
        };
      
      default:
        return state;
    }
  }
