export const GenerateSlugName = async (name) => {
  const normalizedName = name
    ?.trim()
    ?.toLowerCase()
    .replace(/\s+/g, '-') // Replace consecutive spaces with a single hyphen
    .replace(/[^a-zA-Z0-9-]/g, '') // Remove special characters (except '-')
    .replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen

  return normalizedName;
};
